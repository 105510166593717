import React, { memo } from 'react';

import { Handle, Position } from 'react-flow-renderer';


import {  
  Image, Stack, Text, Icon,
} from '@fluentui/react';

import { Validate } from '../Validate';
import { eExecutionType } from '../IDeus';

export default memo(({ data }) => {

  var val = new Validate(data.rule);
  var err = <></>;
  if(!val.isValid)
  {
    err = <><Text className='node-error'>SYNTAX ERRORS</Text></>;
  }

  var input = <></>;
  if(!data.root)
  {
    input = <Handle
              type="target"
              position={Position.Top}
              className="node-input"
              style={{ background: "rgb(0,192,255)", height: 10, width: 10  }}
              onConnect={(params) => console.log('handle onConnect', params)}
            />
  }

  var delay = <></>;
  if(data.rule.executionType === eExecutionType.Delay && (data.rule.secondsDelay ?? 0) > 0)
  {
    delay = <Icon iconName="Clock" style={{paddingRight: 5}} />;
  }

  return (
    <div className={data.selected ? "node-selected" : "node-container"}>
      {input}
      <Stack horizontal>
        <Stack.Item grow={3} align="stretch" horizontal className="node-type">
          {delay} Write Log Entry
        </Stack.Item>
        <Stack.Item grow={1} horizontalAlign="end">
          {(data.breakpoint ? <Icon iconName="CircleStopSolid" style={{paddingRight: 5, color: "red"}} /> : <></>)}
        </Stack.Item>
      </Stack>
      <Stack horizontal className="node-header-external" tokens={{childrenGap: 10}}>
        <Image src="/icons/operations/log.svg" width={20} height={20} />
        <strong>{data.rule.name}</strong>
      </Stack>
      <Stack>
          <Text className="node-parameters-header">Title</Text>
          <Text className="node-parameters">{data.rule.opParameters["title"]}</Text>
          {err}
        </Stack>
      <Handle
        type="source"
        position={Position.Bottom}
        id="OnTrue"
        className="node-output"
        style={{ right: 10, background: '#00cc66', height: 10, width: 10  }}
      />
      <Handle
        type="source"
        position={Position.Right}
        id="OnFail"
        className="node-output-fail"
      />
    </div>
  );
});
