import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  ITemplate, IPagination,  libraryClient,  } from './IDeus';

import {  Post, Put, Delete } from './authorisation/AMAPI';

import {  
    Stack, Text,
    IconButton, PrimaryButton, DefaultButton, 
    DetailsList, IColumn, SelectionMode, 
    TextField, 
    Dialog, DialogFooter, 
    DialogType, IDetailsListProps, IDetailsRowStyles, DetailsRow, Modal, Panel, PanelType, 
} from '@fluentui/react';
import { Separator } from '@fluentui/react';

import TemplateProperties from './TemplateProperties' 
import TemplateFinder from './TemplateFinder' 


type IState = {
    templates: ITemplate[],
    selectedTemplate: ITemplate,

    pagination: IPagination,
    showDialog: boolean,
    doDelete: boolean,
    doImport: boolean,
    doCopy: boolean,
    showCalled: boolean,

    actionID: string,

    copyName: string,
    importTemplate?: ITemplate,

    propsPanelOpen: boolean,
    propsPanelTitle: string,
}
  
  
interface IProps extends WrappedComponentProps {
  client: string
  folder: string
  history?: any
}


class Templates extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            templates: [],
            selectedTemplate: {} as ITemplate,

            pagination: {
                totalCount: 0,
                current: 0,
                pageSize: 20,
                sortField: "",
                sortOrder: "asc"
            },

            showDialog: false,
            doDelete: false,
            doImport: false,
            doCopy: false,

            showCalled: false,

            actionID: "",

            copyName: "",

            propsPanelOpen: false,
            propsPanelTitle: "",

        };

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.createTemplate = this.createTemplate.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCopy = this.onCopy.bind(this);
        this.onImport = this.onImport.bind(this);
        this.onSave = this.onSave.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
    }

    componentDidUpdate(prevProps: IProps) {
      if (prevProps.client !== this.props.client || prevProps.folder !== this.props.folder) {
          this.authenticatedrefresh();
      }
    }

// picker    
  handleFieldChange(event: any){
    const target = event.target;
    const value : string = target.type === 'checkbox' ? target.checked : target.value;
    const name : string = target.name;


    switch(name)
    {
        case "copyName":
            this.setState({copyName: value});
            break;
    }
  }

  handleChange(event: any){
      const target = event.target;
      const value : string = target.type === 'checkbox' ? target.checked : target.value;
      const name : string = target.name;

      var r = JSON.parse(JSON.stringify(this.state.selectedTemplate));
      switch(name)
      {
          case "name":
              r.name = value;
              break;
          case "description":
              r.description = value;
              break;
          case "opParameter":
              r.opParameter = value;
              break;
      }
      this.setState({selectedTemplate: r});
    }

    componentDidMount()
    {
      this.refresh();
    }

    createTemplate()
    {
        this.setState({
            selectedTemplate: {
                id: "",
                clientID: this.props.client,
                name: "New Template",
                description: "",
                folderID: this.props.folder,
                template: ""
            },
            propsPanelTitle: "Create Template",
            propsPanelOpen: true

        });
    }
  
    refresh()
    {
        this.authenticatedrefresh();
    }


   
    onPageChange(page: number)
    {
      var p: IPagination = Object.assign(this.state.pagination);
      p.current = page;
      this.setState({pagination: p});
      this.authenticatedrefresh();
    }


    private _onColumnClick = (ev: any, column: IColumn): void => {
    };      

   
    private authenticatedrefresh()
    {
      const params = this.state.pagination;

      const paging = {
        current: params.current + 1,
        pagesize: params.pageSize,
        sortfield: params.sortField,
        sortorder: params.sortOrder,
      }

      const post = {
        paging: paging,
        currentFolder: this.props.folder,
        currentClient: this.props.client
      }

      Post("template/Search",post)
        .then(response => {
              var p = this.state.pagination;
              p.totalCount = response.data.totalCount;   
              this.setState({ 
                templates: response.data.results, 
                pagination: p, 
              });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    onSave(sched: ITemplate)
    {
        if(sched.id === "")
        {
            Post("template",sched)
            .then(response => {
                sched.id = response.data;
                  this.setState({ 
                    selectedTemplate: sched, 
                    propsPanelOpen: false,
                  });
                  this.authenticatedrefresh();
                })
            .catch(function (error) {
              console.log(error);
            });
        }
        else
        {
            Put("template/" + sched.id,sched)
            .then(response => {
              this.authenticatedrefresh();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
    }


    private _onItemInvoked = (item: ITemplate): void => {
        this.setState({selectedTemplate: item, propsPanelTitle: "Edit Template", propsPanelOpen: true});
    };

    public onDelete()
      {
        Delete("template/" + this.state.actionID)
        .then(response => {
          this.setState({doDelete: false});
          this.authenticatedrefresh();
        })
        .catch(function (error) {
          console.log(error);
        });
    }


      public onCopy()
      {
        const post = {
          id: this.state.actionID,
          newname: this.state.copyName,
        }
  
        Post("template/Copy",post)
          .then(response => {
            this.setState({doCopy: false});
            this.authenticatedrefresh();
            })
          .catch(function (error) {
            console.log(error);
          });
      }

      public onImport(template?: ITemplate)
      {
        if(template==null)
        {
          return;
        }
        var folder = this.props.folder;
        if(folder==="Uncategorized")  folder="";
        const post = {
          id: template.id,
          newname: template.name,
          clientid: this.props.client,
          folderid: folder,
        }
  
        Post("template/import",post)
          .then(response => {
            this.setState({doImport: false, importTemplate: undefined});
            this.authenticatedrefresh();
            })
          .catch(function (error) {
            console.log(error);
          });
      }

    

      render() 
    {

        var props = <></>;

        if(this.state.selectedTemplate)
        {
            props = <TemplateProperties client={this.props.client} template={this.state.selectedTemplate} onChange={this.onSave} onMoveFolder={this.onSave}  />
        }


        const columns: IColumn[] = [
          {
              key: 'name',
              name: this.props.intl.formatMessage({ id: 'schedule.name' }),
              fieldName: 'name',
              minWidth: 200,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'desc',
              name: this.props.intl.formatMessage({ id: 'connection.description' }),
              fieldName: 'description',
              minWidth: 300,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              isSortedDescending: false,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'lang',
              name: "Language",
              fieldName: 'templateLanguage',
              minWidth: 200,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'rulesetedit',
              name: 'RuleSet',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               return <IconButton iconProps={{ iconName: 'Edit' }} onClick={()=>this.setState({selectedTemplate: item})} title="Rules" ariaLabel="Rules"  />
              },
            },
            {
              key: 'rulecopy',
              name: 'Duplicate',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               return <IconButton iconProps={{ iconName: 'copy' }} onClick={()=>this.setState({doCopy: true, copyName: "", actionID: item.id})} title="Copy" ariaLabel="Rules"  />
              },
            },
            {
              key: 'delete',
              name: 'Delete',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.setState({doDelete: true, actionID: item.id})} title="Delete" ariaLabel="Delete"  />
              },
            },
  
        ];

        return (
          <>
            <Stack horizontal grow verticalFill>
              <Stack grow={2} style={{paddingTop: 5}} >
                <Stack horizontal tokens={{childrenGap: 5}}>
                  <PrimaryButton text="Create new template" iconProps={{ iconName: 'PageAdd' }} onClick={()=>this.createTemplate()} width={300} disabled={!this.props.client}/>
                  <DefaultButton text="Import" iconProps={{ iconName: 'Import' }} onClick={()=>this.setState({doImport: true})} width={300} disabled={!this.props.client}/>
                </Stack>
                  <DetailsList  items={this.state.templates} 
                              columns={columns} 
                              onItemInvoked={this._onItemInvoked}
                              onRenderRow={this._onRenderRow}
                              selectionMode={SelectionMode.none}
                  />
                </Stack>
                <Panel
                  headerText={this.state.propsPanelTitle}
                  type={PanelType.medium}
                  isOpen={this.state.propsPanelOpen}
                  onDismiss={()=>{ this.setState({propsPanelOpen: false})}}
                >
                    {props}
                    </Panel>
            </Stack>
            <Dialog
              hidden={!this.state.doDelete}
              onDismiss={()=>{this.setState({doDelete: false})}}
              dialogContentProps={{
                  type: DialogType.normal,
                  title: 'Delete Template',
                  closeButtonAriaLabel: 'Close',
                  subText: 'Are you sure you want to delete this template.',
                }}
            >
            <DialogFooter>
              <PrimaryButton onClick={()=>{this.onDelete()}} text="Delete" />
              <DefaultButton onClick={()=>{this.setState({doDelete: false})}} text="Cancel" />
            </DialogFooter>
            </Dialog>
            <Dialog
              hidden={!this.state.doCopy}
              onDismiss={()=>{this.setState({doCopy: false})}}
              dialogContentProps={{
                  type: DialogType.normal,
                  title: 'Copy Template',
                  closeButtonAriaLabel: 'Close',
                  subText: 'Please provide a name for the new template.',
                }}
            >
                <TextField label={this.props.intl.formatMessage({ id: 'schedule.name' })} value={this.state.copyName}  name="copyName" onChange={this.handleFieldChange}/>
              
            <DialogFooter>
              <PrimaryButton onClick={()=>{this.onCopy()}} text="Copy" />
              <DefaultButton onClick={()=>{this.setState({doCopy: false})}} text="Cancel" />
            </DialogFooter>
            </Dialog>
            <Modal
        isOpen={this.state.doImport}
        onDismiss={()=>{this.setState({doImport: false})}}
        isBlocking={false}
      >
        <Stack style={{margin: 10}}>
          <Text variant="xLarge">Import Rule Set</Text>
        <TemplateFinder client={libraryClient} templateSelected={(sched: ITemplate) => {this.setState({importTemplate: sched})}}/>
        <Separator/>
            <Stack  tokens={{childrenGap: 10}} >
              <Stack.Item  align="end">
                <Stack horizontal tokens={{childrenGap: 10}}>
              <PrimaryButton text="Import" disabled={this.state.importTemplate == null} onClick={()=>{
                this.onImport(this.state.importTemplate)
              }} />
              <DefaultButton text="Cancel" onClick={()=>{this.setState({doImport: false, importTemplate: undefined})}} />
              </Stack>
              </Stack.Item>
            </Stack>
        </Stack>
        </Modal>
        </>        );
    }

    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
      const customStyles: Partial<IDetailsRowStyles> = {};
      if (props) {
        if(this.state.selectedTemplate){
          if (props.item.id  === this.state.selectedTemplate.id) {
            // Every other row renders with a different background color
            customStyles.root = { backgroundColor: "#785a00" };
          }
    
          }
          return <DetailsRow {...props} styles={customStyles} />;
      }
      return null;
    };
}

export default injectIntl(Templates);