import React, { memo } from 'react';

import { Handle, Position } from 'react-flow-renderer';


import {  
  Image, Stack, Icon,
} from '@fluentui/react';

import { eExecutionType } from '../IDeus';

export default memo(({ data }) => {
  var input = <></>;
  if(!data.root)
  {
    input = <Handle
              type="target"
              position={Position.Top}
              className="node-input"
              onConnect={(params) => console.log('handle onConnect', params)}
            />
  }

  var delay = <></>;
  if(data.rule.executionType === eExecutionType.Delay && (data.rule.secondsDelay ?? 0) > 0)
  {
    delay = <Icon iconName="Clock" style={{paddingRight: 5}} />;
  }

  return (
    <div className={data.selected ? "node-selected" : "node-container"}>
      {input}
      <Stack horizontal className="node-type">
        {delay} Directory Search
      </Stack>
      <Stack horizontal className="node-header-file" tokens={{childrenGap: 10}}>
        <Image src="/icons/operations/directoryread.svg" width={20} height={20} />
        <strong>{data.rule.name}</strong>
      </Stack>
      <Handle
        type="source"
        position={Position.Bottom}
        id="OnTrue"
        className="node-output"
      />
      <Handle
        type="source"
        position={Position.Right}
        id="OnFail"
        className="node-output-fail"
      />
    </div>
  );
});
