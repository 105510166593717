import 'rc-time-picker/assets/index.css';

import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { 
         IApiSpecification,
         IFolderSet,
         IFolder
         } from './IDeus';

import {  
    Stack, 
    TextField, 
    PrimaryButton,
    IconButton,
    Label, 
    Text,
} from '@fluentui/react';


import { Get, Post } from './authorisation/AMAPI';
import { API } from './IDeusEndpoint';


type IState = {
    apiSpecification: IApiSpecification
    shouldSave: boolean
}
  
  
interface IProps extends WrappedComponentProps {
    folder: string,
    client: string,
}



class OpenAPIProperties extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            apiSpecification: {} as IApiSpecification,
            shouldSave: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentDidMount() {
        this.getFolder();
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.folder !== this.props.folder || prevProps.client !== this.props.client) {
            this.getFolder();
        }
    }

    
    getFolder(): void
    {
        Get("folders/"+this.props.client)
          .then(response => {
            console.log(response.data);
            var set = response.data as IFolderSet;
            var folder = this.findFolder(set.folders);
            console.log("folder",folder);
            if(folder !== undefined)
            {
                this.setState({apiSpecification: folder.apiSpecification});
            }
            })
          .catch(function (error) {
            console.log(error);
          });
    }

    findFolder(folders: IFolder[]): IFolder | undefined
    {
        for(var i = 0; i < folders.length; i++)
        {
            if(folders[i].id === this.props.folder)
            {
                return folders[i];
            }
            var ret = this.findFolder(folders[i].children);
            if(ret !== undefined)
            {
                return ret;
            }
        }

        return undefined;
    }



    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var apispec : IApiSpecification = {...this.state.apiSpecification};
        switch(name)
        {
            case "title":
                apispec.title = value;
                break;
            case "description":
                apispec.description = value;
                break;
            case "version":
                apispec.version = value;
                break;
        }
        this.setState({apiSpecification: apispec, shouldSave: true});
    }

    onError(error: any) {
        console.error(error);
      }

   

    onSave()
    {
        var s = this.state.apiSpecification;
        Post(`folders/SetAPISettings/${this.props.client}/${this.props.folder}`,s)
        .then(response => {
        })
        .catch(function (error) {
          console.log(error);
        });

        this.setState({ shouldSave: false });
    }


    render() 
    {
       
          
        if(this.state.apiSpecification == null) return <>Select a folder...</>;

        var url = API + "/OpenAPI/" + this.props.client + "/" + this.props.folder;

     

        return (
            <>
            <Stack grow verticalFill style={{padding: 10, borderLeft: "1px solid grey", width: 500}} >
                <Stack horizontal tokens={{ childrenGap: 5}} >
                    <PrimaryButton iconProps={{ iconName: "Save" }}  text={this.props.intl.formatMessage({ id: 'std.save' })} disabled={!this.state.shouldSave} onClick={()=>{this.onSave();}}/>
                </Stack>
                <TextField label="Version" value={this.state.apiSpecification.version}  name="version" onChange={this.handleChange}/>
                <TextField label="Title" value={this.state.apiSpecification.title}  name="title" onChange={this.handleChange}/>
                <TextField label="Description" multiline rows={6} value={this.state.apiSpecification.description}  name="description" onChange={this.handleChange} />
                <Stack horizontal tokens={{ childrenGap: 5}} >
                    <IconButton iconProps={{iconName: "Copy"}} onClick={() => {navigator.clipboard.writeText(url)}}/>
                    <Label>URL</Label>
                    <Text block nowrap>{url}</Text>
                </Stack>
            </Stack>
            </>
        );
    }
}

export default injectIntl(OpenAPIProperties);