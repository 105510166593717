import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';



import {  
    Stack, Text,  Separator,
} from '@fluentui/react';


type IState = {
}
  
  
interface IProps extends WrappedComponentProps {
}

class REST extends Component<IProps,IState> {
    render() 
    {
        return (
            <Stack grow verticalFill >
                <Text variant='xLarge'>REST Servicd</Text>
                Call a publicly accessible Web API.<br/>
                Format of the command:<br/>
                Start your path with $ which represents the root and pt each expression n its own line.<br/>
                <Text variant='large'>Format:</Text>
                <Separator />
                <Text variant='large'>Left  Node</Text>
                Output from the API. If the Output Element is an array, each element will be output as a separate data object.
                <Text variant='large'>Right  Node</Text>
                Output the full returned data object, ignoring the output element parameter.
            </Stack>
        )
    }
}
export default injectIntl(REST);
