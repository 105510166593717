import 'rc-time-picker/assets/index.css';

import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  IDataDefinition } from './IDeus';

import {  
    Stack, 
    TextField, 
    PrimaryButton, 
    Separator,
    Dialog,
    DialogFooter,
    DefaultButton,
    DialogType,
} from '@fluentui/react';


import Folders from './Folders';


type IState = {
    dataDefinition: IDataDefinition
    shouldSave: boolean
    editIP: boolean
    doMoveFolder: boolean
    destinationFolder: string
}
  
  
interface IProps extends WrappedComponentProps {
    dataDefinition: IDataDefinition,
    client: string,
    onChange?: any,
    onMoveFolder?: any,
}


class DataDefinitionProperties extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            dataDefinition: this.props.dataDefinition,
            shouldSave: false,
            editIP: false,
            doMoveFolder: false,
            destinationFolder: ""
        };

        this.handleChange = this.handleChange.bind(this);

        this.onSave = this.onSave.bind(this);
        this.onMoveFolder = this.onMoveFolder.bind(this);
        this.onSave = this.onSave.bind(this);
    }
    


    componentDidUpdate(prevProps: IProps) {
        if (prevProps.dataDefinition !== this.props.dataDefinition) {
    
            this.setState({
                dataDefinition: this.props.dataDefinition,
                shouldSave: false
            });
          }
      }

      
    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var ddl : IDataDefinition = {...this.state.dataDefinition};
        switch(name)
        {
            case "name":
                ddl.name = value;
                break;
            case "description":
                ddl.description = value;
                break;
        }
        this.setState({dataDefinition: ddl, shouldSave: true});
    }

   
    
    onMoveFolder()
    {
        var ddl : IDataDefinition = {...this.state.dataDefinition};
        ddl.folderID = this.state.destinationFolder;
        this.setState({dataDefinition: ddl, shouldSave: true});
        if(this.props.onMoveFolder != null)
        {
            this.props.onMoveFolder(ddl);
        }        
    }

    onSave()
    {
        var s = this.state.dataDefinition;

        if(this.props.onChange != null)
        {
            this.props.onChange(s);
        }        
        this.setState({ shouldSave: false });
    }

  
    

    render() 
    {
        if(this.state.dataDefinition == null) return <>Select a data definition from the list...</>;
        if(this.state.dataDefinition.id == null) return <>Select a data definition from the list...</>;
        



        
        return (
            <>
            <Stack grow verticalFill style={{padding: 10}}>
                <Stack horizontal tokens={{ childrenGap: 5}} >
                    <PrimaryButton iconProps={{ iconName: "Save" }}  text={this.props.intl.formatMessage({ id: 'std.save' })} disabled={!this.state.shouldSave} onClick={()=>{this.onSave();}}/>
                    <DefaultButton iconProps={{ iconName: "FabricMovetoFolder" }} text="Move To Folder" onClick={()=>{this.setState({doMoveFolder: true});}}/>
                </Stack>
                <TextField label={this.props.intl.formatMessage({ id: 'schedule.name' })} value={this.state.dataDefinition.name}  name="name" onChange={this.handleChange}/>
                <TextField label={this.props.intl.formatMessage({ id: 'schedule.description' })} multiline value={this.state.dataDefinition.description}  name="description" onChange={this.handleChange}/>
                <Separator />
            </Stack>
            <Dialog
                 hidden={!this.state.doMoveFolder}
                 onDismiss={()=>{this.setState({doMoveFolder: false})}}
                 dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Move Folder',
                    closeButtonAriaLabel: 'Close',
                    subText: 'Choose which folder you want to move this data definition to',
                  }}
               >
                    <Folders clientid={this.props.client} onFolderSelected={(folder) => this.setState({destinationFolder: folder})}  />

                 <DialogFooter>
                   <PrimaryButton onClick={()=>{this.onMoveFolder()}} text="Move" />
                   <DefaultButton onClick={()=>{this.setState({doMoveFolder: false})}} text="Cancel" />
                 </DialogFooter>
               </Dialog>            </>
        );
    }
}

export default injectIntl(DataDefinitionProperties);