import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { IAuthConnection, IPagination,  } from './IDeus';


import { Delete,  Post, Put } from './authorisation/AMAPI';


import {  
    Stack, Image,
    IconButton, PrimaryButton, 
    DetailsList, IColumn, SelectionMode, 
    DialogType, Dialog, DialogFooter, DefaultButton, Panel, PanelType,
} from '@fluentui/react';
import { Separator } from '@fluentui/react';

import AuthorizerProperties from './AuthorizerProperties' 





type IState = {
    authorizers: IAuthConnection[],
    selectedAuthorizer: IAuthConnection,

    pagination: IPagination,
    showDialog: boolean,
    doDelete: boolean,

    propsPanelOpen: boolean,
    propsPanelTitle: string,
}
  
  
interface IProps extends WrappedComponentProps {
  client: string
  folder: string
}


class Authorizers extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            authorizers: [],
            selectedAuthorizer: {} as IAuthConnection,

            pagination: {
                totalCount: 0,
                current: 0,
                pageSize: 10,
                sortField: "",
                sortOrder: "asc"
            },

            showDialog: false,
            doDelete: false,

            propsPanelOpen: false,
            propsPanelTitle: "",

        };

        this.handleChange = this.handleChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.createAuthorizer = this.createAuthorizer.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onSave = this.onSave.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
    }

   
// picker    

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var r = JSON.parse(JSON.stringify(this.state.selectedAuthorizer));
        switch(name)
        {
            case "name":
                r.name = value;
                break;
            case "description":
                r.description = value;
                break;
            case "opParameter":
                r.opParameter = value;
                break;
        }
        this.setState({selectedAuthorizer: r});
    }

    componentDidMount()
    {
      this.refresh();
    }

    componentDidUpdate(prevProps: IProps) {
      if (prevProps.client !== this.props.client || prevProps.folder !== this.props.folder) {
        this.authenticatedrefresh();
          }
        }
    createAuthorizer()
    {
        this.setState({
            selectedAuthorizer: {
                id: "",
                clientID: this.props.client,
                folderID: this.props.folder,
                name: "New Authorizer",
                description: "",

                className: "",
                headers: {},
                parameters: {}
            },
            propsPanelOpen: true, propsPanelTitle: "Edit Authorizer"
        });
    }
  
    refresh()
    {
        this.authenticatedrefresh();
    }


    showDialog(context: string, item: any)
    {
       this.setState({showDialog: true, })
    }


    onPageChange(page: number)
    {
      var p: IPagination = Object.assign(this.state.pagination);
      p.current = page;
      this.setState({pagination: p});
      this.authenticatedrefresh();
    }


    private _onColumnClick = (ev: any, column: IColumn): void => {
    };      

    private authenticatedrefresh()
    {
      const params = this.state.pagination;

      const paging = {
        current: params.current + 1,
        pagesize: params.pageSize,
        sortfield: params.sortField,
        sortorder: params.sortOrder,
      }

      const post = {
        paging: paging,
        currentClient: this.props.client,
        currentFolder: this.props.folder,
      }

      Post("authorizers/Search",post)
        .then(response => {
              var p = this.state.pagination;
              p.totalCount = response.data.totalCount;   
              this.setState({ 
                authorizers: response.data.results, 
                pagination: p, 
              });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    onSave(conn: IAuthConnection)
    {
        console.log("Connections:onSave");
        if(conn.id === "")
        {
            Post("authorizers",conn)
            .then(response => {
              this.authenticatedrefresh();
              conn.id = response.data;
              this.setState({ 
                selectedAuthorizer: conn, 
                propsPanelOpen: false,
              });
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        else
        {
            Put("authorizers/" + conn.id,conn)
            .then(response => {
              this.setState({ 
                propsPanelOpen: false,
              });
              this.authenticatedrefresh();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
    }


    private _onItemInvoked = (item: IAuthConnection): void => {
        this.setState({selectedAuthorizer: item, propsPanelOpen: true, propsPanelTitle: "Edit Authorizer"});
      };


      onDelete() {
        Delete("authorizers/" + this.state.selectedAuthorizer.id)
        .then(response => {
          this.setState({doDelete: false});
          this.authenticatedrefresh();
        })
        .catch(function (error) {
          console.log(error);
        });
      }

      
     
    render() 
    {

        var props = <></>;

        if(this.state.selectedAuthorizer)
        {
            props = <AuthorizerProperties client={this.props.client} connection={this.state.selectedAuthorizer} onChange={this.onSave}  />
        }


        const columns: IColumn[] = [
          {
            key: 'ruletype',
            name: '',
            ariaLabel: '',
            isRowHeader: true,
            isResizable: true,
            minWidth: 50,
            maxWidth: 50,
            data: 'string',
            onRender: (item:IAuthConnection) => {
              var i;
              switch(item.className)
              {
                  case "BasicAuthorizer":
                    i = <Image src={"/icons/operations/authbasic.svg"} width={24} height={24} className="list-icon" />
                    break;
                  case "AuthorisorOAuth":
                    i = <Image src={"/icons/operations/authoauth.svg"} width={24} height={24} className="list-icon" />
                    break;
                  case "AWS4Authorizer":
                    i = <Image src={"/icons/operations/authcognito.svg"} width={24} height={24} className="list-icon" />
                    break;
              }
              return <>{i}</>
            }
            },
            {
              key: 'name',
              name: this.props.intl.formatMessage({ id: 'connection.name' }),
              fieldName: 'name',
              minWidth: 200,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'desc',
              name: this.props.intl.formatMessage({ id: 'connection.description' }),
              fieldName: 'description',
              minWidth: 300,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              isSortedDescending: false,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'subtype',
              name: this.props.intl.formatMessage({ id: 'connection.type' }),
              fieldName: 'className',
              minWidth: 240,
              maxWidth: 320,
              currentWidth: 120,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
              onRender: (item: any) => {
                switch(item.className)
                {
                  case "BasicAuthorizer":
                    return <span>Basic Authorizer</span>
                  case "AWSAuthorisor":
                    return <span>AWS Cognito Authorizer</span>
                  case "AuthorisorOAuth":
                    return <span>OAuth2 Authorizer</span>
                  case "AmadeusAuthoriser":
                    return <span>Amadeus GDS Authorizer</span>                    
                }
               },
             },
            {
              key: 'type',
              name: 'type',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               // return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.deleteClient(item.id)} title="Delete" ariaLabel="Delete" disabled={!item.canDelete} />
               return <IconButton iconProps={{ iconName: 'Edit' }} onClick={()=>{this.setState({selectedAuthorizer: item, propsPanelTitle: "Edit Authorizer", propsPanelOpen: true})}}    />
              },
            },
            {
              key: 'delete',
              name: 'Delete',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               // return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.deleteClient(item.id)} title="Delete" ariaLabel="Delete" disabled={!item.canDelete} />
               return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>{this.setState({doDelete: true, selectedAuthorizer: item})}} title="Delete" ariaLabel="Delete"  />
              },
            },
  
        ];

        console.log(this.state.authorizers);
        return (<>
            <Stack horizontal grow verticalFill>
                <Stack grow={2} >
                  <Stack horizontal tokens={{childrenGap: 5}} style={{padding: 5}}>
                    <PrimaryButton text="Create new authorizer" onClick={()=>this.createAuthorizer()} width={300} disabled={!this.props.client}/>
                  </Stack>
                  <DetailsList  items={this.state.authorizers} 
                              columns={columns} 
                              onItemInvoked={this._onItemInvoked}
                              selectionMode={SelectionMode.none}
                  />
                </Stack>
                <Separator vertical />
                <Panel
                  headerText={this.state.propsPanelTitle}
                  type={PanelType.medium}
                  isOpen={this.state.propsPanelOpen}
                  onDismiss={()=>{ this.setState({propsPanelOpen: false})}}
                >
                    {props}
                </Panel>
            </Stack>
            <Dialog
                 hidden={!this.state.doDelete}
                 onDismiss={()=>{this.setState({doDelete: false})}}
                 dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Delete Authorizer',
                    closeButtonAriaLabel: 'Close',
                    subText: 'Are you sure you want to delete this Authorizer.',
                  }}
               >
                 <DialogFooter>
                   <PrimaryButton onClick={()=>{this.onDelete()}} text="Delete" />
                   <DefaultButton onClick={()=>{this.setState({doDelete: false})}} text="Cancel" />
                 </DialogFooter>
               </Dialog>            </>
        );
    }
}

export default injectIntl(Authorizers);