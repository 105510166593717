import React from 'react';
import { FormattedMessage } from 'react-intl';

import { NavLink as RouterNavLink } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Auth } from "aws-amplify";


import '@fortawesome/fontawesome-free/css/all.css';
import './App.css';
import { Dropdown, IDropdownOption, Image } from '@fluentui/react';
import { Get } from './authorisation/AMAPI';
import { IClient } from './IDeus';


interface IProps {
  onSetClient?: any,
  client: string
}

interface IState {
  isOpen: boolean
  clients: IClient[]
  client: string
}


// function UserAvatar(props: any) {
//   // If a user avatar is available, return an img tag with the pic
//   if (props.user.avatar) {
//     return <img
//       src={props.user.avatar} alt="user"
//       className="rounded-circle align-self-center mr-2"
//       style={{ width: '32px' }}></img>;
//   }

//   // No avatar available, return a default icon
//   return <i
//     className="far fa-user-circle fa-lg rounded-circle align-self-center mr-2"
//     style={{ width: '32px' }}></i>;
// }

// function AuthNavItem(props: any) {
//   // If authenticated, return a dropdown with the user's info and a
//   // sign out button
//   if (props.isAuthenticated) {
//     return (
//       <UncontrolledDropdown>
//         <DropdownToggle nav caret>
//           <UserAvatar user={props.user} />
//         </DropdownToggle>
//         <DropdownMenu right>
//           <h5 className="dropdown-item-text mb-0">{props.user.displayName}</h5>
//           <p className="dropdown-item-text text-muted mb-0">{props.user.email}</p>
//           <DropdownItem divider />
//           <DropdownItem onClick={props.authButtonMethod}>Sign Out</DropdownItem>
//         </DropdownMenu>
//       </UncontrolledDropdown>
//     );
//   }



  // // Not authenticated, return a sign in link
  // return (
  //   <NavItem>
  //   </NavItem>
  // );
//}

export default class NavBar extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      clients: [],
      client: this.props.client
    };
    this.refresh = this.refresh.bind(this);
    this.onClientChanged = this.onClientChanged.bind(this);
    this.authenticatedrefresh = this.authenticatedrefresh.bind(this);

  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  async signOut(e: any) {
    e.preventDefault();
    await Auth.signOut();
    window.location.reload();
  }


  componentDidMount()
  {
    this.refresh();
  }

  refresh()
  {
    this.authenticatedrefresh();
  }
  private authenticatedrefresh()
  {
    Get("client")
      .then(response => {
            this.setState({ 
              clients: response.data, 
            });
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  onClientChanged(ev: any,option?: IDropdownOption)
  {
      if(option === undefined)    return;
      this.setState({ client: option.key.toString() });
      if(this.props.onSetClient)
      {
        this.props.onSetClient(option.key.toString());
      }
  }

  render() {
    // Only show calendar nav item if logged in
    let adminmenu = null;

    if (true) {

      adminmenu = (

        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            <FormattedMessage id="menu.administration" />
          </DropdownToggle>
          <DropdownMenu right>
            <LinkContainer to="/Clients" exact>
              <DropdownItem>
                <FormattedMessage id="menu.clients" />
              </DropdownItem>
            </LinkContainer>
            <LinkContainer to="/Packages" exact>
              <DropdownItem>
                <FormattedMessage id="menu.packages" />
              </DropdownItem>
            </LinkContainer>
          </DropdownMenu>
        </UncontrolledDropdown>


      );
    }


    let mainmenu = (

      <UncontrolledDropdown nav inNavbar>
        <DropdownToggle nav caret>
          <FormattedMessage id="menu" />
        </DropdownToggle>
        <DropdownMenu right>
          <LinkContainer to="/Rulesets" exact>
            <DropdownItem>
              <FormattedMessage id="menu.schedules" />
            </DropdownItem>
          </LinkContainer>
          <LinkContainer to="/Connections" exact>
            <DropdownItem>
              <FormattedMessage id="menu.connections" />
            </DropdownItem>
          </LinkContainer>
          <LinkContainer to="/Authorizers" exact>
            <DropdownItem>
              <FormattedMessage id="menu.authorizers" />
            </DropdownItem>
          </LinkContainer>
          <LinkContainer to="/DDL" exact>
            <DropdownItem>
              <FormattedMessage id="menu.ddl" />
            </DropdownItem>
          </LinkContainer>
          <LinkContainer to="/Keys" exact>
            <DropdownItem>
              <FormattedMessage id="menu.keys" />
            </DropdownItem>
          </LinkContainer>
          <LinkContainer to="/Templates" exact>
            <DropdownItem>
              <FormattedMessage id="menu.templates" />
            </DropdownItem>
          </LinkContainer>
          <LinkContainer to="/PackageImport" exact>
            <DropdownItem>
              <FormattedMessage id="menu.package.import" />
            </DropdownItem>
          </LinkContainer>

        </DropdownMenu>
      </UncontrolledDropdown>


    );


    if(this.state.clients == null)
    {
      return <div>Loading...</div>
    }
    
    const clients: IDropdownOption[] = this.state.clients.map(client => {
      return { key: client.id, text: client.name + ((!client.active) ? " (Inactive)" : "") };
    });


    return (
      <div>
        <Navbar className='navbar-mine' color="dark" dark expand="md" fixed="top" style={{backgroundColor: "#2f4858 !important" }}>
          <Container fluid style={{backgroundColor: "#2f4858", padding: 0, margin: 0}}>
            <NavbarBrand href="/"><Image style={{height: 36}} src="/logo.png" /></NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="mr-auto" navbar>
                <NavItem>
                  <RouterNavLink to="/" className="nav-link" exact><FormattedMessage id="menu.home" /></RouterNavLink>
                </NavItem>
                {mainmenu}

              </Nav>
              <Nav className="justify-content-end" navbar>
                {adminmenu}
                <div className='client-label'> Client</div>
                <div>
                  <Dropdown options={clients} style={{width: 200}}
                                          selectedKey={this.state.client}
                                          onChange={this.onClientChanged}
                   />
                </div>
                <div className="LoginSignOut">
                  <button className="sign-out-button" onClick={this.signOut}> Sign out</button>
                </div>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </div>
    );
  }
}
