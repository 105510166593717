import 'rc-time-picker/assets/index.css';

import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { ISchedule, 
         repeatTypes, 
         eRepeatType, 
         repeatValueUnits, 
         scheduleTypes, 
         eScheduleType, 
         parameterTypes, 
         eParameterType, 
         IDataDefinition,
         rationalizeNumber
         } from './IDeus';

import {  
    Stack, 
    TextField, 
    DatePicker, DayOfWeek,
    Toggle,
    PrimaryButton, 
    Dropdown,
    IDropdownOption,
    IconButton,
    Dialog,
    DialogFooter,
    DefaultButton,
    DialogType,
    Pivot,
    PivotItem,
    Label,
    Text,
} from '@fluentui/react';

import moment, { Moment } from 'moment';

import TimePicker from 'rc-time-picker';
import Folders from './Folders';
import DDLFinder from './DDLFinder';
import { API } from './IDeusEndpoint';
import ScheduleFinder from './RulesetFinder';



type IState = {
    schedule: ISchedule
    shouldSave: boolean
    currDate: Date | null | undefined
    currTime: Moment
    editIP: boolean
    doMoveFolder: boolean
    doGetAuthorizer: boolean
    destinationFolder: string
    doSetDDL: boolean
}
  
interface IProps extends WrappedComponentProps {
    schedule: ISchedule,
    client: string,
    onChange?: any,
    onMoveFolder?: any,
}

class ScheduleProperties extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        var m : Moment = moment();
        var d : Date = new Date();
        if(this.props.schedule.executeAt != null)
        {
            d = new Date(this.props.schedule.executeAt);
        }
        m.hours(d.getHours());
        m.minutes(d.getMinutes());
        m.seconds(d.getSeconds());

        this.state = {
            schedule: this.props.schedule,
            shouldSave: false,
            currDate: d,
            currTime: m,
            editIP: false,
            doMoveFolder: false,
            doGetAuthorizer: false,
            destinationFolder: "",
            doSetDDL: false
            
        };

        this.handleChange = this.handleChange.bind(this);
        this.onToggleActive = this.onToggleActive.bind(this);
        this.onToggleLog = this.onToggleLog.bind(this);
        this.onToggleLogAll = this.onToggleLogAll.bind(this);
        this.onToggleLogExceptions = this.onToggleLogExceptions.bind(this);
        this.onToggleAPIKey = this.onToggleAPIKey.bind(this);
        this.onToggleAuthorizationHeader = this.onToggleAuthorizationHeader.bind(this);
        this.onToggleReturnAuthObject = this.onToggleReturnAuthObject.bind(this);
        this.onDateChange = this.onDateChange.bind(this);
        this.onTimeChange = this.onTimeChange.bind(this);
        this.onRepeatTypeChanged = this.onRepeatTypeChanged.bind(this);
        this.onParameterTypeChanged = this.onParameterTypeChanged.bind(this);
        this.onTypeChanged = this.onTypeChanged.bind(this);
        this.onRepeatValueUnitsChanged = this.onRepeatValueUnitsChanged.bind(this);

        this.onSave = this.onSave.bind(this);
        this.onMoveFolder = this.onMoveFolder.bind(this);
        this.onSave = this.onSave.bind(this);
    }


    componentDidUpdate(prevProps: IProps) {
        if (prevProps.schedule !== this.props.schedule) {
            var m : Moment = moment();
            var d : Date = new Date();
            if(this.props.schedule.executeAt != null)
            {
                d = new Date(this.props.schedule.executeAt);
            }
            m.hours(d.getHours());
            m.minutes(d.getMinutes());
            m.seconds(d.getSeconds());
    
            this.setState({
                schedule: this.props.schedule,
                currDate: d,
                currTime: m,
                shouldSave: false
            });
          }
      }

      onTypeChanged(ev: any,option?: IDropdownOption)
      {
          if(option === undefined)    return;
  
          var sched : ISchedule = {...this.state.schedule};
          switch(option.key)
          {
              case 0:   sched.type = eScheduleType.TimedSchedule; break;
              case 1:   sched.type = eScheduleType.APIEndpoint; break;
          }
          this.setState({schedule: sched, shouldSave: true });
      }

    onRepeatTypeChanged(ev: any,option?: IDropdownOption)
    {
        if(option === undefined)    return;

        var sched : ISchedule = {...this.state.schedule};
        switch(option.key)
        {
            case 0:   sched.repeatType = eRepeatType.None; break;
            case 1:   sched.repeatType = eRepeatType.Fixed; break;
            case 2:   sched.repeatType = eRepeatType.Relative; break;
            case 3:   sched.repeatType = eRepeatType.FirstDayOfMonth; break;
            case 4:   sched.repeatType = eRepeatType.FirstWorkingDayOfMonth; break;
            case 5:   sched.repeatType = eRepeatType.LastDayOfMonth; break;
            case 6:   sched.repeatType = eRepeatType.LastWorkingDayOfMonth; break;
        }
        this.setState({schedule: sched, shouldSave: true });
    }

    onParameterTypeChanged(ev: any,option?: IDropdownOption)
    {
        if(option === undefined)    return;

        var sched : ISchedule = {...this.state.schedule};
        switch(option.key)
        {
            case 0:   sched.parameterType = eParameterType.None; break;
            case 1:   sched.parameterType = eParameterType.Number; break;
            case 2:   sched.parameterType = eParameterType.Text; break;
            case 3:   sched.parameterType = eParameterType.Post; break;
            case 4:   sched.parameterType = eParameterType.File; break;
        }
        this.setState({schedule: sched, shouldSave: true });
    }


    onRepeatValueUnitsChanged(ev: any,option?: any)
    {
        var sched : ISchedule = {...this.state.schedule};
        sched.repeatValueUnits = option.key || 0;
        this.setState({schedule: sched, shouldSave: true });
    }

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var sched : ISchedule = JSON.parse(JSON.stringify(this.state.schedule));
        switch(name)
        {
            case "name":
                sched.name = value;
                break;
            case "apiKey":
                sched.apiKey = value;
                break;
            case "allowIP":
                sched.allowIP = value;
                break;
            case "description":
                sched.description = value;
                break;
            case "externalid":
                sched.externalID = value;
                break;
            case "repeatvalue":
                sched.repeatValue = Number.parseInt(value);
                break;
        }
        this.setState({schedule: sched, shouldSave: true});
    }

    private onToggleActive()
    {
        var sched: ISchedule = {...this.state.schedule};
        sched.active = !sched.active;
        this.setState({ schedule: sched, shouldSave: true });
    }
    private onToggleAPIKey()
    {
        var sched: ISchedule = {...this.state.schedule};
        sched.requireAPIKey = !(sched.requireAPIKey ?? false);
        this.setState({ schedule: sched, shouldSave: true });
    }
    private onToggleAuthorizationHeader()
    {
        var sched: ISchedule = {...this.state.schedule};
        sched.authorizationHeader = !(sched.authorizationHeader ?? false);
        this.setState({ schedule: sched, shouldSave: true });
    }
    private onToggleReturnAuthObject()
    {
        var sched: ISchedule = {...this.state.schedule};
        sched.returnAuthObject = !(sched.returnAuthObject ?? false);
        this.setState({ schedule: sched, shouldSave: true });
    }
    private onToggleLog()
    {
        var sched: ISchedule = {...this.state.schedule};
        sched.logExecution = !sched.logExecution;
        this.setState({ schedule: sched, shouldSave: true });
    }
    private onToggleLogAll()
    {
        var sched: ISchedule = {...this.state.schedule};
        sched.logAllSteps = !sched.logAllSteps;
        this.setState({ schedule: sched, shouldSave: true });
    }
    private onToggleLogExceptions()
    {
        var sched: ISchedule = {...this.state.schedule};
        sched.logExceptions = !(sched.logExceptions ?? false);
        this.setState({ schedule: sched, shouldSave: true });
    }

  

    onMoveFolder()
    {
        var sched : ISchedule = {...this.state.schedule};
        sched.folderID = this.state.destinationFolder;
        this.setState({schedule: sched, shouldSave: true});
        if(this.props.onMoveFolder != null)
        {
            this.props.onMoveFolder(sched);
        }        
    }

    onSave()
    {
        var s = this.state.schedule;
        var d = this.state.currDate || new Date();
        var t = this.state.currTime;

     
        d.setHours(t.hour());
        d.setMinutes(t.minutes());
        d.setSeconds(t.seconds());

        s.executeAt = d.toISOString();

        if(this.props.onChange != null)
        {
            this.props.onChange(s);
        }        
        this.setState({ shouldSave: false });
    }

    onTimeChange(value: Moment) {
        this.setState({ currTime: value, shouldSave: true})
    }
      
    onDateChange(value: Date | null | undefined) {
        this.setState({ currDate: value, shouldSave: true})
    }

  
    render() 
    {
        if(this.state.schedule == null) return <>Select a rule set from the list...</>;
        if(this.state.schedule.id == null) return <>Select a rule set from the list...</>;

        var bytesin = rationalizeNumber(this.state.schedule.externalBytesReceived);
        var bytesout = rationalizeNumber(this.state.schedule.externalBytesSent);

        var repeatval = <></>;
        switch(this.state.schedule.repeatType)
        {
            case eRepeatType.Fixed:
            case eRepeatType.Relative:
                repeatval = 
                    <Stack>
                        <TextField label={this.props.intl.formatMessage({ id: 'schedule.repeat.value' })} value={this.state.schedule.repeatValue.toString()}  name="repeatvalue" onChange={this.handleChange}/>
                        <Dropdown
                            placeholder="Select..."
                            options={repeatValueUnits}
                            selectedKey={this.state.schedule.repeatValueUnits}
                            onChange={this.onRepeatValueUnitsChanged}
                        />
                        
                    </Stack>

        } 

        var param = <></>;
        var security = <></>;
        if(this.state.schedule.type === eScheduleType.TimedSchedule)
        {
            param = 
            <>                
                <DatePicker onSelectDate={this.onDateChange} firstDayOfWeek={DayOfWeek.Sunday} value={this.state.currDate || new Date()} placeholder="Select date..." ariaLabel="Select date" />
                <TimePicker
                    showSecond
                    value={this.state.currTime}
                    className="xxx"
                    onChange={this.onTimeChange}
                />
                <Dropdown
                        placeholder="Select..."
                        label="Repeat"
                        options={repeatTypes}
                        selectedKey={this.state.schedule.repeatType}
                        onChange={this.onRepeatTypeChanged}
                        />
                {repeatval}
            </>
        }
        else if(this.state.schedule.type === eScheduleType.APIEndpoint)
        {
            var url = "";
            var restAction = "GET";
            switch(this.state.schedule.parameterType)
            {
                case eParameterType.None:
                    url = API + "/Exec/Call/" + this.props.client + "/" + this.state.schedule.externalID;
                    break;
                case eParameterType.Number:
                    url = API + "/Exec/Call9/" + this.props.client + "/" + this.state.schedule.externalID + "/{parameter}";
                    break;
                case eParameterType.Text:
                    url = API + "/Exec/CallX/" + this.props.client + "/" + this.state.schedule.externalID + "/{parameter}";
                    break;
                case eParameterType.Post:
                    url = API + "/Exec/Call/" + this.props.client + "/" + this.state.schedule.externalID;
                    restAction = "POST";
                    break;
                case eParameterType.File:
                    url = API + "/Exec/File/" + this.props.client + "/" + this.state.schedule.externalID;
                    restAction = "POST";
                    break;
                case eParameterType.Put:
                    url = API + "/Exec/File/" + this.props.client + "/" + this.state.schedule.externalID;
                    restAction = "PUT";
                    break;
                case eParameterType.Delete:
                    url = API + "/Exec/File/" + this.props.client + "/" + this.state.schedule.externalID + "/{parameter}";
                    restAction = "DELETE";
                    break;
            }
            param = 
            <>                
                <Dropdown
                        placeholder="Select..."
                        label="Parameter Type"
                        options={parameterTypes}
                        selectedKey={this.state.schedule.parameterType}
                        onChange={this.onParameterTypeChanged}
                    />
                <Label>{restAction}</Label>
                <Stack horizontal tokens={{ childrenGap: 5}} >
                    <IconButton iconProps={{iconName: "Copy"}} onClick={() => {navigator.clipboard.writeText(url)}}/>
                    <Label>URL</Label>
                    <Text block nowrap>{url}</Text>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 5}} >
                    <Label>Validate</Label>
                    <TextField readOnly value={this.state.schedule.parameterDDLName} /> 
                    <IconButton iconProps={{iconName: "Search"}} 
                        onClick={() => {
                            this.setState({doSetDDL: true});
                        }}/>
                    <IconButton iconProps={{iconName: "EraseTool"}} 
                        onClick={() => {
                            var sched: ISchedule = JSON.parse(JSON.stringify(this.state.schedule));
                            sched.parameterDDLID = "";
                            sched.parameterDDLName = "";
                            this.setState({ schedule: sched, shouldSave: true, doSetDDL: false });
                        }}/>
                </Stack>
            </>
            security = <PivotItem headerText="Security">
                <Toggle label={this.props.intl.formatMessage({ id: 'schedule.requirekey' })} inlineLabel onText="On" offText="Off" checked={this.state.schedule.requireAPIKey ?? false} onChange={()=>{this.onToggleAPIKey();}} />
                <TextField label={this.props.intl.formatMessage({ id: 'schedule.apikey' })} disabled={!(this.state.schedule.requireAPIKey ?? false)} value={this.state.schedule.apiKey ?? ""}  name="apiKey" onChange={this.handleChange}/>

                <Toggle label={this.props.intl.formatMessage({ id: 'schedule.authheader' })} inlineLabel onText="On" offText="Off" checked={this.state.schedule.authorizationHeader ?? false} onChange={()=>{this.onToggleAuthorizationHeader();}} />
                <Stack horizontal tokens={{ childrenGap: 5}} >
                    <Label>Authorizer</Label>
                    <TextField readOnly value={this.state.schedule.authorizerName} /> 
                    <IconButton iconProps={{iconName: "Search"}} 
                        onClick={() => {
                            this.setState({doGetAuthorizer: true});
                        }}/>
                    <IconButton iconProps={{iconName: "EraseTool"}} 
                        onClick={() => {
                            var sched: ISchedule = JSON.parse(JSON.stringify(this.state.schedule));
                            sched.authorizerID = "";
                            sched.authorizerName = "";
                            this.setState({ schedule: sched, shouldSave: true, doSetDDL: false });
                        }}/>
                </Stack>
                <Toggle label={this.props.intl.formatMessage({ id: 'schedule.authobject' })} inlineLabel onText="On" offText="Off" checked={this.state.schedule.returnAuthObject ?? false} onChange={()=>{this.onToggleReturnAuthObject();}} />


                <TextField multiline rows={3} label={this.props.intl.formatMessage({ id: 'schedule.allowIP' })} value={this.state.schedule.allowIP ?? ""}  name="allowIP" onChange={this.handleChange}/>
            </PivotItem>
        }


        
        return (
            <>
            <Stack grow verticalFill style={{padding: 10}}>
                <Stack horizontal tokens={{ childrenGap: 5}} >
                    <PrimaryButton iconProps={{ iconName: "Save" }}  text={this.props.intl.formatMessage({ id: 'std.save' })} disabled={!this.state.shouldSave} onClick={()=>{this.onSave();}}/>
                    <DefaultButton iconProps={{ iconName: "FabricMovetoFolder" }} text="Move To Folder" onClick={()=>{this.setState({doMoveFolder: true});}}/>
                </Stack>
                <TextField label={this.props.intl.formatMessage({ id: 'schedule.name' })} value={this.state.schedule.name}  name="name" onChange={this.handleChange}/>
                <TextField label={this.props.intl.formatMessage({ id: 'schedule.description' })} multiline value={this.state.schedule.description}  name="description" onChange={this.handleChange}/>
                <TextField label={this.props.intl.formatMessage({ id: 'schedule.externalid' })} value={this.state.schedule.externalID}  name="externalid" onChange={this.handleChange}/>
                <Dropdown
                        placeholder="Select..."
                        label="Type"
                        options={scheduleTypes}
                        selectedKey={this.state.schedule.type}
                        onChange={this.onTypeChanged}
                        />
                <Toggle label={this.props.intl.formatMessage({ id: 'schedule.active' })} inlineLabel onText="On" offText="Off" checked={this.state.schedule.active} onChange={()=>{this.onToggleActive();}} />
                <Pivot>
                    <PivotItem headerText="Parameters">
                        {param}
                    </PivotItem>
                    {security}
                    <PivotItem headerText="Logging">
                        <Toggle label={this.props.intl.formatMessage({ id: 'schedule.log' })} inlineLabel onText="On" offText="Off" checked={this.state.schedule.logExecution} onChange={()=>{this.onToggleLog();}} />
                        <Toggle label={this.props.intl.formatMessage({ id: 'schedule.logall' })} inlineLabel onText="On" offText="Off" checked={this.state.schedule.logAllSteps} onChange={()=>{this.onToggleLogAll();}} />
                        <Toggle label={this.props.intl.formatMessage({ id: 'schedule.logexceptions' })} inlineLabel onText="On" offText="Off" checked={this.state.schedule.logExceptions} onChange={()=>{this.onToggleLogExceptions();}} />
                    </PivotItem>
                    <PivotItem headerText="Metrics">
                        <TextField readOnly label="Rule Executions" value={(this.state.schedule.currentExecRuleCount ?? 0).toString()} />
                        <TextField readOnly label="External Bytes Received" value={bytesin.value} suffix={bytesin.scale} />
                        <TextField readOnly label="External Bytes Sent" value={bytesout.value} suffix={bytesout.scale} />
                    </PivotItem>
                </Pivot>
            </Stack>

            <DDLFinder 
            // @ts-ignore
                isOpen={this.state.doSetDDL}
                client={this.props.client}
                onDismiss={()=>{this.setState({doSetDDL: false})}}
                onDDLSelected={(ddl: IDataDefinition)=>{
                    var sched: ISchedule = JSON.parse(JSON.stringify(this.state.schedule));
                    sched.parameterDDLID = ddl.id;
                    sched.parameterDDLName = ddl.name;
                    this.setState({ schedule: sched, shouldSave: true, doSetDDL: false });
                }}
            /> 

            <Dialog
                 hidden={!this.state.doGetAuthorizer}
                 onDismiss={()=>{this.setState({doGetAuthorizer: false})}}
                 minWidth={700}
                 dialogContentProps={{
                    type: DialogType.largeHeader,
                    title: 'Select Authorization Ruleset',
                    closeButtonAriaLabel: 'Close',
                    subText: 'Choose which ruleset will validate the Authorization Header',
                  }}
            >
                    <ScheduleFinder client={this.props.client} 
                                    scheduleSelected={(sched: ISchedule) => {
                                        var s = JSON.parse(JSON.stringify(this.state.schedule));
                                        s.authorizerID = sched.id ?? "";
                                        s.authorizerName = sched.name ?? "";
                                        this.setState({schedule: s, shouldSave: true, doGetAuthorizer: false})
                                    }}/>
                    <DialogFooter>
                   <DefaultButton onClick={()=>{this.setState({doGetAuthorizer: false})}} text="Cancel" />
                 </DialogFooter>
            </Dialog>    

            <Dialog
                 hidden={!this.state.doMoveFolder}
                 onDismiss={()=>{this.setState({doMoveFolder: false})}}
                 dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Move Folder',
                    closeButtonAriaLabel: 'Close',
                    subText: 'Choose which folder you want to move this ruleset to',
                  }}
            >
                <Folders 
                // @ts-ignore
                    identifier="RULESET" 
                    clientid={this.props.client} 
                    onFolderSelected={(folder: string) => this.setState({destinationFolder: folder})}  
                />

                 <DialogFooter>
                   <PrimaryButton onClick={()=>{this.onMoveFolder()}} text="Move" />
                   <DefaultButton onClick={()=>{this.setState({doMoveFolder: false})}} text="Cancel" />
                 </DialogFooter>
            </Dialog>    
            </>
        );
    }
}

export default injectIntl(ScheduleProperties);