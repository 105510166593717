import 'rc-time-picker/assets/index.css';

import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { IClient, rationalizeNumber } from './IDeus';

import {  
    Stack, 
    TextField, 
    Text, 
    PrimaryButton, 
    Label,
    Toggle,
    IconButton,
    Pivot,
    PivotItem,
} from '@fluentui/react';


import moment, { Moment } from 'moment';

type IState = {
    client: IClient
    shouldSave: boolean
    currDate: Date | null | undefined
    currTime: Moment
}
  
interface IProps extends WrappedComponentProps {
    client: IClient,
    onChange?: any,
}

class ClientProperties extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        var m : Moment = moment();
        var d : Date = new Date();

        this.state = {
            client: this.props.client,
            shouldSave: false,
            currDate: d,
            currTime: m
        };

        this.handleChange = this.handleChange.bind(this);

        this.onSave = this.onSave.bind(this);
        this.doSave = this.doSave.bind(this);
    }


    componentDidUpdate(prevProps: IProps) {
        if (prevProps.client !== this.props.client) {
    
            this.setState({
                client: this.props.client,
                shouldSave: false
            });
          }
      }

      private onToggleActive()
      {
          var client: IClient = {...this.state.client};
          client.active = !client.active;
          this.setState({ client: client, shouldSave: true });
      }
      private onToggleAPIKey()
      {
          var client: IClient = {...this.state.client};
          client.requireAPIKey = !(client.requireAPIKey ?? false);
          this.setState({ client: client, shouldSave: true });
      }  
      doSave(r: IClient)
      {
          console.log("doSave");
          if(this.props.onChange == null)     return;
  
          this.props.onChange(r);
      }
  
    handleChange(event: any)
    {
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var client : IClient = {...this.state.client};
        switch(name)
        {
            case "name":
                client.name = value;
                break;
            case "line1":
                client.addressLine1 = value;
                break;
            case "line2":
                client.addressLine2 = value;
                break;
            case "city":
                client.city = value;
                break;
            case "postcode":
                client.postcode = value;
                break;
            case "email":
                client.contactEmail = value;
                break;
            case "apikey":
                client.apiKey = value;
                break;
            }
        this.setState({client: client, shouldSave: true});
    }




    componentDidMount()
    {
      this.refresh();
    }

    onSave()
    {
        var s = this.state.client;
        if(this.props.onChange != null)
        {
            this.props.onChange(s);
        }        
        this.setState({ shouldSave: false });
    }


    refresh()
    {

    }

    render() 
    { 
        if(this.state.client == null) return <>Select a client from the list...</>;
        if(this.state.client.id == null) return <>Select a client from the list...</>;

        var bytesin = rationalizeNumber(this.state.client.externalBytesReceived);
        var bytesout = rationalizeNumber(this.state.client.externalBytesSent);
        
        return (
            <Stack grow verticalFill style={{padding: 10, borderLeft: "1px solid grey"}} tokens={{childrenGap: 10}}>
                <Stack horizontal tokens={{ childrenGap: 5}} >
                    <PrimaryButton iconProps={{ iconName: "Save" }}  text={this.props.intl.formatMessage({ id: 'std.save' })} disabled={!this.state.shouldSave} onClick={()=>{this.onSave();}}/>
                </Stack>
                <Stack horizontal tokens={{ childrenGap: 5}} >
                    <Label>ID</Label>
                    <Text>{this.state.client.id}</Text>
                    <IconButton iconProps={{iconName: "Copy"}} onClick={() => {navigator.clipboard.writeText(this.state.client.id)}}/>
                </Stack>
                <Toggle label={this.props.intl.formatMessage({ id: 'schedule.active' })} inlineLabel onText="On" offText="Off" checked={this.state.client.active} onChange={()=>{this.onToggleActive();}} />
                <Pivot>
                    <PivotItem headerText="Address">
                        <TextField label={this.props.intl.formatMessage({ id: 'client.name' })} value={this.state.client.name}  name="name" onChange={this.handleChange}/>
                        <TextField label={this.props.intl.formatMessage({ id: 'client.line1' })} value={this.state.client.addressLine1}  name="line1" onChange={this.handleChange}/>
                        <TextField label={this.props.intl.formatMessage({ id: 'client.line2' })} value={this.state.client.addressLine2}  name="line2" onChange={this.handleChange}/>
                        <TextField label={this.props.intl.formatMessage({ id: 'client.city' })} value={this.state.client.city}  name="city" onChange={this.handleChange}/>
                        <TextField label={this.props.intl.formatMessage({ id: 'client.postcode' })} value={this.state.client.postcode}  name="postcode" onChange={this.handleChange}/>
                        <TextField label={this.props.intl.formatMessage({ id: 'client.email' })} value={this.state.client.contactEmail}  name="email" onChange={this.handleChange}/>
                    </PivotItem>
                    <PivotItem headerText="API Protection">
                        <Toggle label={this.props.intl.formatMessage({ id: 'schedule.requirekey' })} inlineLabel onText="On" offText="Off" checked={this.state.client.requireAPIKey ?? false} onChange={()=>{this.onToggleAPIKey();}} />
                        <TextField label={this.props.intl.formatMessage({ id: 'schedule.apikey' })} disabled={!(this.state.client.requireAPIKey ?? false)} value={this.state.client.apiKey ?? ""}  name="apikey" onChange={this.handleChange}/>
                        <TextField multiline rows={3} label={this.props.intl.formatMessage({ id: 'schedule.allowIP' })} value={this.state.client.allowIP ?? ""}  name="allowIP" onChange={this.handleChange}/>
                    </PivotItem>
                    <PivotItem headerText="Metrics">
                        <TextField readOnly label="Rule Executions" value={(this.state.client.currentExecRuleCount ?? 0).toString()} />
                        <TextField readOnly label="API Calls" value={(this.state.client.currentAPICallCount ?? 0).toString()} />
                        <TextField readOnly label="Scheduled Executions" value={(this.state.client.currentScheduleCallCount ?? 0).toString()} />
                        <TextField readOnly label="External Bytes Received" value={bytesin.value} suffix={bytesin.scale} />
                        <TextField readOnly label="External Bytes Sent" value={bytesout.value} suffix={bytesout.scale} />
                    </PivotItem>
                </Pivot>
            </Stack>
        );
    }
}

export default injectIntl(ClientProperties);