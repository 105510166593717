import React, { memo } from 'react';

import { Handle, Position } from 'react-flow-renderer';


import {  
  Image, Stack, Text, Icon,
} from '@fluentui/react';

import { eExecutionType } from '../IDeus';

export default memo(({ data }) => {
  var input = <></>;
  if(!data.root)
  {
    input = <Handle
              type="target"
              position={Position.Top}
              onConnect={(params) => console.log('handle onConnect', params)}
              className="node-input"
              />
  }

  var delay = <></>;
  if(data.rule.executionType === eExecutionType.Delay && (data.rule.secondsDelay ?? 0) > 0)
  {
    delay = <Icon iconName="Clock" style={{paddingRight: 5}} />;
  }


  return (
    <div className={data.selected ? "node-selected" : "node-container"}>
      {input}
      <Stack horizontal className="node-type">
        {delay} File Read
      </Stack>
      <Stack horizontal className="node-header-file" tokens={{childrenGap: 10}}>
        <Image src="/icons/operations/fileread.svg" width={20} height={20} />
        <strong>{data.rule.name}</strong>
      </Stack>
      <Stack>
        <Text className="node-parameters-header">Storage</Text>
        <Text className="node-parameters">{data.rule.opParameters["storage"]}</Text>
      </Stack>
      <Handle
        type="source"
        position={Position.Bottom}
        id="OnTrue"
        className="node-output-true"
      />
      <Handle
        type="source"
        position={Position.Bottom}
        id="OnFalse"
        className="node-output-false"
      />
      <Handle
        type="source"
        position={Position.Right}
        id="OnFail"
        className="node-output-fail"
      />
    </div>
  );
});
