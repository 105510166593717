import 'rc-time-picker/assets/index.css';

import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  IField, eFieldType, IDataDefinition } from './IDeus';

import {  
    Stack, 
    TextField, 
    DatePicker, DayOfWeek,
    Toggle,
    Dropdown,
    Separator,
    IconButton,
    List,
} from '@fluentui/react';


import StringList from './StringList';
import DDLFinder from './DDLFinder';
import { Get } from './authorisation/AMAPI';
import { Label } from 'reactstrap';
import DDLField from './DDLField';


type IState = {
    field: IField,
    doFindDDL: boolean,
    namedObject?: IDataDefinition
}
  
  
interface IProps extends WrappedComponentProps {
    field: IField,
    client: string,
    onChange: any,
}


class DDLFieldProperties extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            field: this.props.field,
            doFindDDL: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.onTypeChange = this.onTypeChange.bind(this);
        this.onArrayTypeChange = this.onArrayTypeChange.bind(this);
        this.onBuiltInChange = this.onBuiltInChange.bind(this);

        this.onMinimumDateChange = this.onMinimumDateChange.bind(this);
        this.onMaximumDateChange = this.onMaximumDateChange.bind(this);

        this.onToggleRequired = this.onToggleRequired.bind(this);
        this.onTogglePK = this.onTogglePK.bind(this);
        this.onToggleValidate = this.onToggleValidate.bind(this);
        this.onToggleCaseSensitive = this.onToggleCaseSensitive.bind(this);

        this.getNamedDDL = this.getNamedDDL.bind(this);

    }
    


    componentDidUpdate(prevProps: IProps) {
        if (prevProps.field !== this.props.field) {
            this.setState({
                field: this.props.field,
            },()=>{
                if((this.state.field.type === eFieldType.NamedObject || 
                    this.state.field.type === eFieldType.NamedObjectArray) && 
                    this.state.field.objectID !== this.state.namedObject?.id)
                {
                    this.getNamedDDL();
                }
            });
          }
      }

      
    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var field : IField = { ...this.state.field};
        switch(name)
        {
            case "name":
                field.name = value;
                break;
            case "description":
                field.description = value;
                break;
            case "regExValidation":
                field.regExValidation = value;
                break;
            case "length":
                field.length = parseInt(value);
                break;
            case "minimumValue":
                field.minimumValue = value;
                break;
            case "maximumValue":
                field.maximumValue = value;
                break;
        }
        this.setState({field: field});
        this.props.onChange(field);
    }

   
    componentDidMount()
    {
      this.refresh();
    }

    private onToggleRequired()
    {
        var field: IField = { ...this.state.field};
        field.required = !(field.required ?? false);
        this.setState({ field: field });
        this.props.onChange(field);
    }  

    private onToggleValidate()
    {
        var field: IField = { ...this.state.field};
        field.validate = !(field.validate ?? false);
        this.setState({ field: field });
        this.props.onChange(field);
    }  
  
    private onTogglePK()
    {
        var field: IField = { ...this.state.field};
        field.primaryKey = !(field.primaryKey ?? false);
        this.setState({ field: field });
        this.props.onChange(field);
    }  
  
    private onToggleCaseSensitive()
    {
        var field: IField = { ...this.state.field};
        field.caseSensitive = !(field.caseSensitive ?? false);
        this.setState({ field: field });
        this.props.onChange(field);
    }  

    refresh()
    {

    }

    onTypeChange(ev: any,option?: any)
    {
        var field: IField  = { ...this.state.field};
        field.type = option.key;
        this.setState({field: field});
        this.props.onChange(field);
    }
    onArrayTypeChange(ev: any,option?: any)
    {
        var field: IField  = { ...this.state.field};
        field.arrayType = option.key;
        this.setState({field: field});
        this.props.onChange(field);
    }
    onBuiltInChange(ev: any,option?: any)
    {
        var r: IField  =  { ...this.state.field};
        r.builtInValidation = option.key;
        this.setState({field: r});
        this.props.onChange(r);
    }

    onMinimumDateChange(value: Date | null | undefined) {
        var field: IField  =  { ...this.state.field};
        field.minimumValue = value?.toDateString() || "";
        this.setState({field: field});
        this.props.onChange(field);
    }

    onMaximumDateChange(value: Date | null | undefined) {
        var field: IField  =  { ...this.state.field};
        field.maximumValue = value?.toDateString() || "";
        this.setState({field: field});
        this.props.onChange(field);
    }

    private getNamedDDL()
    {
        console.log(this.state.field)
      Get("datadefinition/" + this.state.field.objectID)
        .then(response => {
              this.setState({ 
                namedObject: response.data, 
              });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    render() 
    {
        if(this.state.field == null) return <>Select a field from the list...</>;
        if(this.state.field.id == null) return <>Select a field from the list...</>;
        const onRenderCell =(item: any, index: number | undefined) => {
            return (
              <div>
                <DDLField 
                          onSelected={(id:string)=>{}} 
                          field={item} 
                          onChange={()=>{}} 
                          onDelete={()=>{}} 
                          onAddChild={()=>{}}
                          displayOnly
                          />
  
              </div>
            );
          };
  
        var typeparams = <></>;
        switch(this.state.field.type)
        {
            case eFieldType.String:
                typeparams = <>
                <TextField label="Length (0=Unlimited)" value={this.state.field.length.toString()}  name="length" onChange={this.handleChange}/>

                </>;
                break;
            case eFieldType.NamedObject:
            case eFieldType.NamedObjectArray:
                typeparams = <>
                    <Label>Object</Label> 
                <Stack horizontal>
                    <TextField readOnly value={this.state.namedObject?.name}  name="length" onChange={this.handleChange}/>
                    <IconButton iconProps={{iconName: "Search"}} 
                            onClick={() => {
                                this.setState({doFindDDL: true});
                            }}/>
                </Stack>
                <List
                    style={{overflowY: "auto", height: "300px", border: "1px solid black", backgroundColor: "#e0e0e0"}}
                    items={this.state.namedObject?.fields}
                    onRenderCell={onRenderCell}
                  />
                </>;
                break;
            case eFieldType.Array:
                typeparams = <>
                <Dropdown
                    label='Array Type'
                    placeholder="Select data type..."
                    options={[
                        {key: 0, text: "String" },
                        {key: 1, text: "Integer" },
                        {key: 2, text: "Float" },
                        {key: 3, text: "Date Time" },
                        {key: 4, text: "Boolean" },
                        {key: 5, text: "Object" },
                        {key: 6, text: "Array" },
                        {key: 7, text: "Named Object" },
                        {key: 8, text: "Named Object Array" },
                    ]}
                    selectedKey={this.state.field.arrayType}
                    onChange={this.onArrayTypeChange}
                />
                </>;
                break;
        }

        var validateparams = <></>;
        if(this.state.field.validate)
        {
            switch(this.state.field.type)
            {
                case eFieldType.String:
                    validateparams = <>
                        <TextField label="Regular Expression" value={this.state.field.regExValidation}  name="regExValidation" onChange={this.handleChange}/>
                        <Dropdown
                            label='Built In Validation'
                            placeholder="Select validation..."
                            options={[
                                {key: "", text: "(None)" },
                                {key: "USZIP", text: "US ZIP CODE" },
                                {key: "UKPOSTCODE", text: "UK POSTCODE" },
                                {key: "EMAIL", text: "EMAIL" },
                                {key: "URL", text: "URL" },
                            ]}
                            selectedKey={this.state.field.builtInValidation}
                            onChange={this.onBuiltInChange}
                        />
                        <StringList 
                            label="Valid Values"
                            values={this.state.field.allowableValues} 
                            onChange={(values: string[]) => {
                            var field: IField = JSON.parse(JSON.stringify(this.state.field));
                            field.allowableValues = values;
                            this.setState({field: field})
                            this.props.onChange(field);
                        }} />
                        <Toggle label="Case Sensitive" inlineLabel onText="Yes" offText="No" checked={this.state.field.validate ?? false} onChange={()=>{this.onToggleValidate();}} />
                    </>;
                    break;
                case eFieldType.Integer:
                    validateparams = <>
                        <TextField label="Minimum Value" value={this.state.field.minimumValue}  name="minimumValue" onChange={this.handleChange}/>
                        <TextField label="Maximum Value" value={this.state.field.maximumValue}  name="maximumValue" onChange={this.handleChange}/>
                    </>;
                    break;
                case eFieldType.Float:
                    validateparams = <>
                        <TextField label="Minimum Value" value={this.state.field.minimumValue}  name="minimumValue" onChange={this.handleChange}/>
                        <TextField label="Maximum Value" value={this.state.field.maximumValue}  name="maximumValue" onChange={this.handleChange}/>
                    </>;
                    break;
                case eFieldType.DateTime:
                    validateparams = <>
                        <DatePicker label='Minimum Date' onSelectDate={this.onMinimumDateChange} firstDayOfWeek={DayOfWeek.Sunday} value={new Date(this.state.field.minimumValue) || new Date()} placeholder="Select date..." ariaLabel="Select date" />
                        <DatePicker label='Maximum Date' onSelectDate={this.onMaximumDateChange} firstDayOfWeek={DayOfWeek.Sunday} value={new Date(this.state.field.maximumValue) || new Date()} placeholder="Select date..." ariaLabel="Select date" />
                    </>;
                    break;
                case eFieldType.Array:
                    validateparams = <>
                        <TextField label="Minimum Length" value={this.state.field.minimumValue}  name="minimumValue" onChange={this.handleChange}/>
                        <TextField label="Maximum Length" value={this.state.field.maximumValue}  name="maximumValue" onChange={this.handleChange}/>
                    </>;
                    break;
    
            }
        }


        
        return (
            <>
            <Stack horizontal>
            <Stack grow verticalFill style={{padding: 10, width: 400, borderLeft: "1px solid grey"}}>
                <TextField label={this.props.intl.formatMessage({ id: 'schedule.name' })} value={this.state.field.name}  name="name" onChange={this.handleChange}/>
                <TextField label={this.props.intl.formatMessage({ id: 'schedule.description' })} multiline value={this.state.field.description}  name="description" onChange={this.handleChange}/>
                <Dropdown
                    label='Type'
                    placeholder="Select data type..."
                    options={[
                        {key: 0, text: "String" },
                        {key: 1, text: "Integer" },
                        {key: 2, text: "Float" },
                        {key: 3, text: "Date Time" },
                        {key: 4, text: "Boolean" },
                        {key: 5, text: "Object" },
                        {key: 6, text: "Array" },
                        {key: 7, text: "Named Object" },
                        {key: 8, text: "Named Object Array" },
                    ]}
                    selectedKey={this.state.field.type}
                    onChange={this.onTypeChange}
                />
                {typeparams}                
                <Toggle label="Primary Key" inlineLabel onText="Yes" offText="No" checked={this.state.field.primaryKey ?? false} onChange={()=>{this.onTogglePK();}} />
                <Toggle label="Required" inlineLabel onText="Yes" offText="No" checked={this.state.field.required ?? false} onChange={()=>{this.onToggleRequired();}} />
                <Separator />
                        
            </Stack>
            <Stack grow verticalFill style={{padding: 10, width: 400, borderLeft: "1px solid grey"}}>
            <Toggle label="Validate" inlineLabel onText="On" offText="Off" checked={this.state.field.validate ?? false} onChange={()=>{this.onToggleValidate();}} />
                {validateparams}
            </Stack>
            </Stack>

            <DDLFinder 
            // @ts-ignore
                isOpen={this.state.doFindDDL}
                client={this.props.client}
                onDismiss={()=>{this.setState({doFindDDL: false})}}
                onDDLSelected={(ddl: IDataDefinition)=>{
                    var field: IField = JSON.parse(JSON.stringify(this.state.field));
                    console.log("SELECTED",ddl)
                    field.objectID = ddl.id;
                    this.setState({field: field, namedObject: ddl, doFindDDL: false});
                    this.props.onChange(field);

                }}
            />   
            </>
        );
    }
}

export default injectIntl(DDLFieldProperties);