import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {IRule, ISchedule, ITask, IConnection, uuid, IAuthConnection, eOpCode, eScheduleType, eExecutionType, IcpuIn, IcpuOut, ITemplate, eNoExecutionAction } from './IDeus';


//@ts-ignore
import ReactFlow, { FlowTransform } from 'react-flow-renderer';
//@ts-ignore
import { Background, Controls } from 'react-flow-renderer';

import NOPNode from './nodes/NOPNode'
import SequenceNode from './nodes/SequenceNode'
import ParallelNode from './nodes/ParallelNode'
import RESTNode from './nodes/RESTNode'
import ExpressionNode from './nodes/ExpressionNode'
import ScheduleNode from './nodes/ScheduleNode'
import GenSequenceNode from './nodes/GenSequenceNode'
import LogNode from './nodes/LogNode'
import AggregateNode from './nodes/AggregateNode'
import AddToAggregateNode from './nodes/AddToAggregateNode';
import AggregateFailNode from './nodes/AggregateFailNode'
import FileReceiveNode from './nodes/FileReceiveNode'
import FileDeleteNode from './nodes/FileDeleteNode'
import FileCSVReadNode from './nodes/FileCSVReadNode';
import DirectorySearchNode from './nodes/DirectorySearchNode';
import DatabaseNode from './nodes/DatabaseNode';
import SetValuesNode from './nodes/SetValuesNode';
import TranslateNode from './nodes/TranslateNode';
import EncryptionNode from './nodes/EncryptionNode';
import TemplateNode from './nodes/TemplateNode';
import ExternalExecNode from './nodes/ExternalExecNode';


import { Get, Post, } from './authorisation/AMAPI';

import {  
    Stack, 
    Separator,
    PrimaryButton, DefaultButton,
    Dialog, DialogFooter, DialogType, Label, List, TextField, FontIcon, Toggle,
} from '@fluentui/react';

import RuleProperties from './RuleProperties' 

const nodeTypes = {
    nopNode: NOPNode,
    sequenceNode: SequenceNode,
    parallelNode: ParallelNode,
    restNode: RESTNode,
    restNode4: RESTNode,
    restNode5: RESTNode,
    restNode6: RESTNode,
    restNode7: RESTNode,
    restNode8: RESTNode,
    restNode9: RESTNode,
    genSequenceNode: GenSequenceNode,
    expressionNode: ExpressionNode,
    scheduleNode: ScheduleNode,
    logNode: LogNode,
    aggregateNode: AggregateNode,
    addtoaggregateNode: AddToAggregateNode,
    aggregateFailNode: AggregateFailNode,
    filereceiveNode: FileReceiveNode, 
    filedeleteNode: FileDeleteNode,
    filecsvreadNode: FileCSVReadNode,
    directorysearchNode: DirectorySearchNode,
    databaseNode: DatabaseNode,
    setvaluesNode: SetValuesNode,
    translateNode: TranslateNode,
    templateNode: TemplateNode,
    encryptionNode: EncryptionNode,
    externalexecNode: ExternalExecNode,
  };

  



type IState = {
    scheduleID: string,
    schedule: ISchedule,
    selectedRule?: IRule,
    clipboard?: IRule,
    clipX?: number,
    clipY?: number,
    elements: any[],

    connections: IConnection[],
    schedules: ISchedule[],
    authConnections: IAuthConnection[],
    templates: ITemplate[],

   
    graphKey: string,
    listKey: string,

   
    viewport: FlowTransform

    ExecQueue: ITask[],
    InternalExecQueue: ITask[],
    Accumulator: string,
    stepError: string,

    getInput: boolean,
    inputValue: string,


    currTask?: ITask,

    selectedIndex: number,

    breakpoints: string[],

}
  
  
interface IProps extends WrappedComponentProps {
    scheduleID: string
    client: string
}


class Debugger extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);
        
        this.state = {
            scheduleID: this.props.scheduleID,
            viewport: {
                zoom: 1,
                x: 0,
                y: 0
            },
            schedule: {
                id: uuid(),
                clientID: "",
                name: "New Schedule",
                description: "",
                externalID: "",
                folderID: "",
                type: eScheduleType.TimedSchedule,
                parameterDDLName: "",
                active: false,

                executeAt: new Date().toISOString(),
                repeatType: 0,
                repeatValueUnits: 0,
                repeatValue: 1,

                logExecution: false,

                currentCallCount: 0,
                currentExecRuleCount: 0,

                externalBytesReceived: 0,
                externalBytesSent: 0,

                rule: {
                    id: uuid(),
                    name: "New Rule",
                    description: "",
                    x: 100,
                    y: 100,

                    validateInputDDLID: "",
                    validateInputDDLName: "",

                    executionType: eExecutionType.Parallel,
                    secondsDelay: 0,
                    checkForExecution: false,
                    executionCheck: "",
                    noExecutionAction: eNoExecutionAction.GoLeft,
        
                    opCode: 2,
                    opParameters: {},

                    onTrue: [],
                    onFalse: [],
                    onFail: []
                }

                

            },
            elements: [],

            connections: [],
            schedules: [],
            authConnections: [],
            templates: [],


            graphKey: uuid(),
            listKey: uuid(),

            ExecQueue: [],
            InternalExecQueue: [],
            Accumulator: "",
            stepError: "",

            currTask: undefined,

            selectedIndex: -1,

            getInput: false,
            inputValue: "",

            breakpoints: [],


        };

        this.handleChange = this.handleChange.bind(this);
        this.generateGraph = this.generateGraph.bind(this);

        this.onElementClick = this.onElementClick.bind(this);
        
        this.loadConnections = this.loadConnections.bind(this);
        this.loadSchedules = this.loadSchedules.bind(this);
        this.loadAuthConnections = this.loadAuthConnections.bind(this);

        this.setSelectedRule = this.setSelectedRule.bind(this);
        this.findSelectedRule = this.findSelectedRule.bind(this);
        this.findRule = this.findRule.bind(this);
        this.findParentRule = this.findParentRule.bind(this);

        this.doStep = this.doStep.bind(this);
        this.doRun = this.doRun.bind(this);
        this.doSkip = this.doSkip.bind(this);
        this.doReset = this.doReset.bind(this);
        this.toggleBreakpoint = this.toggleBreakpoint.bind(this);
        this.addNode = this.addNode.bind(this);
        this.loadTemplates = this.loadTemplates.bind(this);

    }
      
    loadTemplates(): void
    {

        Get("template/forclient/"+this.state.schedule.clientID)
        .then(response => {
            this.setState({templates: response.data})
        });

    }

    toggleBreakpoint(ruleid: string)
    {
        var breakpoints = JSON.parse(JSON.stringify(this.state.breakpoints));
        if(breakpoints.indexOf(ruleid) > -1)
        {
            breakpoints.splice(breakpoints.indexOf(ruleid),1);
        }
        else
        {
            breakpoints.push(ruleid);
        }
        this.state.elements.forEach(e => { 
            if(e.data !== undefined)
                e.data.breakpoint = (breakpoints.indexOf(e.data.rule.id) > -1)
        });                
        this.setState({breakpoints: breakpoints, graphKey: uuid()});
    }
    
// picker    

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;
        var eq: ITask[] = JSON.parse(JSON.stringify(this.state.ExecQueue));
        var currtask: ITask = JSON.parse(JSON.stringify(this.state.currTask));
        if(eq.length === 0)  return;

        var ip = "";
        if(name==="param")
        {
            if(value!=="")
            {
                if(isNaN(parseInt(value)))
                {
                    ip = "{\"param\":\"" + value + "\"}";
                }
                else
                {
                    ip = "{\"param\":" + value + " }";
                }
    
            }
        }
        else
        {
            ip = value;
        }


        eq[0].value = ip;
        currtask.value = ip;
        this.setState({ExecQueue: eq, currTask: currtask, inputValue: value});
    }

    componentDidMount()
    {
      this.refresh();
      this.loadSchedules();
      this.loadTemplates();

    }
  
    loadConnections()
    {
        Get("connection/forclient/"+this.state.schedule.clientID)
          .then(response => {
                var s: IConnection[] = response.data;
                this.setState({ 
                  connections: s, 
                });
            })
          .catch(function (error) {
            console.log(error);
          });
        
    }

    loadSchedules()
    {
        Get("ruleset")
          .then(response => {
                var s: ISchedule[] = response.data;
                this.setState({ 
                  schedules: s, 
                });
            })
          .catch(function (error) {
            console.log(error);
          });
        
    }

    loadAuthConnections(){

        Get("authorizers/forclient/"+this.state.schedule.clientID)
        .then(response => {
            this.setState({authConnections: response.data})
        });

    }
  
    refresh()
    {
  
        Get("ruleset/" + this.state.scheduleID)
          .then(response => {
                var s: ISchedule = response.data;
                var eq: ITask[] = JSON.parse(JSON.stringify(this.state.ExecQueue));
                var task: ITask = {
                  ruleID: s.rule.id,
                  rulesetID: s.id,
                  value: ""
                }
                eq.push(task);
                this.setState({ 
                  schedule: s, 
                  ExecQueue: eq
                });
                this.loadConnections();
                this.loadAuthConnections();
                this.generateGraph(s);

            })
          .catch(function (error) {
            console.log(error);
          });
  
    }

    doReset()
    {
        var s: ISchedule = JSON.parse(JSON.stringify(this.state.schedule));
        var eq: ITask[] = [];
        var task: ITask = {
          ruleID: s.rule.id,
          rulesetID: s.id,
          value: ""
        }
        eq.push(task);
        this.setState({ 
          ExecQueue: eq, InternalExecQueue: [], Accumulator: "", currTask: undefined, selectedIndex: -1
        });
    }

    nodeName(op: number)
    {
        switch(op)
        {
            case eOpCode.NOP: return "nopNode";
            case eOpCode.ExecSequence: return "sequenceNode";
            case eOpCode.ExecREST: return "restNode";
            case eOpCode.ExecGenerateSequence: return "genSequenceNode";
            case eOpCode.ExecBooleanExpression: return "expressionNode";
            case eOpCode.ExecSchedule: return "scheduleNode";
            case eOpCode.ExecLog: return "logNode";
            case eOpCode.ExecAggregate: return "aggregateNode";
            case eOpCode.ExecAddToAggregate: return "addtoaggregateNode";
            case eOpCode.ExecAggregateFail: return "aggregateFailNode";
            case eOpCode.ExecFileReceive: return "filereceiveNode";
            case eOpCode.ExecFileDelete: return "filedeleteNode";
            case eOpCode.ExecCSVFileRead: return "filecsvreadNode";
            case eOpCode.ExecDirectorySearch: return "directorysearchNode";
            case eOpCode.ExecDatabaseQuery: return "databaseNode";
            case eOpCode.ExecSetValues: return "setvaluesNode";
            case eOpCode.ExecTranslate: return "translateNode";
            case eOpCode.ExecEncryption: return "encryptionNode";
        }
    }

    generateGraph(schedule: ISchedule)
    {
        console.log("Generate Graph")
        var elements: any[] = [];
        var selected = schedule.rule.id===this.state.selectedRule?.id;
        var node = {
            id: schedule.rule.id,
            type: this.nodeName(schedule.rule.opCode), // input node
            data: { rule: schedule.rule, root: true, selected: selected, breakpoint: this.state.breakpoints.indexOf(schedule.rule.id) > -1 },
            position: { x: schedule.rule.x, y: this.state.schedule.rule.y },
        }
        elements.push(node);

        schedule.rule.onTrue.forEach(child => 
            { 
                this.addNode(elements,child, schedule.rule.id, "OnTrue");
            });
        schedule.rule.onFalse.forEach(child => 
            { 
                this.addNode(elements,child, schedule.rule.id, "OnFalse");
            });
        schedule.rule.onFail.forEach(child => 
            { 
                this.addNode(elements,child, schedule.rule.id, "OnFail");
            });
        this.setState({elements: elements});
    }

    addNode(elements: any[],rule: IRule, parentID: string, handle: string)
    {
        var selected = rule.id===this.state.selectedRule?.id;
        var node = {
            id: rule.id,
            type: this.nodeName(rule.opCode), // input node
            data: { rule: rule, root: false, selected: selected, breakpoint: this.state.breakpoints.indexOf(rule.id ?? "") > -1  },
            position: { x: rule.x, y: rule.y },
        }
        var join = { 
            id: parentID+'-'+node.id, 
            source: parentID, 
            target: rule.id, 
            animated: true, 
            sourceHandle:handle 
        };
        elements.push(node);
        elements.push(join);
        rule.onTrue.forEach(r => this.addNode(elements,r,rule.id, "OnTrue"));
        rule.onFalse.forEach(r => this.addNode(elements,r,rule.id, "OnFalse"));
        rule.onFail.forEach(r => this.addNode(elements,r,rule.id, "OnFail"));
    }

    onElementClick(ev: any,element: any)
    {
        this.setSelectedRule(element.id);
        this.state.elements.forEach(e => { 
            if(e.data !== undefined)
                e.data.selected = (e.id===element?.id)
        });
        this.setState({ graphKey: uuid()});
    }

    

    setSelectedRule(id: string)
    {
        this.findSelectedRule(this.state.schedule.rule,id);
    }

    setRule(src: IRule, root: IRule): boolean
    {
        if(root.id === src.id)
        {
            root.opCode = src.opCode;
            root.name = src.name;
            root.description = src.description;
            root.opParameters = src.opParameters;
            root.executionType = src.executionType;
            root.secondsDelay = src.secondsDelay;
            return true;
        }   

        var i: number;
        for(i = 0; i < root.onTrue.length;i++)
        {
            if(this.setRule(src,root.onTrue[i])) return true;
        }

        for(i = 0; i < root.onFalse.length;i++)
        {
            if(this.setRule(src,root.onFalse[i])) return true;
        }

        for(i = 0; i < root.onFail.length;i++)
        {
            if(this.setRule(src,root.onFail[i])) return true;
        }

        return false;
    }

    findRule(id: string, root: IRule): IRule | null
    {
        if(root.id === id)
        {
            return root;
        }   

        var i: number;
        var f: IRule | null;
        for(i = 0; i < root.onTrue.length;i++)
        {
            f = this.findRule(id,root.onTrue[i]);
            if( f!== null)  return f;
        }
        for(i = 0; i < root.onFalse.length;i++)
        {
            f = this.findRule(id,root.onFalse[i]);
            if( f!== null)  return f;
        }
        for(i = 0; i < root.onFail.length;i++)
        {
            f = this.findRule(id,root.onFail[i]);
            if( f!== null)  return f;
        }
        return null;
    }

    findParentRule(id: string, root: IRule): IRule | null
    {
        if(root.id === id)
        {
            return null;
        }   

        var i: number;
        var f: IRule | null;
        for(i = 0; i < root.onTrue.length;i++)
        {
            if( root.onTrue[i].id === id)  return root;
        }
        for(i = 0; i < root.onFalse.length;i++)
        {
            if( root.onFalse[i].id === id)  return root;
        }
        for(i = 0; i < root.onFail.length;i++)
        {
            if( root.onFail[i].id === id)  return root;
        }
        // Ok - Not this rule, so try children
        for(i = 0; i < root.onTrue.length;i++)
        {
            f = this.findParentRule(id,root.onTrue[i]);
            if( f!== null)  return f;
        }
        for(i = 0; i < root.onFalse.length;i++)
        {
            f = this.findParentRule(id,root.onFalse[i]);
            if( f!== null)  return f;
        }
        for(i = 0; i < root.onFail.length;i++)
        {
            f = this.findParentRule(id,root.onFail[i]);
            if( f!== null)  return f;
        }

        return null;
    }

    findSelectedRule(r: IRule, id: string)
    {
        if(r.id === id)
        {
            this.setState({selectedRule: r});
            return true;
        }
        var i: number;
        for(i = 0; i <r.onTrue.length;i++)
        {
            if(this.findSelectedRule(r.onTrue[i],id))      return true;
        }
        for(i = 0; i <r.onFalse.length;i++)
        {
            if(this.findSelectedRule(r.onFalse[i],id))      return true;
        }
        for(i = 0; i <r.onFail.length;i++)
        {
            if(this.findSelectedRule(r.onFail[i],id))      return true;
        }
        return false;
    }

    doSkip()
    {
        var eq: ITask[] = JSON.parse(JSON.stringify(this.state.ExecQueue));
        eq.splice(0,1);
        this.setState({ExecQueue: eq, selectedIndex: -1});
    }

    doStep()
    {
        var eq: ITask[];
        var fromInternal: boolean = false;
        if(this.state.InternalExecQueue.length > 0)
        {
            eq = JSON.parse(JSON.stringify(this.state.InternalExecQueue));
            fromInternal = true;
        }
        else
        {
            eq = JSON.parse(JSON.stringify(this.state.ExecQueue));
        }

        var task: IcpuIn = {
            task: eq[0],
            accumulator: this.state.Accumulator,
            internalExecQueue: fromInternal,
        };

        if(task.task.value === "@ACCUMULATOR@")
        {
            task.task.value = this.state.Accumulator;
        }

        Post("ruleset/stepinternal" ,task)
        .then(response => {
            var ret: IcpuOut = response.data;
            var ct: ITask = {} as ITask;
            var eq: ITask[];
            var ieq: ITask[];
            var i: number;
            if(task.internalExecQueue)
            {
                eq = JSON.parse(JSON.stringify(this.state.ExecQueue));
                ieq = JSON.parse(JSON.stringify(this.state.InternalExecQueue));
                ieq.splice(0,1);
                ret.execQueue.forEach(t => {
                    eq.push(t);
                });
                ret.internalExecQueue.forEach(t => {
                    ieq.push(t);
                });
                i = -1;
                if(ieq.length > 0)
                {
                    ct = ieq[0];
                    i = 0;
                    this.setSelectedRule(ct.ruleID);
                    this.state.elements.forEach(e => { 
                        if(e.data !== undefined)
                            e.data.selected = (e.id===ct.ruleID)
                    });                
                }
                else if(eq.length > 0)
                {
                    ct = eq[0];
                    i = 0;
                    this.setSelectedRule(ct.ruleID);
                    this.state.elements.forEach(e => { 
                        if(e.data !== undefined)
                            e.data.selected = (e.id===ct.ruleID)
                    });                
                }
                
                this.setState({
                    ExecQueue: eq, 
                    InternalExecQueue: ieq, 
                    Accumulator: ret.accumulator, 
                    currTask: ct, 
                    selectedIndex: i,
                    graphKey: uuid(), 
                    listKey: uuid()
                });
            }
            else
            {
                eq = JSON.parse(JSON.stringify(this.state.ExecQueue));
                ieq = JSON.parse(JSON.stringify(this.state.InternalExecQueue));
                eq.splice(0,1);
                ret.execQueue.forEach(t => {
                    eq.push(t);
                });
                ret.internalExecQueue.forEach(t => {
                    ieq.push(t);
                });
                i = -1;
                if(ieq.length > 0)
                {
                    ct = ieq[0];
                    i = 0;
                    this.setSelectedRule(ct.ruleID);
                    this.state.elements.forEach(e => { 
                        if(e.data !== undefined)
                            e.data.selected = (e.id===ct.ruleID)
                    });                
                }
                else if(eq.length > 0)
                {
                    ct = eq[0];
                    i = 0;
                    this.setSelectedRule(ct.ruleID);
                    this.state.elements.forEach(e => { 
                        if(e.data !== undefined)
                            e.data.selected = (e.id===ct.ruleID)
                    });                
                }
                this.setState({
                    ExecQueue: eq,
                    InternalExecQueue: ieq,
                    Accumulator: ret.accumulator, 
                    currTask: ct, 
                    selectedIndex: i,
                    graphKey: uuid(), 
                    listKey: uuid()
                });
            }
          })
        .catch(error => {
            this.setState({stepError: "Error while debugging. Cannot continue."});
        });
    }
  
    async doRun()
    {

        var eq: ITask[] = JSON.parse(JSON.stringify(this.state.ExecQueue));
        var ieq: ITask[] = JSON.parse(JSON.stringify(this.state.InternalExecQueue));

        var fromInternal: boolean = false;
        var acc: string = "";

        var task: IcpuIn = {
            task: eq[0],
            accumulator: this.state.Accumulator,
            internalExecQueue: fromInternal,
        };

        var running: boolean = true;
        while(running)
        {
            if(ieq.length > 0)
            {
                task = {
                    task: ieq[0],
                    accumulator: this.state.Accumulator,
                    internalExecQueue: true,
                };
            }
            else if(eq.length > 0)
            {
                task = {
                    task: eq[0],
                    accumulator: this.state.Accumulator,
                    internalExecQueue: false,
                };
            }
            else
            {
                running = false;
                break;
            }

            if(this.state.breakpoints.indexOf(task.task.ruleID) > -1)
            {
                break;
            }
            if(task.task.value === "@ACCUMULATOR@")
            {
                task.task.value = acc;
            }
            task.accumulator = acc;
            
            try
            {
                var response = await Post("ruleset/stepinternal" ,task);
                console.log(response);
                var ret: IcpuOut = response.data;
                if(task.internalExecQueue)
                {
                    ieq.splice(0,1);
                }
                else
                {
                    eq.splice(0,1);
                }
                ret.execQueue.forEach(t => {
                    eq.push(t);
                });
                ret.internalExecQueue.forEach(t => {
                    ieq.push(t);
                });
                acc = ret.accumulator;
            }
            catch(error)
            {
                running = false;
                this.setState({stepError: "Error while debugging. Cannot continue."});
            }
        }
        this.setState({
            ExecQueue: eq,
            InternalExecQueue: ieq,
            Accumulator: acc, 
            graphKey: uuid(), 
            listKey: uuid()
        });
    }
  
    
    render() {

        var props = <></>;

        if(this.state.selectedRule)
        {
            props = <>
            <Toggle label="Breakpoint" checked={this.state.breakpoints.indexOf(this.state.selectedRule?.id ?? "") > -1} onChange={()=>{this.toggleBreakpoint(this.state.selectedRule?.id ?? "")}}/>
            <Label>Rule Properties</Label>
            <RuleProperties client={this.props.client} readOnly rule={this.state.selectedRule} templates={this.state.templates} schedules={this.state.schedules} connections={this.state.connections} authConnections={this.state.authConnections}  />
            </>
        }

        const onRenderCell = (item?: ITask | undefined, index?: number, isScrolling?: boolean): JSX.Element => {
            if(item==null)
            {
                return <>UNKKNOWN RULE</>;
            }
            var r = this.findRule(item.ruleID,this.state.schedule.rule);
            if(r == null)
            {
                return <>UNABLE TO FIND RULE</>;
            }
            var bg = "Transparent"
            if(this.state.selectedIndex === (index ?? -1))
            {
                bg = "LightGray" ;
            }
            return (
              <Stack onClick={()=>{
                    console.log("ONCLICK",index)
                    this.setSelectedRule(item.ruleID);
                    this.state.elements.forEach(e => { 
                        if(e.data !== undefined)
                            e.data.selected = (e.id===item.ruleID)
                    });
                    this.setState({
                        currTask: item, 
                        selectedIndex: (index ?? -1),
                        graphKey: uuid(), 
                        listKey: uuid()
                    });
                }}
                style={{backgroundColor:  bg}}
              >
                <Stack horizontal tokens={{childrenGap: 10}}>
                {((index ?? -1) < this.state.InternalExecQueue.length) ? <FontIcon iconName="StepInsert"  /> :  <FontIcon iconName="Step"  />}
                {r.name}
                </Stack>
              </Stack>
            );
          };


        var task = <></>;
        if(this.state.currTask != null)
        {
            var setinput = <></>;
            if(this.state.schedule.rule.id === this.state.currTask.ruleID)
            {
                setinput = <PrimaryButton text="Set Input" onClick={()=>{this.setState({getInput: true});}} />
            }
            task = <>
                <Label>Rule Input Value</Label>
                {setinput}
                <TextField readOnly={this.state.schedule.rule.id !== this.state.currTask.ruleID} onChange={this.handleChange} value={this.state.currTask.value} multiline rows={5} />
            </>
        }

        var eq: ITask[] = JSON.parse(JSON.stringify(this.state.InternalExecQueue));
        this.state.ExecQueue.forEach(t => {
            eq.push(t);
        });

        var acc = <></>
        if(this.state.Accumulator !== "")
        {
            acc = <TextField label="Accumulator" name="acc" value={this.state.Accumulator} readOnly multiline rows={5} />
        }

          return (<>
            <Stack verticalFill>
                <Stack horizontal tokens={{ childrenGap: 5}}>
                    <h3 style={{paddingLeft: 20}}>{this.state.schedule.name}</h3>
                </Stack>
                <Stack horizontal grow verticalFill>
                <Stack grow={4} >
                    <ReactFlow 
                            key={this.state.graphKey}
                            onElementClick={this.onElementClick} 
                            defaultZoom={this.state.viewport.zoom}
                            defaultPosition={[this.state.viewport.x,this.state.viewport.y]}
                            nodeTypes={nodeTypes}
                            nodesConnectable={true}
                            snapToGrid={true}
                            elements={this.state.elements}>
                                
                                <Controls />
                        <Background  
                            gap={12}
                            size={1}
                            color={'#c0c0c0'}
                        />
                    </ReactFlow>
                </Stack>
                <Separator vertical />
                <Stack style={{width: 500}}>
                    <Stack horizontal tokens={{ childrenGap: 5}}>
                        <DefaultButton text="Step" iconProps={{ iconName: "TestStep" }} onClick={()=>this.doStep()} />
                        <DefaultButton text="Skip" iconProps={{ iconName: "ChevronRightEnd6" }} onClick={()=>this.doSkip()} />
                        <DefaultButton text="Run" iconProps={{ iconName: "Running" }} onClick={()=>this.doRun()} />
                        <DefaultButton text="Reset" iconProps={{ iconName: "Refresh" }} onClick={()=>this.doReset()} />
                    </Stack>
                    <Label>Execution Queue</Label>
                    <List key={this.state.listKey} items={eq} onRenderCell={onRenderCell} style={{height: 300, minHeight:300, maxHeight: 300, border: "1px solid gray"}} />
                    {acc}
                    {task}
                    {props}
                </Stack>
                </Stack>
                </Stack>  
                <Dialog
                 hidden={!this.state.stepError}
                 onDismiss={()=>{this.setState({stepError: "", ExecQueue: [], Accumulator: "", InternalExecQueue: []})}}
                 dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Debug Error',
                    closeButtonAriaLabel: 'Close',
                    subText: this.state.stepError,
                  }}
               >
                 <DialogFooter>
                   <PrimaryButton onClick={()=>{
                    this.setState({stepError: "", ExecQueue: [], Accumulator: "", InternalExecQueue: []})
                    }} text="Stop" />
                 </DialogFooter>
               </Dialog>                 
               <Dialog
                 hidden={!this.state.getInput}
                 onDismiss={()=>{this.setState({getInput: false})}}
                 dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Input Value',
                    closeButtonAriaLabel: 'Close',
                    subText: "Enter the value for the ruleset input",
                  }}
               >
                <TextField label="Input Value" value={this.state.inputValue} name="param" onChange={this.handleChange} />
                 <DialogFooter>
                   <PrimaryButton onClick={()=>{
                    this.setState({getInput: false})
                    }} text="OK" />
                 </DialogFooter>
               </Dialog>                 
               </>
        );
      };
      
}

export default injectIntl(Debugger);