import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  ITemplate, IPagination,  } from './IDeus';

import {  Post } from './authorisation/AMAPI';

import {  
    Stack, 
    DetailsList, IColumn, SelectionMode, 
    IDetailsListProps, IDetailsRowStyles, DetailsRow, Label, 
} from '@fluentui/react';
import { Separator } from '@fluentui/react';

import Folders from './Folders' 

type IState = {
    templates: ITemplate[],
    selectedTemplate: ITemplate,

    currentFolder: string

    pagination: IPagination,
    showDialog: boolean,
    doDelete: boolean,
    doCopy: boolean,
    showCalled: boolean,

    actionID: string,

    copyName: string,
}
  
  
interface IProps extends WrappedComponentProps {
  client: string,
  templateSelected?: any
}


class ScheduleFinder extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            templates: [],
            selectedTemplate: {} as ITemplate,

            pagination: {
                totalCount: 0,
                current: 0,
                pageSize: 20,
                sortField: "",
                sortOrder: "asc"
            },

            currentFolder: "Uncategorized",

            showDialog: false,
            doDelete: false,
            doCopy: false,

            showCalled: false,

            actionID: "",

            copyName: "",

        };

        this.onPageChange = this.onPageChange.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onFolderSelected = this.onFolderSelected.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
    }

    componentDidUpdate(prevProps: IProps) {
      if (prevProps.client !== this.props.client) {
        this.authenticatedrefresh();
          }
    }

 
    
    componentDidMount()
    {
      this.refresh();
    }

    
  
    refresh()
    {
        this.authenticatedrefresh();
    }


    

    onPageChange(page: number)
    {
      var p: IPagination = Object.assign(this.state.pagination);
      p.current = page;
      this.setState({pagination: p});
      this.authenticatedrefresh();
    }


    private _onColumnClick = (ev: any, column: IColumn): void => {
    };      

    private authenticatedrefresh()
    {
      const params = this.state.pagination;

      const paging = {
        current: params.current + 1,
        pagesize: params.pageSize,
        sortfield: params.sortField,
        sortorder: params.sortOrder,
      }

      const post = {
        paging: paging,
        currentFolder: this.state.currentFolder,
        currentClient: this.props.client
      }

      Post("template/Search",post)
        .then(response => {
              var p = this.state.pagination;
              p.totalCount = response.data.totalCount;   
              this.setState({ 
                templates: response.data.results, 
                pagination: p, 
              });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    


    private _onItemInvoked = (item: ITemplate): void => {
      console.log("ITEM INVOKED")
        this.setState({selectedTemplate: item});
        if(this.props.templateSelected != null)
        {
          this.props.templateSelected(item);
        }
    };

    
   
      public onFolderSelected(folder: string)
      {
        this.setState({currentFolder: folder},()=>{
          this.authenticatedrefresh();
        });
      }

      render() 
    {

        const columns: IColumn[] = [
          {
              key: 'name',
              name: this.props.intl.formatMessage({ id: 'schedule.name' }),
              fieldName: 'name',
              minWidth: 0,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              isSorted: true,
              isSortedDescending: false,
              sortAscendingAriaLabel: 'Sorted A to Z',
              sortDescendingAriaLabel: 'Sorted Z to A',
              onColumnClick: this._onColumnClick,
              data: 'string',
              isPadded: true,
            },
        ];

        return (
          <>
          <Stack>
            <Stack horizontal grow verticalFill>
              <Stack grow={2}>
                <Label>Folders</Label>
                <Folders clientid={this.props.client} onFolderSelected={this.onFolderSelected}  />
              </Stack>
                <Separator vertical />
                <Stack grow={3} style={{paddingTop: 5}} >
                    <DetailsList  items={this.state.templates} 
                                columns={columns} 
                                onItemInvoked={this._onItemInvoked}
                                onRenderRow={this._onRenderRow}
                                selectionMode={SelectionMode.none}
                    />


                </Stack>
            </Stack>
          </Stack>
          </>        );
    }

    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
      const customStyles: Partial<IDetailsRowStyles> = {};
      if (props) {
        if(this.state.selectedTemplate){
          if (props.item.id  === this.state.selectedTemplate.id) {
            // Every other row renders with a different background color
            customStyles.root = { backgroundColor: "lightskyblue" };
          }
    
          }
          return <DetailsRow {...props} styles={customStyles} />;
      }
      return null;
    };
}

export default injectIntl(ScheduleFinder);