import { IRule, eOpCode, isJsonString } from "./IDeus";

export class Validate {
    isValid: boolean;
    readonly rule: IRule;
    paramMessages: {[key: string]: string};

    constructor(inRule: IRule) {
        this.isValid = true;
        this.rule = inRule;
        this.paramMessages = {};
        switch(this.rule.opCode)   {
            case eOpCode.ExecLog:
                this.validateExecLog();
                break;
            case eOpCode.ExecREST:
                this.validateExecREST();
                break;
            case eOpCode.ExecGenerateSequence:
                this.validateExecSequence();
                break;
            case eOpCode.ExecAggregate:
                this.validateExecAggregate();
                break;
            case eOpCode.ExecAddToAggregate:
                this.validateExecAddToAggregate();
                break;
            case eOpCode.ExecBooleanExpression:
                this.validateExecBooleanExpression();
                break;
            case eOpCode.ExecSchedule:
                this.validateExecSchedule();
                break;
            case eOpCode.ExecDatabaseQuery:
                this.validateExecDatabase();
                break;
        }
        // console.log("Validate",this.rule);
        // console.log("returns valid:",this.isValid, this.paramMessages);
    }

    

    validateExecSchedule() {
        this.paramMessages = {
            "schedule": "",
        };
        if((this.rule.opParameters["schedule"] ?? "") === "") {
            this.isValid = false;
            this.paramMessages["schedule"] = "Schedule is required";     
            console.log("VALIDATE SCHEDULE:",this.rule);       
        }
    }


    validateExecBooleanExpression() {
        this.paramMessages = {
            "expression": "",
        };
        if((this.rule.opParameters["expression"] ?? "") === "") {
            this.isValid = false;
            this.paramMessages["expression"] = "Boolean expression is required";            
        }
    }

    validateExecAggregate() {
        this.paramMessages = {
            "template": "",
        };
        if((this.rule.opParameters["template"] ?? "") === "") {
            this.isValid = false;
            this.paramMessages["template"] = "Template is required";            
        }
        else
        {
            if(!isJsonString(this.rule.opParameters["template"])) {
                this.isValid = false;
                this.paramMessages["template"] = "Template is not valid JSON";            
            }
        }
    }
   
    validateExecAddToAggregate() {
        this.paramMessages = {
            "outputelement": "",
        };
        if((this.rule.opParameters["outputelement"] ?? "") === "") {
            this.isValid = false;
            this.paramMessages["outputelement"] = "Output is required";            
        }
    }

    validateExecLog() {
        this.paramMessages = {
            "entry": "",
            "connection": "",
            "title": ""
        };
        if((this.rule.opParameters["entry"] ?? "") === "") {
            this.isValid = false;
            this.paramMessages["entry"] = "Entry is required";            
        }
        if((this.rule.opParameters["connection"] ?? "") === "") {
            this.isValid = false;
            this.paramMessages["connection"] = "Connection is required";            
        }
        if((this.rule.opParameters["title"] ?? "") === "") {
            this.isValid = false;
            this.paramMessages["title"] = "Title is required";            
        }
    }

    validateExecSequence() {
        var parts;
        this.paramMessages = {
            "sequence": "",
            "format": "",
        };
        if((this.rule.opParameters["sequence"] ?? "") === "") {
            this.isValid = false;
            this.paramMessages["sequence"] = "Sequence definition is required";            
        }
        else {
            if(this.rule.opParameters["sequence"].includes("..")) {
                parts = this.rule.opParameters["sequence"].split("..");
                if(parts.length !== 2) {
                    this.isValid = false;
                    this.paramMessages["sequence"] = "Numerical sequence definition needs from/to";     
                }       
                else if((parts[0] ?? "")==="" || (parts[1] ?? "")==="") {
                    this.isValid = false;
                    this.paramMessages["sequence"] = "Numerical Up To sequence definition needs from/to";     
                }
            }
            else if(this.rule.opParameters["sequence"].includes("->")) {
                parts = this.rule.opParameters["sequence"].split("->");
                if(parts.length !== 2) {
                    this.isValid = false;
                    this.paramMessages["sequence"] = "Numerical Up To sequence definition needs from/to";     
                }
                else if((parts[0] ?? "")==="" || (parts[1] ?? "")==="") 
                {
                    this.isValid = false;
                    this.paramMessages["sequence"] = "Numerical Up To sequence definition needs from/to";     
                }
            }
            else if(this.rule.opParameters["sequence"].includes("<-")) {
                parts = this.rule.opParameters["sequence"].split("<-");
                if(parts.length !== 2) {
                    this.isValid = false;
                    this.paramMessages["sequence"] = "Numerical Down To sequence definition needs from/to";     
                }       
                else if((parts[0] ?? "")==="" || (parts[1] ?? "")==="") 
                {
                    this.isValid = false;
                    this.paramMessages["sequence"] = "Numerical Down To sequence definition needs from/to";     
                }
            }
            else if(this.rule.opParameters["sequence"].includes("/")) {
                parts = this.rule.opParameters["sequence"].split("/");
                if(parts.length < 2) {
                    this.isValid = false;
                    this.paramMessages["sequence"] = "A character sequence needs at least 2 options";     
                }       
                else if((parts[0] ?? "")==="" || (parts[1] ?? "")==="") 
                {
                    this.isValid = false;
                    this.paramMessages["sequence"] = "A character sequence needs at least 2 options";     
                }
            }
            else {
                this.isValid = false;
                this.paramMessages["sequence"] = "Invalid sequence format";     
            }
        }
        if((this.rule.opParameters["format"] ?? "") === "") {
            this.isValid = false;
            this.paramMessages["connection"] = "Output format is required";            
        }
    }

    validateExecREST() {
        this.paramMessages = {
            "url": "",
            "method": "",
            "body": "",
            "format": "",
            "connection": "",
            "outputelement": "",
            "authConnection": ""
        };
        if((this.rule.opParameters["connection"] ?? "") === "") {
            this.isValid = false;
            this.paramMessages["connection"] = "Connection is required";            
        }
        if((this.rule.opParameters["method"] ?? "") === "") {
            this.isValid = false;
            this.paramMessages["method"] = "Method is required";            
        }

    }

    validateExecDatabase(){
        this.paramMessages = {
            "connection": "",
            "database": "",
            "type": "",
            "query": ""
        };
        if((this.rule.opParameters["connection"] ?? "") === "") {
            this.isValid = false;
            this.paramMessages["connection"] = "Connection is required";            
        }
        if((this.rule.opParameters["database"] ?? "") === "") {
            this.isValid = false;
            this.paramMessages["database"] = "Database is required";            
        }
        if((this.rule.opParameters["type"] ?? "") === "") {
            this.isValid = false;
            this.paramMessages["type"] = "Database Command Type is required";            
        }
        else {
            switch(this.rule.opParameters["type"])
            {
                case "QUERY":
                    if((this.rule.opParameters["query"] ?? "") === "" && (this.rule.opParameters["table"] ?? "") === "") {
                        this.isValid = false;
                        this.paramMessages["query"] = "Database Query is required";            
                    }
                    break;
                case "INSERT":
                    if((this.rule.opParameters["query"] ?? "") === "") {
                        this.isValid = false;
                        this.paramMessages["query"] = "Table Name is required";            
                    }
                    break;
                case "NONQUERY":
                    if((this.rule.opParameters["query"] ?? "") === "") {
                        this.isValid = false;
                        this.paramMessages["query"] = "Database Command is required";            
                    }
                    break;
            }
        }
    }

  }