import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  IClient, IPagination, rationalizeNumber } from './IDeus';


import { Delete,  Post, Put } from './authorisation/AMAPI';


import {  
    Stack, Text,
    IconButton, PrimaryButton, 
    DetailsList, IColumn, SelectionMode, 
    DialogType, Dialog, DialogFooter, DefaultButton,
} from '@fluentui/react';
import { Separator } from '@fluentui/react';

import ClientProperties from './ClientProperties' 


type IState = {
    clients: IClient[],
    selectedClient: IClient,

    pagination: IPagination,
    showDialog: boolean,

    doDelete: boolean,
    actionID: string,
}
  
  
interface IProps extends WrappedComponentProps {
}


class Clients extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            clients: [],
            selectedClient: {} as IClient,

            pagination: {
                totalCount: 0,
                current: 0,
                pageSize: 10,
                sortField: "",
                sortOrder: "asc"
            },

            showDialog: false,

            doDelete: false,

            actionID: ""


        };

        this.handleChange = this.handleChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.createClient = this.createClient.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
    }

    componentDidUpdate(prevProps: IProps) {
    }

// picker    

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var r = JSON.parse(JSON.stringify(this.state.selectedClient));
        switch(name)
        {
            case "name":
                r.name = value;
                break;
            case "description":
                r.description = value;
                break;
            case "opParameter":
                r.opParameter = value;
                break;
        }
        this.setState({selectedClient: r});
    }

    componentDidMount()
    {
      this.refresh();
    }

    createClient()
    {
        this.setState({
            selectedClient: {
                id: "",
                name: "New Client",
                addressLine1: "",
                addressLine2: "",
                city: "",
                country: "",
                postcode: "",
                contactEmail: "",
                active: true,
                currentAPICallCount: 0,
                currentExecRuleCount: 0,
                currentScheduleCallCount: 0,
                externalBytesSent: 0,
                externalBytesReceived: 0,
            },

        });
    }
  
    refresh()
    {
        this.authenticatedrefresh();
    }

    public onDelete()
    {
      Delete("client/" + this.state.actionID)
      .then(response => {
        this.setState({doDelete: false});
        this.authenticatedrefresh();
      })
      .catch(function (error) {
        console.log(error);
      });
    }

    showDialog(context: string, item: any)
    {
      this.setState({doDelete: true, actionID: item.id, });
    }


    onPageChange(page: number)
    {
      var p: IPagination = Object.assign(this.state.pagination);
      p.current = page;
      this.setState({pagination: p});
      this.authenticatedrefresh();
    }


    private _onColumnClick = (ev: any, column: IColumn): void => {
    };      

 
    private authenticatedrefresh()
    {
      const params = this.state.pagination;

      const paging = {
        current: params.current + 1,
        pagesize: params.pageSize,
        sortfield: params.sortField,
        sortorder: params.sortOrder,
      }

      const post = {
        paging: paging,
      }

      Post("client/Search",post)
        .then(response => {
              var p = this.state.pagination;
              p.totalCount = response.data.totalCount;   
              this.setState({ 
                clients: response.data.results, 
                pagination: p, 
              });
        })
        .catch(function (error) {
          console.log(error);
        });

    }

    onSave(client: IClient)
    {
        if(client.id === "")
        {
            Post("client",client)
            .then(response => {
                client.id = response.data;
                  this.setState({ 
                    selectedClient: client, 
                  });
                  this.authenticatedrefresh();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        else
        {
            Put("client/" + client.id,client)
            .then(response => {
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        this.authenticatedrefresh();
    }


    private _onItemInvoked = (item: IClient): void => {
        this.setState({selectedClient: item});
      };

    render() 
    {

        var props = <></>;

        if(this.state.selectedClient)
        {
            props = <ClientProperties client={this.state.selectedClient}  onChange={this.onSave}  />
        }


        const columns: IColumn[] = [
            {
              key: 'name',
              name: this.props.intl.formatMessage({ id: 'connection.name' }),
              fieldName: 'name',
              minWidth: 0,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              onColumnClick: this._onColumnClick,
              data: 'string',
              isPadded: true,
              onRender: (item: any) => {
                var col = "white" ;
                if(!item.active) col = "silver";
                // return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.deleteClient(item.id)} title="Delete" ariaLabel="Delete" disabled={!item.canDelete} />
                return <Text style={{color: col}}>{item.name}</Text>
               },
             },
             {
              key: 'rulecount',
              name: "Rule Executions",
              fieldName: 'currentExecRuleCount',
              minWidth: 0,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              onColumnClick: this._onColumnClick,
              data: 'number',
              isPadded: true,
             },
             {
              key: 'apicount',
              name: "API Calls",
              fieldName: 'currentAPICallCount',
              minWidth: 0,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              onColumnClick: this._onColumnClick,
              data: 'number',
              isPadded: true,
             },
             {
              key: 'schedcalls',
              name: "Scheduled Execs",
              fieldName: 'currentScheduleCallCount',
              minWidth: 0,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              onColumnClick: this._onColumnClick,
              data: 'number',
              isPadded: true,
             },
             {
              key: 'datasize',
              name: "Data Usage",
              minWidth: 0,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              onColumnClick: this._onColumnClick,
              data: 'number',
              isPadded: true,
              onRender: (item: IClient) => {
                var val = rationalizeNumber(item.externalBytesReceived + item.externalBytesSent);
                // return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.deleteClient(item.id)} title="Delete" ariaLabel="Delete" disabled={!item.canDelete} />
                return <Text >{val.value} {val.scale}</Text>
               },
             },
            {
              key: 'delete',
              name: 'Delete',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               // return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.deleteClient(item.id)} title="Delete" ariaLabel="Delete" disabled={!item.canDelete} />
               return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.showDialog("delete", item )} title="Delete" ariaLabel="Delete"  />
              },
            },
  
        ];

        console.log(this.state.clients);
        return (<>
            <Stack horizontal grow verticalFill>
                <Stack grow={2} >
                  <Stack horizontal tokens={{childrenGap: 5}} style={{paddingTop: 5}}>
                  <PrimaryButton text="Create new client" onClick={()=>this.createClient()} width={300} />

                  </Stack>
                    <DetailsList  items={this.state.clients} 
                                columns={columns} 
                                onItemInvoked={this._onItemInvoked}
                                selectionMode={SelectionMode.none}
                    />


                </Stack>
                <Separator vertical />
                <Stack grow={2} style={{width: 400}}>
                    {props}
                </Stack>
            </Stack>
            <Dialog
              hidden={!this.state.doDelete}
              onDismiss={()=>{this.setState({doDelete: false})}}
              dialogContentProps={{
                  type: DialogType.normal,
                  title: 'Delete Client',
                  closeButtonAriaLabel: 'Close',
                  subText: 'Are you sure you want to delete this client. This will delete everything associated with this client and is non reversible.',
                }}
            >
            <DialogFooter>
              <PrimaryButton onClick={()=>{this.onDelete()}} text="Delete" />
              <DefaultButton onClick={()=>{this.setState({doDelete: false})}} text="Cancel" />
            </DialogFooter>
            </Dialog>
            </>
        );
    }
}

export default injectIntl(Clients);