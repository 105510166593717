import 'rc-time-picker/assets/index.css';

import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';


import {  
    Stack, 
    TextField, 
    DatePicker, 
    Dropdown,
    IDropdownOption,
} from '@fluentui/react';



import { ITranslationObject } from '../IDeus';


 


type IState = {
    object: ITranslationObject
}
  
  
interface IProps extends WrappedComponentProps {
    object: ITranslationObject,
    onChange?: any,
}



class ConstantProperties extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            object: this.props.object
        };


    }


    componentDidUpdate(prevProps: IProps) {
        if (prevProps.object !== this.props.object) {
            this.setState({
                object: this.props.object,
            });
          }
    }

    isJsonString(str: string) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

 
    render() 
    {
       
          
        if(this.state.object == null) return <>Select an object from the graph...</>;
        if(this.state.object.id == null) return <>Select an object from the graph...</>;

     

        const dataTypes: IDropdownOption[] = [
            { key: "STRING", text: 'String', data: { icon: 'database.svg' } },
            { key: "NUMBER", text: 'Number', data: { icon: 'database.svg' } },
            { key: "NULL", text: 'Null', data: { icon: 'database.svg' } },
            { key: "BOOLEAN", text: 'Boolean', data: { icon: 'database.svg' } },
            { key: "DATE", text: 'Date', data: { icon: 'database.svg' } },
            { key: "NOW", text: 'Now', data: { icon: 'database.svg' } },
            { key: "UNIQUE", text: 'Unique Value', data: { icon: 'database.svg' } },
          ];

          const boolValues: IDropdownOption[] = [
            { key: "TRUE", text: 'True', data: { icon: 'database.svg' } },
            { key: "FALSE", text: 'False', data: { icon: 'database.svg' } },
          ];

          const uniqueValues: IDropdownOption[] = [
            { key: "PIN4", text: '4 Digit PIN', data: { icon: 'database.svg' } },
            { key: "PIN5", text: '5 Digit PIN', data: { icon: 'database.svg' } },
            { key: "PIN6", text: '6 Digit PIN', data: { icon: 'database.svg' } },
            { key: "GUID", text: 'GUID', data: { icon: 'database.svg' } },
          ];

          var type = this.state.object.opParameters["type"];
        var val = this.state.object.opParameters["value"];

        var valinput = <></>;
        switch(type)
        {
            case "STRING":
            case "NUMBER":
                valinput = <TextField 
                    label="Value" 
                    value={val}
                    name="template"                     
                    onChange={(ev: any) => {
                        const target = ev.target;
                        const value : string = target.value;
                        var obj : ITranslationObject = JSON.parse(JSON.stringify(this.state.object));
                        obj.opParameters["value"] = value;
                        this.setState({object: obj});                    
                        this.props.onChange(obj);
                    }}
                />
                break;
            case "BOOLEAN":
                valinput = <Dropdown
                    placeholder="Select value"
                    label="Boolean Value"
                    ariaLabel="Boolean Value"
                    options={boolValues}
                    selectedKey={val}
                    onChange={(ev: any,option?: any) => {
                        var obj : ITranslationObject = JSON.parse(JSON.stringify(this.state.object));
                        obj.opParameters["value"] = option.key;
                        this.setState({object: obj});                    
                        this.props.onChange(obj);
                    }}
                />
                break;
            case "UNIQUE":
                valinput = <Dropdown
                                placeholder="Select value"
                                label="Unique Value"
                                ariaLabel="Unique Value"
                                options={uniqueValues}
                                selectedKey={val}
                                onChange={(ev: any,option?: any) => {
                                    var obj : ITranslationObject = JSON.parse(JSON.stringify(this.state.object));
                                    obj.opParameters["value"] = option.key;
                                    this.setState({object: obj});                    
                                    this.props.onChange(obj);
                                }}
                            />
                break;
            case "NOW":
                valinput = <TextField
                                label="Offset" 
                                value={val}
                                onChange={(ev: any) => {
                                    const target = ev.target;
                                    const value : string = target.value;
                                    var obj : ITranslationObject = JSON.parse(JSON.stringify(this.state.object));
                                    obj.opParameters["value"] = value;
                                    this.setState({object: obj});                    
                                    this.props.onChange(obj);
                                }}
                            />
                break;
            case "DATE":
                var dateval;
                try
                {
                    dateval = new Date(val);
                }
                catch(e)
                {
                    dateval = new Date();
                }
                valinput = <DatePicker
                                label="Start date"
                                allowTextInput
                                ariaLabel="Select a date"
                                value={dateval}
                                onSelectDate={(newval) => {
                                    if(newval == null) return;
                                    var obj : ITranslationObject = JSON.parse(JSON.stringify(this.state.object));
                                    obj.opParameters["value"] = newval.toISOString();
                                    this.setState({object: obj});                    
                                    this.props.onChange(obj);
                                }}
                            />
                break;
        }

        
        return (
            <>
            <Stack grow verticalFill style={{padding: 10, borderLeft: "1px solid grey", width: "100%"}} >
                <Stack style={{ paddingBottom: 10}}>
                    <Stack horizontal tokens={{ childrenGap: 10}} >
                        <h3>Constant</h3>
                    </Stack>
                </Stack>
                <Dropdown
                    placeholder="Select data type"
                    label="Data Type"
                    ariaLabel="Template Language"
                    options={dataTypes}
                    selectedKey={type}
                    onChange={(ev: any,option?: any) => {
                        var obj : ITranslationObject = JSON.parse(JSON.stringify(this.state.object));
                        obj.opParameters["type"] = option.key;
                        switch(option.key)
                        {
                            case "DATE":
                                obj.opParameters["value"] = new Date().toISOString();
                                break;
                            case "BOOLEAN":
                                obj.opParameters["value"] = "TRUE";
                                break;
                            case "NUMBER":
                                obj.opParameters["value"] = "0";
                                break;
                            case "STRING":
                                obj.opParameters["value"] = "";
                                break;
            
                        }
                        this.setState({object: obj});                    
                        this.props.onChange(obj);
                    }}
                    />
                {valinput}
            </Stack>

            </>
        );
    }
}

export default injectIntl(ConstantProperties);