import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import store from './store/store'

import reportWebVitals from './reportWebVitals';
import {IntlProvider} from 'react-intl';

import awsconfig from './aws-exports';
import Amplify from 'aws-amplify';

import English from './lang/en.json';
import { initializeIcons } from '@fluentui/react/lib/Icons';

import { Provider, teamsTheme } from '@fluentui/react-northstar'
//import { Provider as StoreProvider}  from 'react-redux'


Amplify.configure(awsconfig);

const locale = navigator.language;

initializeIcons(/* optional base url */);

ReactDOM.render(
  <React.StrictMode>
 <Provider theme={teamsTheme}>
    <IntlProvider locale ={locale} messages={English}>
        <App />
    </IntlProvider>
  </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
