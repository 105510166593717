import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  IDataDefinition,  uuid, } from './IDeus';

import {  Get,  } from './authorisation/AMAPI';

import {  
    Stack, 
    PrimaryButton,
    Text,
    Modal,  
    Pivot, PivotItem, List, TextField, 
} from '@fluentui/react';
import { Separator } from '@fluentui/react';

import DDLField from './DDLField'; 

type IState = {
    showModal: boolean,
    dataDefinition: IDataDefinition,
    json: string,
}
  
interface IProps extends WrappedComponentProps {
  ddlId: string,
  onDismiss: any
  isOpen: boolean
}


class DDLViewer extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            dataDefinition: {} as IDataDefinition,
            json: "",
            showModal: false,

        };
        this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
    }

    componentDidUpdate(prevProps: IProps) {
      if (prevProps.ddlId !== this.props.ddlId) {
        this.authenticatedrefresh();
      }
    }

    componentDidMount()
    {
      this.authenticatedrefresh();
    }

    private authenticatedrefresh()
    {
      if(this.props.ddlId === "") return;

      Get("datadefinition/" + this.props.ddlId)
      .then(response => {
            this.setState({ 
              dataDefinition: response.data, 
            });
      })
      .catch(function (error) {
        console.log(error);
      });

      Get("datadefinition/template/" + this.props.ddlId)
      .then(response => {
        console.log(response.data);
          this.setState({
            json: response.data,
          });
        })
      .catch(function (error) {
        console.log(error);
      });
    }


    render() 
    {

      const onRenderCell =(item: any, index: number | undefined) => {
        return (
          <div>
            <DDLField 
                      onSelected={(id:string)=>{}} 
                      displayOnly
                      field={item} 
                      key={uuid()} 
                      />

          </div>
        );
      };

        return (
          <Modal
          isOpen={this.props.isOpen}
          onDismiss={()=>{this.props.onDismiss();}}
          isBlocking={false}
          >
            <Stack style={{margin: 10, width: 600, height: 400}}>
                <Text variant="xLarge">DDL Viewer</Text>
                <Separator/>
                <Stack horizontal grow verticalFill>
                  <Pivot>
                    <PivotItem headerText="Fields">
                      <List
                        style={{overflowY: "auto", height: "100%"}}
                        items={this.state.dataDefinition.fields}
                        onRenderCell={onRenderCell}
                      />
                    </PivotItem>
                    <PivotItem headerText="Json">
                      <TextField multiline rows={10} value={this.state.json}/>
                    </PivotItem>
                  </Pivot>
                </Stack>
              </Stack>
              <Separator/>
              <Stack horizontal grow style={{padding: 10}} horizontalAlign="end">
                <PrimaryButton text="Cancel" onClick={()=>{this.props.onDismiss();}} />
              </Stack>
          </Modal>
          );
    }

}

export default injectIntl(DDLViewer);