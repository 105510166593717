import 'rc-time-picker/assets/index.css';

import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { 
         IConnection,
         IAuthConnection,
         IKey
        } from '../IDeus';

import {  
    Stack, 
    TextField, 
    Dropdown,
    Label,
    IconButton,
} from '@fluentui/react';
import React from 'react';

import KeyFinder from '../KeyFinder';


type IState = {
    opParameters: {[key: string]: string},
    dataerror: string,
    onGetKey: boolean,
}
  
  
interface IProps extends WrappedComponentProps {
    opParameters: {[key: string]: string},
    readOnly?: boolean,
    client: string,
    onChange?: any,
    connections: IConnection[],
    authConnections: IAuthConnection[],
}



class EncryptionProperties extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            opParameters: this.props.opParameters,
            dataerror: "",
            onGetKey: false,
        };

        this.handleChange = this.handleChange.bind(this);
    }


    componentDidUpdate(prevProps: IProps) {
        if (prevProps.opParameters !== this.props.opParameters) {
            this.setState({
                opParameters: this.props.opParameters,
            });
          }
    }


    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var parameters  = {...this.state.opParameters};
        parameters[name] = value;
        this.setState({opParameters: parameters});
        this.props.onChange(parameters);
    }

    onError(error: any) {
        console.error(error);
    }

    
    render() 
    {
        if(this.state.opParameters == null) return <>Select a rule set from the list...</>;

        return (<>
                <Stack>
                    <Label>Encryption Direction</Label>
                    <Dropdown
                            disabled={this.props.readOnly}
                            placeholder="Select direction..."
                                options={[
                                    {key: "ENCRYPT", text: "Encrypt" },
                                    {key: "DECRYPT", text: "Decrypt" },
                                    {key: "HASH", text: "Hash" },
                                ]}
                                selectedKey={this.state.opParameters["direction"]}
                                onChange={(ev: any,option?: any) =>
                                    {
                                        var param  = {...this.state.opParameters};
                                        param["direction"] = option.key;
                                        this.setState({opParameters: param}) ;
                                        this.props.onChange(param);
                                    }}
                        />
                        <Label>Key</Label>
                        <Stack horizontal>
                            <Stack.Item grow>
                            <TextField readOnly value={this.state.opParameters["keyname"]}   />
                            </Stack.Item>
                            <IconButton iconProps={{iconName: "Search"}} 
                            onClick={() => {
                                this.setState({onGetKey: true});
                            }}/>                        
                        </Stack>

                    <TextField readOnly={this.props.readOnly} multiline rows={6} label={this.props.intl.formatMessage({ id: 'rule.fields' })} value={this.state.opParameters["fields"]}  name="fields" onChange={this.handleChange} />
                    </Stack>
                    <KeyFinder 
                        isOpen={this.state.onGetKey}
                        client={this.props.client}
                        onDismiss={()=>{this.setState({onGetKey: false})}}
                        onKeySelected={(key: IKey)=>{
                            var param  = {...this.state.opParameters};
                            param["keyid"] = key.id;
                            param["keyname"] = key.name;
                            this.setState({opParameters: param}) ;
                            this.props.onChange(param);
                        }}
                    /> 
        </>
        );
    }
}

export default injectIntl(EncryptionProperties);