import 'rc-time-picker/assets/index.css';

import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { IAuthConnection,  } from './IDeus';

import {  
    Stack, 
    TextField, 
    PrimaryButton, DefaultButton, IconButton,
    Dialog, DialogFooter, DialogType,
    Dropdown,
    IDropdownOption,
    DetailsList,
    IColumn,
    SelectionMode,
    Separator,
    Image,
} from '@fluentui/react';

import FolderPicker from './FolderPicker';

import moment, { Moment } from 'moment';

import { Label } from 'reactstrap';
import { Get } from './authorisation/AMAPI';



type IState = {
    authorizer: IAuthConnection
    shouldSave: boolean
    currDate: Date | null | undefined
    currTime: Moment
    editHeader: boolean
    doDelete: boolean
    doAuthorizing: boolean
    headerName: string
    headerOrigName: string
    headerValue: string
    headerIndex: number
    doMoveFolder: boolean
}
  
  
interface IProps extends WrappedComponentProps {
    connection: IAuthConnection,
    onChange?: any,
    client: string
}


class AuthorizerProperties extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        var m : Moment = moment();
        var d : Date = new Date();

        this.state = {
            authorizer: this.props.connection,
            shouldSave: false,
            currDate: d,
            currTime: m,
            editHeader: false,
            doDelete: false,
            doAuthorizing: false,
            headerName: "",
            headerOrigName: "",
            headerValue: "",
            headerIndex: -1,
            doMoveFolder: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleParamChange = this.handleParamChange.bind(this);
        this.handleComboChange = this.handleComboChange.bind(this);
        this.handleClassNameChange = this.handleClassNameChange.bind(this);

        this.saveHeader = this.saveHeader.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.doSave = this.doSave.bind(this);

        this.authorize = this.authorize.bind(this);
    }

    onDelete() {
        if(this.state.headerIndex < 0)  return;
        var r = JSON.parse(JSON.stringify(this.state.authorizer));
        delete r.headers[this.state.headerName];
        this.setState({
            doDelete: false,
            authorizer: r
        });
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.connection !== this.props.connection) {
    
            this.setState({
                authorizer: this.props.connection,
                shouldSave: false
            });
          }
      }


     
  

      doSave(r: IAuthConnection)
      {
          console.log("doSave");
          if(this.props.onChange == null)     return;
  
          this.props.onChange(r);
      }
  
    handleChange(event: any)
    {
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var conn : IAuthConnection = JSON.parse(JSON.stringify(this.state.authorizer));
        console.log("handleChange: " + name + " = " + value);
        console.log(conn);
        switch(name)
        {
            case "name":
                conn.name = value;
                break;
            case "description":
                conn.description = value;
                break;
            case "headerName":
                this.setState({headerName: value});
                return;
            case "headerValue":
                this.setState({headerValue: value});
                return;
    
            }
        this.setState({authorizer: conn, shouldSave: true});
    }


    handleComboChange(ev: any,option: any,name: string)
    {
        var conn : IAuthConnection = JSON.parse(JSON.stringify(this.state.authorizer));

        conn.parameters[name] = option.key;
        this.setState({authorizer: conn, shouldSave: true});
    }

    handleClassNameChange(ev: any,option: any)
    {
        var auth : IAuthConnection = JSON.parse(JSON.stringify(this.state.authorizer));

        for (const key in auth.parameters) 
        {
            delete auth.parameters[key];
        }
        auth.className = option.key;
        switch(option.key)
        {
            case "BasicAuthorizer":
                auth.parameters["username"] = "";
                auth.parameters["password"] = "";
                break;
            case "AuthorisorOAuth":
                auth.parameters["clientid"] = "";
                auth.parameters["clientsecret"] = "";
                auth.parameters["authenticateurl"] = "";
                auth.parameters["scope"] = "";
                auth.parameters["state"] = "";
                auth.parameters["authtoken"] = "";
                break;
            case "AWS4Authorizer":  
                auth.parameters["region"] = "";
                auth.parameters["clientid"] = "";
                auth.parameters["poolid"] = "";
                auth.parameters["username"] = "";
                auth.parameters["password"] = "";
                auth.parameters["tokenname"] = "";
                auth.parameters["tokentype"] = "";
                auth.parameters["authkey"] = "";
                break;
    
        }
        this.setState({authorizer: auth, shouldSave: true});
    }


    handleParamChange(event: any){
        const target = event.target;
        var value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var conn : IAuthConnection = JSON.parse(JSON.stringify(this.state.authorizer));
        if(name==="authenticateurl")
        {
            if(value.toLowerCase().substring(0,8) === "https://")   value = value.substring(8);
            if(value.toLowerCase().substring(0,7) === "http://")   value = value.substring(8);
        }
        conn.parameters[name] = value;

        this.setState({authorizer: conn, shouldSave: true});
    }

    componentDidMount()
    {
      this.refresh();
    }

    onSave()
    {
        var s = this.state.authorizer;
        if(this.props.onChange != null)
        {
            this.props.onChange(s);
        }        
        this.setState({ shouldSave: false });
    }


    refresh()
    {

    }

    saveHeader()
    {
        var r = JSON.parse(JSON.stringify(this.state.authorizer));
        if(this.state.headerIndex < 0)  {
            r.headers[this.state.headerName] = this.state.headerValue;
            this.setState({editHeader: false, shouldSave: true, authorizer: r});
            return;
        };
        delete r.headers[this.state.headerOrigName];
        r.headers[this.state.headerName] = this.state.headerValue;
        this.setState({editHeader: false, shouldSave: true, authorizer: r});
    }

    authorize()
    {
        Get("authorizers/authorize/" + this.state.authorizer.id)
        .then(response => {
          this.setState({doAuthorizing: true});
        })
        .catch(function (error) {
          console.log(error);
        });

    }

    
  




    render() 
    {
        if(this.state.authorizer == null) return <>Select an authorizer from the list...</>;
        if(this.state.authorizer.id == null) return <>Select an authorizer from the list...</>;
        
        var subtypeset = <></>;
        var parameters = <></>;

        console.log(this.state.authorizer);

        const authorizers: IDropdownOption[] = [
            { key: "BasicAuthorizer", text: 'Basic', data: { icon: 'authbasic.svg' } },
            { key: "AWSAuthorisor", text: 'Amazon Cognito', data: { icon: 'authcognito.svg' } },
            { key: "AuthorisorOAuth", text: 'OAuth 2.0', data: { icon: 'authoauth.svg' } },
            { key: "AmadeusAuthoriser", text: 'Amadeus', data: { icon: 'authamadeus.svg' } },
          ];

          const columns: IColumn[] = [
            {
              key: 'name',
              name: this.props.intl.formatMessage({ id: 'authorizer.header.name' }),
              fieldName: 'name',
              minWidth: 0,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'value',
              name: this.props.intl.formatMessage({ id: 'authorizer.header.value' }),
              fieldName: 'value',
              minWidth: 120,
              maxWidth: 240,
              currentWidth: 120,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
            },
            {
                key: 'edit',
                name: 'Edit',
                ariaLabel: '',
                isIconOnly: true,
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: any, index?: number) => {
                 // return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.deleteClient(item.id)} title="Delete" ariaLabel="Delete" disabled={!item.canDelete} />
                 return <IconButton iconProps={{ iconName: 'Edit' }} onClick={()=>{this.setState({editHeader: true, headerName: item.name, headerOrigName: item.name, headerValue: item.value, headerIndex: index ?? -1})}}    />
                },
              },
              {
                key: 'delete',
                name: 'Delete',
                ariaLabel: '',
                isIconOnly: true,
                fieldName: 'id',
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: any, index?: number) => {
                 // return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.deleteClient(item.id)} title="Delete" ariaLabel="Delete" disabled={!item.canDelete} />
                 return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>{this.setState({doDelete: true, headerName: item.name, headerIndex: index ?? -1})}} title="Delete" ariaLabel="Delete"  />
                },
              },
        ];

        const onRenderOption = (option: any | undefined): JSX.Element => 
        {
            if(option == null)  return<></>;
            return (
                <Stack horizontal  tokens={{childrenGap: 5}}>
                   {option.data && option.data.icon && (
                <Image src={"/icons/operations/" + option.data.icon} width={24} height={24}  className="list-icon" />
                   )}
                <span>{option.text}</span>
              </Stack>
            );
        }

        var headers = new Array<{name: string, value: string}>();
        for(var k in this.state.authorizer.headers)
        {
            headers.push({name: k, value: this.state.authorizer.headers[k]});
        }
        subtypeset = <Stack>
            <Label >Headers</Label>
            <DetailsList  items={headers} 
                        columns={columns} 
                        selectionMode={SelectionMode.none}
                        
            />        
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                <Stack.Item align='end'>
                    <DefaultButton iconProps={{iconName: "Add"}} text="Add" onClick={()=>{this.setState({editHeader: true, headerName: "", headerValue: "", headerIndex: -1})}} />
                </Stack.Item>
            </Stack>        
        </Stack>

        switch(this.state.authorizer.className)
        {
            case "BasicAuthorizer":
                parameters = <> 
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.username' })} required value={this.state.authorizer.parameters["username"]}  name="username" onChange={this.handleParamChange}/>
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.password' })}  required value={this.state.authorizer.parameters["password"]} type="password" canRevealPassword name="password" onChange={this.handleParamChange}/>
                </>;
                break;
            case "AuthorisorOAuth":
                parameters = <> 
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.clientid' })} required value={this.state.authorizer.parameters["clientid"]}  name="clientid" onChange={this.handleParamChange}/>
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.clientsecret' })}  required  value={this.state.authorizer.parameters["clientsecret"]} name="clientsecret" onChange={this.handleParamChange}/>
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.authenticateurl' })} prefix="https://" required  value={this.state.authorizer.parameters["authenticateurl"]} name="authenticateurl" onChange={this.handleParamChange}/>
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.scope' })} required  value={this.state.authorizer.parameters["scope"]} name="scope" onChange={this.handleParamChange}/>
                <PrimaryButton text="Authorize" onClick={this.authorize} />
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.state' })}    value={this.state.authorizer.parameters["state"]} readOnly />
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.authtoken' })}    value={this.state.authorizer.parameters["authtoken"]} readOnly/>
                </>;
                break;
            case "AWSAuthorisor":
                parameters = <> 
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.region' })} required value={this.state.authorizer.parameters["region"]}  name="region" onChange={this.handleParamChange}/>
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.clientid' })} required value={this.state.authorizer.parameters["clientid"]}  name="clientid" onChange={this.handleParamChange}/>
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.poolid' })} required value={this.state.authorizer.parameters["poolid"]}  name="poolid" onChange={this.handleParamChange}/>
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.username' })} required value={this.state.authorizer.parameters["username"]}  name="username" onChange={this.handleParamChange}/>
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.password' })}  required type="password" canRevealPassword name="password" onChange={this.handleParamChange}/>
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.tokenname' })} value={this.state.authorizer.parameters["tokenname"]}  name="tokenname" onChange={this.handleParamChange}/>
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.tokentype' })} value={this.state.authorizer.parameters["tokentype"]}  name="tokentype" onChange={this.handleParamChange}/>
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.authkey' })} value={this.state.authorizer.parameters["authkey"]}  name="authkey" onChange={this.handleParamChange}/>
                </>;
                break;
        }
     
        return (
            <>
            <Stack grow verticalFill style={{padding: 10}} tokens={{childrenGap: 10}}>
                <Stack horizontal tokens={{ childrenGap: 5}} >
                    <PrimaryButton iconProps={{ iconName: "Save" }}  text={this.props.intl.formatMessage({ id: 'std.save' })} disabled={!this.state.shouldSave} onClick={()=>{this.onSave();}}/>
                    <DefaultButton iconProps={{ iconName: "FabricMovetoFolder" }} text="Move To Folder" onClick={()=>{this.setState({doMoveFolder: true});}}/>
                </Stack>
                <TextField label={this.props.intl.formatMessage({ id: 'authorizer.name' })} value={this.state.authorizer.name}  name="name" onChange={this.handleChange}/>
                <TextField multiline label={this.props.intl.formatMessage({ id: 'authorizer.description' })} value={this.state.authorizer.description}  name="description" onChange={this.handleChange}/>
                <Dropdown
                            placeholder="Select Authorizer..."
                            options={authorizers}
                            selectedKey={this.state.authorizer.className}
                            onRenderOption={onRenderOption}
                            onChange={(e,o) => this.handleClassNameChange(e,o)}
                            label={this.props.intl.formatMessage({ id: 'authorizer.type' })}
                        />
                {subtypeset}
                <Separator />
                {parameters}
            </Stack>
            <Dialog
                 hidden={!this.state.editHeader}
                 onDismiss={()=>{this.setState({editHeader: false})}}
                 dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Edit Header',
                    closeButtonAriaLabel: 'Close',
                    subText: 'Set the values for the header to be used to authorize the API call.'
                  }}
               >
                   <Stack>
                        <TextField value={this.state.headerName} label="Name" name="headerName" onChange={this.handleChange}/>
                        <TextField value={this.state.headerValue} label="Vaue" name="headerValue" onChange={this.handleChange}/>
                   </Stack>

                 <DialogFooter>
                   <PrimaryButton onClick={()=>{this.saveHeader()}} text="Save" />
                   <DefaultButton onClick={()=>{this.setState({editHeader: false})}} text="Cancel" />
                 </DialogFooter>
               </Dialog>
               <Dialog
                 hidden={!this.state.doDelete}
                 onDismiss={()=>{this.setState({doDelete: false})}}
                 dialogContentProps={{
                    type: DialogType.normal,
                    title: "Delete Header ",
                    closeButtonAriaLabel: 'Close',
                    subText: 'Are you sure you want to delete this header?',
                  }}
               >
                 <DialogFooter>
                   <PrimaryButton onClick={()=>{this.onDelete()}} text="Delete" />
                   <DefaultButton onClick={()=>{this.setState({doDelete: false})}} text="Cancel" />
                 </DialogFooter>
               </Dialog>
               <Dialog
                 hidden={!this.state.doAuthorizing}
                 onDismiss={()=>{this.setState({doAuthorizing: false})}}
                 dialogContentProps={{
                    type: DialogType.normal,
                    title: "Authorizing",
                    closeButtonAriaLabel: 'Close',
                    subText: 'A request to authorize this Auhorizer is being sent to the OAuth server.  Please wait for the response.',
                  }}
               >
                 <DialogFooter>
                   <PrimaryButton onClick={()=>{this.setState({doAuthorizing: false})}} text="Close" />
                 </DialogFooter>
               </Dialog>
               <FolderPicker client={this.props.client} isOpen={this.state.doMoveFolder} onDismiss={()=>{this.setState({doMoveFolder: false});}} onFolderSelected={(folder:string)=>{
                        var conn : IAuthConnection = {...this.state.authorizer};
                        conn.folderID = folder;
                        this.setState({authorizer: conn, shouldSave: true, doMoveFolder: false});
            }}/>
            </>
        );
    }
}

export default injectIntl(AuthorizerProperties);