import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';



import {  
    Stack, Text,  Separator,
} from '@fluentui/react';


type IState = {
}
  
  
interface IProps extends WrappedComponentProps {
}

class Agregate extends Component<IProps,IState> {
    render() 
    {
        return (
            <Stack grow verticalFill >
                <Text variant='xLarge'>Output</Text>
                Define the format of the output.<br/>
                Format of the command:<br/>
                Start your path with $ which represents the root and pt each expression n its own line.<br/>
                <Text variant='large'>Examples:</Text>
                <Text>A Json object with two properties</Text>
                &#123;<br/>
                <Stack verticalFill style={{paddingLeft: 10}}>
                    "users" : [],<br/>
                    "message" : ""
                </Stack>
                &#125;<br/>
                <i>When setting an element defined as an array, the item will be added to the array.</i>
                <Separator />
                <Text variant='large'>Left  Node</Text>
                All these rules will be executed to gather the output.
                <br/><br/><Text variant='large'>Right  Node</Text>
                All these rules will be executed after the data has been gathered.
            </Stack>
        )
    }
}
export default injectIntl(Agregate);
