import 'rc-time-picker/assets/index.css';

import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { IPackage, 
         IPackageConfig, 
         uuid
         } from './IDeus';

import {  
    Stack, 
    TextField, 
    PrimaryButton, 
    Dropdown,
    Dialog,
    DialogFooter,
    DefaultButton,
    DialogType,
    IconButton,
    DetailsList,
    IDetailsListProps,
    IDetailsRowStyles,
    DetailsRow,
    SelectionMode,
    IColumn,
} from '@fluentui/react';


import Folders from './Folders';


type IState = {
    package: IPackage
    shouldSave: boolean
    shouldParamSave: boolean
    doMoveFolder: boolean
    destinationFolder: string    
    doSetDDL: boolean
    onGetDDL: boolean
    doSetTemplate: boolean,
    dataerror: string,

    selectedParameter: IPackageConfig,
    doEdit: boolean,
    doDelete: boolean,
    actionID: string,

}
  
  
interface IProps extends WrappedComponentProps {
    package: IPackage,
    client: string,
    onChange?: any,
    onMoveFolder?: any,
}



class PackageProperties extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            package: this.props.package,
            shouldSave: false,
            shouldParamSave: false,
            doMoveFolder: false,
            destinationFolder: "",
            doSetDDL: false,
            onGetDDL: false,
            doSetTemplate: false,
            dataerror: "",

            doEdit: false,
            doDelete: false,
            actionID: "",

            selectedParameter: {} as IPackageConfig,
        };

        this.handleChange = this.handleChange.bind(this);

        this.onSave = this.onSave.bind(this);
        this.onMoveFolder = this.onMoveFolder.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSaveParameter = this.onSaveParameter.bind(this);
    }


    componentDidUpdate(prevProps: IProps) {
        if (prevProps.package !== this.props.package) {
            this.setState({
                package: this.props.package,
                shouldSave: false
            });
          }
    }

      

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        if(name.startsWith("param"))
        {
            var param : IPackageConfig = {...this.state.selectedParameter};
            switch(name)
            {
                case "paramname":
                    param.name = value;
                    break;
                case "paramparamname":
                    param.parameterName = value;
                    break;
                case "paramdescription":
                    param.description = value;
                    break;
            }
            this.setState({selectedParameter: param, shouldParamSave: true});
        }
        else
        {
            var pack : IPackage = {...this.state.package};
            switch(name)
            {
                case "name":
                    pack.name = value;
                    break;
                case "description":
                    pack.description = value;
                    break;
                case "image":
                    pack.image = value;
                    break;
            }
            this.setState({package: pack, shouldSave: true});
            }
    }

    componentDidMount()
    {
      this.refresh();
    }

    onError(error: any) {
        console.error(error);
      }

    onMoveFolder()
    {
        var pack : IPackage = {...this.state.package};
        pack.folderID = this.state.destinationFolder;
        this.setState({package: pack, shouldSave: true});
        if(this.props.onMoveFolder != null)
        {
            this.props.onMoveFolder(pack);
        }        
    }

    onSave()
    {
        var s = this.state.package;


        if(this.props.onChange != null)
        {
            this.props.onChange(s);
        }        
        this.setState({ shouldSave: false });
    }

    onSaveParameter()
    {
        var s : IPackageConfig = {...this.state.selectedParameter};
        var pack : IPackage = {...this.state.package};
        if(s.id === "") 
        {
            s.id = uuid();
            pack.config.push(s);
        }
        else
        {
            pack.config.forEach(item => {
                if(item.id === s.id)
                {
                    item.name = s.name;
                    item.description = s.description;
                    item.parameterName = s.parameterName;
                    item.parameterType = s.parameterType;
                }
            });
        }
        this.setState({ package: pack, shouldParamSave: false, doEdit: false, selectedParameter: s  });
    }

    refresh()
    {

    }

    render() 
    {
       
          
        if(this.state.package == null) return <>Select a rule set from the list...</>;
        if(this.state.package.id == null) return <>Select a rule set from the list...</>;

        const columns: IColumn[] = [
            {
                key: 'name',
                name: this.props.intl.formatMessage({ id: 'schedule.name' }),
                fieldName: 'name',
                minWidth: 0,
                maxWidth: 0,
                currentWidth: 0,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
              },
              {
                key: 'rulesetedit',
                name: 'RuleSet',
                ariaLabel: '',
                isIconOnly: true,
                fieldName: 'id',
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: any) => {
                 return <IconButton iconProps={{ iconName: 'Edit' }} onClick={()=>this.setState({selectedParameter: item})} title="Config Parameter" ariaLabel="Config Parameter"  />
                },
              },
              {
                key: 'delete',
                name: 'Delete',
                ariaLabel: '',
                isIconOnly: true,
                fieldName: 'id',
                minWidth: 16,
                maxWidth: 16,
                onRender: (item: any) => {
                 return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.setState({doDelete: true, actionID: item.id})} title="Delete" ariaLabel="Delete"  />
                },
              },
    
          ];
  

        return (
            <>
            <Stack grow verticalFill style={{padding: 10, borderLeft: "1px solid grey", width: "100%"}} >
                <Stack style={{ paddingBottom: 10}}>
                    <Stack horizontal tokens={{ childrenGap: 5}} >
                        <PrimaryButton iconProps={{ iconName: "Save" }}  text={this.props.intl.formatMessage({ id: 'std.save' })} disabled={!this.state.shouldSave} onClick={()=>{this.onSave();}}/>
                        <DefaultButton iconProps={{ iconName: "Folder" }} text="Move To Folder" onClick={()=>{this.setState({doMoveFolder: true});}}/>
                    </Stack>
                    <TextField label={this.props.intl.formatMessage({ id: 'schedule.name' })} value={this.state.package.name}  name="name" onChange={this.handleChange}/>
                    <TextField label={this.props.intl.formatMessage({ id: 'schedule.description' })} multiline value={this.state.package.description}  name="description" onChange={this.handleChange}/>
                    <TextField label={this.props.intl.formatMessage({ id: 'package.image' })} value={this.state.package.image}  name="image" onChange={this.handleChange}/>
                    <Stack horizontal style={{paddingTop: 10}}>
                        <DefaultButton iconProps={{ iconName: "Add" }} text="Add Parameter" onClick={()=>{
                            var newparam : IPackageConfig = {
                                id: "",
                                name: "New Parameter",
                                description: "",
                                parameterName: "new_parameter",
                                parameterType: "STRING",
                                currentValue: "",
                            }
                            this.setState({selectedParameter: newparam, doEdit: true});
                        }}/>
                    </Stack>
                    <DetailsList  items={this.state.package.config} 
                              columns={columns} 
                              onItemInvoked={(item: IPackageConfig): void => {
                                    this.setState({selectedParameter: item, shouldParamSave: false, doEdit: true});
                              }}
                              onRenderRow={this._onRenderRow}
                              selectionMode={SelectionMode.none}
                  />
                </Stack>
            </Stack>

            <Dialog
                 hidden={!this.state.doMoveFolder}
                 onDismiss={()=>{this.setState({doMoveFolder: false})}}
                 dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Move Folder',
                    closeButtonAriaLabel: 'Close',
                    subText: 'Choose which folder you want to move this ruleset to',
                  }}
            >
                <Folders 
                    clientid={this.props.client} 
                    onFolderSelected={(folder: string) => this.setState({destinationFolder: folder})}  
                />

                 <DialogFooter>
                   <PrimaryButton onClick={()=>{this.onMoveFolder()}} text="Move" />
                   <DefaultButton onClick={()=>{this.setState({doMoveFolder: false})}} text="Cancel" />
                 </DialogFooter>
            </Dialog>    
            <Dialog
                 hidden={!this.state.doEdit}
                 onDismiss={()=>{this.setState({doEdit: false})}}
                 dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Edit Parameter',
                    closeButtonAriaLabel: 'Close',
                    subText: 'Set ',
                  }}
            >
                <Stack>
                    <TextField label={this.props.intl.formatMessage({ id: 'schedule.name' })} value={this.state.selectedParameter.name}  name="paramname" onChange={this.handleChange}/>
                    <TextField label={this.props.intl.formatMessage({ id: 'schedule.description' })} multiline value={this.state.selectedParameter.description}  name="paramdescription" onChange={this.handleChange}/>
                    <TextField label="Parameter Name" value={this.state.selectedParameter.parameterName}  name="paramparamname" onChange={this.handleChange}/>
                    <Dropdown
                        label='Type'
                        placeholder="Select data type..."
                        options={[
                            {key: "STRING", text: "String" },
                            {key: "NUMBER", text: "Number" },
                        ]}
                        selectedKey={this.state.selectedParameter.parameterType}
                        onChange={(ev: any,option?: any) =>
                            {
                                var r: IPackageConfig  = JSON.parse(JSON.stringify(this.state.selectedParameter));
                                r.parameterType = option.key;
                                this.setState({selectedParameter: r});
                            }}
                    />
                </Stack>
                 <DialogFooter>
                   <PrimaryButton disabled={!this.state.shouldParamSave} onClick={()=>{this.onSaveParameter()}} text="Save" />
                   <DefaultButton onClick={()=>{this.setState({doEdit: false})}} text="Cancel" />
                 </DialogFooter>
            </Dialog>    
            </>
        );
    }

    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        if (props) {
          if(this.state.selectedParameter){
            if (props.item.id  === this.state.selectedParameter.id) {
              // Every other row renders with a different background color
              customStyles.root = { backgroundColor: "lightskyblue" };
            }
      
            }
            return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
      };
}

export default injectIntl(PackageProperties);