import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  IDataDefinition, IPagination,  } from './IDeus';

import {  Post, Put, Delete } from './authorisation/AMAPI';

import {  
    Stack,
    IconButton, PrimaryButton, DefaultButton, 
    DetailsList, IColumn, SelectionMode, 
    TextField, 
    Dialog, DialogFooter, 
    DialogType, IDetailsListProps, IDetailsRowStyles, DetailsRow, IContextualMenuProps, List, Checkbox, PanelType, Panel, 
} from '@fluentui/react';

import DataDefinitionProperties from './DataDefinitionProperties'; 
import { Link } from 'react-router-dom';
import DDLFinder from './DDLFinder';
import DDLGenerate from './DDLGenerate';




type IState = {
    datadefinitions: IDataDefinition[],
    selectedDataDefinition: IDataDefinition,

    pagination: IPagination,
    showDialog: boolean,
    doDelete: boolean,
    doImport: boolean,
    doJsonGen: boolean,
    doOpenAPIGen: boolean,
    doCopy: boolean,
    showCalled: boolean,

    exampleJson: string,

    openAPIBase: string,
    openAPIAddress: string,
    openAPISchemas: string[],
    openAPISelectedSchemas: string[],

    actionID: string,

    copyName: string,
    importSchedule?: IDataDefinition,

    doGenerate: boolean,

    propsPanelOpen: boolean,
    propsPanelTitle: string,
}
  
  
interface IProps extends WrappedComponentProps {
  client: string
  history?: any
  folder: string
}


class DataDefinitions extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            datadefinitions: [],
            selectedDataDefinition: {} as IDataDefinition,

            pagination: {
                totalCount: 0,
                current: 0,
                pageSize: 20,
                sortField: "",
                sortOrder: "asc"
            },

            showDialog: false,
            doDelete: false,
            doJsonGen: false,
            doOpenAPIGen: false,
            doImport: false,
            doCopy: false,

            showCalled: false,

            exampleJson: "",

            openAPIAddress: "master/examples/v3.0/petstore.yaml",
            openAPIBase: "https://raw.githubusercontent.com/OAI/OpenAPI-Specification/",
            openAPISchemas: [],
            openAPISelectedSchemas: [],

            actionID: "",

            copyName: "",

            doGenerate: false,

            propsPanelOpen: false,
            propsPanelTitle: "",


        };

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.createNewDDL = this.createNewDDL.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCopy = this.onCopy.bind(this);
        this.onGenerateExample = this.onGenerateExample.bind(this);
        this.onImport = this.onImport.bind(this);
        this.onSave = this.onSave.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
        this.getOpenAPISchemas = this.getOpenAPISchemas.bind(this);
        this.generateOpenAPISchemas = this.generateOpenAPISchemas.bind(this);
    }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.client !== this.props.client || prevProps.folder !== this.props.folder) {
        this.authenticatedrefresh();
      }
    }

// picker    
  handleFieldChange(event: any){
    const target = event.target;
    const value : string = target.type === 'checkbox' ? target.checked : target.value;
    const name : string = target.name;


    switch(name)
    {
        case "copyName":
            this.setState({copyName: value});
            break;
        case "examplejson":
          this.setState({exampleJson: value});
          break;
        case "openapibase":
          this.setState({openAPIBase: value});
          break;
        case "openapiaddress":
          this.setState({openAPIAddress: value});
          break;
      }
  }

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var r = JSON.parse(JSON.stringify(this.state.selectedDataDefinition));
        switch(name)
        {
            case "name":
                r.name = value;
                break;
            case "description":
                r.description = value;
                break;
            case "opParameter":
                r.opParameter = value;
                break;
        }
        this.setState({selectedDataDefinition: r});
    }

    componentDidMount()
    {
      this.refresh();
    }

    createNewDDL()
    {
        this.setState({
            selectedDataDefinition: {
                id: "",
                clientID: this.props.client,
                name: "New Data Definition",
                description: "",
                folderID: this.props.folder,
                fields: [],
            },
            propsPanelTitle: "New Data Definition", propsPanelOpen: true
        });
    }
  
    refresh()
    {
        this.authenticatedrefresh();
    }


    showDialog(context: string, item: any)
    {
        this.setState({showDialog: true, })
    }


    onPageChange(page: number)
    {
      var p: IPagination = Object.assign(this.state.pagination);
      p.current = page;
      this.setState({pagination: p});
      this.authenticatedrefresh();
    }


  

    private authenticatedrefresh()
    {
      const params = this.state.pagination;

      const paging = {
        current: params.current + 1,
        pagesize: params.pageSize,
        sortfield: params.sortField,
        sortorder: params.sortOrder,
      }

      const post = {
        paging: paging,
        currentFolder: this.props.folder,
        currentClient: this.props.client
      }

      Post("datadefinition/Search",post)
        .then(response => {
              var p = this.state.pagination;
              p.totalCount = response.data.totalCount;   
              this.setState({ 
                datadefinitions: response.data.results, 
                pagination: p, 
              });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    getOpenAPISchemas()
    {
      const post = {
        baseuri: this.state.openAPIBase,
        api: this.state.openAPIAddress,
      };
      Post("datadefinition/openapi/getschemas",post)
        .then(response => {
              this.setState({ 
                openAPISchemas: response.data.schemaNames, 
              });
        })
        .catch(function (error) {
          console.log(error);
        });

    }

    generateOpenAPISchemas()
    {
      const post = {
        baseuri: this.state.openAPIBase,
        api: this.state.openAPIAddress,
        client: this.props.client,
        folder: this.props.folder,
        schemas: this.state.openAPISelectedSchemas,
      };
      Post("datadefinition/openapi/generateschemas",post)
        .then(response => {
              this.setState({ doOpenAPIGen: false, });
              this.authenticatedrefresh();
        })
        .catch(function (error) {
          console.log(error);
        });
    }


    onSave(sched: IDataDefinition)
    {
        if(sched.id === "")
        {
            Post("datadefinition",sched)
            .then(response => {
                sched.id = response.data;
                this.setState({ 
                  selectedDataDefinition: sched, 
                  propsPanelOpen: false,
                });
                this.authenticatedrefresh();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        else
        {
            Put("datadefinition/" + sched.id,sched)
            .then(response => {
              this.setState({ 
                propsPanelOpen: false,
              });
            this.authenticatedrefresh();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
    }


    private _onItemInvoked = (item: IDataDefinition): void => {
        this.setState({selectedDataDefinition: item, propsPanelTitle: "Edit Data Definition", propsPanelOpen: true});
    };

    public onDelete()
      {
        Delete("datadefinition/" + this.state.actionID)
        .then(response => {
          this.setState({doDelete: false});
          this.authenticatedrefresh();
        })
        .catch(function (error) {
          console.log(error);
        });
    }


      public onCopy()
      {
        const post = {
          id: this.state.actionID,
          newname: this.state.copyName,
        }
  
        Post("datadefinition/Copy",post)
          .then(response => {
            this.setState({doCopy: false});
            this.authenticatedrefresh();
            })
          .catch(function (error) {
            console.log(error);
          });
      }

      public onGenerateExample()
      {
        const post = {
          client: this.props.client,
          folder: this.props.folder,
          newname: this.state.copyName,
          example: this.state.exampleJson,
        }

  
        Post("datadefinition/example",post)
          .then(response => {
            this.setState({doJsonGen: false},()=>{
              this.authenticatedrefresh();
            });
            })
          .catch(function (error) {
            console.log(error);
          });
      }

      public onImport(ddl?: IDataDefinition)
      {
        if(ddl==null)
        {
          return;
        }
        var folder = this.props.folder;
        if(folder==="Uncategorized")  folder="";
        const post = {
          id: ddl.id,
          newname: ddl.name,
          clientid: this.props.client,
          folderid: folder,
        }
  
        Post("datadefinition/import",post)
          .then(response => {
            this.setState({doImport: false, importSchedule: undefined});
            this.authenticatedrefresh();
            })
          .catch(function (error) {
            console.log(error);
          });
      }

   

    render() 
    {

        var props = <></>;

        if(this.state.selectedDataDefinition)
        {
            props = <DataDefinitionProperties client={this.props.client} dataDefinition={this.state.selectedDataDefinition} onChange={this.onSave} onMoveFolder={this.onSave}  />
        }


        const columns: IColumn[] = [
          {
              key: 'name',
              name: this.props.intl.formatMessage({ id: 'schedule.name' }),
              fieldName: 'name',
              minWidth: 200,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'desc',
              name: this.props.intl.formatMessage({ id: 'connection.description' }),
              fieldName: 'description',
              minWidth: 300,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              isSortedDescending: false,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'rulesetedit',
              name: 'RuleSet',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               return <IconButton iconProps={{ iconName: 'Edit' }} onClick={()=>this.setState({selectedDataDefinition: item})} title="Edit DDL Description" ariaLabel="Edit DDL Description"  />
              },
            },
            {
              key: 'Definition',
              name: 'Rule',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               return <Link to={'/ddleditor/'+item.id}><IconButton iconProps={{ iconName: 'Dictionary' }} 
                    title="Edit Data Definition" ariaLabel="Edit Data Definition"  /></Link>
              },
            },
            {
              key: 'rulecopy',
              name: 'Duplicate',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               return <IconButton iconProps={{ iconName: 'copy' }} onClick={()=>this.setState({doCopy: true, copyName: "", actionID: item.id})} title="Copy" ariaLabel="Rules"  />
              },
            },
            {
              key: 'delete',
              name: 'Delete',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.setState({doDelete: true, actionID: item.id})} title="Delete" ariaLabel="Delete"  />
              },
            },
  
        ];

        const menuProps: IContextualMenuProps = {
          items: [
            {
              key: 'sql',
              text: 'From SQL Table',
              iconProps: { iconName: 'Database' },
              onClick: () => {
                this.setState({doGenerate: true})
              }
            },
            {
              key: 'json',
              text: 'Example JSON',
              iconProps: { iconName: 'TextDocument' },
              onClick: () => {
                this.setState({doJsonGen: true})
              }
            },
            {
              key: 'openapi',
              text: 'Open API Schema',
              iconProps: { iconName: 'AzureAPIManagement' },
              onClick: () => {
                this.setState({doOpenAPIGen: true})
              }
            },
          ],
        };

        return (
          <>
            <Stack horizontal grow verticalFill>
                <Stack grow={3} style={{paddingTop: 5}} >
                  <Stack horizontal tokens={{childrenGap: 5}}>
                  <PrimaryButton text="Create new data definition" iconProps={{ iconName: 'PageAdd' }} onClick={()=>this.createNewDDL()} width={300} disabled={!this.props.client} />
                  <DefaultButton text="Import" iconProps={{ iconName: 'Import' }} onClick={()=>this.setState({doImport: true})} width={300} disabled={!this.props.client}/>
                  <DefaultButton 
                      text="Generate" 
                      iconProps={{ iconName: 'Settings' }}  
                      menuProps={menuProps}
                      disabled={!this.props.client}
                      />

                  </Stack>
                    <DetailsList  items={this.state.datadefinitions} 
                                columns={columns} 
                                onItemInvoked={this._onItemInvoked}
                                onRenderRow={this._onRenderRow}
                                selectionMode={SelectionMode.none}
                    />


                </Stack>
                <Panel
                  headerText={this.state.propsPanelTitle}
                  type={PanelType.medium}
                  isOpen={this.state.propsPanelOpen}
                  onDismiss={()=>{ this.setState({propsPanelOpen: false})}}
                >
                    {props}
                </Panel>
            </Stack>
            <Dialog
              hidden={!this.state.doDelete}
              onDismiss={()=>{this.setState({doDelete: false})}}
              dialogContentProps={{
                  type: DialogType.normal,
                  title: 'Delete Data Definition',
                  closeButtonAriaLabel: 'Close',
                  subText: 'Are you sure you want to delete this data definition. This is non reversible.',
                }}
            >
            <DialogFooter>
              <PrimaryButton onClick={()=>{this.onDelete()}} text="Delete" />
              <DefaultButton onClick={()=>{this.setState({doDelete: false})}} text="Cancel" />
            </DialogFooter>
            </Dialog>
            <Dialog
              hidden={!this.state.doCopy}
              onDismiss={()=>{this.setState({doCopy: false})}}
              dialogContentProps={{
                  type: DialogType.normal,
                  title: 'Copy Data Definition',
                  closeButtonAriaLabel: 'Close',
                  subText: 'Please provide a name for the new data definition.',
                }}
            >
                <TextField label={this.props.intl.formatMessage({ id: 'schedule.name' })} value={this.state.copyName}  name="copyName" onChange={this.handleFieldChange}/>
              
            <DialogFooter>
              <PrimaryButton onClick={()=>{this.onCopy()}} text="Copy" />
              <DefaultButton onClick={()=>{this.setState({doCopy: false})}} text="Cancel" />
            </DialogFooter>
            </Dialog>

            <Dialog
              hidden={!this.state.doJsonGen}
              onDismiss={()=>{this.setState({doJsonGen: false})}}
              dialogContentProps={{
                  type: DialogType.normal,
                  title: 'Generate from example JSON',
                  closeButtonAriaLabel: 'Close',
                  subText: 'Please provide an example JSON object to generate the data definition from.',
                }}
            >
              <Stack tokens={{childrenGap: 10}}>
                <TextField label={this.props.intl.formatMessage({ id: 'schedule.name' })} value={this.state.copyName}  name="copyName" onChange={this.handleFieldChange}/>
                <TextField label="Example Object" multiline rows={12} value={this.state.exampleJson}  name="examplejson" onChange={this.handleFieldChange}/>
              </Stack>
              
            <DialogFooter>
              <PrimaryButton onClick={()=>{this.onGenerateExample()}} text="Generate" />
              <DefaultButton onClick={()=>{this.setState({doJsonGen: false})}} text="Cancel" />
            </DialogFooter>
            </Dialog>

            <Dialog
              hidden={!this.state.doOpenAPIGen}
              onDismiss={()=>{this.setState({doOpenAPIGen: false})}}
              minWidth={500}
              dialogContentProps={{
                  type: DialogType.normal,
                  title: 'Generate from Open API Schema',
                  closeButtonAriaLabel: 'Close',
                  subText: 'Please provide the address of the OpenAPI specification.',
                }}
            >
              <Stack tokens={{childrenGap: 10}}>
                <TextField label="Base Address" value={this.state.openAPIBase}  name="openapibase" onChange={this.handleFieldChange}/>
                <TextField label="API" value={this.state.openAPIAddress}  name="openapiaddress" onChange={this.handleFieldChange}/>
                <Stack horizontal>
                  <DefaultButton text="Find" onClick={()=>{
                    this.getOpenAPISchemas();
                  }} />
                </Stack>
                <List style={{height: 200}} items={this.state.openAPISchemas} 
                            onRenderCell={(item: string | undefined, index: number | undefined, isScrolling: boolean | undefined) => {
                                       if (item === undefined) return <></>;
                                       var items = [...this.state.openAPISelectedSchemas];
                                       var isChecked = items.includes(item);
                                        return (
                                          <div data-is-focusable>
                                            <div >
                                              <Checkbox 
                                                  label={item} 
                                                  checked={isChecked} 
                                                  onChange={(ev, checked) => {
                                                    items = [...this.state.openAPISelectedSchemas]
                                                    if (items.includes(item)) {
                                                      items.splice(items.indexOf(item), 1);
                                                    } else {
                                                      items.push(item);
                                                    }
                                                    this.setState({openAPISelectedSchemas: items, openAPISchemas: [...this.state.openAPISchemas]});
                                                  }} />
                                            </div>
                                          </div>
                                        );
                                      }} />
              </Stack>
              
            <DialogFooter>
              <PrimaryButton onClick={()=>{this.generateOpenAPISchemas()}} text="Generate" />
              <DefaultButton onClick={()=>{this.setState({doOpenAPIGen: false})}} text="Cancel" />
            </DialogFooter>
            </Dialog>

            <DDLFinder 
            // @ts-ignore
                isOpen={this.state.doImport}
                client="62badadf5a14b100e0285fe1"
                onDismiss={()=>{this.setState({doImport: false})}}
                onDDLSelected={(ddl: IDataDefinition)=>{
                  this.onImport(ddl);
                }}
            />   
            <DDLGenerate
              isOpen={this.state.doGenerate}
              client={this.props.client}
              folder={this.props.folder}
              onDismiss={()=>{
                this.setState({doGenerate: false});
                this.authenticatedrefresh();
              }}
            />          
        </>        );
    }

    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
      const customStyles: Partial<IDetailsRowStyles> = {};
      if (props) {
        if(this.state.selectedDataDefinition){
          if (props.item.id  === this.state.selectedDataDefinition.id) {
            // Every other row renders with a different background color
            customStyles.root = { backgroundColor: "#785a00" };
          }
    
          }
          return <DetailsRow {...props} styles={customStyles} />;
      }
      return null;
    };
}

export default injectIntl(DataDefinitions);