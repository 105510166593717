import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  IDataDefinition,  uuid, ITranslation, ITranslationObject, eTranslationObjectType, IPath } from './IDeus';

import {  Get, Post, Put } from './authorisation/AMAPI';


import {  
    Stack, 
    PrimaryButton, DefaultButton,
    Modal,
    ICommandBarItemProps,
    CommandBar,  
} from '@fluentui/react';
import { Separator } from '@fluentui/react';



import createEngine, { DiagramModel, DefaultNodeModel, DefaultPortModel,  DiagramModelGenerics, DiagramEngine, DefaultLinkModel,  } from '@projectstorm/react-diagrams';
import { CanvasWidget } from '@projectstorm/react-canvas-core';
import styled from '@emotion/styled';
import { css, Global } from '@emotion/react';
import ConstantProperties from './translations/ConstantProperties';



type IState = {
  translation: ITranslation,
    inputdatadefinition: IDataDefinition,
    outputdatadefinition: IDataDefinition,

    sourceRule: string,
    sourceNode: string,
    connecting: boolean,


    graphKey: string,

    elements: any[],

    model: DiagramModel<DiagramModelGenerics>
    engine: DiagramEngine

    selectedObject: ITranslationObject


}
  
  
interface IProps extends WrappedComponentProps {
  translation: string,
  inputDDL: string,
  outputDDL: string,
  onTranslationCreated: any
  onDismiss?: any
  isOpen: boolean  
}




namespace S {
  export const Container = styled.div<{ color: string; background: string }>`
    height: 100%;
    background-color: ${(p) => p.background};
    background-size: 50px 50px;
    display: flex;
    > * {
      height: 100%;
      min-height: 100%;
      width: 100%;
    }
    background-image: linear-gradient(
        0deg,
        transparent 24%,
        ${(p) => p.color} 25%,
        ${(p) => p.color} 26%,
        transparent 27%,
        transparent 74%,
        ${(p) => p.color} 75%,
        ${(p) => p.color} 76%,
        transparent 77%,
        transparent
      ),
      linear-gradient(
        90deg,
        transparent 24%,
        ${(p) => p.color} 25%,
        ${(p) => p.color} 26%,
        transparent 27%,
        transparent 74%,
        ${(p) => p.color} 75%,
        ${(p) => p.color} 76%,
        transparent 77%,
        transparent
      );
  `;

  export const Expand = css`
    html,
    body,
    #root {
      height: 100%;
    }
  `;
}

const engine = createEngine();

class TranslationEditor extends Component<IProps,IState> {

  
    constructor(props: any) {
        super(props);

        this.state = {
            translation: {
                id: "",
                inputDDL: "",
                outputDDL: "",
                objects: [],
                paths: [],
            },
            inputdatadefinition: {} as IDataDefinition,
            outputdatadefinition: {} as IDataDefinition,

          
            sourceNode: "",
            sourceRule: "",
            connecting: false,
            graphKey: uuid(),

            elements: [],

            model: new DiagramModel(),

            engine: new DiagramEngine(),

            selectedObject: {} as ITranslationObject
          };



        this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
        this.generateGraph = this.generateGraph.bind(this);

        this.onSave = this.onSave.bind(this);
        this.resetIO = this.resetIO.bind(this);


    }

    componentDidUpdate(prevProps: IProps) {
      if (prevProps.translation !== this.props.translation || 
        prevProps.inputDDL !== this.props.inputDDL ||
        prevProps.outputDDL !== this.props.outputDDL ||
        prevProps.isOpen !== this.props.isOpen) {
        this.authenticatedrefresh();
      }
     
    }

    
    componentDidMount()
    {
      this.authenticatedrefresh();
    }


    private resetIO()
    {
      //
      //  Find input object
      var trans = {...this.state.translation}
      var inputObject = trans.objects.find(o => o.objectType === eTranslationObjectType.InputObject);
      if(inputObject == null) return;
      var inputID = inputObject?.id;
      inputObject.outputs.length = 0;
      this.state.inputdatadefinition.fields.forEach(field => {
        inputObject?.outputs.push({
          name: field.name,
        })
      });

      //
      //  Find output object
      var outputObject = trans.objects.find(o => o.objectType === eTranslationObjectType.OutputObject);
      if(outputObject == null) return;
      var outputID = outputObject?.id;
      outputObject.inputs.length = 0;
      this.state.outputdatadefinition.fields.forEach(field => {
        outputObject?.inputs.push({
          name: field.name,
        })
      });


      //
      // Delete Invalid Links
      var newpaths = trans.paths.filter((value, index, arr) => {
        if(value.inputObject !== inputID && value.outputObject !== outputID)  return true;
        if(value.inputObject === inputID)
        {
          if(inputObject?.outputs.find(o => o.name === value.inputField) == null) return false;
        }
        if(value.outputObject === outputID)
        {
          if(outputObject?.inputs.find(o => o.name === value.outputField) == null) return false;
        }
        return true;
      });

      console.log("Old Paths:",trans.paths);
      console.log("New Paths:",newpaths);
      trans.paths = newpaths;

      this.setState({translation: trans},()=>{
        this.generateGraph();
      });

    }


    private authenticatedrefresh()
    {
      console.log("PROPS", this.props);

      if(this.props.inputDDL === "" || this.props.outputDDL === "") return;
      if(!this.props.isOpen) return;
      Get("datadefinition/" + this.props.inputDDL)
        .then(response => {
              console.log("GOT INPUT DEFINITIONS")
              console.log(response.data)
              this.setState({ 
                inputdatadefinition: response.data, 
              },()=>{
                Get("datadefinition/" + this.props.outputDDL)
                .then(response => {
                  console.log("GOT OUTPUT DEFINITIONS")
                  console.log(response.data)
                  this.setState({outputdatadefinition: response.data},()=>{                    
                    if(this.props.translation === "") {
                      var inObject: ITranslationObject = {
                        id: uuid(),
                        name: "INPUT",
                        objectType: eTranslationObjectType.InputObject,
                        x: 10,
                        y: 10,
                        opParameters: {},
                        inputs: [],
                        outputs: [],
                      }
                      this.state.inputdatadefinition.fields.forEach(field => {
                        inObject.outputs.push({
                          name: field.name,
                        })
                      });
                      var outObject: ITranslationObject = {
                        id: uuid(),
                        name: "OUTPUT",
                        objectType: eTranslationObjectType.OutputObject,
                        x: 500,
                        y: 10,
                        opParameters: {},
                        inputs: [],
                        outputs: [],
                      }
                      this.state.outputdatadefinition.fields.forEach(field => {
                        outObject.inputs.push({
                          name: field.name,
                        })
                      });
                      var trans: ITranslation = {
                        id: "",
                        inputDDL: this.props.inputDDL,
                        outputDDL: this.props.outputDDL,
                        paths: [],
                        objects: [
                          inObject,
                          outObject
                        ]
                      };
                      this.setState({translation: trans},()=>{
                        this.generateGraph();
                      });
                    }
                    else {
                      console.log("Getting Translation")
                      Get("translation/" + this.props.translation)
                      .then(response => {
                            this.setState({ 
                              translation: response.data, 
                            },()=>{
                              this.generateGraph();
                            });
                      })
                      .catch(error => {
                        console.log(error);
                      })
                    }

                  });
                })
                .catch(function (error) {
                  console.log(error);
                });
  
              });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    

    generateGraph()
    {

      if(this.state.translation == null)  return;
      console.log("Generate Graph");

      var translation = this.state.translation;

      var model = new DiagramModel();

      //
      // Add Objects
      translation.objects.forEach(object => {
        var col = "rgb(0,192,255)";

        switch(object.objectType)
        {
          case eTranslationObjectType.InputObject:
            case eTranslationObjectType.Constant:
            col = "rgb(0,192,255)";
            break;
          case eTranslationObjectType.OutputObject:
            col = "rgb(192,255,0)";
            break;
          case eTranslationObjectType.ToUpperCase:
          case eTranslationObjectType.ToLowerCase:
            case eTranslationObjectType.SplitName:
            col = "rgb(255,178,0)";
            break;
          case eTranslationObjectType.MathAdd:
          case eTranslationObjectType.MathSubtract:
          case eTranslationObjectType.MathMultiply:
          case eTranslationObjectType.MathDivide:
            col = "rgb(255,0,255)";
            break;
        }

        var node = new DefaultNodeModel(object.name, col);
        node.setPosition(object.x, object.y);
        object.internalid = node.getID();
        object.inputs.forEach(input => {
          node.addPort(new DefaultPortModel(true, input.name, input.name));
        });
        object.outputs.forEach(output => {
          node.addPort(new DefaultPortModel(false, output.name, output.name));
        });   

        node.registerListener({
          entityRemoved: (event: any) => {
            console.log("Removed");
            console.log(event);
            var objects = this.state.translation.objects;
            var i = objects.findIndex(o => o.id === event.entity.getID());
            objects.splice(i, 1);
            this.setState({translation: {...this.state.translation, objects: objects}});
          },
          selectionChanged: (ev: any) => {
            console.log("Selection Changed",ev);
            this.state.translation.objects.forEach(object => {
              if(object.internalid === ev.entity.getID())
              {
                this.setState({selectedObject: object});
              }
            });
          },
          positionChanged: (ev:any) => {
            var translate: ITranslation = JSON.parse(JSON.stringify(this.state.translation));
            translate.objects.forEach(object => {
              if(object.internalid === ev.entity.getID())
              {
                object.x = ev.entity.position.x;
                object.y = ev.entity.position.y;
              }
            });
            this.setState({translation: translate});
          }
        })  
        model.addNode(node);
      });

      //
      // Add Paths
      console.log("TRANSLATION", translation);

      translation.paths.forEach(path => {
        var link = new DefaultLinkModel();
        var inobjid = "";
        var outobjid = "";   
        this.state.translation.objects.forEach(object => {
          if(object.id === path.inputObject)
          {
            inobjid = object.internalid || "";
          }
          if(object.id === path.outputObject)
          {
            outobjid = object.internalid || "";
          }
        });
        var fromport = model.getNode(inobjid).getPort(path.inputField);
        var toport = model.getNode(outobjid).getPort(path.outputField);
        link.setSourcePort(fromport);
        link.setTargetPort(toport);
        model.addLink(link);
      });

      console.log("Model", model);

      model.registerListener({
        nodesUpdated: (ev: any) => {
          console.log("Nodes Updated",ev);
        },
        linksUpdated: (ev:any) => {
          console.log("LINK UPDATE",ev)
        }
      });
  

      this.setState({model: model});
    }

    

     
    
    onSave()
    {
      var m = this.state.model;
      var translation: ITranslation = JSON.parse(JSON.stringify(this.state.translation));

      //
      //  Convert all links to translation paths
      translation.paths = [];
      var links = m.getLinks();
      links.forEach(link => {
        var srcPort = link.getSourcePort();
        var srcObjectInternalID: string = srcPort.getParent().getID();
        var srcObject: ITranslationObject | undefined = translation.objects.find(object => object.internalid === srcObjectInternalID);        

        var inputPort = srcPort.getOptions().name;
        var inputObject = srcObject?.id || "";

        var destPort = link.getTargetPort();
        var destObjectInternalID: string = destPort.getParent().getID();
        var destObject: ITranslationObject | undefined = translation.objects.find(object => object.internalid === destObjectInternalID);        
        var outputPort = destPort.getOptions().name;
        var outputObject = destObject?.id || "";
        var path: IPath = {
          inputObject: inputObject,
          inputField: inputPort,
          outputObject: outputObject,
          outputField: outputPort,
        }
        translation.paths.push(path);
      });
      
    
      if(translation.id === "")
      {
          Post("translation",translation)
          .then(response => {
              translation.id = response.data;
                this.setState({ 
                  translation: translation, 
                });
                this.props.onTranslationCreated(translation.id);
                this.authenticatedrefresh();
              })
          .catch(function (error) {
            console.log(error);
          });
      }
      else
      {
          Put("translation/" + translation.id,translation)
          .then(response => {
            this.authenticatedrefresh();
          })
          .catch(function (error) {
            console.log(error);
          });
      }
  }


    render() 
    {
      if(!this.props.isOpen) return <></>;


      const _items: ICommandBarItemProps[] = [
        {
          key: 'newItem',
          text: 'New',
          iconProps: { iconName: 'Add' },
          split: true,
          ariaLabel: 'New',
          splitButtonAriaLabel: 'More New options',
          subMenuProps: {
            items: [
              { key: 'ToUpper', text: 'To Upper', iconProps: { iconName: 'FontIncrease' }, onClick: () => { 
                var translation: ITranslation = JSON.parse(JSON.stringify(this.state.translation));
                translation.objects.push({
                  id: uuid(),
                  name: "To Upper",
                  objectType: eTranslationObjectType.ToUpperCase,
                  x: 300,
                  y: 200,
                  opParameters: {},
                  inputs: [
                    {
                      name: "In",
                    }
                  ],
                  outputs: [
                    {
                      name: "Out",
                    }
                  ],
                });
                this.setState({translation: translation},()=>{
                  this.generateGraph();
                });
               } },
              { key: 'ToLower', text: 'To Lower', iconProps: { iconName: 'FontDecrease' }, onClick: () => { 
                var translation: ITranslation = JSON.parse(JSON.stringify(this.state.translation));
                translation.objects.push({
                  id: uuid(),
                  name: "To Lower",
                  objectType: eTranslationObjectType.ToLowerCase,
                  x: 300,
                  y: 200,
                  opParameters: {},
                  inputs: [
                    {
                      name: "In",
                    }
                  ],
                  outputs: [
                    {
                      name: "Out",
                    }
                  ],
                });
                this.setState({translation: translation},()=>{
                  this.generateGraph();
                });
               } },
               { key: 'StringJoin', text: 'String Join', iconProps: { iconName: 'CalculatorAddition' }, onClick: () => { 
                var translation: ITranslation = JSON.parse(JSON.stringify(this.state.translation));
                translation.objects.push({
                  id: uuid(),
                  name: "String Join",
                  objectType: eTranslationObjectType.StringJoin,
                  x: 300,
                  y: 200,
                  opParameters: {},
                  inputs: [
                    {
                      name: "In 1",
                    },
                    {
                      name: "In 2",
                    }
                  ],
                  outputs: [
                    {
                      name: "Out",
                    }
                  ],
                });
                this.setState({translation: translation},()=>{
                  this.generateGraph();
                });
               } },
               { key: 'splitname', text: 'Split Name', iconProps: { iconName: 'FontDecrease' }, onClick: () => { 
                var translation: ITranslation = JSON.parse(JSON.stringify(this.state.translation));
                translation.objects.push({
                  id: uuid(),
                  name: "Split Name",
                  objectType: eTranslationObjectType.SplitName,
                  x: 300,
                  y: 200,
                  opParameters: {},
                  inputs: [
                    {
                      name: "In",
                    }
                  ],
                  outputs: [
                    {
                      name: "Title",
                    },
                    {
                      name: "Forename",
                    },
                    {
                      name: "Middle Names",
                    },
                    {
                      name: "Surname",
                    },
                    {
                      name: "Suffix",
                    },
                  ],
                });
                this.setState({translation: translation},()=>{
                  this.generateGraph();
                });
               } },
               { key: 'joinname', text: 'Join Name', iconProps: { iconName: 'FontDecrease' }, onClick: () => { 
                var translation: ITranslation = JSON.parse(JSON.stringify(this.state.translation));
                translation.objects.push({
                  id: uuid(),
                  name: "Join Name",
                  objectType: eTranslationObjectType.JoinName,
                  x: 300,
                  y: 200,
                  opParameters: {},
                  outputs: [
                    {
                      name: "In",
                    }
                  ],
                  inputs: [
                    {
                      name: "Title",
                    },
                    {
                      name: "Forename",
                    },
                    {
                      name: "Middle Names",
                    },
                    {
                      name: "Surename",
                    },
                    {
                      name: "Suffix",
                    },
                  ],
                });
                this.setState({translation: translation},()=>{
                  this.generateGraph();
                });
               } },
               { key: 'Multiply', text: 'Multiply', iconProps: { iconName: 'CalculatorMultiply' }, onClick: () => { 
                var translation: ITranslation = JSON.parse(JSON.stringify(this.state.translation));
                translation.objects.push({
                  id: uuid(),
                  name: "Multiply",
                  objectType: eTranslationObjectType.MathMultiply,
                  x: 300,
                  y: 200,
                  opParameters: {},
                  inputs: [
                    {
                      name: "In 1",
                    },
                    {
                      name: "In 2",
                    }
                  ],
                  outputs: [
                    {
                      name: "Out",
                    }
                  ],
                });
                this.setState({translation: translation},()=>{
                  this.generateGraph();
                });
               } },
               { key: 'Divide', text: 'Divide', iconProps: { iconName: 'CalculatorDivision' }, onClick: () => { 
                var translation: ITranslation = JSON.parse(JSON.stringify(this.state.translation));
                translation.objects.push({
                  id: uuid(),
                  name: "Divide",
                  objectType: eTranslationObjectType.MathMultiply,
                  x: 300,
                  y: 200,
                  opParameters: {},
                  inputs: [
                    {
                      name: "Dividend",
                    },
                    {
                      name: "Divisor",
                    }
                  ],
                  outputs: [
                    {
                      name: "Out",
                    }
                  ],
                }
                );
                this.setState({translation: translation},()=>{
                  this.generateGraph();
                });
               } },            
               { key: 'Add', text: 'Addition', iconProps: { iconName: 'CalculatorAddition' }, onClick: () => { 
                var translation: ITranslation = JSON.parse(JSON.stringify(this.state.translation));
                translation.objects.push({
                  id: uuid(),
                  name: "Addition",
                  objectType: eTranslationObjectType.MathAdd,
                  x: 300,
                  y: 200,
                  opParameters: {},
                  inputs: [
                    {
                      name: "In 1",
                    },
                    {
                      name: "In 2",
                    }
                  ],
                  outputs: [
                    {
                      name: "Out",
                    }
                  ],
                });
                this.setState({translation: translation},()=>{
                  this.generateGraph();
                });
               } },
               { key: 'Subtract', text: 'Subtraction', iconProps: { iconName: 'CalculatorSubtract' }, onClick: () => { 
                var translation: ITranslation = JSON.parse(JSON.stringify(this.state.translation));
                translation.objects.push({
                  id: uuid(),
                  name: "Subtraction",
                  objectType: eTranslationObjectType.MathAdd,
                  x: 300,
                  y: 200,
                  opParameters: {},
                  inputs: [
                    {
                      name: "Minuend",
                    },
                    {
                      name: "Subtrahend",
                    }
                  ],
                  outputs: [
                    {
                      name: "Out",
                    }
                  ],
                });
                this.setState({translation: translation},()=>{
                  this.generateGraph();
                });
               } },
               { key: 'Constant', text: 'Constant', iconProps: { iconName: 'TextField' }, onClick: () => { 
                var translation: ITranslation = JSON.parse(JSON.stringify(this.state.translation));
                translation.objects.push({
                  id: uuid(),
                  name: "Constant",
                  objectType: eTranslationObjectType.Constant,
                  x: 300,
                  y: 200,
                  opParameters: {
                    type: "NUMBER",
                    value: "0"
                  },
                  inputs: [],
                  outputs: [
                    {
                      name: "Out",
                    }
                  ],
                });
                this.setState({translation: translation},()=>{
                  this.generateGraph();
                });
               } },
              ],
          },
        },
        {
          key: 'update',
          text: 'Update Input/Output',
          iconProps: { iconName: 'Refresh' },
          onClick: () => {
            this.resetIO();
          }
        },
      ];
    
    
      var props = <></>;

      if(this.state.selectedObject != null)
      {
        switch(this.state.selectedObject.objectType)
        {
          case eTranslationObjectType.Constant:
            props = <ConstantProperties object={this.state.selectedObject} onChange={(object: ITranslationObject)=>{
              var translation: ITranslation = JSON.parse(JSON.stringify(this.state.translation));
              console.log("NEW VALUE",object)
              translation.objects.forEach(obj => {
                if(object.internalid === obj.internalid)
                {
                  obj.opParameters = object.opParameters;
                }
              });
              this.setState({translation: translation});
            }} />;
        }
      }

      var model = this.state.model;
      engine.setModel(model)

      return (
        <Modal
          isOpen={this.props.isOpen}
          onDismiss={()=>{this.props.onDismiss();}}
          isBlocking={false}
        >
          <Stack style={{margin: 10, width: 1100, height: 600}} >
            <h3>Translation Editor</h3>
            <CommandBar
              items={_items}
              ariaLabel="Use left and right arrow keys to navigate between commands"
            />
            <Separator />
            <Stack horizontal tokens={{childrenGap: 10}}>
              <Stack style={{width: 800, height: 500}}>
                <Global styles={S.Expand} />            
                <S.Container
                  background={'rgb(60, 60, 60)'}
                  color={ 'rgba(255,255,255, 0.05)'}
                >
                  <CanvasWidget  engine={engine}  />
                </S.Container>
              </Stack>
              <Stack style={{width: 300}}>
                {props}
              </Stack>
            </Stack>
          </Stack>
          <Separator/>
          <Stack horizontal tokens={{childrenGap: 5}} grow style={{padding: 10, maxHeight: 50, height: 50}} horizontalAlign="end">
            <PrimaryButton text="Save" onClick={()=>{this.onSave();}} />
            <DefaultButton text="Close" onClick={()=>{this.props.onDismiss();}} />
          </Stack>
        </Modal>
        );
    }

   
}

export default injectIntl(TranslationEditor);