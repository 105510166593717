import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {IRule, ISchedule, IConnection, uuid, IAuthConnection, eOpCode, eScheduleType, eExecutionType, libraryClient, ITemplate, eNoExecutionAction } from './IDeus';
import { v4 as uuidv4 } from 'uuid';


import ReactFlow, { FlowTransform } from 'react-flow-renderer';
import { Background, Controls } from 'react-flow-renderer';

import NOPNode from './nodes/NOPNode'
import SequenceNode from './nodes/SequenceNode'
import ParallelNode from './nodes/ParallelNode'
import RESTNode from './nodes/RESTNode'
import ExpressionNode from './nodes/ExpressionNode'
import ScheduleNode from './nodes/ScheduleNode'
import GenSequenceNode from './nodes/GenSequenceNode'
import LogNode from './nodes/LogNode'
import AggregateNode from './nodes/AggregateNode'
import AggregateFailNode from './nodes/AggregateFailNode'
import AddToAggregateNode from './nodes/AddToAggregateNode';
import FileReceiveNode from './nodes/FileReceiveNode'
import FileDeleteNode from './nodes/FileDeleteNode'
import FileCSVReadNode from './nodes/FileCSVReadNode';
import DirectorySearchNode from './nodes/DirectorySearchNode';
import DatabaseNode from './nodes/DatabaseNode';
import SetValuesNode from './nodes/SetValuesNode';
import TranslateNode from './nodes/TranslateNode';
import TemplateNode from './nodes/TemplateNode';
import EncryptionNode from './nodes/EncryptionNode';
import ExternalExecNode from './nodes/ExternalExecNode';


import { Get, Put } from './authorisation/AMAPI';

import {  
    Stack, 
    Separator,
    PrimaryButton, DefaultButton,
    Dialog, DialogFooter, DialogType, Modal, Text, Toggle,
} from '@fluentui/react';

import RuleProperties from './RuleProperties' 
import ScheduleFinder from './RulesetFinder';
import DDLViewer from './DDLViewer';

const nodeTypes = {
    nopNode: NOPNode,
    sequenceNode: SequenceNode,
    parallelNode: ParallelNode,
    restNode: RESTNode,
    restNode4: RESTNode,
    restNode5: RESTNode,
    restNode6: RESTNode,
    restNode7: RESTNode,
    restNode8: RESTNode,
    restNode9: RESTNode,
    genSequenceNode: GenSequenceNode,
    expressionNode: ExpressionNode,
    scheduleNode: ScheduleNode,
    logNode: LogNode,
    aggregateNode: AggregateNode,
    addtoaggregateNode: AddToAggregateNode,
    aggregateFailNode: AggregateFailNode,
    filereceiveNode: FileReceiveNode, 
    filedeleteNode: FileDeleteNode,
    filecsvreadNode: FileCSVReadNode,
    directorysearchNode: DirectorySearchNode,
    databaseNode: DatabaseNode,
    setvaluesNode: SetValuesNode,
    translateNode: TranslateNode,
    templateNode: TemplateNode,
    encryptionNode: EncryptionNode,
    externalexecNode: ExternalExecNode,
  };

  



type IState = {
    scheduleID: string,
    schedule: ISchedule,
    selectedRule?: IRule,
    shouldSave: boolean,
    clipboard?: IRule,
    clipX?: number,
    clipY?: number,
    elements: any[],

    connections: IConnection[],
    schedules: ISchedule[],
    authConnections: IAuthConnection[],
    templates: ITemplate[],

    sourceRule: string,
    sourceNode: string,
    connecting: boolean,

    graphKey: string,

    doDelete: boolean,
    doImport: boolean,
    importClient: string,

    importSchedule?: ISchedule,

    viewDDLID: string,
    viewDDL: boolean,

    viewport: FlowTransform
}
  
  
interface IProps extends WrappedComponentProps {
    scheduleID: string
    client: string
}


class Editor extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);
        
        this.state = {
            scheduleID: this.props.scheduleID,
            viewport: {
                zoom: 1,
                x: 0,
                y: 0
            },
            schedule: {
                id: uuid(),
                clientID: "",
                name: "New Schedule",
                description: "",
                externalID: "",
                folderID: "",
                type: eScheduleType.TimedSchedule,
                parameterDDLName: "",
                active: false,

                executeAt: new Date().toISOString(),
                repeatType: 0,
                repeatValueUnits: 0,
                repeatValue: 1,

                logExecution: false,

                currentCallCount: 0,
                currentExecRuleCount: 0,
                externalBytesReceived: 0,
                externalBytesSent: 0,
                rule: {
                    id: uuid(),
                    name: "New Rule",
                    description: "",
                    x: 100,
                    y: 100,

                    validateInputDDLID: "",
                    validateInputDDLName: "",

                    executionType: eExecutionType.Parallel,
                    secondsDelay: 0,

                    checkForExecution: false,
                    executionCheck: "",
                    noExecutionAction: eNoExecutionAction.GoLeft,
        
                    opCode: 2,
                    opParameters: {},

                    onTrue: [],
                    onFalse: [],
                    onFail: []
                }

                

            },
            elements: [],

            connections: [],
            schedules: [],
            authConnections: [],
            templates: [],

            sourceNode: "",
            sourceRule: "",
            connecting: false,
            graphKey: uuid(),

            doDelete: false,
            doImport: false,
            importClient: libraryClient,

            viewDDLID: "",
            viewDDL: false,

            shouldSave: false,
        };

        this.handlePropsChange = this.handlePropsChange.bind(this);
        this.generateGraph = this.generateGraph.bind(this);

        this.onElementClick = this.onElementClick.bind(this);
        this.onConnectStart = this.onConnectStart.bind(this);
        this.onConnectStop = this.onConnectStop.bind(this);
        
        this.onSave = this.onSave.bind(this);
        this.loadConnections = this.loadConnections.bind(this);
        this.loadSchedules = this.loadSchedules.bind(this);
        this.loadAuthConnections = this.loadAuthConnections.bind(this);
        this.loadTemplates = this.loadTemplates.bind(this);

        this.setSelectedRule = this.setSelectedRule.bind(this);
        this.findSelectedRule = this.findSelectedRule.bind(this);
        this.findRule = this.findRule.bind(this);
        this.findParentRule = this.findParentRule.bind(this);
        
        this.onDelete = this.onDelete.bind(this);
        this.onCut = this.onCut.bind(this);
        this.onCopy = this.onCopy.bind(this);
        this.onPaste = this.onPaste.bind(this);
        this.onImport = this.onImport.bind(this);

        this.handleMove = this.handleMove.bind(this);
    }
      

    handleMove(event: any, node: any )
    {
        
        this.setSelectedRule(node.id);
        this.state.elements.forEach(e => { 
            if(e.data !== undefined)
                e.data.selected = (e.id===node?.id)
        });

        var s = {...this.state.schedule};
        var r = this.findRule(node.id,s.rule);
        if(r == null)       return;


        var dx = node.position.x - r.x;
        var dy = node.position.y - r.y;

        this.rulesetMove(r,dx,dy);

        r.x = node.position.x;
        r.y = node.position.y;

        this.setState({
            schedule: s, 
            graphKey: uuid(),
            shouldSave: true
        },()=>{ this.generateGraph(s)});


    }

// picker    

    handlePropsChange(rule: IRule): void
    {
        var s: ISchedule = JSON.parse(JSON.stringify(this.state.schedule));
        var e = JSON.parse(JSON.stringify(this.state.elements));
        this.setRule(rule,s.rule);
        var i: number;
        for(i = 0; i < e.length; i++)
        {
            if(e[i].id === rule.id)
            {
                e[i].data.rule = rule;
                e[i].type = this.nodeName(rule.opCode); 
                break;
            }
        }
        this.setState({
            elements: e,
            schedule: s, 
            graphKey: uuid(),
            shouldSave: true
        });

    }

    componentDidMount()
    {
      this.refresh();
      this.loadSchedules();
      this.loadTemplates();
    }
  
    loadConnections(): void
    {
        if(this.props.client==="") return;
        Get("connection/forclient/"+this.props.client)
          .then(response => {
                var s: IConnection[] = response.data;
                this.setState({ 
                  connections: s, 
                });
            })
          .catch(function (error) {
            console.log(error);
          });
        
    }

    loadSchedules(): void
    {
        Get("ruleset")
          .then(response => {
                var s: ISchedule[] = response.data;
                this.setState({ 
                  schedules: s, 
                });
            })
          .catch(function (error) {
            console.log(error);
          });
        
    }

    loadAuthConnections(): void
    {
        if(this.props.client==="") return;

        Get("authorizers/forclient/"+this.props.client)
        .then(response => {
            this.setState({authConnections: response.data})
        });

    }

    loadTemplates(): void
    {
        if(this.props.client==="") return;

        Get("template/forclient/"+this.props.client)
        .then(response => {
            this.setState({templates: response.data})
        });

    }
  
    refresh(): void
    {
  
        Get("ruleset/" + this.state.scheduleID)
          .then(response => {
                var s: ISchedule = response.data;
                this.setState({ 
                  schedule: s, 
                  shouldSave: false,
                });
                this.loadConnections();
                this.loadAuthConnections();
                this.generateGraph(s);
            })
          .catch(function (error) {
            console.log(error);
          });
  
    }

    onSave()
    {
        Put("ruleset/" + this.state.scheduleID,this.state.schedule)
          .then(response => {
              this.setState({ shouldSave: false });
            })
          .catch(function (error) {
            console.log(error);
          });

    }

    nodeName(op: number)
    {
        switch(op)
        {
            case eOpCode.NOP: return "nopNode";
            case eOpCode.ExecSequence: return "sequenceNode";
            case eOpCode.ExecREST: return "restNode";
            case eOpCode.ExecGenerateSequence: return "genSequenceNode";
            case eOpCode.ExecBooleanExpression: return "expressionNode";
            case eOpCode.ExecSchedule: return "scheduleNode";
            case eOpCode.ExecLog: return "logNode";
            case eOpCode.ExecAggregate: return "aggregateNode";
            case eOpCode.ExecAddToAggregate: return "addtoaggregateNode";
            case eOpCode.ExecAggregateFail: return "aggregateFailNode";
            case eOpCode.ExecFileReceive: return "filereceiveNode";
            case eOpCode.ExecFileDelete: return "filedeleteNode";
            case eOpCode.ExecCSVFileRead: return "filecsvreadNode";
            case eOpCode.ExecDirectorySearch: return "directorysearchNode";
            case eOpCode.ExecDatabaseQuery: return "databaseNode";
            case eOpCode.ExecTranslate: return "translateNode";
            case eOpCode.ExecTemplate: return "templateNode";
            case eOpCode.ExecSetValues: return "setvaluesNode";
            case eOpCode.ExecEncryption: return "encryptionNode";
            case eOpCode.ExecExternalCommand: return "externalexecNode";
        }
    }

    generateGraph(schedule: ISchedule)
    {
        var elements: any[] = [];
        var selected = schedule.rule.id===this.state.selectedRule?.id;
        var node = {
            id: schedule.rule.id,
            type: this.nodeName(schedule.rule.opCode), // input node
            data: { rule: schedule.rule, root: true, selected: selected },
            position: { x: schedule.rule.x, y: this.state.schedule.rule.y },
        }
        elements.push(node);

        schedule.rule.onTrue.forEach(child => 
            { 
                this.addNode(elements,child, schedule.rule.id, "OnTrue");
            });
        schedule.rule.onFalse.forEach(child => 
            { 
                this.addNode(elements,child, schedule.rule.id, "OnFalse");
            });
        schedule.rule.onFail.forEach(child => 
            { 
                this.addNode(elements,child, schedule.rule.id, "OnFail");
            });
        this.setState({elements: elements});
    }

    addNode(elements: any[],rule: IRule, parentID: string, handle: string)
    {
        var selected = rule.id===this.state.selectedRule?.id;
        var node = {
            id: rule.id,
            type: this.nodeName(rule.opCode), // input node
            data: { rule: rule, root: false, selected: selected  },
            position: { x: rule.x, y: rule.y },
        }
        var join = { 
            id: parentID+'-'+node.id, 
            source: parentID, 
            target: rule.id, 
            animated: true, 
            sourceHandle:handle,
            data: { label: 'Input 1' },
        };
        elements.push(node);
        elements.push(join);
        rule.onTrue.forEach(r => this.addNode(elements,r,rule.id, "OnTrue"));
        rule.onFalse.forEach(r => this.addNode(elements,r,rule.id, "OnFalse"));
        rule.onFail.forEach(r => this.addNode(elements,r,rule.id, "OnFail"));
    }

    onElementClick(ev: any,element: any)
    {
        this.setSelectedRule(element.id);
        this.state.elements.forEach(e => { 
            if(e.data !== undefined)
                e.data.selected = (e.id===element?.id)
        });
        this.setState({ graphKey: uuid()});
    }

    onConnectStop(ev: any)
    {
        if(!this.state.connecting)  return;

        var sched = JSON.parse(JSON.stringify(this.state.schedule));
        var parent = this.findRule(this.state.sourceRule,sched.rule);

        if(parent === null) 
        {
            return;
        }
        var newRule: IRule = {
                id: uuid(),
                name: "New Rule",
                description: "",
                x: (( ev.layerX - this.state.viewport.x ) / this.state.viewport.zoom) - 75,
                y: ( ev.layerY - this.state.viewport.y ) / this.state.viewport.zoom,

                validateInputDDLID: "",
                validateInputDDLName: "",

                executionType: eExecutionType.Parallel,
                secondsDelay: 0,

                checkForExecution: false,
                executionCheck: "",
                noExecutionAction: eNoExecutionAction.GoLeft,

                opCode: 0,
                opParameters: {},

                onTrue: [],
                onFalse: [],
                onFail: []
        }

        switch(this.state.sourceNode)
        {
            case "OnFail":
                parent.onFail.push(newRule);
                break;
            case "OnTrue":
                parent.onTrue.push(newRule);
                break;
            case "OnFalse":
                parent.onFalse.push(newRule);
                break;
        }

        this.setState({schedule: sched, connecting: false, shouldSave: true});
        this.generateGraph(this.state.schedule);
    }

    setSelectedRule(id: string): void
    {
        this.findSelectedRule(this.state.schedule.rule,id);
    }

    setRule(src: IRule, root: IRule): boolean
    {
        if(root.id === src.id)
        {
            root.opCode = src.opCode;
            root.name = src.name;
            root.description = src.description;
            root.opParameters = src.opParameters;
            root.executionType = src.executionType;
            root.secondsDelay = src.secondsDelay;
            root.validateInputDDLID = src.validateInputDDLID;
            root.validateInputDDLName = src.validateInputDDLName;
            root.checkForExecution = src.checkForExecution;
            root.executionCheck = src.executionCheck;
            root.noExecutionAction = src.noExecutionAction;
            return true;
        }   

        var i: number;
        for(i = 0; i < root.onTrue.length;i++)
        {
            if(this.setRule(src,root.onTrue[i])) return true;
        }

        for(i = 0; i < root.onFalse.length;i++)
        {
            if(this.setRule(src,root.onFalse[i])) return true;
        }

        for(i = 0; i < root.onFail.length;i++)
        {
            if(this.setRule(src,root.onFail[i])) return true;
        }

        return false;
    }

    findRule(id: string, root: IRule): IRule | null
    {
        if(root.id === id)
        {
            return root;
        }   

        var i: number;
        var f: IRule | null;
        for(i = 0; i < root.onTrue.length;i++)
        {
            f = this.findRule(id,root.onTrue[i]);
            if( f!== null)  return f;
        }
        for(i = 0; i < root.onFalse.length;i++)
        {
            f = this.findRule(id,root.onFalse[i]);
            if( f!== null)  return f;
        }
        for(i = 0; i < root.onFail.length;i++)
        {
            f = this.findRule(id,root.onFail[i]);
            if( f!== null)  return f;
        }
        return null;
    }

    findParentRule(id: string, root: IRule): IRule | null
    {
        if(root.id === id)
        {
            return null;
        }   

        var i: number;
        var f: IRule | null;
        for(i = 0; i < root.onTrue.length;i++)
        {
            if( root.onTrue[i].id === id)  return root;
        }
        for(i = 0; i < root.onFalse.length;i++)
        {
            if( root.onFalse[i].id === id)  return root;
        }
        for(i = 0; i < root.onFail.length;i++)
        {
            if( root.onFail[i].id === id)  return root;
        }
        // Ok - Not this rule, so try children
        for(i = 0; i < root.onTrue.length;i++)
        {
            f = this.findParentRule(id,root.onTrue[i]);
            if( f!== null)  return f;
        }
        for(i = 0; i < root.onFalse.length;i++)
        {
            f = this.findParentRule(id,root.onFalse[i]);
            if( f!== null)  return f;
        }
        for(i = 0; i < root.onFail.length;i++)
        {
            f = this.findParentRule(id,root.onFail[i]);
            if( f!== null)  return f;
        }

        return null;
    }

    findSelectedRule(r: IRule, id: string): boolean
    {
        if(r.id === id)
        {
            this.setState({selectedRule: r});
            return true;
        }
        var i: number;
        for(i = 0; i <r.onTrue.length;i++)
        {
            if(this.findSelectedRule(r.onTrue[i],id))      return true;
        }
        for(i = 0; i <r.onFalse.length;i++)
        {
            if(this.findSelectedRule(r.onFalse[i],id))      return true;
        }
        for(i = 0; i <r.onFail.length;i++)
        {
            if(this.findSelectedRule(r.onFail[i],id))      return true;
        }
        return false;
    }

    onConnectStart(event: any, params: any): void
    {
        this.setState({connecting: true, sourceRule: params.nodeId, sourceNode: params.handleId })
    }

    onDelete(): void
    {
        var sched: ISchedule = {...this.state.schedule};
        var id = this.state.selectedRule?.id ?? "";
        var node = this.findParentRule(id,sched.rule);
        if(node === null) return;
        var i: number;
        for(i = 0; i < node.onTrue.length;i++)
        {
            if( node.onTrue[i].id === id)  
            {
                node.onTrue.splice(i,1);
                this.setState({schedule: sched, doDelete: false, shouldSave: true});
                this.generateGraph(sched);
                return;
            }
        }
        for(i = 0; i < node.onFalse.length;i++)
        {
            if( node.onFalse[i].id === id)  
            {
                node.onFalse.splice(i,1);
                this.setState({schedule: sched, doDelete: false, shouldSave: true});
                this.generateGraph(sched);
                return;
            }
        }
        for(i = 0; i < node.onFail.length;i++)
        {
            if( node.onFail[i].id === id)              
            {
                node.onFail.splice(i,1);
                this.setState({schedule: sched, doDelete: false, shouldSave: true});
                this.generateGraph(sched);
                return;
            }
        }
        this.setState({schedule: sched, doDelete: false});
    }

    onCut(): void
    {
        var sched: ISchedule = {...this.state.schedule};
        var id = this.state.selectedRule?.id ?? "";
        var node = this.findParentRule(id,sched.rule);
        if(node === null) return;
        var i: number;
        var clip;
        for(i = 0; i < node.onTrue.length;i++)
        {
            if( node.onTrue[i].id === id)  
            {
                clip = node.onTrue[i];
                node.onTrue.splice(i,1);
                this.setState({schedule: sched, clipboard: clip, clipX: node.x, clipY: node.y, shouldSave: true});
                this.generateGraph(sched);
                return;
            }
        }
        for(i = 0; i < node.onFalse.length;i++)
        {
            if( node.onFalse[i].id === id)  
            {
                clip = node.onFalse[i];
                node.onFalse.splice(i,1);
                this.setState({schedule: sched, clipboard: clip, clipX: node.x, clipY: node.y, shouldSave: true});
                this.generateGraph(sched);
                return;
            }
        }
        for(i = 0; i < node.onFail.length;i++)
        {
            if( node.onFail[i].id === id)              
            {
                clip = node.onFail[i];
                node.onFail.splice(i,1);
                this.setState({schedule: sched, clipboard: clip, clipX: node.x, clipY: node.y, shouldSave: true});
                this.generateGraph(sched);
                return;
            }
        }
    }

    onCopy(): void
    {
        var sched: ISchedule = {...this.state.schedule};
        var id = this.state.selectedRule?.id ?? "";
        var node = this.findParentRule(id,sched.rule);
        if(node === null) return;
        var i: number;
        var clip;
        for(i = 0; i < node.onTrue.length;i++)
        {
            if( node.onTrue[i].id === id)  
            {
                clip = node.onTrue[i];
                this.setState({clipboard: clip, clipX: node.x, clipY: node.y});
                this.generateGraph(sched);
                return;
            }
        }
        for(i = 0; i < node.onFalse.length;i++)
        {
            if( node.onFalse[i].id === id)  
            {
                clip = node.onFalse[i];
                this.setState({clipboard: clip, clipX: node.x, clipY: node.y});
                this.generateGraph(sched);
                return;
            }
        }
        for(i = 0; i < node.onFail.length;i++)
        {
            if( node.onFail[i].id === id)              
            {
                clip = node.onFail[i];
                this.setState({clipboard: clip, clipX: node.x, clipY: node.y});
                this.generateGraph(sched);
                return;
            }
        }
    }

    onPaste(): void
    {
        if(this.state.clipboard == null)    return;
        if(this.state.selectedRule == null) return;
        var sched = {...this.state.schedule};
        var id = this.state.selectedRule?.id ?? "";
        var node = this.findParentRule(id,sched.rule);
        if(node === null) return;
        var i: number;
        var clip = this.state.clipboard;

        // var dx = this.state.clipX ?? 0 - node.x;
        // var dy = this.state.clipY ?? 0 - node.y;

        var dx = this.state.selectedRule.x - clip.x;
        var dy = this.state.selectedRule.y - clip.y;

        this.rulesetNewIDs(clip,dx,dy);

        for(i = 0; i < node.onTrue.length;i++)
        {
            if( node.onTrue[i].id === id)  
            {
                node.onTrue[i] = clip;
                this.setState({schedule: sched, shouldSave: true});
                this.generateGraph(sched);
                return;
            }
        }
        for(i = 0; i < node.onFalse.length;i++)
        {
            if( node.onFalse[i].id === id)  
            {
                node.onFalse[i] = clip;
                this.setState({schedule: sched, shouldSave: true});
                this.generateGraph(sched);
                return;
            }
        }
        for(i = 0; i < node.onFail.length;i++)
        {
            if( node.onFail[i].id === id)              
            {
                node.onFail[i] = clip;
                this.setState({schedule: sched, shouldSave: true});
                this.generateGraph(sched);
                return;
            }
        }
    }

    onImport(schedule?: ISchedule): void
    {
        if(!schedule)   return;
        var sched = {...this.state.schedule};
        var id = this.state.selectedRule?.id ?? "";
        var node = this.findParentRule(id,sched.rule);
        if(node === null) return;
        var i: number;

        var dx = schedule.rule.x ?? 0 - node.x;
        var dy = schedule.rule.y ?? 0 - node.y;

        this.rulesetNewIDs(schedule.rule,dx,dy);

        for(i = 0; i < node.onTrue.length;i++)
        {
            if( node.onTrue[i].id === id)  
            {
                node.onTrue[i] = schedule.rule;
                this.setState({schedule: sched, shouldSave: true});
                this.generateGraph(sched);
                return;
            }
        }
        for(i = 0; i < node.onFalse.length;i++)
        {
            if( node.onFalse[i].id === id)  
            {
                node.onFalse[i] = schedule.rule;
                this.setState({schedule: sched, shouldSave: true});
                this.generateGraph(sched);
                return;
            }
        }
        for(i = 0; i < node.onFail.length;i++)
        {
            if( node.onFail[i].id === id)              
            {
                node.onFail[i] = schedule.rule;
                this.setState({schedule: sched, shouldSave: true});
                this.generateGraph(sched);
                return;
            }
        }

    }
  

    rulesetMove(ruleset: IRule, dx: number, dy: number): void
    {
        ruleset.x += dx;
        ruleset.y += dy;
        ruleset.onTrue.forEach(r => this.rulesetMove(r,dx,dy));
        ruleset.onFalse.forEach(r => this.rulesetMove(r,dx,dy));
        ruleset.onFail.forEach(r => this.rulesetMove(r,dx,dy));
    }

    rulesetNewIDs(ruleset: IRule, dx: number, dy: number): void
    {
        ruleset.id = uuidv4();
        ruleset.x += dx;
        ruleset.y += dy;
        ruleset.onTrue.forEach(r => this.rulesetNewIDs(r,dx,dy));
        ruleset.onFalse.forEach(r => this.rulesetNewIDs(r,dx,dy));
        ruleset.onFail.forEach(r => this.rulesetNewIDs(r,dx,dy));
    }

    render() {

        var props = <></>;

        if(this.state.selectedRule)
        {
            props = <RuleProperties client={this.props.client} templates={this.state.templates} rule={this.state.selectedRule} schedules={this.state.schedules} connections={this.state.connections} authConnections={this.state.authConnections} onChange={this.handlePropsChange} />
        }

          return (<>
            <Stack verticalFill>
                <Stack horizontal tokens={{ childrenGap: 5}} style={{paddingTop: 10, paddingBottom: 10}}>
                    <PrimaryButton text="Save" disabled={!this.state.shouldSave} onClick={()=>{this.onSave();}}/>
                    <DefaultButton text="Delete" iconProps={{ iconName: "Delete" }} onClick={()=>{this.setState({doDelete: true})}} disabled={this.state.selectedRule == null}  />
                    <DefaultButton text="Cut" iconProps={{ iconName: "Cut" }} onClick={this.onCut} disabled={this.state.selectedRule == null}  />
                    <DefaultButton text="Copy" iconProps={{ iconName: "Copy" }} onClick={this.onCopy} disabled={this.state.selectedRule == null} />
                    <DefaultButton text="Paste" iconProps={{ iconName: "Paste" }} onClick={this.onPaste} disabled={this.state.selectedRule == null || this.state.clipboard == null} />
                    <DefaultButton text="Import" iconProps={{ iconName: 'Import' }} onClick={()=>this.setState({doImport: true})} width={300} disabled={this.state.selectedRule == null}/>
                    <DefaultButton text="View Input" iconProps={{ iconName: 'View' }} onClick={()=>this.setState({viewDDL: true, viewDDLID: this.state.schedule?.parameterDDLID ?? ""})} width={300} disabled={(this.state.schedule?.parameterDDLID ?? "") === ""}/>
                    <h3 style={{paddingLeft: 20}}>{this.state.schedule.name}</h3>
                </Stack>
                <Stack horizontal grow >
                <Stack grow={4} >
                    <ReactFlow 
                            key={this.state.graphKey}
                            onConnectStart={this.onConnectStart}
                            onConnectEnd={this.onConnectStop} 
                            onElementClick={this.onElementClick} 
                            onNodeDragStop={this.handleMove}
                            defaultZoom={this.state.viewport.zoom}
                            defaultPosition={[this.state.viewport.x,this.state.viewport.y]}
                            onMoveEnd={(e: FlowTransform | undefined) =>{ console.log("onMoveEnd",e); if(e) this.setState({viewport: e}) }}
                            nodeTypes={nodeTypes}
                            nodesConnectable={true}
                            snapToGrid={true}
                            elements={this.state.elements}
                            >
                                <Controls />
                        <Background  
                            gap={12}
                            size={1}
                            color={'#c0c0c0'}
                        />
                    </ReactFlow>
                </Stack>
                <Separator vertical />
                <Stack style={{width: 400}}>
                    {props}
                </Stack>
                </Stack>
                </Stack>  
                 <Dialog
                 hidden={!this.state.doDelete}
                 onDismiss={()=>{this.setState({doDelete: false})}}
                 dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Delete Rule',
                    closeButtonAriaLabel: 'Close',
                    subText: 'Are you sure you want to delete this node. This will delete all child nodes and is non reversible.',
                  }}
               >
                 <DialogFooter>
                   <PrimaryButton onClick={()=>{this.onDelete()}} text="Delete" />
                   <DefaultButton onClick={()=>{this.setState({doDelete: false})}} text="Cancel" />
                 </DialogFooter>
               </Dialog>
               <Modal
        isOpen={this.state.doImport}
        onDismiss={()=>{this.setState({doImport: false})}}
        isBlocking={false}
      >
        <Stack style={{margin: 10}}>            
          <Text variant="xLarge">Import Rule Set</Text>
          <Toggle 
                label="Import from" onText="Global Library" offText="My Library" 
                checked={this.state.importClient === libraryClient}
                onChange={(e:any)=>{
                    var client = this.state.importClient === libraryClient ? this.props.client : libraryClient;
                    this.setState({importClient: client})}} 
                />
        <ScheduleFinder client={this.state.importClient} scheduleSelected={(sched: ISchedule) => {this.setState({importSchedule: sched})}}/>
        <Separator/>
            <Stack  tokens={{childrenGap: 10}} >
              <Stack.Item  align="end">
                <Stack horizontal tokens={{childrenGap: 10}}>
              <PrimaryButton text="Import" disabled={this.state.importSchedule == null} onClick={()=>{
                this.onImport(this.state.importSchedule)
              }} />
              <DefaultButton text="Cancel" onClick={()=>{this.setState({doImport: false, importSchedule: undefined})}} />
              </Stack>
              </Stack.Item>
            </Stack>
        </Stack>
        </Modal>
        <DDLViewer isOpen={this.state.viewDDL} ddlId={this.state.viewDDLID} onDismiss={()=>{this.setState({viewDDL: false})}}  />
                       </>
        );
      };
      
}

export default injectIntl(Editor);