import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';



import {  
      Dialog, DialogFooter, DefaultButton, DialogType, 
} from '@fluentui/react';

import Folders from './Folders' 

type IState = {

    currentFolder: string

}
  
interface IProps extends WrappedComponentProps {
  client: string,
  onFolderSelected?: any
  onDismiss?: any
  isOpen: boolean
}


class FolderPicker extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            currentFolder: "Uncategorized",

        };

        this.onFolderSelected = this.onFolderSelected.bind(this);
    }

    componentDidUpdate(prevProps: IProps) {
      if (prevProps.client !== this.props.client) {
        //this.authenticatedrefresh();
      }
     
    }

 
    
  

    
   
      public onFolderSelected(folder: string)
      {
        this.setState({currentFolder: folder},()=>{
          //this.authenticatedrefresh();
        });
      }

      render() 
    {
        return (
            <Dialog
                 hidden={!this.props.isOpen}
                 onDismiss={()=>{this.props.onDismiss()}}
                 dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Move Folder',
                    closeButtonAriaLabel: 'Close',
                    subText: 'Choose which folder you want to move this object to',
                  }}
            >
                <Folders 
                    clientid={this.props.client} 
                    onFolderSelected={(folder: string) => this.props.onFolderSelected(folder)}  
                />

                 <DialogFooter>
                   <DefaultButton onClick={()=>{this.props.onDismiss()}} text="Cancel" />
                 </DialogFooter>
            </Dialog>    
          );
    }


}

export default injectIntl(FolderPicker);