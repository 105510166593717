import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { IRule, IDataDefinition, IConnection, ISchedule, IAuthConnection, eOpCode, eExecutionType, ITemplate, eNoExecutionAction, IKey } from './IDeus';

import {  
    Stack, 
    TextField, Dropdown, IDropdownOption, DropdownMenuItemType,
    Image, 
    Label, Text, Toggle, Pivot, PivotItem, IconButton, ActionButton, Dialog, DialogFooter, DefaultButton, List, Separator,
} from '@fluentui/react';

import RuleHelp from './help/RuleHelp';

import { Validate } from './Validate';
import DDLFinder from './DDLFinder';
import KeyFinder from './KeyFinder';
import TranslationEditor from './TranslationEditor';

import { Get } from './authorisation/AMAPI';
import ExternalExecProperties from './ruleproperties/ExternalExecProperties';
import EncryptionProperties from './ruleproperties/EncryptionProperties';


type IState = {
    rule: IRule,
    param1: string,
    param2: string,
    param3: string,
    param4: string,
    param5: string,
    param6: string,
    param7: string,
    param8: string,
    param9: string,

    param1Name: string,
    param2Name: string,

    onGetDDL: boolean,
    onGetKey: boolean,
    doSetTemplate: boolean,

    databases: string[],
    doGetDatabases: boolean,

    tables: string[],
    doGetTables: boolean,

    doGetInputFormat: boolean,
    doGetOutputFormat: boolean,

    showTranslationEditor: boolean,

}
  
  
interface IProps extends WrappedComponentProps {
    rule: IRule,
    onChange?: any,
    connections: IConnection[],
    schedules: ISchedule[],
    templates: ITemplate[],
    authConnections: IAuthConnection[],
    readOnly?: boolean,
    client: string,
}


class RuleProperties extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        var p1 = "";
        var p2 = "";
        var p3 = "";
        var p4 = "";
        var p5 = "";
        var p6 = "";
        var p7 = "";
        var p8 = "";
        var p9 = "";


        switch(this.props.rule.opCode)
        {
            case eOpCode.ExecREST:
                p1 = this.props.rule.opParameters["url"] ?? "";
                p2 = this.props.rule.opParameters["method"] ?? "";
                p3 = this.props.rule.opParameters["body"] ?? "";
                p4 = this.props.rule.opParameters["format"] ?? "";
                p5 = this.props.rule.opParameters["connection"] ?? "";
                p6 = this.props.rule.opParameters["outputelement"] ?? "";
                p7 = this.props.rule.opParameters["authConnection"] ?? "";
                break;
            case eOpCode.ExecGenerateSequence:
                p1 = this.props.rule.opParameters["sequence"] ?? "";
                p2 = this.props.rule.opParameters["format"] ?? "";
                break;
            case eOpCode.ExecBooleanExpression:
                p1 = this.props.rule.opParameters["expression"] ?? "";
                break;
            case eOpCode.ExecSchedule:
                p1 = this.props.rule.opParameters["schedule"] ?? "";
                p2 = this.props.rule.opParameters["schedulename"] ?? "";
                break;
            case eOpCode.ExecLog:
                p1 = this.props.rule.opParameters["entry"] ?? "";
                p2 = this.props.rule.opParameters["connection"] ?? "";
                p3 = this.props.rule.opParameters["title"] ?? "";
                break;
            case eOpCode.ExecDatabaseQuery:
                p1 = this.props.rule.opParameters["query"] ?? "";
                p2 = this.props.rule.opParameters["connection"] ?? "";
                p3 = this.props.rule.opParameters["database"] ?? "";
                p4 = this.props.rule.opParameters["type"] ?? "";
                p5 = this.props.rule.opParameters["table"] ?? "";
                break;
            case eOpCode.ExecAggregate:
                p1 = this.props.rule.opParameters["template"] ?? "";
                break;
            case eOpCode.ExecAggregateFail:
                p1 = this.props.rule.opParameters["code"] ?? "";
                p2 = this.props.rule.opParameters["message"] ?? "";
                break;
            case eOpCode.ExecAddToAggregate:
                p1 = this.props.rule.opParameters["outputelement"] ?? "";
                p2 = this.props.rule.opParameters["addtoelement"] ?? "";
                break;
            case eOpCode.ExecSetValues: //  Add To Output
                p1 = this.props.rule.opParameters["setvalues"];
                p2 = this.props.rule.opParameters["outputvalue"];
                break;
            case eOpCode.ExecFileReceive:
                p1 = this.props.rule.opParameters["storage"] ?? "";
                p2 = this.props.rule.opParameters["connection"] ?? "";
                break;
            case eOpCode.ExecCSVFileRead:
                p1 = this.props.rule.opParameters["storage"] ?? "";
                p2 = this.props.rule.opParameters["header"] ?? "FALSE";
                p3 = this.props.rule.opParameters["useheader"] ?? "FALSE";
                p4 = this.props.rule.opParameters["connection"] ?? "";
                break;
            case eOpCode.ExecFileDelete:
                p1 = this.props.rule.opParameters["storage"] ?? "";
                p2 = this.props.rule.opParameters["connection"] ?? "";
                break;
            case eOpCode.ExecTranslate:
                p1 = this.props.rule.opParameters["inputformat"] ?? "";
                p2 = this.props.rule.opParameters["inputformatname"] ?? "";
                p3 = this.props.rule.opParameters["outputformat"] ?? "";
                p4 = this.props.rule.opParameters["outputformatname"] ?? "";
                p5 = this.props.rule.opParameters["translation"] ?? "";
                break;
            case eOpCode.ExecTemplate:
                p2 = this.props.rule.opParameters["template"] ?? "";
                var t = this.props.templates.find(t => t.id === p2);
                if(t!=null)
                {
                    p1 = t.dataTemplate || "UNDEFINED";
                }
                break;
            case eOpCode.ExecEncryption:
                p1 = this.props.rule.opParameters["fields"] ?? "";
                p2 = this.props.rule.opParameters["type"] ?? "";
                p3 = this.props.rule.opParameters["direction"] ?? "";
                p4 = this.props.rule.opParameters["keyid"] ?? "";
                p5 = this.props.rule.opParameters["keyname"] ?? "";
                break;
            }
    
        this.state = {
            rule: this.props.rule,
            param1: p1,
            param2: p2,
            param3: p3,
            param4: p4,
            param5: p5,
            param6: p6,
            param7: p7,
            param8: p8,
            param9: p9,

            param1Name: "",
            param2Name: "",


            onGetDDL: false,
            onGetKey: false,
            doSetTemplate: false,

            databases: [],
            doGetDatabases: false,

            tables: [],
            doGetTables: false,

            doGetInputFormat: false,
            doGetOutputFormat: false,

            showTranslationEditor: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.onTemplateChange = this.onTemplateChange.bind(this);
        this.onParam1Change = this.onParam1Change.bind(this);
        this.onParam2Change = this.onParam2Change.bind(this);
        this.onParam3Change = this.onParam3Change.bind(this);
        this.onParam4Change = this.onParam4Change.bind(this);
        this.onParam5Change = this.onParam5Change.bind(this);
        this.onParam7Change = this.onParam7Change.bind(this);
        this.onToggleDelay = this.onToggleDelay.bind(this);
        this.onToggleP2 = this.onToggleP2.bind(this);
        this.onToggleP3 = this.onToggleP3.bind(this);
        this.getDefaultAuthorisor = this.getDefaultAuthorisor.bind(this);
        this.setOpCode = this.setOpCode.bind(this);
        this.setNoExecute = this.setNoExecute.bind(this);
        this.getTables = this.getTables.bind(this);
        this.getDatabases = this.getDatabases.bind(this);
        this.onParametersChanged = this.onParametersChanged.bind(this);
    }

    getTemplate(id: string): void
    {
        Get("datadefinition/template/"+id)
          .then(response => {
            console.log(response.data);
                var r  = {...this.state.rule};
                r.opParameters["template"] = response.data;
                this.setState({ 
                  rule: r, onGetDDL: false, param1: response.data, doSetTemplate: false
                });
                this.doSave(r,response.data);
            })
          .catch(function (error) {
            console.log(error);
          });
        
    }

    getTables(): void
    {
        Get("ruleset/GetTables/"+this.state.param2+"/"+this.state.param3)
          .then(response => {
            console.log(response.data);
                this.setState({ 
                  tables: response.data
                });
            })
          .catch(function (error) {
            console.log(error);
          });
    }

    getDatabases(): void
    {
        Get("ruleset/GetDatabases/"+this.state.param2)
          .then(response => {
            console.log(response.data);
                this.setState({ 
                  databases: response.data,
                });
            })
          .catch(function (error) {
            console.log(error);
          });
    }

    private onToggleDelay(): void
    {
        var r: IRule  = {...this.state.rule};
        r.executionType  = this.state.rule.executionType === eExecutionType.Delay ? eExecutionType.Parallel : eExecutionType.Delay;
        this.setState({rule: r }) ;
        this.doSave(r);
    }

    private onToggleP2(name:string): void
    {
        var r  = {...this.state.rule};
        var newval = this.state.param2 === "TRUE" ? "FALSE" : "TRUE";
        this.setState({param2: newval }) ;
        this.doSave(r,null,newval);
    }

    private onToggleP3(name:string): void
    {
        var r  = {...this.state.rule};
        var newval = this.state.param3 === "TRUE" ? "FALSE" : "TRUE";
        this.setState({param3: newval }) ;
        this.doSave(r,null,null,newval);
    }

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.rule !== this.props.rule) {
            var p1 = "";
            var p2 = "";
            var p3 = "";
            var p4 = "";
            var p5 = "";
            var p6 = "";
            var p7 = "";
            var p8 = "";
            var p9 = "";

            switch(this.props.rule.opCode)
            {
                case eOpCode.ExecREST: 
                    p1 = this.props.rule.opParameters["url"] ?? "";
                    p2 = this.props.rule.opParameters["method"] ?? "";
                    p3 = this.props.rule.opParameters["body"] ?? "";
                    p4 = this.props.rule.opParameters["format"] ?? "";
                    p5 = this.props.rule.opParameters["connection"] ?? "";
                    p6 = this.props.rule.opParameters["outputelement"] ?? "";
                    p7 = this.props.rule.opParameters["authConnection"] ?? "";
                    break;
                case eOpCode.ExecGenerateSequence:    
                    p1 = this.props.rule.opParameters["sequence"] ?? "";
                    p2 = this.props.rule.opParameters["format"] ?? "";
                    break;
                case eOpCode.ExecBooleanExpression:   
                    p1 = this.props.rule.opParameters["expression"] ?? "";
                    break;
                case eOpCode.ExecSchedule:     
                    p1 = this.props.rule.opParameters["schedule"] ?? "";
                    p2 = this.props.rule.opParameters["schedulename"] ?? "";
                    break;
                case eOpCode.ExecLog:    
                    p1 = this.props.rule.opParameters["entry"] ?? "";
                    p2 = this.props.rule.opParameters["connection"] ?? "";
                    p3 = this.props.rule.opParameters["title"] ?? "";
                    break;
                case eOpCode.ExecDatabaseQuery:    
                    p1 = this.props.rule.opParameters["query"] ?? "";
                    p2 = this.props.rule.opParameters["connection"] ?? "";
                    p3 = this.props.rule.opParameters["database"] ?? "";
                    p4 = this.props.rule.opParameters["type"] ?? "";
                    p5 = this.props.rule.opParameters["table"] ?? "";
                    break;
                case eOpCode.ExecAggregate:
                    p1 = this.props.rule.opParameters["template"] ?? "";
                    break;
                case eOpCode.ExecAggregateFail:
                    p1 = this.props.rule.opParameters["code"] ?? "";
                    p2 = this.props.rule.opParameters["message"] ?? "";
                    break;
                case eOpCode.ExecAddToAggregate:    
                    p1 = this.props.rule.opParameters["outputelement"] ?? "";
                    p2 = this.props.rule.opParameters["addtoelement"] ?? "";
                    break;
                case eOpCode.ExecSetValues: //  Add To Output
                    p1 = this.props.rule.opParameters["setvalues"];
                    p2 = this.props.rule.opParameters["outputvalue"];
                    break;
                case eOpCode.ExecFileReceive:    
                    p1 = this.props.rule.opParameters["storage"] ?? "";
                    p2 = this.props.rule.opParameters["connection"] ?? "";
                    break;
                case eOpCode.ExecCSVFileRead:    
                    p1 = this.props.rule.opParameters["storage"] ?? "";
                    p2 = this.props.rule.opParameters["header"] ?? "FALSE";
                    p3 = this.props.rule.opParameters["useheader"] ?? "FALSE";
                    p4 = this.props.rule.opParameters["connection"] ?? "";
                    break;
                case eOpCode.ExecFileDelete:    
                    p1 = this.props.rule.opParameters["storage"] ?? "";
                    p2 = this.props.rule.opParameters["connection"] ?? "";
                    break;
                case eOpCode.ExecTranslate:
                    p1 = this.props.rule.opParameters["inputformat"] ?? "";
                    p2 = this.props.rule.opParameters["inputformatname"] ?? "";
                    p3 = this.props.rule.opParameters["outputformat"] ?? "";
                    p4 = this.props.rule.opParameters["outputformatname"] ?? "";
                    p5 = this.props.rule.opParameters["translation"] ?? "";
                    break;
                case eOpCode.ExecTemplate:
                    p2 = this.props.rule.opParameters["template"] ?? "";
                    var t = this.props.templates.find(t => t.id === p2);
                    if(t!=null)
                    {
                        p1 = t.dataTemplate || "UNDEFINED";
                    }
                    break;
                case eOpCode.ExecEncryption:
                    p1 = this.props.rule.opParameters["fields"] ?? "";
                    p2 = this.props.rule.opParameters["type"] ?? "";
                    p3 = this.props.rule.opParameters["direction"] ?? "";
                    p4 = this.props.rule.opParameters["keyid"] ?? "";
                    p5 = this.props.rule.opParameters["keyname"] ?? "";
                    break;
            }


            this.setState({
              param1: p1,
              param2: p2,
              param3: p3,
              param4: p4,
              param5: p5,
              param6: p6,
              param7: p7,
              param8: p8,
              param9: p9,
              rule: this.props.rule
            });
          }
      }

      onParametersChanged(opParameters: {[key: string]: string}) {
        if(this.props.onChange == null)     return;

        console.log("onParametersChanged",opParameters);

        var r  = {...this.state.rule};
        r.opParameters = {...opParameters};
        this.setState({rule: r});
        this.props.onChange(r);
    }



// picker    
    onParam1Change(ev: any,option?: any)
    {
        var r  = {...this.state.rule};
        this.setState({param1: option.key}) ;
        this.doSave(r,option.key || "");
    }

    onParam2Change(ev: any,option?: any)
    {
        var r  = {...this.state.rule};
        this.setState({param2: option.key}) ;
        this.doSave(r,null,option.key || "");
    }

    onParam3Change(ev: any,option?: any)
    {
        var r  = {...this.state.rule};
        this.setState({param3: option.key}) ;
        this.doSave(r,null,null,option.key || "");
    }

    onTemplateChange(ev: any,option?: any)
    {
        var r  = {...this.state.rule};
        this.setState({param2: option.key});

        var t = this.props.templates.find(t => t.id === option.key);
        if(t!=null)
        {
            this.setState({param1: t.dataTemplate || "UNDEFINED"});
        }
        
        this.doSave(r,null,option.key || "");
    }


    onParam4Change(ev: any,option?: any)
    {
        var r  = {...this.state.rule};
        this.setState({param4: option.key}) ;
        this.doSave(r,null,null,null,option.key || "");
    }

    onParam5Change(ev: any,option?: any)
    {
        var r  = {...this.state.rule};
        this.setState({param5: option.key}) ;
        this.doSave(r,null,null,null,null,option.key || "");
    }

  
    onParam7Change(ev: any,option?: any){
        var r  = {...this.state.rule};
       this.setState({param7: option.key}) ;
       this.doSave(r,null,null,null,null,null,null,option.key || "")
    }

    addAuthorisorOptions(): IDropdownOption[]{
        var options: IDropdownOption[] = [];
        options.push({key: "", text: "None"}); 

        options.push(...this.props.authConnections.map(c => {   
            let rObj: IDropdownOption = { key: c.id, text: c.name}
            return rObj
        }));
                             
        return options;
    }

    getDefaultAuthorisor(): string | null | undefined {
        var result = this.state.param7;
        if(result === "") {
            var connection = this.props.connections.find(x => x.id === this.state.param5);
            if(connection) {
                var id = connection.parameters["defaultAuthConnection"]
                if(id) {
                    return id;
                }
            }
        }

        return result;
    }

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var r = {...this.state.rule};
        switch(name)
        {
            case "param1":
                this.setState({param1: value});
                this.doSave(r,value);
                return;
            case "param2":
                this.setState({param2: value}) ;
                this.doSave(r,null,value);
                return;
            case "param3":
                this.setState({param3: value}) ;
                this.doSave(r,null,null,value);
                return;
            case "param4":
                this.setState({param4: value}) ;
                this.doSave(r,null,null,null,value);
                return;
            case "param5":
                this.setState({param5: value}) ;
                this.doSave(r,null,null,null,null,value);
                return;
            case "param6":
                this.setState({param6: value}) ;
                this.doSave(r,null,null,null,null,null,value);
                return;
            case "param7":
                this.setState({param7: value}) ;
                this.doSave(r,null,null,null,null,null,null,value);
                return;
            case "param8":
                this.setState({param8: value}) ;
                this.doSave(r,null,null,null,null,null,null,null,value);
                return;
            case "param9":
                this.setState({param9: value}) ;
                this.doSave(r,null,null,null,null,null,null,null,null,value);
                return;
        }
        switch(name)
        {
            case "name":
                r.name = value;
                break;
            case "description":
                r.description = value;
                break;
            case "executionCheck":
                r.executionCheck = value;
                break;
            case "secondsDelay":
                if(value === "") {
                    r.secondsDelay = 0;
                }
                else
                {
                    if(isNaN(parseInt(value, 10)))  return;                
                    r.secondsDelay = parseInt(value, 10);
                }
        }
        this.setState({rule: r});
        this.doSave(r);
    }

    
    setOpCode(opcode: number)
    {
        var q = this.state.rule;
        q.opCode = opcode;
        this.setState({rule: q, });
        this.doSave(q);
    }

    setNoExecute(option: number)
    {
        var q = this.state.rule;
        q.noExecutionAction = option;
        this.setState({rule: q, });
        this.doSave(q);
    }


    doSave(r: IRule, p1: string | null = null, p2: string | null = null, p3: string | null = null, p4: string | null = null, p5: string | null = null, p6: string | null = null, p7: string | null = null, p8: string | null = null, p9: string | null = null     )
    {

        if(this.props.onChange == null)     return;

        console.log("doSave",r);

        var lp1 = p1 ?? this.state.param1; 
        var lp2 = p2 ?? this.state.param2; 
        var lp3 = p3 ??  this.state.param3; 
        var lp4 = p4 ?? this.state.param4; 
        var lp5 = p5 ?? this.state.param5; 
        var lp6 = p6 ?? this.state.param6; 
        var lp7 = p7 ?? this.state.param7; 

        switch(r.opCode)
        {
            case eOpCode.ExecREST: //  REST
                r.opParameters["url"] = lp1;
                r.opParameters["method"] = lp2;
                r.opParameters["body"] = lp3;
                r.opParameters["format"] = lp4;
                r.opParameters["connection"] = lp5;
                r.opParameters["outputelement"] = lp6;
                r.opParameters["authConnection"] = lp7;
                break;
            case eOpCode.ExecGenerateSequence: //  Generate Sequence
                r.opParameters["sequence"] = lp1;
                r.opParameters["format"] = lp2;
                break;
            case eOpCode.ExecBooleanExpression: //  Boolean
                r.opParameters["expression"] = lp1;
                break;
            case eOpCode.ExecSchedule: //  Schedule
                r.opParameters["schedule"] = lp1;
                r.opParameters["schedulename"] = lp2;
                break;
            case eOpCode.ExecLog: //  Log
                r.opParameters["entry"] = lp1;
                r.opParameters["connection"] = lp2;
                r.opParameters["title"] = lp3;
                break;
            case eOpCode.ExecDatabaseQuery: //  Log
                r.opParameters["query"] = lp1;
                r.opParameters["connection"] = lp2;
                r.opParameters["database"] = lp3;
                r.opParameters["type"] = lp4;
                r.opParameters["table"] = lp5;
                break;
            case eOpCode.ExecAggregate: //  Output
                r.opParameters["template"] = lp1;
                break;
            case eOpCode.ExecAggregateFail: //  Output
                r.opParameters["code"] = lp1;
                r.opParameters["message"] = lp2;
                break;
            case eOpCode.ExecAddToAggregate: //  Add To Output
                r.opParameters["outputelement"] = lp1;
                r.opParameters["addtoelement"] = lp2;
                break;
            case eOpCode.ExecSetValues: //  Add To Output
                r.opParameters["setvalues"] = lp1;
                r.opParameters["outputvalue"] = lp2;
                break;
            case eOpCode.ExecFileReceive: //  File Receieve
                r.opParameters["storage"] = lp1;
                r.opParameters["connection"] = lp2;
                break;
            case eOpCode.ExecCSVFileRead: //  File Read
                r.opParameters["storage"] = lp1;
                r.opParameters["header"] = lp2;
                r.opParameters["useheader"] = lp3;
                r.opParameters["connection"] = lp4;
                break;
            case eOpCode.ExecFileDelete: //  File Delete
                r.opParameters["storage"] = lp1;
                r.opParameters["connection"] = lp2;
                break;
            case eOpCode.ExecTranslate:
                r.opParameters["inputformat"] = lp1;
                r.opParameters["inputformatname"] = lp2;
                r.opParameters["outputformat"] = lp3;
                r.opParameters["outputformatname"] = lp4;
                r.opParameters["translation"] = lp5;
                break;
            case eOpCode.ExecTemplate: //  Output
                r.opParameters["template"] = lp2;
                break;
            case eOpCode.ExecEncryption:
                r.opParameters["fields"] = lp1;
                r.opParameters["type"] = lp2;
                r.opParameters["direction"] = lp3;
                r.opParameters["keyid"] = lp4;
                r.opParameters["keyname"] = lp5;
                break;

        } 
        this.setState({rule: r});
        this.props.onChange(r);
    }


    render() 
    {
        const onRenderOption = (option: any | undefined): JSX.Element => 
        {
            if(option == null)  return<></>;
            return (
                <Stack horizontal  tokens={{childrenGap: 5}}>
                   {option.data && option.data.icon && (
                <Image src={"/icons/operations/" + option.data.icon} width={24} height={24} style={{filter: (option.data.filter || "")}} />
                   )}
                <span>{option.text}</span>
              </Stack>
            );
        }

        if(this.state.rule == null) return;

        var paramControl = <></>;
        var extra;

        var val: Validate = new Validate(this.state.rule);

        switch(this.state.rule.opCode)
        {
            case eOpCode.ExecSequence: // Execute a sequence 
                break;
            case eOpCode.ExecREST:
                var p5 = this.state.rule.opParameters["connection"] ?? "";
                var c = this.props.connections.find( c => c.id === p5);   
                var params = c?.parameters;
                var prefix = "?";
                if(params !== undefined)
                {
                    var param = params["Base URL"];
                    if(param.length > 16)
                    {
                        param = param.substring(0,16) + "...";
                    }
                    if(param !== undefined)
                    {
                        prefix = "https://" + param;
                    }
                }
                if(c?.subtype === "graphql") 
                {
                    paramControl = <Stack tokens={{childrenGap: 5}}>
                        <Label>Connection</Label>
                        <Dropdown
                                placeholder="Select..."
                                disabled={this.props.readOnly}
                                options={this.props.connections.filter( c => c.type==="api").map(c => {   
                                    let rObj: IDropdownOption = { key: c.id, text: c.name}
                                    return rObj
                                })}
                                selectedKey={this.state.param5}
                                onChange={this.onParam5Change}
                                errorMessage={val.paramMessages["connection"]}
                            />

                    <Label>Authorizor</Label>
                    <Dropdown
                                disabled={this.props.readOnly}
                                placeholder="None"
                            options={this.addAuthorisorOptions()}
                            selectedKey={this.getDefaultAuthorisor()}
                            onChange={this.onParam7Change}
                        />                        

                    <TextField readOnly={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.outputelement' })} value={this.state.param6}  name="param6" onChange={this.handleChange}/>
                    <TextField readOnly={this.props.readOnly} multiline rows={6} label="Query" value={this.state.param3}  name="param3" onChange={this.handleChange}/>
                    <TextField readOnly={this.props.readOnly} multiline rows={6} label="Format" value={this.state.param4}  name="param4" onChange={this.handleChange}/>
                </Stack>
                }
                else
                {
                    paramControl = <Stack tokens={{childrenGap: 5}}>
                    <Stack horizontal tokens={{childrenGap: 10}}>
                        <Label>Method</Label>
                        <Dropdown
                                disabled={this.props.readOnly}
                                placeholder="Select method..."
                                options={[
                                    {key: "POST", text: "POST" },
                                    {key: "GET", text: "GET" },
                                    {key: "PUT", text: "PUT" },
                                    {key: "DELETE", text: "DELETE" },
                                    {key: "POSTURL", text: "POST IN URL" },
                                ]}
                                selectedKey={this.state.param2}
                                onChange={this.onParam2Change}
                                errorMessage={val.paramMessages["method"]}
                        />

                        <Label>Connection</Label>
                        <Dropdown
                                placeholder="Select..."
                                disabled={this.props.readOnly}
                                options={this.props.connections.filter( c => c.type==="api").map(c => {   
                                    let rObj: IDropdownOption = { key: c.id, text: c.name}
                                    return rObj
                                })}
                                selectedKey={this.state.param5}
                                onChange={this.onParam5Change}
                                errorMessage={val.paramMessages["connection"]}
                            />
                    </Stack>

                    <Label>Authorizor</Label>
                    <Dropdown
                                disabled={this.props.readOnly}
                                placeholder="None"
                            options={this.addAuthorisorOptions()}
                            selectedKey={this.getDefaultAuthorisor()}
                            onChange={this.onParam7Change}
                        />                        

                    <TextField readOnly={this.props.readOnly} prefix={prefix} label={this.props.intl.formatMessage({ id: 'rule.endpoint' })} value={this.state.param1}  name="param1" onChange={this.handleChange} errorMessage={val.paramMessages["url"]}/>
                    <TextField readOnly={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.outputelement' })} value={this.state.param6}  name="param6" onChange={this.handleChange}/>
                    <TextField readOnly={this.props.readOnly} multiline rows={6} label="Body" value={this.state.param3}  name="param3" onChange={this.handleChange}/>
                    <TextField readOnly={this.props.readOnly} multiline rows={6} label="Format" value={this.state.param4}  name="param4" onChange={this.handleChange}/>
                </Stack>

                }
                break;
            case eOpCode.ExecGenerateSequence: // Gen Sequence
                paramControl = <Stack>
                    <TextField readOnly={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.sequence' })} value={this.state.param1}  name="param1" onChange={this.handleChange}  errorMessage={val.paramMessages["sequence"]}/>
                    <TextField readOnly={this.props.readOnly} multiline rows={3} label={this.props.intl.formatMessage({ id: 'rule.format' })} value={this.state.param2}  name="param2" onChange={this.handleChange}  errorMessage={val.paramMessages["format"]}/>
                </Stack>
                break;
            case eOpCode.ExecBooleanExpression: // Boolean
                paramControl = <Stack>
                    <TextField readOnly={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.expression' })} value={this.state.param1}  name="param1" onChange={this.handleChange} errorMessage={val.paramMessages["expression"]}/>
                </Stack>
                break;
            case eOpCode.ExecSchedule: // Schedule
                paramControl = <Stack>
                    <Dropdown
                            disabled={this.props.readOnly}
                            placeholder="Select..."
                            options={this.props.schedules.map(c => {   
                                let rObj: IDropdownOption = { key: c.id, text: c.name}
                                return rObj
                             })}
                            selectedKey={this.state.param1}
                            onChange={(ev: any,option?: any)=>{
                                var r  = JSON.parse(JSON.stringify(this.state.rule));
                                var sched = this.props.schedules.find( s => s.id === option.key);
                                this.setState({param1: option.key, param2: sched?.name || ""}) ;
                                this.doSave(r,option.key || "",sched?.name || "");
                            }}
                            errorMessage={val.paramMessages["schedule"]}
                        />
                </Stack>
                break;
            case eOpCode.ExecLog:
                paramControl = <Stack>
                    <Label>Connection</Label>
                    <Dropdown
                            disabled={this.props.readOnly}
                            placeholder="Select..."
                            options={this.props.connections.filter( c => c.type==="log").map(c => {   
                                let rObj: IDropdownOption = { key: c.id, text: c.name}
                                return rObj
                             })}
                            selectedKey={this.state.param2}
                            onChange={this.onParam2Change}
                            errorMessage={val.paramMessages["connection"]}
                        />

                        <TextField readOnly={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.log.title' })} value={this.state.param3}  name="param3" onChange={this.handleChange} errorMessage={val.paramMessages["title"]}/>
                        <TextField readOnly={this.props.readOnly} multiline label={this.props.intl.formatMessage({ id: 'rule.log' })} value={this.state.param1}  name="param1" onChange={this.handleChange} errorMessage={val.paramMessages["entry"]}/>
                    </Stack>
                break;
            case eOpCode.ExecDatabaseQuery:    
                extra = <></>
                var currConnection: IConnection[] = this.props.connections.filter( c => c.id ===this.state.param2);
                var subtype = "";
                if(currConnection.length > 0)
                {
                    subtype = currConnection[0].subtype;
                }
                switch(this.state.param4) {
                    case "QUERY":
                        if(subtype==="mongo")
                        {
                            extra = <>
                            <Label>Collection</Label>
                            <Stack horizontal>
                             <Stack.Item grow>
                            <TextField readOnly={this.props.readOnly} value={this.state.param5}  name="param5" onChange={this.handleChange} errorMessage={val.paramMessages["table"]}/>

                            </Stack.Item>
                            <IconButton iconProps={{iconName: "Search"}} 
                            onClick={() => {
                                this.getTables();
                                this.setState({doGetTables: true});
                            }}/>
                            </Stack>
                            <TextField multiline readOnly={this.props.readOnly} label="Query" value={this.state.param1}  name="param1" onChange={this.handleChange} errorMessage={val.paramMessages["query"]}/>
                            </>

                        }
                        else
                        {
                            extra = <TextField multiline readOnly={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.database.query' })} value={this.state.param1}  name="param1" onChange={this.handleChange} errorMessage={val.paramMessages["query"]}/>
                        }
                        break;
                    case "NONQUERY":
                        extra = <TextField multiline readOnly={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.database.query' })} value={this.state.param1}  name="param1" onChange={this.handleChange} errorMessage={val.paramMessages["query"]}/>
                        break;
                    case "INSERT":
                        extra = <Stack>
                            <Label>{subtype==="mongo" ? "Collection" : "Table"}</Label>
                        <Stack horizontal>
                            <Stack.Item grow>
                            <TextField readOnly={this.props.readOnly} value={this.state.param5}  name="param5" onChange={this.handleChange} errorMessage={val.paramMessages["table"]}/>
                            </Stack.Item>
                            <IconButton iconProps={{iconName: "Search"}} 
                            onClick={() => {
                                this.getTables();
                                this.setState({doGetTables: true});
                            }}/>
                            </Stack>
                        </Stack>
                        
                        break;
                    case "DELETE":
                        extra = <Stack>
                            <Label>{subtype==="mongo" ? "Collection" : "Table"}</Label>
                        <Stack horizontal>
                            <Stack.Item grow>
                            <TextField readOnly={this.props.readOnly} value={this.state.param5}  name="param5" onChange={this.handleChange} errorMessage={val.paramMessages["table"]}/>
                            </Stack.Item>
                            <IconButton iconProps={{iconName: "Search"}} 
                            onClick={() => {
                                this.getTables();
                                this.setState({doGetTables: true});
                            }}/>
                            </Stack>
                            <TextField multiline readOnly={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.database.query' })} value={this.state.param1}  name="param1" onChange={this.handleChange} errorMessage={val.paramMessages["query"]}/>
                        </Stack>
                        
                        break;
                    case "UPDATE":
                        extra = <Stack>
                            <Label>{subtype==="mongo" ? "Collection" : "Table"}</Label>
                        <Stack horizontal>
                            <Stack.Item grow>
                            <TextField readOnly={this.props.readOnly} value={this.state.param5}  name="param5" onChange={this.handleChange} errorMessage={val.paramMessages["table"]}/>
                            </Stack.Item>
                            <IconButton iconProps={{iconName: "Search"}} 
                            onClick={() => {
                                this.getTables();
                                this.setState({doGetTables: true});
                            }}/>
                            </Stack>
                            <TextField readOnly={this.props.readOnly} label="Object ID Field" value={this.state.param1}  name="param1" onChange={this.handleChange} errorMessage={val.paramMessages["query"]}/>
                        </Stack>
                        
                        break;
                    }
                paramControl = <Stack>
                    <Label>Connection</Label>
                    <Dropdown
                            disabled={this.props.readOnly}
                            placeholder="Select..."
                            options={this.props.connections.filter( c => c.type==="database").map(c => {   
                                let rObj: IDropdownOption = { key: c.id, text: c.name}
                                return rObj
                             })}
                            selectedKey={this.state.param2}
                            onChange={this.onParam2Change}
                            errorMessage={val.paramMessages["connection"]}
                        />
                        <Label>Database</Label>
                        <Stack horizontal>
                            <Stack.Item grow>
                            <TextField readOnly={this.props.readOnly} value={this.state.param3}  name="param3" onChange={this.handleChange} errorMessage={val.paramMessages["database"]}/>
                            </Stack.Item>
                            <IconButton iconProps={{iconName: "Search"}} 
                            onClick={() => {
                                this.getDatabases();
                                this.setState({doGetDatabases: true});
                            }}/>                        
                        </Stack>
                        <Label>Command Type</Label>
                        <Dropdown
                            disabled={this.props.readOnly}
                            placeholder="Select operation..."
                                options={(subtype==="mongo")
                                ?
                                [
                                    {key: "QUERY", text:  "Query"  },
                                    {key: "INSERT", text: "Insert document" },
                                    {key: "DELETE", text: "Delete document" },
                                    {key: "UPDATE", text: "Update document" },
                                ]
                                :
                                [
                                    {key: "QUERY", text: "SQL Query" },
                                    {key: "INSERT", text: "Insert record" },
                                    {key: "NONQUERY", text: "Execute Command" },
                                ]}
                                selectedKey={this.state.param4}
                                onChange={this.onParam4Change}
                                errorMessage={val.paramMessages["type"]}
                                />
                        {extra}
                    </Stack>
                break;
            case eOpCode.ExecTranslate:    
                paramControl = <Stack>
                        <Label>Input Format</Label>
                        <Stack horizontal>
                            <Stack.Item grow>
                            <TextField readOnly value={this.state.param2}  name="param2"  errorMessage={val.paramMessages["database"]}/>
                            </Stack.Item>
                            <IconButton iconProps={{iconName: "Search"}} 
                            onClick={() => {
                                this.setState({onGetDDL: true, doGetInputFormat: true});
                            }}/>                        
                        </Stack>
                        <Label>Output Format</Label>
                        <Stack horizontal>
                            <Stack.Item grow>
                            <TextField readOnly value={this.state.param4}  name="param4"  errorMessage={val.paramMessages["database"]}/>
                            </Stack.Item>
                            <IconButton iconProps={{iconName: "Search"}} 
                            onClick={() => {
                                this.setState({onGetDDL: true, doGetOutputFormat: true, doGetInputFormat: false});
                            }}/>                        
                        </Stack>
                        <Stack horizontal style={{paddingTop: 10}} tokens={{ childrenGap: 10 }}>
                        <DefaultButton iconProps={{iconName: "Edit"}} disabled={this.state.param1 === "" || this.state.param3 === ""} onClick={() => {
                            this.setState({showTranslationEditor: true})
                        }}>Edit Translation</DefaultButton>
                        {/* <DefaultButton iconProps={{iconName: "Edit"}} disabled={this.state.param1 === "" || this.state.param3 === ""} onClick={() => {
                            this.setState({showTranslationEditor: true})
                        }}>Reset Translation</DefaultButton> */}
                        </Stack>
                    </Stack>
                break;
            case eOpCode.ExecTemplate: // Add to Aggregare
                paramControl = <Stack>
                    <Label>Template</Label>
                    <Dropdown
                            disabled={this.props.readOnly}
                            placeholder="Select..."
                            options={this.props.templates.map(c => {   
                                let rObj: IDropdownOption = { key: c.id, text: c.name}
                                return rObj
                             })}
                            selectedKey={this.state.param2}
                            onChange={this.onTemplateChange}
                            errorMessage={val.paramMessages["template"]}
                        />
                    <TextField style={{backgroundColor: "#ccc"}} readOnly label={this.props.intl.formatMessage({ id: 'rule.model' })} multiline rows={6} value={this.state.param1}  name="param1" onChange={this.handleChange} errorMessage={val.paramMessages["model"]}/>
                            </Stack>
                break;
            case eOpCode.ExecAggregate: // Add to Aggregare
                paramControl = <Stack>
                    <TextField readOnly={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.template' })} multiline rows={6} value={this.state.param1}  name="param1" onChange={this.handleChange} errorMessage={val.paramMessages["template"]}/>
                    <ActionButton iconProps={{ iconName: 'Edit' }} onClick={()=>{this.setState({onGetDDL: true, doSetTemplate: true})}} >Load from DDL</ActionButton>
                            </Stack>
                break;
            case eOpCode.ExecAggregateFail: // Add to Aggregare
                paramControl = <Stack>
                    <TextField readOnly={this.props.readOnly} label="Code" value={this.state.param1}  name="param1" onChange={this.handleChange} />
                    <TextField readOnly={this.props.readOnly} label="Message" value={this.state.param2}  name="param2" onChange={this.handleChange} />
                    
                            </Stack>
                break;
            case eOpCode.ExecAddToAggregate: // Add to Aggregare
                paramControl = <Stack>
                    <TextField readOnly={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.outputelement' })} multiline rows={6} value={this.state.param1}  name="param1" onChange={this.handleChange}  errorMessage={val.paramMessages["outputelement"]}/>
                </Stack>
                break;
            case eOpCode.ExecSetValues: // Add to Aggregare
                paramControl = <Stack>
                    <TextField readOnly={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.outputelement' })} multiline rows={6} value={this.state.param1}  name="param1" onChange={this.handleChange}  errorMessage={val.paramMessages["setvalues"]}/>
                </Stack>
                break;
            case eOpCode.ExecFileReceive: // File Receive
            extra = <></>;
                if(this.state.param1 === "CLOUD") {
                    extra = <>
                    <Label>Connection</Label>
                    <Dropdown
                            placeholder="Select..."
                            disabled={this.props.readOnly}
                        
                            options={this.props.connections.filter( c => c.type==="cloud").map(c => {   
                                let rObj: IDropdownOption = { key: c.id, text: c.name}
                                return rObj
                            })}
                            selectedKey={this.state.param2}
                            onChange={this.onParam2Change}
                            errorMessage={val.paramMessages["connection"]}
                        />
                        </>
                    }
                    paramControl = <Stack>
                        <Label>Storage</Label>
                        <Dropdown
                            disabled={this.props.readOnly}
                            placeholder="Select file store..."
                                options={[
                                    {key: "MEMORY", text: "Memory" },
                                    {key: "FILESYSTEM", text: "Local File System" },
                                    {key: "CLOUD", text: "Cloud Storage" },
                                ]}
                                selectedKey={this.state.param1}
                                onChange={this.onParam1Change}
                                errorMessage={val.paramMessages["storage"]}
                        />
                        {extra}
                </Stack>
                break;
            case eOpCode.ExecCSVFileRead: // CSV File Read
                extra = <></>;
                if(this.state.param1 === "CLOUD") {
                    extra = <>
                    <Label>Connection</Label>
                    <Dropdown
                            placeholder="Select..."
                            disabled={this.props.readOnly}
                        
                            options={this.props.connections.filter( c => c.type==="cloud").map(c => {   
                                let rObj: IDropdownOption = { key: c.id, text: c.name}
                                return rObj
                            })}
                            selectedKey={this.state.param4}
                            onChange={this.onParam4Change}
                            errorMessage={val.paramMessages["connection"]}
                        />
                        </>
                    }
                    paramControl = <Stack>
                        <Label>Read From</Label>
                        <Dropdown
                            disabled={this.props.readOnly}
                            placeholder="Select file source..."
                                options={[
                                    {key: "MEMORY", text: "Memory" },
                                    {key: "FILESYSTEM", text: "File System" },
                                    {key: "CLOUD", text: "Cloud Storage" },
                                ]}
                                selectedKey={this.state.param1}
                                onChange={this.onParam1Change}
                                errorMessage={val.paramMessages["storage"]}
                        />
                        {extra}
                        <Toggle disabled={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.csv.header' })} checked={(this.state.param2 ?? "FALSE") === "TRUE"}  onChange={()=>this.onToggleP2("header")}  />
                        <Toggle label={this.props.intl.formatMessage({ id: 'rule.csv.useheader' })} disabled={this.state.param2 === "FALSE" || this.props.readOnly} checked={(this.state.param3 ?? "FALSE") === "TRUE"}  onChange={()=>this.onToggleP3("useheader")}  />
                </Stack>
                break;
            case eOpCode.ExecFileDelete: // CSV File Read
                if(this.state.param1 === "CLOUD") {
                    extra = <>
                    <Label>Connection</Label>
                    <Dropdown
                            placeholder="Select..."
                            disabled={this.props.readOnly}
                        
                            options={this.props.connections.filter( c => c.type==="cloud").map(c => {   
                                let rObj: IDropdownOption = { key: c.id, text: c.name}
                                return rObj
                            })}
                            selectedKey={this.state.param2}
                            onChange={this.onParam2Change}
                            errorMessage={val.paramMessages["connection"]}
                        />
                        </>
                    }
                    paramControl = <Stack>
                        <Label>Read From</Label>
                        <Dropdown
                            disabled={this.props.readOnly}
                            placeholder="Select file source..."
                                options={[
                                    {key: "MEMORY", text: "Memory" },
                                    {key: "FILESYSTEM", text: "File System" },
                                    {key: "CLOUD", text: "Cloud Storage" },
                                ]}
                                selectedKey={this.state.param1}
                                onChange={this.onParam1Change}
                                errorMessage={val.paramMessages["storage"]}
                        />
                        {extra}
                </Stack>
                break;
            case eOpCode.ExecEncryption: 
                paramControl = <EncryptionProperties opParameters={this.state.rule.opParameters} onChange={this.onParametersChanged} readOnly={this.props.readOnly} client={this.props.client} connections={this.props.connections} authConnections={this.props.authConnections} />
            break;
            case eOpCode.ExecExternalCommand: 
                paramControl = <ExternalExecProperties opParameters={this.state.rule.opParameters} onChange={this.onParametersChanged} readOnly={this.props.readOnly} client={this.props.client} connections={this.props.connections} authConnections={this.props.authConnections} />
                break;

        }

        const ruleOperations: IDropdownOption[] = [
            { key: 'Header', text: 'Basic Commands', itemType: DropdownMenuItemType.Header },
            { key: eOpCode.NOP, text: 'NOP (No Operation)', data: { icon: 'nop.svg', filter: "invert(83%) sepia(16%) saturate(5411%) hue-rotate(334deg) brightness(102%) contrast(102%)" } },
            { key: eOpCode.ExecSequence, text: 'Execute Sequence', data: { icon: 'sequence.svg', filter: "invert(83%) sepia(16%) saturate(5411%) hue-rotate(334deg) brightness(102%) contrast(102%)" } },
            { key: eOpCode.ExecGenerateSequence, text: 'Generate a sequence', data: { icon: 'gensequence.svg', filter: "invert(83%) sepia(16%) saturate(5411%) hue-rotate(334deg) brightness(102%) contrast(102%)" } },
            { key: eOpCode.ExecBooleanExpression, text: 'Boolean Expression', data: { icon: 'boolean.svg', filter: "invert(83%) sepia(16%) saturate(5411%) hue-rotate(334deg) brightness(102%) contrast(102%)" } },
            { key: eOpCode.ExecSchedule, text: 'Execute Schedule', data: { icon: 'schedule2.svg', filter: "invert(83%) sepia(16%) saturate(5411%) hue-rotate(334deg) brightness(102%) contrast(102%)" } },
            { key: eOpCode.ExecSetValues, text: 'Set Values', data: { icon: 'setvalues.svg', filter: "invert(83%) sepia(16%) saturate(5411%) hue-rotate(334deg) brightness(102%) contrast(102%)" } },
            { key: eOpCode.ExecTranslate, text: 'Execute Translation', data: { icon: 'translate.svg', filter: "invert(83%) sepia(16%) saturate(5411%) hue-rotate(334deg) brightness(102%) contrast(102%)" } },
            { key: eOpCode.ExecEncryption, text: 'Execute Encryption', data: { icon: 'encryption.svg', filter: "invert(83%) sepia(16%) saturate(5411%) hue-rotate(334deg) brightness(102%) contrast(102%)" } },
            { key: 'divider_5', text: '-', itemType: DropdownMenuItemType.Divider },
            { key: 'Header5', text: 'External Services', itemType: DropdownMenuItemType.Header },
            { key: eOpCode.ExecREST, text: 'Web Service', data: { icon: 'rest.svg', filter: "invert(11%) sepia(100%) saturate(5505%) hue-rotate(296deg) brightness(98%) contrast(109%)" } },
            { key: eOpCode.ExecDatabaseQuery, text: 'Database Query', data: { icon: 'database.svg', filter: "invert(11%) sepia(100%) saturate(5505%) hue-rotate(296deg) brightness(98%) contrast(109%)" } },
            { key: eOpCode.ExecLog, text: 'Write Log Entry', data: { icon: 'log.svg', filter: "invert(11%) sepia(100%) saturate(5505%) hue-rotate(296deg) brightness(98%) contrast(109%)" } },
            { key: eOpCode.ExecExternalCommand, text: 'Execute External', data: { icon: 'externalcommand.svg', filter: "invert(11%) sepia(100%) saturate(5505%) hue-rotate(296deg) brightness(98%) contrast(109%)" } },
            // { key: 'divider_2', text: '-', itemType: DropdownMenuItemType.Divider },
            // { key: 'Header2', text: 'FTP Commands', itemType: DropdownMenuItemType.Header },
            // { key: eOpCode.ExecFTPSearch, text: 'FTP Query', data: { icon: 'ftpquery.svg' } },
            // { key: eOpCode.ExecFTPUpload, text: 'FTP Upload', data: { icon: 'ftpupload.svg' } },
            // { key: eOpCode.ExecFTPDownload, text: 'FTP Download', data: { icon: 'ftpdownload.svg' } },
            { key: 'divider_3', text: '-', itemType: DropdownMenuItemType.Divider },
            { key: 'Header3', text: 'Output Commands', itemType: DropdownMenuItemType.Header },
            { key: eOpCode.ExecAggregate, text: 'Output', data: { icon: 'aggregate.svg', filter: "invert(50%) sepia(67%) saturate(3569%) hue-rotate(352deg) brightness(98%) contrast(94%)"  } },
            { key: eOpCode.ExecTemplate, text: 'Execute Template', data: { icon: 'translate.svg', filter: "invert(50%) sepia(67%) saturate(3569%) hue-rotate(352deg) brightness(98%) contrast(94%)" } },
            { key: eOpCode.ExecAddToAggregate, text: 'Add To Output', data: { icon: 'addtoaggregate.svg', filter: "invert(50%) sepia(67%) saturate(3569%) hue-rotate(352deg) brightness(98%) contrast(94%)" } },
            { key: eOpCode.ExecAggregateFail, text: 'Fail Output', data: { icon: 'aggregate.svg', filter: "invert(50%) sepia(67%) saturate(3569%) hue-rotate(352deg) brightness(98%) contrast(94%)"  } },
            { key: 'divider_4', text: '-', itemType: DropdownMenuItemType.Divider },
            { key: 'Header4', text: 'File Commands', itemType: DropdownMenuItemType.Header },
            { key: eOpCode.ExecDirectorySearch, text: 'Directory Search', data: { icon: 'directoryread.svg', filter: "invert(80%) sepia(49%) saturate(524%) hue-rotate(127deg) brightness(94%) contrast(87%)" } },
            { key: eOpCode.ExecFileReceive, text: 'Receive File', data: { icon: 'filereceive.svg', filter: "invert(80%) sepia(49%) saturate(524%) hue-rotate(127deg) brightness(94%) contrast(87%)" } },
            { key: eOpCode.ExecFileDelete, text: 'Delete File', data: { icon: 'filedelete.svg', filter: "invert(80%) sepia(49%) saturate(524%) hue-rotate(127deg) brightness(94%) contrast(87%)" } },
            { key: eOpCode.ExecCSVFileRead, text: 'CSV File Read', data: { icon: 'fileread.svg', filter: "invert(80%) sepia(49%) saturate(524%) hue-rotate(127deg) brightness(94%) contrast(87%)" } },
        ];

        const ruleNoExecute: IDropdownOption[] = [
            { key: eNoExecutionAction.GoLeft, text: 'Continue on Left/Center', data: {} },
            { key: eNoExecutionAction.GoRight, text: 'Continue on Right (if available)', data: {} },
            { key: eNoExecutionAction.EndStream, text: 'End this stream', data: {} }
        ]
        

        return (
            <Stack grow verticalFill style={{padding: 10, borderLeft: "1px solid grey"}}>
                <TextField readOnly={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.name' })} value={this.state.rule.name}  name="name" onChange={this.handleChange}/>
                <TextField readOnly={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.description' })} value={this.state.rule.description}  name="description" onChange={this.handleChange}/>
                <Dropdown
                    placeholder="Select a command"
                    disabled={this.props.readOnly}
                    label="Command"
                    ariaLabel="Command"
                    options={ruleOperations}
                    onRenderOption={onRenderOption}
                    selectedKey={this.state.rule.opCode}
                    onChange={(ev: any,option?: any) => {this.setOpCode(option.key as eOpCode)}}
                />
                <Stack horizontal tokens={{ childrenGap: 5}} style={{paddingTop: 10}}>
                    <Label>Validate Input</Label>
                    <TextField readOnly value={this.state.rule.validateInputDDLName} /> 
                    <IconButton iconProps={{iconName: "Search"}} 
                        onClick={() => {
                            console.log("Get DDL")
                            this.setState({onGetDDL: true});
                        }}/>
                    <IconButton iconProps={{iconName: "EraseTool"}} 
                        onClick={() => {
                            var rule: IRule = JSON.parse(JSON.stringify(this.state.rule));
                            rule.validateInputDDLID = "";
                            rule.validateInputDDLName = "";
                            this.setState({ rule: rule, onGetDDL: false });
                            this.doSave(rule);
                        }}/>
                </Stack>
                <Pivot>
                    <PivotItem headerText="Parameters">
                        {paramControl}
                    </PivotItem>
                    <PivotItem headerText="Advanced">
                        <Toggle inlineLabel disabled={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.execution.check' })} checked={this.state.rule.checkForExecution}  onChange={()=>{
                                    var r: IRule  = {...this.state.rule};
                                    r.checkForExecution  = !r.checkForExecution;
                                    this.setState({rule: r }) ;
                                    this.doSave(r);                            
                        }}  />
                        <TextField readOnly={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.execution.expresion' })} disabled={!this.state.rule.checkForExecution} value={this.state.rule.executionCheck}  name="executionCheck" onChange={this.handleChange}/>
                        <Dropdown
                            placeholder="Select an option"
                            disabled={this.props.readOnly || !this.state.rule.checkForExecution}
                            label={this.props.intl.formatMessage({ id: 'rule.execution.onfail' })}
                            ariaLabel="Command"
                            options={ruleNoExecute}
                            selectedKey={this.state.rule.noExecutionAction}
                            onChange={(ev: any,option?: any) => {this.setNoExecute(option.key as eNoExecutionAction)}}
                        />
                        <Separator />
                        <Toggle inlineLabel disabled={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.delay' })} checked={this.state.rule.executionType === eExecutionType.Delay}  onChange={()=>this.onToggleDelay()}  />
                        <TextField readOnly={this.props.readOnly} label={this.props.intl.formatMessage({ id: 'rule.delay.seconds' })} disabled={this.state.rule.executionType !== eExecutionType.Delay} value={(this.state.rule.secondsDelay ?? 0).toString()}  name="secondsDelay" onChange={this.handleChange}/>
                    </PivotItem>
                    <PivotItem headerText="Help">
                        <RuleHelp rule={this.state.rule.opCode} />
                    </PivotItem>

                </Pivot>
                <DDLFinder 
            // @ts-ignore
                isOpen={this.state.onGetDDL}
                client={this.props.client}
                onDismiss={()=>{this.setState({onGetDDL: false, doSetTemplate: false, doGetInputFormat: false, doGetOutputFormat: false})}}
                onDDLSelected={(ddl: IDataDefinition)=>{
                    var r: IRule;
                    if(this.state.doSetTemplate)
                    {
                        this.getTemplate(ddl.id);
                    }
                    else if(this.state.doGetInputFormat)
                    {
                        r  = JSON.parse(JSON.stringify(this.state.rule));
                        this.setState({param1: ddl.id, param2: ddl.name, onGetDDL: false});
                        this.doSave(r,ddl.id,ddl.name);                        
                    }
                    else if(this.state.doGetOutputFormat)
                    {
                        r  = JSON.parse(JSON.stringify(this.state.rule));
                        this.setState({param3: ddl.id, param4: ddl.name, onGetDDL: false});
                        this.doSave(r,null,null,ddl.id,ddl.name);                        
                    }
                    else
                    {
                        var rule: IRule = JSON.parse(JSON.stringify(this.state.rule));
                        rule.validateInputDDLID = ddl.id;
                        rule.validateInputDDLName = ddl.name;
                        this.setState({ onGetDDL: false });
                        this.doSave(rule);

                    }
                }}
            /> 

            <KeyFinder 
            // @ts-ignore
                isOpen={this.state.onGetKey}
                client={this.props.client}
                onDismiss={()=>{this.setState({onGetDDL: false, doSetTemplate: false, doGetInputFormat: false, doGetOutputFormat: false})}}
                onKeySelected={(key: IKey)=>{
                        var r  = JSON.parse(JSON.stringify(this.state.rule));
                        this.setState({param4: key.id, param5: key.name, onGetKey: false});
                        this.doSave(r,null,null,null,key.id,key.name);                        
                }}
            /> 


            <Dialog hidden={!this.state.doGetDatabases} onDismiss={()=>{this.setState({doGetDatabases: false})}}>
                <Label>Select a database</Label>
                <List
                    items={this.state.databases}
                    onRenderCell={(item?: string, index?: number, isScrolling?: boolean): JSX.Element => {
                        return (
                          <div data-is-focusable onClick={()=>{
                            var r  = JSON.parse(JSON.stringify(this.state.rule));
                            this.doSave(r,null,null,item);
                            this.setState({doGetDatabases: false, param3: item ?? ""});
                          }}>
                            <div >
                              {item}
                            </div>
                          </div>
                        );
                      }
                    }
                    style={{ width: '100%', border: '1px solid #eaeaea' }}
                />
                <DialogFooter>
                    <DefaultButton onClick={()=>{this.setState({doGetDatabases: false})}} text="Cancel" />
                </DialogFooter>
            </Dialog>
            <Dialog hidden={!this.state.doGetTables} onDismiss={()=>{this.setState({doGetTables: false})}}>
                <Label>Select a table</Label>
                <List
                    items={this.state.tables}
                    onRenderCell={(item?: string, index?: number, isScrolling?: boolean): JSX.Element => {
                        return (
                          <div data-is-focusable onClick={()=>{
                            var r  = JSON.parse(JSON.stringify(this.state.rule));
                            this.doSave(r,null,null,null,null,item);
                            this.setState({doGetTables: false, param5: item ?? ""});
                          }}>
                            <div >
                              {item}
                            </div>
                          </div>
                        );
                      }
                    }
                    style={{ width: '100%', border: '1px solid #eaeaea' }}
                />
                <DialogFooter>
                    <DefaultButton onClick={()=>{this.setState({doGetTables: false})}} text="Cancel" />
                </DialogFooter>
            </Dialog>
            <TranslationEditor 
                translation={this.state.param5}
                inputDDL={this.state.param1}
                outputDDL={this.state.param3}
                isOpen={this.state.showTranslationEditor}
                onDismiss={()=>{this.setState({showTranslationEditor: false})}}
                onTranslationCreated={(id:string) => {
                    var r  = JSON.parse(JSON.stringify(this.state.rule));
                    this.setState({param5: id }) ;
                    this.doSave(r,null,null,null,null,id);
                }}
             />
            </Stack>
        );
    }
}

export default injectIntl(RuleProperties);