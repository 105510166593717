import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  IConnection, IPagination, IAuthConnection } from './IDeus';


import { Delete, Get, Post, Put } from './authorisation/AMAPI';


import {  
    Stack, 
    IconButton, PrimaryButton, 
    DetailsList, IColumn, SelectionMode, 
    DialogType, Image, Dialog, DialogFooter, DefaultButton, Panel, PanelType,
} from '@fluentui/react';

import ConnectionProperties from './ConnectionProperties' 


type IState = {
    connections: IConnection[],
    selectedConnection: IConnection,

    authConnections: IAuthConnection[],

    pagination: IPagination,
    showDialog: boolean,

    doDelete: boolean,
    actionID: string,

    propsPanelOpen: boolean,
    propsPanelTitle: string,
}
  
  
interface IProps extends WrappedComponentProps {
  client: string
  folder: string
}


class Connections extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            connections: [],
            selectedConnection: {} as IConnection,

            pagination: {
                totalCount: 0,
                current: 0,
                pageSize: 10,
                sortField: "",
                sortOrder: "asc"
            },

            showDialog: false,

            authConnections: [],

            doDelete: false,
            actionID: "",

            propsPanelOpen: false,
            propsPanelTitle: "",

        };

        this.handleChange = this.handleChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.createConnection = this.createConnection.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onSave = this.onSave.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
        this.loadAuthConnections = this.loadAuthConnections.bind(this);
    }

    componentDidUpdate(prevProps: IProps) {
      if (prevProps.client !== this.props.client || prevProps.folder !== this.props.folder) {
        this.authenticatedrefresh();
          }
        }

// picker    

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var r = JSON.parse(JSON.stringify(this.state.selectedConnection));
        switch(name)
        {
            case "name":
                r.name = value;
                break;
            case "description":
                r.description = value;
                break;
            case "opParameter":
                r.opParameter = value;
                break;
        }
        this.setState({selectedConnection: r});
    }

    componentDidMount()
    {
      this.refresh();
    }

    createConnection()
    {
        this.setState({
            selectedConnection: {
                id: "",
                clientID: this.props.client,
                folderID: this.props.folder,
                name: "New Connection",
                description: "",

                type: "",
                subtype: "",

                parameters: {},

                
            },
            propsPanelTitle: "Create Connection",
            propsPanelOpen: true
        });
    }
  
    refresh()
    {
        this.authenticatedrefresh();
    }


    onPageChange(page: number)
    {
      var p: IPagination = Object.assign(this.state.pagination);
      p.current = page;
      this.setState({pagination: p});
      this.authenticatedrefresh();
    }


    private authenticatedrefresh()
    {
      const params = this.state.pagination;

      const paging = {
        current: params.current + 1,
        pagesize: params.pageSize,
        sortfield: params.sortField,
        sortorder: params.sortOrder,
      }

      const post = {
        paging: paging,
        currentClient: this.props.client,
        currentFolder: this.props.folder,
      }

      Post("connection/Search",post)
        .then(response => {
              var p = this.state.pagination;
              p.totalCount = response.data.totalCount;   
              this.setState({ 
                connections: response.data.results, 
                pagination: p, 
              });
        })
        .catch(function (error) {
          console.log(error);
        });

        this.loadAuthConnections();
    }

    onSave(conn: IConnection)
    {
        console.log("Connections:onSave");
        if(conn.id === "")
        {
            Post("connection",conn)
            .then(response => {
                conn.id = response.data;
                this.setState({ 
                  selectedConnection: conn, 
                  propsPanelOpen: false,
                });
                this.authenticatedrefresh();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        else
        {
            Put("connection/" + conn.id,conn)
            .then(response => {
              this.setState({ 
                propsPanelOpen: false,
              });
              this.authenticatedrefresh();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        this.authenticatedrefresh();
    }

    loadAuthConnections(){

      Get("connection/GetAuthConnections", {schema: "Bearer"})
      .then(response => {
          this.setState({authConnections: response.data})
      });

  }

    private _onItemInvoked = (item: IConnection): void => {
        this.setState({selectedConnection: item, propsPanelTitle: "Edit Connection", propsPanelOpen: true});
      };

      public onDelete()
    {
      Delete("connection/" + this.state.actionID)
      .then(response => {
        this.setState({doDelete: false});
        this.authenticatedrefresh();
      })
      .catch(function (error) {
        console.log(error);
      });
    }


    render() 
    {

        var props = <></>;

        if(this.state.selectedConnection)
        {
            props = <ConnectionProperties client={this.props.client} connection={this.state.selectedConnection} authConnections={this.state.authConnections} onChange={this.onSave}  />
        }


        const columns: IColumn[] = [
            {
              key: 'ruletype',
              name: '',
              ariaLabel: '',
              isRowHeader: true,
              isResizable: true,
              minWidth: 50,
              maxWidth: 50,
              data: 'string',
              onRender: (item: IConnection) => {
                var i;
                switch(item.subtype)
                {
                    case "graylog":
                      i = <Image src={"/icons/operations/graylog.svg"} width={24} height={24} className="list-icon" />
                      break;
                    case "mongo":
                      i = <Image src={"/icons/operations/mongo.svg"} width={24} height={24} className="list-icon" />
                      break;
                    case "mysql":
                      i = <Image src={"/icons/operations/mysql.svg"} width={24} height={24} className="list-icon" />
                      break;
                    case "sqlserver":
                      i = <Image src={"/icons/operations/sqlserver.svg"} width={24} height={24} className="list-icon" />
                      break;
                    case "rest":
                    case "server":
                    case "graphql":
                      i = <Image src={"/icons/operations/rest.svg"} width={24} height={24} className="list-icon" />
                      break;
                    case "s3":
                      i = <Image src={"/icons/operations/s3.svg"} width={24} height={24} className="list-icon" />
                      break;
                }
                return <>{i}</>
              },
            },
            {
              key: 'name',
              name: this.props.intl.formatMessage({ id: 'connection.name' }),
              fieldName: 'name',
              minWidth: 0,
              maxWidth: 200,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              isSortedDescending: false,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'desc',
              name: this.props.intl.formatMessage({ id: 'connection.description' }),
              fieldName: 'description',
              minWidth: 300,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              isSortedDescending: false,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'subtype',
              name: this.props.intl.formatMessage({ id: 'connection.type' }),
              fieldName: 'subtype',
              minWidth: 120,
              maxWidth: 240,
              currentWidth: 120,
              isRowHeader: true,
              isResizable: true,
              isSortedDescending: false,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'type',
              name: 'type',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               // return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.deleteClient(item.id)} title="Delete" ariaLabel="Delete" disabled={!item.canDelete} />
               return <IconButton iconProps={{ iconName: 'Edit' }} onClick={()=>{this.setState({selectedConnection: item, propsPanelTitle: "Edit Connection", propsPanelOpen: true})}}    />
              },
            },
            {
              key: 'delete',
              name: 'Delete',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               // return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.deleteClient(item.id)} title="Delete" ariaLabel="Delete" disabled={!item.canDelete} />
               return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.setState({doDelete: true, actionID: item.id})} title="Delete" ariaLabel="Delete"  />
              },
            },
  
        ];
        return (
            <Stack horizontal grow verticalFill>
                <Stack grow={2} >
                  <Stack horizontal style={{padding: 5}}>
                  <PrimaryButton text="Create new connection" iconProps={{ iconName: 'PageAdd' }} onClick={()=>this.createConnection()} width={300} disabled={!this.props.client}/>
                  </Stack>
                    <DetailsList  items={this.state.connections} 
                                columns={columns} 
                                onItemInvoked={this._onItemInvoked}
                                selectionMode={SelectionMode.none}
                    />


                </Stack>
                <Panel
                  headerText={this.state.propsPanelTitle}
                  type={PanelType.medium}
                  isOpen={this.state.propsPanelOpen}
                  onDismiss={()=>{ this.setState({propsPanelOpen: false})}}
                >
                  {props}
                </Panel>
                <Dialog
              hidden={!this.state.doDelete}
              onDismiss={()=>{this.setState({doDelete: false})}}
              dialogContentProps={{
                  type: DialogType.normal,
                  title: 'Delete Connection',
                  closeButtonAriaLabel: 'Close',
                  subText: 'Are you sure you want to delete this connection. This action cannot be undone. ',
                }}
            >
            <DialogFooter>
              <PrimaryButton onClick={()=>{this.onDelete()}} text="Delete" />
              <DefaultButton onClick={()=>{this.setState({doDelete: false})}} text="Cancel" />
            </DialogFooter>
            </Dialog>            </Stack>
        );
    }
}

export default injectIntl(Connections);