import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  IKey, IPagination,   } from './IDeus';

import {  Post, Put, Delete } from './authorisation/AMAPI';

import {  
    Stack, 
    IconButton, PrimaryButton, DefaultButton, 
    DetailsList, IColumn, SelectionMode, 
    Dialog, DialogFooter, 
    DialogType, IDetailsListProps, IDetailsRowStyles, DetailsRow, Panel, PanelType, 
} from '@fluentui/react';

import KeyProperties from './KeyProperties' 


type IState = {
    keys: IKey[],
    selectedKey: IKey,

    pagination: IPagination,
    showDialog: boolean,
    doDelete: boolean,
    showCalled: boolean,

    actionID: string,

    propsPanelOpen: boolean,
    propsPanelTitle: string,

}
  
  
interface IProps extends WrappedComponentProps {
  client: string
  folder: string
  history?: any
}


class Keys extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            keys: [],
            selectedKey: {} as IKey,

            pagination: {
                totalCount: 0,
                current: 0,
                pageSize: 20,
                sortField: "",
                sortOrder: "asc"
            },

            showDialog: false,
            doDelete: false,

            showCalled: false,

            actionID: "",

            propsPanelOpen: false,
            propsPanelTitle: "",

        };

        this.handleChange = this.handleChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.createKey = this.createKey.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSave = this.onSave.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
    }

    componentDidUpdate(prevProps: IProps) {
      if (prevProps.client !== this.props.client || prevProps.folder !== this.props.folder) {
        this.authenticatedrefresh();
      }
    }



  handleChange(event: any){
      const target = event.target;
      const value : string = target.type === 'checkbox' ? target.checked : target.value;
      const name : string = target.name;

      var r = JSON.parse(JSON.stringify(this.state.selectedKey));
      switch(name)
      {
          case "name":
              r.name = value;
              break;
          case "description":
              r.description = value;
              break;
          case "opParameter":
              r.opParameter = value;
              break;
      }
      this.setState({selectedKey: r});
    }

    componentDidMount()
    {
      this.refresh();
    }

    createKey()
    {
        this.setState({
            selectedKey: {
                id: "",
                clientID: this.props.client,
                name: "New Key",
                description: "",
                folderID: this.props.folder,
                type: "SYMETRIC"
            },

            propsPanelTitle: "Create Key",
            propsPanelOpen: true

        });
    }
  
    refresh()
    {
        this.authenticatedrefresh();
    }


   
    onPageChange(page: number)
    {
      var p: IPagination = Object.assign(this.state.pagination);
      p.current = page;
      this.setState({pagination: p});
      this.authenticatedrefresh();
    }


    private _onColumnClick = (ev: any, column: IColumn): void => {
    };      

   
    private authenticatedrefresh()
    {
      const params = this.state.pagination;

      const paging = {
        current: params.current + 1,
        pagesize: params.pageSize,
        sortfield: params.sortField,
        sortorder: params.sortOrder,
      }

      const post = {
        paging: paging,
        currentFolder: this.props.folder,
        currentClient: this.props.client
      }

      Post("key/Search",post)
        .then(response => {
              var p = this.state.pagination;
              p.totalCount = response.data.totalCount;   
              this.setState({ 
                keys: response.data.results, 
                pagination: p, 
              });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    onSave(sched: IKey)
    {
        if(sched.id === "")
        {
            Post("key",sched)
            .then(response => {
                sched.id = response.data;
                this.setState({ 
                  selectedKey: sched, 
                  propsPanelOpen: false,
                });
                this.authenticatedrefresh();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        else
        {
            Put("key/" + sched.id,sched)
            .then(response => {
              this.setState({ 
                propsPanelOpen: false,
              });
              this.authenticatedrefresh();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
    }


    private _onItemInvoked = (item: IKey): void => {
        this.setState({selectedKey: item, propsPanelTitle: "Edit Key", propsPanelOpen: true});
    };

    public onDelete()
      {
        Delete("key/" + this.state.actionID)
        .then(response => {
          this.setState({doDelete: false});
          this.authenticatedrefresh();
        })
        .catch(function (error) {
          console.log(error);
        });
    }


   

   
    

    render() 
    {

        var props = <></>;

        if(this.state.selectedKey)
        {
            props = <KeyProperties client={this.props.client} encryptionKey={this.state.selectedKey} onChange={this.onSave} onMoveFolder={this.onSave}  />
        }


        const columns: IColumn[] = [
          {
              key: 'name',
              name: this.props.intl.formatMessage({ id: 'schedule.name' }),
              fieldName: 'name',
              minWidth: 0,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'desc',
              name: this.props.intl.formatMessage({ id: 'connection.description' }),
              fieldName: 'description',
              minWidth: 300,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              isSortedDescending: false,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'type',
              name: "Type",
              fieldName: 'type',
              minWidth: 0,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'rulesetedit',
              name: 'RuleSet',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               return <IconButton iconProps={{ iconName: 'Edit' }} onClick={()=>this.setState({selectedKey: item})} title="Rules" ariaLabel="Rules"  />
              },
            },
            {
              key: 'delete',
              name: 'Delete',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.setState({doDelete: true, actionID: item.id})} title="Delete" ariaLabel="Delete"  />
              },
            },
  
        ];

        return (
          <>
            <Stack horizontal grow verticalFill>
              <Stack style={{paddingTop: 5}} >
                <Stack horizontal tokens={{childrenGap: 5}}>
                <PrimaryButton text="Create new key" iconProps={{ iconName: 'PageAdd' }} onClick={()=>this.createKey()} width={300} disabled={!this.props.client}/>
                </Stack>
                  <DetailsList  items={this.state.keys} 
                              columns={columns} 
                              onItemInvoked={this._onItemInvoked}
                              onRenderRow={this._onRenderRow}
                              selectionMode={SelectionMode.none}
                  />
                </Stack>
                <Panel
                  headerText={this.state.propsPanelTitle}
                  type={PanelType.medium}
                  isOpen={this.state.propsPanelOpen}
                  onDismiss={()=>{ this.setState({propsPanelOpen: false})}}
                >
                  {props}
                </Panel>
            </Stack>
            <Dialog
              hidden={!this.state.doDelete}
              onDismiss={()=>{this.setState({doDelete: false})}}
              dialogContentProps={{
                  type: DialogType.normal,
                  title: 'Delete Key',
                  closeButtonAriaLabel: 'Close',
                  subText: 'Are you sure you want to delete this key.',
                }}
            >
            <DialogFooter>
              <PrimaryButton onClick={()=>{this.onDelete()}} text="Delete" />
              <DefaultButton onClick={()=>{this.setState({doDelete: false})}} text="Cancel" />
            </DialogFooter>
            </Dialog>
        </>        );
    }

    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
      const customStyles: Partial<IDetailsRowStyles> = {};
      if (props) {
        if(this.state.selectedKey){
          if (props.item.id  === this.state.selectedKey.id) {
            // Every other row renders with a different background color
            customStyles.root = { backgroundColor: "#785a00" };
          }
    
          }
          return <DetailsRow {...props} styles={customStyles} />;
      }
      return null;
    };
}

export default injectIntl(Keys);