/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:28a95507-bb16-47d4-8fe0-5eb0c6f744a5",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_XXw0rfrHt",
    "aws_user_pools_web_client_id": "1d1duqimscijbdnfse6itsvbb5",
    "oauth": {}
};

export default awsmobile;