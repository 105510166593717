import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';



import {  
    Stack, 
    Image,
    ImageFit, Text
} from '@fluentui/react';

type IState = {
}
  
  
interface IProps extends WrappedComponentProps {
}


class Home extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);

        this.state = {
        };

    }

    componentDidUpdate(prevProps: IProps) {
    }

// picker    

    render() 
    {

        return (<>
            <Stack  grow verticalAlign='start'>
                <Image imageFit={ImageFit.contain} src="/robobrain.svg" style={{width: "100%"}} />
                <Stack grow horizontal tokens={{childrenGap: 30}} >
                    <Stack style={{paddingLeft: 50}}>
                    <Image imageFit={ImageFit.contain} src="/jump.png"  />

                    </Stack>
                    <Text variant="xLarge">
<p>
Cascade is a new paradigm for the development of middleware.
</p>
<p>
Almost all middleware development languages are based on the concept of a single, monolithic, server-side application 
created by developers trained in understanding the flow of execution. 
</p>
<p>
    This is a very old concept, going back to the early days of the computer.
</p>
<p>
Cascade is different. Instead of focusing on execution flow, Cascade defines the flow of data through a system which is a much more
natural way of thinking.

</p>
                    </Text>
                </Stack>
                <Stack horizontal style={{padding: 30}} tokens={{childrenGap: 60}}>
                    <Stack.Item grow={1} style={{width: "30%"}}>
                        <Image imageFit={ImageFit.contain} src="/chart.png" style={{width: "100%"}} />
                        <Text variant="xLarge">
                            <p>
                            Define your data flows graphically.
                            </p>
                        </Text>
                        <Text variant="large">
                            <p>
                            Data flows are defined by our sumple drag and drop graphical editor. This simplified approach makes
                            it easy for non developers to understand the flow of data through your system.
                            </p>
                        </Text>
                    </Stack.Item>
                    <Stack.Item grow={1}  style={{width: "30%"}}>
                        <Image imageFit={ImageFit.contain} src="/three.png" style={{width: "100%"}} />
                        <Text variant="xLarge">
                            <p>
                            Data flows are massivly scalable.
                            </p>
                        </Text>
                        <Text variant="large">
                            <p>
                            Because the data flow paradigm inherantly isolates the data, flows can run in paralell, not just
                            multithreaded, but multi processor and multi server, allowing your processes to scale to any size.
                            </p>
                        </Text>
                    </Stack.Item>
                    <Stack.Item grow={1}  style={{width: "30%"}}>
                        <Image imageFit={ImageFit.contain} src="/modern.png" style={{width: "100%"}} />
                        <Text variant="xLarge">
                            <p>
                            Built with modern multi platform tools.
                            </p>
                        </Text>
                        <Text variant="large">
                            <p>
                            Designed from the ground up to run on multiple platforms and independant of any specific cloud provider, using tried 
                            and trusted scalable technologies such as Docker, RabbitMQ and MongoDB you can be assured that Cascade will remain 
                            at the forefront of technological development for many years.
                            </p>
                        </Text>
                    </Stack.Item>

                </Stack>
            </Stack>
            </>
        );
    }
}

export default injectIntl(Home);