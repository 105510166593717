import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';


import {  
    Stack,  List, TextField, IconButton, Label, Text,
} from '@fluentui/react';

//import { Tree } from 'antd';

type IState = {
    values: string[],
    currentValue: string,
    selectedIndex: number,
}
  
  
interface IProps extends WrappedComponentProps {
    label: string,
    values: string[],
    onChange: (values: string[]) => void,
}


class StringList extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            values: this.props.values,
            currentValue: "",
            selectedIndex: -1,
        };

        this.handleChange = this.handleChange.bind(this);
        this.onRenderCell = this.onRenderCell.bind(this);
    }


    componentDidUpdate(prevProps: IProps) {
      if(prevProps.values !== this.props.values){
        console.log("StringList componentDidUpdate",this.props.values);
        this.setState({values: this.props.values});
      }
       
    }

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        var values: string[] = JSON.parse(JSON.stringify(this.state.values));
        values[this.state.selectedIndex] = value;

        this.setState({currentValue: value,values: values});
        this.props.onChange(values);

        console.log("handleChange");
    }

   
    onRenderCell(item: string | undefined, index?: number, isScrolling?: boolean)
    {
      var bgColor = ((index ?? 0) === this.state.selectedIndex) ? "#f0f0f0" : "white";
      return (
        <div  
            data-is-focusable={true} 
            style={{backgroundColor: bgColor}}
            onClick={()=>{
              this.setState({selectedIndex: index ?? -1, currentValue: this.state.values[index??0]});
              }}
          >
          <div >
            <Text
              onClick={()=>{
                this.setState({selectedIndex: index ?? -1, currentValue: this.state.values[index??0]});
              }}
              >{item}</Text>
            <IconButton 
                iconProps={{ iconName: 'Delete' }} 
                onClick={()=>{
                            var values: string[] = JSON.parse(JSON.stringify(this.state.values));
                            values.splice(index ?? 0, 1);
                            this.setState({values: values});
                            this.props.onChange(values);
                          }}
            />
          </div>
        </div>
      )
      };
    
    
  
  
    
    render() 
    {
        if(this.state.values == null)  
        {
            return (         
            <Stack grow verticalFill style={{width: 300}}>
                Loading...
            </Stack>
            )
        }

       
        return (
            <Stack grow verticalFill style={{width: 300}}>
              <Label>{this.props.label}</Label>
              <Stack horizontal>
                <TextField value={this.state.currentValue} name="currentValue" onChange={this.handleChange}/>
              </Stack>
              <List items={this.state.values} onRenderCell={this.onRenderCell} />
              <Stack horizontal>
                <IconButton iconProps={{ iconName: 'Add' }} onClick={()=>{
                                              var values: string[] = JSON.parse(JSON.stringify(this.state.values));
                                              values.push("New Value");
                                              this.setState({values: values});
                                              this.props.onChange(values);
                  
                }}    />
              </Stack>
            </Stack>
        );
    }
}


export default injectIntl(StringList);