import React, { memo } from 'react';

import { Handle, Position } from 'react-flow-renderer';
import { Validate } from '../Validate';


import {  
  Image, Stack, Text,
} from '@fluentui/react';


export default memo(({ data }) => {
  var input = <></>;
  if(!data.root)
  {
    input = <Handle
              type="target"
              position={Position.Top}
              className="node-input"
              onConnect={(params) => console.log('handle onConnect', params)}
            />
  }

  var val = new Validate(data.rule);
  var err = <></>;
  if(!val.isValid)
  {
    err = <><Text className='node-error'>SYNTAX ERRORS</Text></>;
  }

  return (
    <div className={data.selected ? "node-selected" : "node-container"}>
      {input}
      <Stack horizontal className="node-type">
        Receive File
      </Stack>
      <Stack horizontal className="node-header-file" tokens={{childrenGap: 10}}>
        <Image src="/icons/operations/filereceive.svg" width={20} height={20} />
        <strong>{data.rule.name}</strong>
      </Stack>
      <Stack className="node-parameters">
        <Text className="node-parameters-header">Storage</Text>
        <Text className="node-parameters" nowrap>{data.rule.opParameters["storage"]}</Text>
        {err}
      </Stack>
      <Handle
        type="source"
        position={Position.Bottom}
        id="OnTrue"
        className="node-output"
      />
      <Handle
        type="source"
        position={Position.Right}
        id="OnFail"
        className="node-output-fail"
      />
    </div>
  );
});
