import 'rc-time-picker/assets/index.css';

import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { IPackage, 
         IPackageConfig,
         } from './IDeus';

import {  
    Stack, 
    TextField, 
    PrimaryButton, 
    Dialog,
    DialogFooter,
    DefaultButton,
    DialogType,
    Separator,
} from '@fluentui/react';

import { Post } from './authorisation/AMAPI';


type IState = {
    package: IPackage
    shouldSave: boolean
    dataerror: string,
    showDone: boolean,
}
  
  
interface IProps extends WrappedComponentProps {
    package: IPackage,
    client: string,
    onChange?: any,
    onClose: any,
}



class PackageInstaller extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            package: this.props.package,
            shouldSave: false,
            dataerror: "",
            showDone: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.installPackage = this.installPackage.bind(this);
    }


    componentDidUpdate(prevProps: IProps) {
        if (prevProps.package !== this.props.package) {
            this.setState({
                package: this.props.package,
                shouldSave: false
            });
          }
    }

    
    installPackage(): void
    {
        Post("package/install/" + this.props.client, this.state.package)
          .then(response => {
            console.log(response.data);
            this.setState({showDone: true});
            })
          .catch(function (error) {
            console.log(error);
          });
    }

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var allDone: boolean = true;
        var pack : IPackage = {...this.state.package};
        pack.config.forEach( (config: IPackageConfig) => {
            if (config.id === name) {
                config.currentValue = value;
            }
            if(config.currentValue === "" || config.currentValue == null) {
                allDone = false;
            }
        } );
        this.setState({package: pack, shouldSave: allDone});
    }

   

    onError(error: any) {
        console.error(error);
    }

   


    render() 
    {
       
          
        if(this.state.package == null) return <>Select the package to install...</>;
        if(this.state.package.id == null) return <>Select the package to install...</>;
       

        var fields = this.state.package.config.map( (config: IPackageConfig) => {
        return <Stack>
            <h5>{config.name}</h5>
            <p>{config.description}</p>
            <TextField key={config.id}  value={config.currentValue} onChange={this.handleChange} name={config.id} />
            <Separator />
        </Stack>
        } );
        
        return (
            <>
            <Stack grow verticalFill style={{padding: 10, borderLeft: "1px solid grey", width: "100%"}} >
                <h3>{this.state.package.name}</h3>
                <p>{this.state.package.description}</p>
                <p>Answer all of the following questions and click Install to add the preconfigured package</p>
                {fields}
                <Stack style={{ padding: 10}}>
                    <Stack horizontal tokens={{ childrenGap: 5}} >
                        <PrimaryButton iconProps={{ iconName: "Save" }}  text="Install" disabled={!this.state.shouldSave} onClick={()=>{this.installPackage();}}/>
                        <DefaultButton iconProps={{ iconName: "Folder" }} text="Cacnel" onClick={()=>{
                            this.props.onClose();
                            }}/>
                    </Stack>
                </Stack>
            </Stack>
            <Dialog
                 hidden={!this.state.showDone}
                 onDismiss={()=>{this.setState({showDone: false})}}
                 dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Package Installed',
                    closeButtonAriaLabel: 'Close',
                    subText: 'Your package has been installed.',
                  }}
            >
                 <DialogFooter>
                   <DefaultButton onClick={()=>{this.setState({showDone: false})}} text="Ok" />
                 </DialogFooter>
            </Dialog>                </>
        );
    }
}

export default injectIntl(PackageInstaller);