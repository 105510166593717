import 'rc-time-picker/assets/index.css';

import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  eFieldType, IField, uuid } from './IDeus';

import {  
    Stack, 
    Image, 
    IconButton,
} from '@fluentui/react';


type IState = {
    field: IField
    shouldSave: boolean
    editIP: boolean
    doMoveFolder: boolean
    destinationFolder: string
}
  
  
interface IProps extends WrappedComponentProps {
    field: IField,
    onChange?: any,
    onSelected?: any,
    onDelete?: any
    onAddChild?: any
    displayOnly?: boolean
    onExtractObject?: any
    selected?: boolean
}


class DDLField extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            field: this.props.field,
            shouldSave: false,
            editIP: false,
            doMoveFolder: false,
            destinationFolder: ""
        };

        this.handleChange = this.handleChange.bind(this);

        this.onSave = this.onSave.bind(this);
        this.onSave = this.onSave.bind(this);
    }
    


    componentDidUpdate(prevProps: IProps) {
        if (prevProps.field !== this.props.field) {
    
            this.setState({
                field: this.props.field,
                shouldSave: false
            });
          }
      }

      
    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var sched : IField = JSON.parse(JSON.stringify(this.state.field));
        switch(name)
        {
            case "name":
                sched.name = value;
                break;
            case "description":
                sched.description = value;
                break;
        }
        this.setState({field: sched, shouldSave: true});
    }

   
    componentDidMount()
    {
      this.refresh();
    }

    


    onSave()
    {
        var s = this.state.field;

        if(this.props.onChange != null)
        {
            this.props.onChange(s);
        }        
        this.setState({ shouldSave: false });
    }

  
    refresh()
    {

    }

    render() 
    {
        if(this.state.field == null) return <>Select a rule set from the list...</>;
        var addChild = <></>;
        var type = <></>;
        switch(this.state.field.type)
        {
            case eFieldType.String:
                type = <Image src="/icons/fieldtypes/string.svg" width={40} height={20} />
                break;
            case eFieldType.Integer:
                type = <Image src="/icons/fieldtypes/integer.svg" width={40} height={20} />
                break;
            case eFieldType.Float:
                type = <Image src="/icons/fieldtypes/float.svg" width={40} height={20} />
                break;
            case eFieldType.DateTime:
                type = <Image src="/icons/fieldtypes/datetime.svg" width={40} height={20} />
                break;
            case eFieldType.Boolean:
                type = <Image src="/icons/fieldtypes/bool.svg" width={40} height={20} />
                break;
            case eFieldType.Object:
                type = <Image src="/icons/fieldtypes/object.svg" width={40} height={20} />
                addChild = <>
                    <IconButton iconProps={{iconName: "Export"}} 
                    onClick={() => {
                        this.props.onExtractObject(this.state.field.id);
                    }}/>
                    <IconButton iconProps={{iconName: "DependencyAdd"}} 
                    onClick={() => {
                        this.props.onAddChild(this.state.field.id);
                    }}/>
                </>                
                break;
            case eFieldType.NamedObject:
                type = <Image src="/icons/fieldtypes/object.svg" width={40} height={20} />
                break;
            case eFieldType.Array:
            case eFieldType.NamedObjectArray:
                type = <Image src="/icons/fieldtypes/array.svg" width={40} height={20} />
                break;

        }
        if(this.props.displayOnly)
        {
            addChild = <></>
        }
        console.log("rendering DDLField");
        console.log(this.state.field.name,this.props.selected);
        return (
            <>
            <Stack onClick={()=>{
                    if(!this.props.displayOnly)
                    {
                        this.props.onSelected(this.state.field.id)
                    }
                }} 

                style={{
                    minHeight: 40, maxHeight: 40, backgroundColor: ((this.props.selected ?? false) ? "#785a00" : "#363636"),   margin: 4,borderRadius: 5, borderColor: "#d6a100", borderWidth: 1, borderStyle: "solid",  cursor: "pointer"
                }}>
                <Stack horizontal tokens={{childrenGap: 10}}>
                    <Stack.Item grow>
                        <Stack horizontal tokens={{childrenGap: 10}}>
                            <Stack.Item style={{backgroundColor: "#d6a100", marginRight: 10, padding: 10}}>
                                {type}
                            </Stack.Item>
                            <Stack.Item style={{padding: 10, fontWeight: this.state.field.required ? "bold" : "normal" }}>
                            {this.state.field.name}
                            </Stack.Item>
                        </Stack>
                    </Stack.Item>
                    <Stack.Item style={{padding: 5}}>
                        {addChild}
                        {this.props.displayOnly ? <></> : <IconButton iconProps={{iconName: "Delete"}}                                onClick={() => {
                                    this.props.onDelete(this.state.field.id);
                                }}/>}
                    </Stack.Item>
                </Stack>
            </Stack>
            {(this.state.field.children.length === 0) ? <></> :
                <div style={{paddingLeft: 20}}>
                    {
                this.state.field.children.map((field: IField) => {
                    return <DDLField 
                        intl={this.props.intl}
                      onSelected={this.props.onSelected} 
                      field={field} 
                      key={uuid()} 
                      onChange={this.props.onChange}
                      onDelete={this.props.onDelete} 
                      onAddChild={this.props.onAddChild}
                      displayOnly={this.props.displayOnly}
                      />
                  })
                  }        
                </div> 
                }
            </>
        );
    }
}

export default injectIntl(DDLField);