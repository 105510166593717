import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';


import { eOpCode } from './../IDeus';

import {  
    Stack
} from '@fluentui/react';

import AddToAggregare from './rules/AddToAgregae'
import Aggregare from './rules/Agregae'
import Rest from './rules/REST'


type IState = {
}

interface IProps extends WrappedComponentProps {
    rule: eOpCode
}

class RuleHelp extends Component<IProps,IState> {
    render() 
    {
        var helptext = <></>;
        switch(this.props.rule)
        {
            case eOpCode.ExecAddToAggregate:
                helptext = <AddToAggregare/>
                break;
            case eOpCode.ExecAggregate:
                helptext = <Aggregare/>
                break;
            case eOpCode.ExecREST:
                helptext = <Rest/>
                break;
    
        }
        return (
            <Stack grow verticalFill style={{padding: 10}}>
                {helptext}
            </Stack>
        )
    }
}
export default injectIntl(RuleHelp);