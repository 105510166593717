import 'rc-time-picker/assets/index.css';

import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { ITemplate, 
         IDataDefinition,
         isJsonString
         } from './IDeus';

import {  
    Stack, 
    TextField, 
    PrimaryButton, 
    Dropdown,
    IDropdownOption,
    Dialog,
    DialogFooter,
    DefaultButton,
    DialogType,
    ActionButton,
} from '@fluentui/react';


import Folders from './Folders';
import DDLFinder from './DDLFinder';
import { Get } from './authorisation/AMAPI';


type IState = {
    template: ITemplate
    shouldSave: boolean
    doMoveFolder: boolean
    destinationFolder: string    
    doSetDDL: boolean
    onGetDDL: boolean
    doSetTemplate: boolean,
    dataerror: string,

}
  
  
interface IProps extends WrappedComponentProps {
    template: ITemplate,
    client: string,
    onChange?: any,
    onMoveFolder?: any,
}



class TemplateProperties extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            template: this.props.template,
            shouldSave: false,
            doMoveFolder: false,
            destinationFolder: "",
            doSetDDL: false,
            onGetDDL: false,
            doSetTemplate: false,
            dataerror: "",
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleEditorChange = this.handleEditorChange.bind(this);

        this.onSave = this.onSave.bind(this);
        this.onMoveFolder = this.onMoveFolder.bind(this);
        this.onSave = this.onSave.bind(this);
    }


    componentDidUpdate(prevProps: IProps) {
        if (prevProps.template !== this.props.template) {
            this.setState({
                template: this.props.template,
                shouldSave: false
            });
          }
    }

    
    getTemplate(id: string): void
    {
        Get("datadefinition/template/"+id)
          .then(response => {
            console.log(response.data);
            var template : ITemplate = JSON.parse(JSON.stringify(this.state.template));
            template.dataTemplate = response.data;
            this.setState({template: template, shouldSave: true});
            })
          .catch(function (error) {
            console.log(error);
          });
        
    }
    handleEditorChange(value: string) {
        var template : ITemplate = JSON.parse(JSON.stringify(this.state.template));
        template.template = value;
        this.setState({ template: template,  shouldSave: true })
      }
      

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var template : ITemplate = {...this.state.template};
        switch(name)
        {
            case "name":
                template.name = value;
                break;
            case "description":
                template.description = value;
                break;
            case "template":
                template.template = value;
                break;
            case "datatemplate":
                template.dataTemplate = value;
                if(!isJsonString(value))
                {
                    this.setState({dataerror: "Data template is not valid JSON"});
                }
                else
                {
                    this.setState({dataerror: ""});
                }
                break;
                }
        this.setState({template: template, shouldSave: true});
    }

    onError(error: any) {
        console.error(error);
      }

    onMoveFolder()
    {
        var sched : ITemplate = {...this.state.template};
        sched.folderID = this.state.destinationFolder;
        this.setState({template: sched, shouldSave: true});
        if(this.props.onMoveFolder != null)
        {
            this.props.onMoveFolder(sched);
        }        
    }

    onSave()
    {
        var s = this.state.template;


        if(this.props.onChange != null)
        {
            this.props.onChange(s);
        }        

        this.setState({ shouldSave: false });
    }


    render() 
    {
       
          
        if(this.state.template == null) return <>Select a rule set from the list...</>;
        if(this.state.template.id == null) return <>Select a rule set from the list...</>;

     

        const templateLanguage: IDropdownOption[] = [
            { key: "SCRIBAN", text: 'Scriban', data: { icon: 'database.svg' } },
            { key: "LIQUID", text: 'Liquid', data: { icon: 'database.svg' } },
          ];
        
        return (
            <>
            <Stack grow verticalFill style={{padding: 10}} >
                <Stack style={{ paddingBottom: 10}}>
                    <Stack horizontal tokens={{ childrenGap: 5}} >
                        <PrimaryButton iconProps={{ iconName: "Save" }}  text={this.props.intl.formatMessage({ id: 'std.save' })} disabled={!this.state.shouldSave} onClick={()=>{this.onSave();}}/>
                        <DefaultButton iconProps={{ iconName: "FabricMovetoFolder" }} text="Move To Folder" onClick={()=>{this.setState({doMoveFolder: true});}}/>
                    </Stack>
                    <TextField label={this.props.intl.formatMessage({ id: 'schedule.name' })} value={this.state.template.name}  name="name" onChange={this.handleChange}/>
                    <TextField label={this.props.intl.formatMessage({ id: 'schedule.description' })} multiline value={this.state.template.description}  name="description" onChange={this.handleChange}/>
                </Stack>
                <TextField label="Data Template" multiline rows={6} value={this.state.template.dataTemplate}  name="datatemplate" onChange={this.handleChange} errorMessage={this.state.dataerror}/>
                <ActionButton iconProps={{ iconName: 'Edit' }} onClick={()=>{this.setState({onGetDDL: true, doSetTemplate: true})}} >Load from DDL</ActionButton>
                <Dropdown
                    placeholder="Select a language"
                    label="Template Language"
                    ariaLabel="Template Language"
                    options={templateLanguage}
                    selectedKey={this.state.template.templateLanguage || ""}
                    onChange={(ev: any,option?: any) => {
                        var sched : ITemplate = JSON.parse(JSON.stringify(this.state.template));
                        sched.templateLanguage = option.key;
                        this.setState({template: sched, shouldSave: true});                    }}
                    />
                <TextField label="Template Text" multiline rows={10} value={this.state.template.template}  name="template" onChange={this.handleChange}/>


                
            </Stack>
            <DDLFinder 
            // @ts-ignore
                isOpen={this.state.onGetDDL}
                client={this.props.client}
                onDismiss={()=>{this.setState({onGetDDL: false, doSetTemplate: false, })}}
                onDDLSelected={(ddl: IDataDefinition)=>{
                    if(this.state.doSetTemplate)
                    {
                        this.getTemplate(ddl.id);
                        this.setState({onGetDDL: false })
                    }
                }}
            /> 

            <Dialog
                 hidden={!this.state.doMoveFolder}
                 onDismiss={()=>{this.setState({doMoveFolder: false})}}
                 dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Move Folder',
                    closeButtonAriaLabel: 'Close',
                    subText: 'Choose which folder you want to move this ruleset to',
                  }}
            >
                <Folders 
                    clientid={this.props.client} 
                    onFolderSelected={(folder: string) => this.setState({destinationFolder: folder})}  
                />

                 <DialogFooter>
                   <PrimaryButton onClick={()=>{this.onMoveFolder()}} text="Move" />
                   <DefaultButton onClick={()=>{this.setState({doMoveFolder: false})}} text="Cancel" />
                 </DialogFooter>
            </Dialog>    
            </>
        );
    }
}

export default injectIntl(TemplateProperties);