
import {
  IDatePickerStrings, IDropdownOption,
} from '@fluentui/react';

export const { API, APIKEY } = require('./IDeusEndpoint');

export const stdDayPickerStrings: IDatePickerStrings = {
  months: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],

  shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],

  days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],

  shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],

  goToToday: 'Go to today',
  prevMonthAriaLabel: 'Go to previous month',
  nextMonthAriaLabel: 'Go to next month',
  prevYearAriaLabel: 'Go to previous year',
  nextYearAriaLabel: 'Go to next year',
  closeButtonAriaLabel: 'Close date picker',
};

export interface IPagination {
  current: number,
  pageSize: number,
  totalCount: number,
  sortField: string,
  sortOrder: string,
}

export interface IFolderSet {
  id: string,
  folders: IFolder[]
}

export interface IFolder {
  id: string,
  name: string,
  apiSpecification: IApiSpecification,
  children: IFolder[],
}

export interface IApiSpecification {
  version: string,
  title: string,
  description: string,
}


export interface IKeyValue {
  key: string
  value: string
}

export interface IRule {
  id: string,
  name: string,
  description: string,
  x: number,
  y: number,

  validateInputDDLID: string,
  validateInputDDLName: string,

  opCode: number,
  opParameters: {[key: string]: string},

  executionType: eExecutionType,
  secondsDelay: number | null,

  checkForExecution: boolean,
  executionCheck: string,
  noExecutionAction: eNoExecutionAction,

  onTrue: IRule[],
  onFalse: IRule[],
  onFail: IRule[],
}

export enum eNoExecutionAction
{
    GoLeft,
    GoRight,
    EndStream,
}

export enum eExecutionType {
  Delay,
  Parallel,
}

export enum eOpCode {
  NOP,

  ExecSequence,
  ExecCommands,

  ExecREST,
  ExecQuery,

  ExecFTPSearch,
  ExecFTPUpload,
  ExecFTPDownload,

  ExecStringReplace,
  ExecGenerateSequence,
  ExecBooleanExpression,

  ExecSchedule,
  ExecLog,

  ExecAggregate,
  ExecAddToAggregate,

  ExecDirectorySearch,
  ExecFileReceive,
  ExecFileDelete,
  ExecCSVFileRead,

  ExecDatabaseQuery,

  ExecTranslate,
  ExecSetValues,

  ExecTemplate,
  ExecRedirectAggregate,

  ExecEncryption,

  ExecAggregateFail,

  ExecExternalCommand,
  
}

export const libraryClient = "62badadf5a14b100e0285fe1";


export enum eRepeatType {
  None,
  Fixed,
  Relative,
  FirstDayOfMonth,
  FirstWorkingDayOfMonth,
  LastDayOfMonth,
  LastWorkingDayOfMonth,
}

export const repeatTypes: IDropdownOption[] = [
  { key: 0, text: "Never" },
  { key: 1, text: "Relative to Current Time" },
  { key: 2, text: "Relative to Execution Time" },
  { key: 3, text: "First day of Month" },
  { key: 4, text: "First Working day of Month" },
  { key: 5, text: "Last day of Month" },
  { key: 6, text: "Last Working day of Month" },
];


export enum eRepeatValueUnits {
  Seconds,
  Minutes,
  Hours,
  Days,
  Weeks,
  Months,
}

export const repeatValueUnits: IDropdownOption[] = [
  { key: 0, text: "Seconds" },
  { key: 1, text: "Minutes" },
  { key: 2, text: "Hours" },
  { key: 3, text: "Days" },
  { key: 4, text: "Weeks" },
  { key: 5, text: "Months" },
];

export const scheduleTypes: IDropdownOption[] = [
  { key: 0, text: "Timed Schedule" },
  { key: 1, text: "API Endpoint" },
];

export enum eScheduleType {
  TimedSchedule,
  APIEndpoint
}

export enum eParameterType
{
  None,
  Number,
  Text,
  Post,
  File,
  Put,
  Delete
}

export const parameterTypes: IDropdownOption[] = [
  { key: 0, text: "None" },
  { key: 1, text: "Number in URL" },
  { key: 2, text: "Text in URL" },
  { key: 3, text: "Object in POST" },
  { key: 4, text: "File" },
  { key: 5, text: "Put" },
  { key: 5, text: "Delete" },
];

export interface IPort {
  name: string,
}

export enum eTranslationObjectType {
  InputObject,
  OutputObject,
  ToUpperCase,
  ToLowerCase,
  MathMultiply,
  MathDivide,
  MathAdd,
  MathSubtract,
  SplitName,
  JoinName,
  StringJoin,
  Constant,
}


export interface ITranslationObject 
{
  id: string,
  name: string,
  objectType: eTranslationObjectType,
  x: number,
  y: number,
  opParameters: {[key: string]: string},
  outputs: IPort[],
  inputs: IPort[],
  internalid?: string,
}

export interface IPath
{
  inputObject: string,
  inputField: string,
  outputObject: string,
  outputField: string,
}

export interface ITranslation
{
  id: string,
  inputDDL: string,
  outputDDL: string,
  objects: ITranslationObject[],
  paths: IPath[],
}


export interface IClient {
  id: string,
  name: string,
  addressLine1: string,
  addressLine2: string,
  city: string,
  country: string,
  postcode: string,
  contactEmail: string,
  active: boolean,
  requireAPIKey?: boolean,
  apiKey?: string,
  allowIP?: string,
  currentExecRuleCount: number,
  currentAPICallCount: number,
  currentScheduleCallCount: number,
  externalBytesSent: number,
  externalBytesReceived: number
}

export interface ITemplate {
  id: string,
  clientID: string,
  name: string,
  description: string,
  folderID: string,

  template?: string,
  templateLanguage?: string,
  dataTemplate?: string,
}

export interface IKey {
  id: string,
  clientID: string,
  name: string,
  description: string,
  folderID: string,
  type: string,

  publicKey?: string,
  passPhrase?: string,
}


export interface IPackage {
  id: string,
  clientID: string,
  name: string,
  description: string,
  image: string,
  folderID: string,

  template?: string,
  templateLanguage?: string,
  dataTemplate?: string,

  config: IPackageConfig[],
}

export interface IPackageConfig {
  id: string,
  name: string,
  description: string,
  parameterName: string,
  parameterType: string,
  currentValue: string,
}



export interface ISchedule {
  id: string,
  clientID: string,
  name: string,
  description: string,
  externalID: string,

  folderID: string,
  logExceptions?: boolean,
  logAllSteps?: boolean,
  requireAPIKey?: boolean,
  apiKey?: string,

  allowIP?: string,

  authorizationHeader?: boolean,
  authorizerID?: string,
  returnAuthObject?: boolean,
  authorizerName?: string,

  parameterType?: eParameterType,
  parameterDDLID?: string,
  parameterDDLName: string,

  type: eScheduleType,

  active: boolean,

  executeAt: string,
  repeatType: number,
  repeatValueUnits: number,
  repeatValue: number,

  logExecution: boolean,
  currentExecRuleCount: number,
  currentCallCount: number,
  externalBytesSent: number,
  externalBytesReceived: number

  rule: IRule;
}

export enum eFieldType
{
    String,
    Integer,
    Float,
    DateTime,
    Boolean,
    Object,
    Array,
    NamedObject,
    NamedObjectArray,
}

export interface IField {
  //  Type of the field
  id: string,
  name: string,
  description: string,
  type: eFieldType,
  length: number,
  precision: number,
  arrayType: eFieldType,
  objectID: string,

  // Validation
  primaryKey: boolean,
  required: boolean,
  validate: boolean,
  minimumValue: string,
  maximumValue: string,
  allowableValues: string[],
  caseSensitive: boolean,
  regExValidation: string,
  builtInValidation: string,

  children: IField[],
}

export interface IDataDefinition {
  id: string,
  clientID: string,
  name: string,
  description: string,
  folderID: string,
  fields: IField[],
}

export interface IKeyArray {
  [key: string]: string
}

export interface IConnection {
  id: string,
  clientID: string,
  folderID: string,
  name: string,
  description: string,

  type: string,
  subtype: string,

  parameters: {[key: string]: string}

}

export interface IAuthConnection {
  id: string,
  clientID: string,
  folderID: string,
  className: string,
  name: string,
  description: string,
  headers: {[key: string]: string},
  parameters: {[key: string]: string}
}

export interface IRationalizedNumber {
  value: string,
  scale: string
}

export function rationalizeNumber(value: number): IRationalizedNumber {
  var ret: IRationalizedNumber = {
    value: value.toString(),
    scale: "bytes"
  }
  if(value > 1024) {
    value = value / 1024;
    ret.scale = "Kb"
    ret.value = value.toFixed(1);
  }
  if(value > 1024) {
    value = value / 1024;
    ret.scale = "Mb"
    ret.value = value.toFixed(1);
  }
  if(value > 1024) {
    value = value / 1024;
    ret.scale = "Gb"
    ret.value = value.toFixed(1);
  }
  if(value > 1024) {
    value = value / 1024;
    ret.scale = "Tb"
    ret.value = value.toFixed(1);
  }
  return ret;
} 
export function uuid() {
  const hashTable = [
    'a','b','c','d','e','f',
    '0','1','2','3','4','5','6','7','8','9'
  ]
  let uuid = []
  for (let i = 0; i < 35; i++) {
    if (i === 7 || i === 12 || i === 17 || i === 22) {
      uuid[i] = '-'
    } else {
      uuid[i] = hashTable[Math.floor(Math.random() * hashTable.length - 1)]
    }
  }
  return uuid.join('')
}

export function isJsonString(str: string) {
  try {
      JSON.parse(str);
  } catch (e) {
      console.log("isJsonString:",str);
      console.log("SON PARSE ERROR:",e);
      return false;
  }
  return true;
}


export interface IAuthUser {
  username: string
  email: string
  idToken: string
  accessToken: string
  tag: string
}

export interface IAPIResults
{
    response: any
    error: any
}

export interface IcpuOut {
  execQueue: ITask[]
  internalExecQueue: ITask[]
  accumulator: string
}

export interface IcpuIn {
  task: ITask
  internalExecQueue: boolean
  accumulator: string
}

export interface IAPIOptions {
  schema?: string
  headers?: any,
  Authorise?: boolean
}

export interface ITask {
  rulesetID: string,
  ruleID: string,
  value: string,
}