import 'rc-time-picker/assets/index.css';

import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { 
         IConnection,
         IAuthConnection
        } from '../IDeus';

import {  
    Stack, 
    TextField, 
    Dropdown,
    IDropdownOption,
    Label,
} from '@fluentui/react';



type IState = {
    opParameters: {[key: string]: string},
    dataerror: string,
}
  
  
interface IProps extends WrappedComponentProps {
    opParameters: {[key: string]: string},
    readOnly?: boolean,
    client: string,
    onChange?: any,
    connections: IConnection[],
    authConnections: IAuthConnection[],
}



class ExternalExecProperties extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            opParameters: this.props.opParameters,
            dataerror: "",
        };

        this.handleChange = this.handleChange.bind(this);
    }


    componentDidUpdate(prevProps: IProps) {
        if (prevProps.opParameters !== this.props.opParameters) {
            this.setState({
                opParameters: this.props.opParameters,
            });
          }
    }


    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var parameters  = {...this.state.opParameters};
        parameters[name] = value;
        this.setState({opParameters: parameters});
        this.props.onChange(parameters);
    }

    onError(error: any) {
        console.error(error);
    }

    
    render() 
    {
        if(this.state.opParameters == null) return <>Select a rule set from the list...</>;
        const osType: IDropdownOption[] = [
            { key: 'WIN', text: 'Windows' },
            { key: "LINUX", text: 'Linux' },
          ];

        return (
            <Stack>
                <Label>Connection</Label>
                <Dropdown
                        placeholder="Select..."
                        disabled={this.props.readOnly}
                        options={this.props.connections.filter( c => (c.type==="api" && c.subtype==="server") ).map(c => {   
                            let rObj: IDropdownOption = { key: c.id, text: c.name}
                            return rObj
                        })}                        
                        selectedKey={this.state.opParameters["connection"]}
                        onChange={(ev: any,option?: any) =>
                            {
                                var param  = {...this.state.opParameters};
                                param["connection"] = option.key;
                                this.setState({opParameters: param}) ;
                                this.props.onChange(param);
                            }}
                />
                <Label>Authorizor</Label>
                <Dropdown
                        disabled={this.props.readOnly}
                        placeholder="None"
                        options={this.props.authConnections.filter( c => c.className==="BasicAuthorizer").map(c => {   
                            let rObj: IDropdownOption = { key: c.id, text: c.name}
                            return rObj
                        })}                        
                        selectedKey={this.state.opParameters["authconnection"]}
                        onChange={(ev: any,option?: any) =>
                            {
                                var param  = {...this.state.opParameters};
                                param["authconnection"] = option.key;
                                this.setState({opParameters: param}) ;
                                this.props.onChange(param);
                            }}                    
                />
                <Label>Operating System</Label>
                <Dropdown
                        disabled={this.props.readOnly}
                        placeholder="None"
                        options={osType}                        
                        selectedKey={this.state.opParameters["os"]}
                        onChange={(ev: any,option?: any) =>
                            {
                                var param  = {...this.state.opParameters};
                                param["os"] = option.key;
                                this.setState({opParameters: param}) ;
                                this.props.onChange(param);
                            }}                    
                />                        
                <TextField multiline rows={6} readOnly={this.props.readOnly} label="Commands" value={this.state.opParameters["command"]}  name="command" onChange={this.handleChange}  />
            </Stack>
        );
    }
}

export default injectIntl(ExternalExecProperties);