import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  IPackage, IPagination,  libraryClient,  } from './IDeus';

import {  Post, Put, Get } from './authorisation/AMAPI';

import {  
    Stack, Text,
    PrimaryButton, DefaultButton, 
    TextField, 
    Dialog, DialogFooter, 
    DialogType, Modal, DocumentCard, DocumentCardPreview, DocumentCardDetails, DocumentCardTitle, DocumentCardType, ImageFit, 
} from '@fluentui/react';
import { Separator } from '@fluentui/react';

import PackageInstaller from './PackageInstaller' 
import TemplateFinder from './TemplateFinder' 


type IState = {
    packages: IPackage[],
    selectedPackage: IPackage,

    currentFolder: string

    pagination: IPagination,
    showDialog: boolean,
    doDelete: boolean,
    doImport: boolean,
    doCopy: boolean,
    showCalled: boolean,

    actionID: string,

    copyName: string,
    importTemplate?: IPackage,
}
  
  
interface IProps extends WrappedComponentProps {
  client: string,
  history?: any
}


class PackageImport extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            packages: [],
            selectedPackage: {} as IPackage,

            pagination: {
                totalCount: 0,
                current: 0,
                pageSize: 20,
                sortField: "",
                sortOrder: "asc"
            },

            currentFolder: "Uncategorized",

            showDialog: false,
            doDelete: false,
            doImport: false,
            doCopy: false,

            showCalled: false,

            actionID: "",

            copyName: "",

        };

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.createPackage = this.createPackage.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onSave = this.onSave.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
    }

    componentDidUpdate(prevProps: IProps) {
      if (prevProps.client !== this.props.client) {
        this.setState({currentFolder: "Uncategorized"},()=>{
          this.authenticatedrefresh();
        });
          }
    }

// picker    
  handleFieldChange(event: any){
    const target = event.target;
    const value : string = target.type === 'checkbox' ? target.checked : target.value;
    const name : string = target.name;


    switch(name)
    {
        case "copyName":
            this.setState({copyName: value});
            break;
    }
  }

  handleChange(event: any){
      const target = event.target;
      const value : string = target.type === 'checkbox' ? target.checked : target.value;
      const name : string = target.name;

      var pack: IPackage = {...this.state.selectedPackage};
      switch(name)
      {
          case "name":
              pack.name = value;
              break;
          case "description":
              pack.description = value;
              break;
      }
      this.setState({selectedPackage: pack});
    }

    componentDidMount()
    {
      this.refresh();
    }

    createPackage()
    {
        this.setState({
            selectedPackage: {
                id: "",
                clientID: this.props.client,
                name: "New Package",
                description: "",
                image: "",
                folderID: this.state.currentFolder,
                config: []
            },

        });
    }
  
    refresh()
    {
        this.authenticatedrefresh();
    }


   
    onPageChange(page: number)
    {
      var p: IPagination = Object.assign(this.state.pagination);
      p.current = page;
      this.setState({pagination: p});
      this.authenticatedrefresh();
    }

   
    private authenticatedrefresh()
    {
      Get("package/forclient/62eccc4c6e71c0d38c44f731")
        .then(response => {
              this.setState({ 
                packages: response.data, 
              });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    onSave(sched: IPackage)
    {
        if(sched.id === "")
        {
            Post("package",sched)
            .then(response => {
                sched.id = response.data;
                  this.setState({ 
                    selectedPackage: sched, 
                  });
                  this.authenticatedrefresh();
                })
            .catch(function (error) {
              console.log(error);
            });
        }
        else
        {
            Put("package/" + sched.id,sched)
            .then(response => {
              this.authenticatedrefresh();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
    }


    private _onItemInvoked = (item: IPackage): void => {
        this.setState({selectedPackage: item});
    };

   

  

      render() 
    {

console.log(this.state);
      if(this.state.packages == null) return <div>Loading...</div>;
      if((this.state.packages?.length ?? 0) === 0) return <div>Loading...</div>;
      if(this.props.client==="") return <div>Select your client...</div>;


        var cards = this.state.packages.map((item: IPackage) => {
          return <DocumentCard
          aria-label="Document Card with icon. View and share files. Created by Aaron Reid a few minutes ago"
          type={DocumentCardType.compact}
        >
          <DocumentCardPreview previewImages={ [
                                {
                                  name: item.name,
                                  linkProps: {
                                    href: 'http://bing.com',
                                    target: '_blank',
                                  },
                                  previewImageSrc: item.image,
                                  iconSrc: item.image,
                                  imageFit: ImageFit.cover,
                                  width: 100,
                                  height: 100,
                                },
                              ]}  />
          <DocumentCardDetails>
            <DocumentCardTitle title={"Add "+item.name} shouldTruncate />
            <DocumentCardTitle title={item.description} showAsSecondaryTitle />
              <Stack horizontal>
                <PrimaryButton text="Import" onClick={() => {this.setState({selectedPackage: item})}} />
              </Stack>
          </DocumentCardDetails>
        </DocumentCard>
        });



        return (
          <>          
            <Stack horizontal grow verticalFill style={{padding: 10}} tokens={{ childrenGap: 10}}>
              <Stack grow>
                <h3>Import Package</h3>
                {cards}
              </Stack>
              <Stack style={{width: 400}}>
                <PackageInstaller client={this.props.client} package={this.state.selectedPackage} onClose={()=>{}} />

              </Stack>
            </Stack>

            <Dialog
              hidden={!this.state.doCopy}
              onDismiss={()=>{this.setState({doCopy: false})}}
              dialogContentProps={{
                  type: DialogType.normal,
                  title: 'Copy Template',
                  closeButtonAriaLabel: 'Close',
                  subText: 'Please provide a name for the new template.',
                }}
            >
                <TextField label={this.props.intl.formatMessage({ id: 'schedule.name' })} value={this.state.copyName}  name="copyName" onChange={this.handleFieldChange}/>
              
            <DialogFooter>
              <DefaultButton onClick={()=>{this.setState({doCopy: false})}} text="Cancel" />
            </DialogFooter>
            </Dialog>
            <Modal
        isOpen={this.state.doImport}
        onDismiss={()=>{this.setState({doImport: false})}}
        isBlocking={false}
      >
        <Stack style={{margin: 10}}>
          <Text variant="xLarge">Import Rule Set</Text>
        <TemplateFinder client={libraryClient} templateSelected={(sched: IPackage) => {this.setState({importTemplate: sched})}}/>
        <Separator/>
            <Stack  tokens={{childrenGap: 10}} >
              <Stack.Item  align="end">
                <Stack horizontal tokens={{childrenGap: 10}}>
              <DefaultButton text="Cancel" onClick={()=>{this.setState({doImport: false, importTemplate: undefined})}} />
              </Stack>
              </Stack.Item>
            </Stack>
        </Stack>
        </Modal>
        </>        );
    }

    
}

export default injectIntl(PackageImport);