import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  IDataDefinition, IPagination, } from './IDeus';

import {  Post, } from './authorisation/AMAPI';

import {  
    Stack, 
    PrimaryButton,
    DetailsList, IColumn, SelectionMode, 
    Text,
    Modal,  
     IDetailsListProps, IDetailsRowStyles, DetailsRow, Label, 
} from '@fluentui/react';
import { Separator } from '@fluentui/react';

import Folders from './Folders' 

type IState = {
    datadefinitions: IDataDefinition[],
    selectedDataDefinition: IDataDefinition,

    currentFolder: string

    pagination: IPagination,
    showModal: boolean,
}
  
interface IProps extends WrappedComponentProps {
  client: string,
  onDDLSelected?: any
  onDismiss?: any
  isOpen: boolean
}



class DDLFinder extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            datadefinitions: [],
            selectedDataDefinition: {} as IDataDefinition,

            pagination: {
                totalCount: 0,
                current: 0,
                pageSize: 20,
                sortField: "",
                sortOrder: "asc"
            },

            currentFolder: "Uncategorized",

            showModal: false,

        };

        this.onPageChange = this.onPageChange.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onFolderSelected = this.onFolderSelected.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
    }

    componentDidUpdate(prevProps: IProps) {
      if (prevProps.client !== this.props.client) {
        this.authenticatedrefresh();
      }
     
    }

 
    
    componentDidMount()
    {
      this.refresh();
    }

    refresh()
    {
        this.authenticatedrefresh();
    }

    onPageChange(page: number)
    {
      var p: IPagination = Object.assign(this.state.pagination);
      p.current = page;
      this.setState({pagination: p});
      this.authenticatedrefresh();
    }

    private _onColumnClick = (ev: any, column: IColumn): void => {
    };      

    private authenticatedrefresh()
    {
      const params = this.state.pagination;

      const paging = {
        current: params.current + 1,
        pagesize: params.pageSize,
        sortfield: params.sortField,
        sortorder: params.sortOrder,
      }

      const post = {
        paging: paging,
        currentFolder: this.state.currentFolder,
        currentClient: this.props.client
      }

      Post("datadefinition/Search",post)
        .then(response => {
              var p = this.state.pagination;
              p.totalCount = response.data.totalCount;   
              this.setState({ 
                datadefinitions: response.data.results, 
                pagination: p, 
              });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    


    private _onItemInvoked = (item: IDataDefinition): void => {
        this.setState({selectedDataDefinition: item});
        if(this.props.onDDLSelected != null)
        {
          this.props.onDDLSelected(item);
        }
        this.props.onDismiss();
    };

    
   
      public onFolderSelected(folder: string)
      {
        this.setState({currentFolder: folder},()=>{
          this.authenticatedrefresh();
        });
      }

      render() 
    {

        const columns: IColumn[] = [
          {
              key: 'name',
              name: this.props.intl.formatMessage({ id: 'schedule.name' }),
              fieldName: 'name',
              minWidth: 0,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              onColumnClick: this._onColumnClick,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'description',
              name: this.props.intl.formatMessage({ id: 'schedule.description' }),
              fieldName: 'description',
              minWidth: 0,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              onColumnClick: this._onColumnClick,
              data: 'string',
              isPadded: true,
            },
        ];

        return (
          <Modal
          isOpen={this.props.isOpen}
          onDismiss={()=>{this.props.onDismiss();}}
          isBlocking={false}
        >
          <Stack style={{margin: 10}}>
                <Text variant="xLarge">Select DDL</Text>
                <Separator/>
                <Stack>
                  <Stack horizontal grow verticalFill>
                    <Stack grow={2}>
                      <Label>Folders</Label>
                      <Folders clientid={this.props.client} onFolderSelected={this.onFolderSelected} style={{ width: '100%',  minHeight:200, maxHeight: 200, minWidth:300 }} />
                    </Stack>
                      <Separator vertical />
                      <Stack grow={3} style={{paddingTop: 5}} >
                          <DetailsList  items={this.state.datadefinitions} 
                                      columns={columns} 
                                      onItemInvoked={this._onItemInvoked}
                                      onRenderRow={this._onRenderRow}
                                      selectionMode={SelectionMode.none}
                          />


                      </Stack>
                  </Stack>
                </Stack>
                </Stack>
                <Separator/>
                <Stack horizontal grow style={{padding: 10}} horizontalAlign="end">
                  <PrimaryButton text="Cancel" onClick={()=>{this.props.onDismiss();}} />
                </Stack>
              </Modal>
          );
    }

    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
      const customStyles: Partial<IDetailsRowStyles> = {};
      if (props) {
        if(this.state.selectedDataDefinition){
          if (props.item.id  === this.state.selectedDataDefinition.id) {
            // Every other row renders with a different background color
            customStyles.root = { backgroundColor: "lightskyblue" };
          }
    
          }
          return <DetailsRow {...props} styles={customStyles} />;
      }
      return null;
    };
}

export default injectIntl(DDLFinder);