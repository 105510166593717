import 'rc-time-picker/assets/index.css';

import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { IConnection,  IAuthConnection } from './IDeus';

import {  
    Stack, 
    TextField, 
    Image, 
    PrimaryButton, 
    Dropdown, 
    IDropdownOption,
    DropdownMenuItemType,
    Label,
    DefaultButton,
    Text,
    Separator,
    Spinner,
} from '@fluentui/react';


import moment, { Moment } from 'moment';

import FolderPicker from './FolderPicker';
import { Get } from './authorisation/AMAPI';

type IState = {
    connection: IConnection
    shouldSave: boolean
    currDate: Date | null | undefined
    currTime: Moment
    doMoveFolder: boolean
    testDB: string
    testResult: string
    isTesting: boolean
}
  
  
interface IProps extends WrappedComponentProps {
    connection: IConnection,
    authConnections: IAuthConnection[],
    onChange?: any,
    client: string,
}


class ConnectionProperties extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        var m : Moment = moment();
        var d : Date = new Date();

        this.state = {
            connection: this.props.connection,
            shouldSave: false,
            currDate: d,
            currTime: m,
            doMoveFolder: false,
            testDB: "",
            testResult: "",
            isTesting: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleParamChange = this.handleParamChange.bind(this);
        this.handleComboChange = this.handleComboChange.bind(this);
        this.setType = this.setType.bind(this);
        this.setSubtype = this.setSubtype.bind(this);
        this.addAuthorisorOptions = this.addAuthorisorOptions.bind(this);
        this.testConnection = this.testConnection.bind(this);

        this.onSave = this.onSave.bind(this);
        this.doSave = this.doSave.bind(this);
    }

    testConnection(){

        this.setState({ isTesting: true });
        Get("connection/test/" + this.state.connection.id + "/" + this.state.testDB, {schema: "Bearer"})
        .then(response => {
            console.log(response);
            this.setState({testResult: response.data, isTesting: false});
        });
    }
  

    componentDidUpdate(prevProps: IProps) {
        if (prevProps.connection !== this.props.connection) {
    
            this.setState({
                connection: this.props.connection,
                shouldSave: false
            });
          }
      }


      setType(type: string)
      {
        var c = this.state.connection;
        c.type = type;
        this.setState({connection: c, shouldSave: true});
      }
  
      setSubtype(type: string, subtype: string)
      {
        var c = this.state.connection;
        c.type = type;
        c.subtype = subtype;
        
        switch(subtype)
        {
            case "rest":
                c.parameters["Base URL"] = "";
                c.parameters["dataformat"] = "";
                break;
            case "graphql":
                c.parameters["Base URL"] = "";
                break;
            case "mongo":
                c.parameters["cluster"] = "";
                c.parameters["authorizer"] = "";
                break;
            case "mysql":
                c.parameters["server"] = "";
                c.parameters["port"] = "";
                c.parameters["authorizer"] = "";
                break;
            case "graylog":
                c.parameters["facility"] = "";
                c.parameters["server"] = "";
                c.parameters["port"] = "";
                break;
            case "s3":
                c.parameters["accesskey"] = "";
                c.parameters["secretaccesskey"] = "";
                c.parameters["region"] = "";
                c.parameters["authorizer"] = "";
                c.parameters["bucket"] = "";
                break;
            }


        this.setState({connection: c, shouldSave: true});
      }

      doSave(r: IConnection)
      {
          console.log("doSave");
          if(this.props.onChange == null)     return;
  
          this.props.onChange(r);
      }
  
    handleChange(event: any)
    {
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var conn : IConnection = {...this.state.connection};
        switch(name)
        {
            case "name":
                conn.name = value;
                break;
            case "description":
                conn.description = value;
                break;
            case "testDB":
                this.setState({testDB: value});
                return;
        }
        this.setState({connection: conn, shouldSave: true});
    }


    handleComboChange(ev: any,option: any,name: string)
    {
        var conn : IConnection = {...this.state.connection};

        conn.parameters[name] = option.key;
        this.setState({connection: conn, shouldSave: true});
    }


    handleParamChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        var conn : IConnection = {...this.state.connection};
        conn.parameters[name] = value;

        this.setState({connection: conn, shouldSave: true});
    }

    componentDidMount()
    {
      this.refresh();
    }

    onSave()
    {
        var s = this.state.connection;
        if(this.props.onChange != null)
        {
            this.props.onChange(s);
        }        
        this.setState({ shouldSave: false });
    }


    refresh()
    {

    }

    addAuthorisorOptions(): IDropdownOption[]{
        var options: IDropdownOption[] = [];
        options.push({key: "", text: "None"}); 

        options.push(...this.props.authConnections.map(c => {   
            let rObj: IDropdownOption = { key: c.id, text: c.name}
            return rObj
        }));
                             
        return options;
    }


    render() 
    {
        if(this.state.connection == null) return <>Select a connection from the list...</>;
        if(this.state.connection.id == null) return <>Select a connection from the list...</>;
        
        var parameters = <></>;

        console.log(this.state.connection);

        const connectionType: IDropdownOption[] = [
            { key: 'Header', text: 'API', itemType: DropdownMenuItemType.Header },
            { key: "rest", text: 'HTTP REST', data: { icon: 'rest.svg', type: "api", subtype: "rest" },  },
            { key: "graphql", text: 'Graph QL', data: { icon: 'rest.svg', type: "api", subtype: "graphql" },  },
            { key: "server", text: 'Generic Server', data: { icon: 'rest.svg', type: "api", subtype: "server" },  },
            { key: 'Header-2', text: 'DATABASE', itemType: DropdownMenuItemType.Header },
            { key: "mongo", text: 'Mongo DB', data: { icon: 'mongo.svg', type: "database", subtype: "mongo" } },
            { key: "mysql", text: 'MySQL', data: { icon: 'mysql.svg', type: "database", subtype: "mysql" } },
            { key: "sqlserver", text: 'MS SQL Server', data: { icon: 'sqlserver.svg', type: "database", subtype: "sqlserver" } },
            { key: 'Header-3', text: 'LOGGING', itemType: DropdownMenuItemType.Header },
            { key: "graylog", text: 'Graylog', data: { icon: 'graylog.svg', type: "log", subtype: "graylog" } },
            { key: 'Header-4', text: 'CLOUD STORAGE', itemType: DropdownMenuItemType.Header },
            { key: "s3", text: 'Amazon S3', data: { icon: 's3.svg', type: "cloud", subtype: "s3" } },
          ];


        var p1: string;
        var p2: string;
        var p3: string;
        var p4: string;
        var p5: string;

        var testConnection = <></>;

        if(this.state.connection.id !== "")
        {
            testConnection = <Stack>
                <Separator />
                <Label>Test Connection to database</Label>
                <Stack horizontal tokens={{childrenGap: 10}}>
                    <Label>{this.props.intl.formatMessage({ id: 'connection.database' })}</Label>
                    <TextField value={this.state.testDB} name="testDB" onChange={this.handleChange}/>
                    <PrimaryButton disabled={this.state.testDB === "" || this.state.isTesting} text={this.props.intl.formatMessage({ id: 'connection.test' })} onClick={this.testConnection}/>
                </Stack>
                <Text>Result: {this.state.isTesting ? <Spinner/> : this.state.testResult}</Text>
            </Stack>;
        }



        switch(this.state.connection.subtype)
        {
            case "rest":
                p1 = this.state.connection.parameters["Base URL"] ?? "";
                p2 = this.state.connection.parameters["dataformat"] ?? "";
                parameters = 
                    <Stack tokens={{childrenGap: 5}}>
                        <TextField prefix="https://" label={this.props.intl.formatMessage({ id: 'connection.endpoint' })} value={p1} name="Base URL" onChange={this.handleParamChange}/>
                        <Dropdown
                            placeholder="Select data format..."
                            options={[
                                {key: "JSON", text: "JSON" },
                                {key: "XML", text: "XML" },
                            ]}
                            selectedKey={p2}
                            onChange={(e,o) => this.handleComboChange(e,o,"dataformat")}
                        />
                    </Stack>

                break;
            case "graphql":
                p1 = this.state.connection.parameters["Base URL"] ?? "";
                parameters = 
                    <Stack tokens={{childrenGap: 5}}>
                        <TextField prefix="https://" label={this.props.intl.formatMessage({ id: 'connection.endpoint' })} value={p1} name="Base URL" onChange={this.handleParamChange}/>
                    </Stack>

                break;
            case "server":
                p1 = this.state.connection.parameters["Base URL"] ?? "";
                p2 = this.state.connection.parameters["port"] ?? "";
                parameters = 
                    <Stack tokens={{childrenGap: 5}}>
                        <TextField label={this.props.intl.formatMessage({ id: 'connection.server' })} value={p1} name="Base URL" onChange={this.handleParamChange}/>
                        <TextField label={this.props.intl.formatMessage({ id: 'connection.port' })} value={p2} name="port" onChange={this.handleParamChange}/>
                    </Stack>

                break;
            case "graylog":
                p1 = this.state.connection.parameters["facility"] ?? "";
                p2 = this.state.connection.parameters["server"] ?? "";
                parameters = 
                    <Stack tokens={{childrenGap: 5}}>
                        <TextField label={this.props.intl.formatMessage({ id: 'connection.graylog.facility' })} value={p1} name="facility" onChange={this.handleParamChange}/>
                        <TextField label={this.props.intl.formatMessage({ id: 'connection.graylog.server' })} value={p2} name="server" onChange={this.handleParamChange}/>
                    </Stack>

                break;
            case "mysql":
                p1 = this.state.connection.parameters["server"] ?? "";
                p2 = this.state.connection.parameters["port"] ?? "";
                p3 = this.state.connection.parameters["authorizer"] ?? "";
                parameters = 
                    <Stack tokens={{childrenGap: 5}}>
                        <TextField label={this.props.intl.formatMessage({ id: 'connection.mysql.server' })} value={p1} name="server" onChange={this.handleParamChange}/>
                        <TextField label={this.props.intl.formatMessage({ id: 'connection.mysql.port' })} value={p2} name="port" onChange={this.handleParamChange}/>
                        <Label>Authorizor</Label>
                        <Dropdown
                                placeholder="None"
                                options={this.addAuthorisorOptions()}
                                selectedKey={p3}
                                onChange={(e,o) => this.handleComboChange(e,o,"authorizer")}
                            />
                        {testConnection}                        
                    </Stack>
                break;
            case "sqlserver":
                p1 = this.state.connection.parameters["server"] ?? "";
                p2 = this.state.connection.parameters["port"] ?? "";
                p3 = this.state.connection.parameters["authorizer"] ?? "";
                parameters = 
                    <Stack tokens={{childrenGap: 5}}>
                        <TextField label={this.props.intl.formatMessage({ id: 'connection.mssql.server' })} value={p1} name="server" onChange={this.handleParamChange}/>
                        <TextField label={this.props.intl.formatMessage({ id: 'connection.mssql.port' })} value={p2} name="port" onChange={this.handleParamChange}/>
                        <Label>Authorizor</Label>
                        <Dropdown
                                placeholder="None"
                                options={this.addAuthorisorOptions()}
                                selectedKey={p3}
                                onChange={(e,o) => this.handleComboChange(e,o,"authorizer")}
                            />
                        {testConnection}                        
                    </Stack>
                break;
            case "mongo":
                p1 = this.state.connection.parameters["cluster"] ?? "";
                p3 = this.state.connection.parameters["authorizer"] ?? "";
                parameters = 
                    <Stack tokens={{childrenGap: 5}}>
                        <TextField label={this.props.intl.formatMessage({ id: 'connection.mongo.cluster' })} value={p1} name="cluster" onChange={this.handleParamChange}/>
                        <Label>Authorizor</Label>
                        <Dropdown
                                placeholder="None"
                                options={this.addAuthorisorOptions()}
                                selectedKey={p3}
                                onChange={(e,o) => this.handleComboChange(e,o,"authorizer")}
                            />                        
                        {testConnection}                        
                    </Stack>
                break;
            case "s3":
                p1 = this.state.connection.parameters["accesskey"] ?? "";
                p2 = this.state.connection.parameters["secretaccesskey"] ?? "";
                p3 = this.state.connection.parameters["region"] ?? "";
                p4 = this.state.connection.parameters["bucket"] ?? "";
                p5 = this.state.connection.parameters["authorizer"] ?? "";
                parameters = 
                    <Stack tokens={{childrenGap: 5}}>
                        <TextField label={this.props.intl.formatMessage({ id: 'connection.s3.accesskey' })} value={p1} name="accesskey" onChange={this.handleParamChange}/>
                        <TextField label={this.props.intl.formatMessage({ id: 'connection.s3.secretaccesskey' })} value={p2} name="secretaccesskey" onChange={this.handleParamChange}/>
                        <TextField label={this.props.intl.formatMessage({ id: 'connection.s3.region' })} value={p3} name="region" onChange={this.handleParamChange}/>
                        <TextField label={this.props.intl.formatMessage({ id: 'connection.s3.bucket' })} value={p4} name="bucket" onChange={this.handleParamChange}/>
                        <Label>Authorizor</Label>
                        <Dropdown
                                placeholder="None"
                                options={this.addAuthorisorOptions()}
                                selectedKey={p5}
                                onChange={(e,o) => this.handleComboChange(e,o,"authorizer")}
                            />                        
                    </Stack>

                break;
    
            // default:
            //     parameters = 
            //         <Stack>
            //             {this.state.connection.parameters.map(p => <TextField label={p.key} value={p.value} name={p.key} onChange={this.handleParamChange}/>)}
            //         </Stack>
    
            //     break;
        }



        const onRenderOption = (option: any | undefined): JSX.Element => 
        {
            if(option == null)  return<></>;
            return (
                <Stack horizontal  tokens={{childrenGap: 5}}>
                   {option.data && option.data.icon && (
                <Image src={"/icons/operations/" + option.data.icon} width={24} height={24}  className="list-icon" />
                   )}
                <span>{option.text}</span>
              </Stack>
            );
        }


        return (
            <>
            <Stack grow verticalFill style={{padding: 10}} tokens={{childrenGap: 10}}>
                <Stack horizontal tokens={{ childrenGap: 5}} >
                    <PrimaryButton iconProps={{ iconName: "Save" }}  text={this.props.intl.formatMessage({ id: 'std.save' })} disabled={!this.state.shouldSave} onClick={()=>{this.onSave();}}/>
                    <DefaultButton iconProps={{ iconName: "FabricMovetoFolder" }} text="Move to folder" onClick={()=>{this.setState({doMoveFolder: true});}}/>
                </Stack>
                <TextField label={this.props.intl.formatMessage({ id: 'rule.name' })} value={this.state.connection.name}  name="name" onChange={this.handleChange}/>
                <TextField multiline label={this.props.intl.formatMessage({ id: 'rule.description' })} value={this.state.connection.description}  name="description" onChange={this.handleChange}/>
                <Dropdown
                    placeholder="Select a connection type"
                    label="Connection Type"
                    ariaLabel="Connection Type"
                    options={connectionType}
                    onRenderOption={onRenderOption}
                    selectedKey={this.state.connection.subtype}
                    onChange={(ev: any,option?: any) => {this.setSubtype(option.data.type, option.data.subtype);}}
                    />
                {parameters}
            </Stack>
            <FolderPicker client={this.props.client} isOpen={this.state.doMoveFolder} onDismiss={()=>{this.setState({doMoveFolder: false});}} onFolderSelected={(folder:string)=>{
                        var conn : IConnection = {...this.state.connection};
                        conn.folderID = folder;
                        this.setState({connection: conn, shouldSave: true, doMoveFolder: false});
            }}/>
            </>
        );
    }
}

export default injectIntl(ConnectionProperties);