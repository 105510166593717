import 'rc-time-picker/assets/index.css';

import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { IKey, 
         } from './IDeus';

import {  
    Stack, 
    TextField, 
    PrimaryButton, 
    Dropdown,
    IDropdownOption,
    Dialog,
    DialogFooter,
    DefaultButton,
    DialogType,
} from '@fluentui/react';


import Folders from './Folders';


type IState = {
    key: IKey
    shouldSave: boolean
    doMoveFolder: boolean
    destinationFolder: string    
    dataerror: string,

}
  
  
interface IProps extends WrappedComponentProps {
    encryptionKey: IKey,
    client: string,
    onChange?: any,
    onMoveFolder?: any,
}



class KeyProperties extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            key: this.props.encryptionKey,
            shouldSave: false,
            doMoveFolder: false,
            destinationFolder: "",
            dataerror: "",
        };

        this.handleChange = this.handleChange.bind(this);

        this.onSave = this.onSave.bind(this);
        this.onMoveFolder = this.onMoveFolder.bind(this);
        this.onSave = this.onSave.bind(this);
    }


    componentDidUpdate(prevProps: IProps) {
        if (prevProps.encryptionKey !== this.props.encryptionKey) {
            this.setState({
                key: this.props.encryptionKey,
                shouldSave: false
            });
          }
    }


    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var template : IKey = {...this.state.key};
        switch(name)
        {
            case "name":
                template.name = value;
                break;
            case "description":
                template.description = value;
                break;
        }
        this.setState({key: template, shouldSave: true});
    }

    onError(error: any) {
        console.error(error);
      }

    onMoveFolder()
    {
        var key : IKey = {...this.state.key};
        key.folderID = this.state.destinationFolder;
        this.setState({key: key, shouldSave: true});
        if(this.props.onMoveFolder != null)
        {
            this.props.onMoveFolder(key);
        }        
    }

    onSave()
    {
        var s = this.state.key;


        if(this.props.onChange != null)
        {
            this.props.onChange(s);
        }        
        this.setState({ shouldSave: false });
    }


    render() 
    {
       
          
        if(this.state.key == null) return <>Select a key from the list...</>;
        if(this.state.key.id == null) return <>Select a key from the list...</>;

     

        const keyTypes: IDropdownOption[] = [
            { key: "SYMETRIC", text: 'Symetric', data: { icon: 'database.svg' } },
            { key: "ASYMETRIC", text: 'Asymetric (Public/Private)', data: { icon: 'database.svg' } },
            { key: "RSA", text: 'RSA Key Storage', data: { icon: 'database.svg' } },
          ];

        var key = <></>;
        switch(this.state.key.type)
        {
            case "SYMETRIC":
                key = <TextField label="Public Key" readOnly multiline rows={10} value={this.state.key.publicKey ?? ""}   />
                break;
            case "RSA":
                key = <> 
                    <TextField label="RSA Key" multiline rows={10} value={this.state.key.publicKey ?? ""}   />
                    <TextField label="Pass phrase" value={this.state.key.publicKey ?? ""}   />
                    <Stack horizontal style={{paddingTop: 10}}>
                        <PrimaryButton iconProps={{ iconName: "Save" }}  text="Generate"  onClick={()=>{}}/>
                    </Stack>
                </>
                break;
        }
        
        return (
            <>
            <Stack grow verticalFill style={{padding: 10, width: "100%"}} >
                <Stack style={{ paddingBottom: 10}}>
                    <Stack horizontal tokens={{ childrenGap: 5}} >
                        <PrimaryButton iconProps={{ iconName: "Save" }}  text={this.props.intl.formatMessage({ id: 'std.save' })} disabled={!this.state.shouldSave} onClick={()=>{this.onSave();}}/>
                        <DefaultButton iconProps={{ iconName: "FabricMovetoFolder" }} text="Move To Folder" onClick={()=>{this.setState({doMoveFolder: true});}}/>
                    </Stack>
                    <TextField label={this.props.intl.formatMessage({ id: 'schedule.name' })} value={this.state.key.name}  name="name" onChange={this.handleChange}/>
                    <TextField label={this.props.intl.formatMessage({ id: 'schedule.description' })} multiline value={this.state.key.description}  name="description" onChange={this.handleChange}/>
                </Stack>
                <Dropdown
                    placeholder="Select key type"
                    label="Key Type"
                    disabled={(this.state.key.id ?? "" ) !== "" }
                    ariaLabel="Key Type"
                    options={keyTypes}
                    selectedKey={this.state.key.type || ""}
                    onChange={(ev: any,option?: any) => {
                        var sched : IKey = JSON.parse(JSON.stringify(this.state.key));
                        sched.type = option.key;
                        this.setState({key: sched, shouldSave: true});                    }}
                    />
                    {key}
            </Stack>

            <Dialog
                 hidden={!this.state.doMoveFolder}
                 onDismiss={()=>{this.setState({doMoveFolder: false})}}
                 dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Move Folder',
                    closeButtonAriaLabel: 'Close',
                    subText: 'Choose which folder you want to move this ruleset to',
                  }}
            >
                <Folders 
                    clientid={this.props.client} 
                    onFolderSelected={(folder: string) => this.setState({destinationFolder: folder})}  
                />

                 <DialogFooter>
                   <PrimaryButton onClick={()=>{this.onMoveFolder()}} text="Move" />
                   <DefaultButton onClick={()=>{this.setState({doMoveFolder: false})}} text="Cancel" />
                 </DialogFooter>
            </Dialog>    
            </>
        );
    }
}

export default injectIntl(KeyProperties);