import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  IDataDefinition, IField, uuid,   } from './IDeus';

import {  Get, Post, Put, Delete } from './authorisation/AMAPI';

import {  
    Stack, 
    PrimaryButton, DefaultButton,  List, Dialog, DialogFooter, DialogType, TextField, 
} from '@fluentui/react';
import { Separator } from '@fluentui/react';

import DDLField from './DDLField'; 
import DDLFieldProperties from './DDLFieldProperties';




type IState = {
    dataDefinition: IDataDefinition,

    selectedField: IField,


    showDialog: boolean,
    doDelete: boolean,
    doImport: boolean,
    doCopy: boolean,
    doExtractObject: boolean,
    extractObjectID: string,
    extractObjectName: string,

    showCalled: boolean,

    actionID: string,

    copyName: string,
    importSchedule?: IDataDefinition,
}
  
  
interface IProps extends WrappedComponentProps {
  dataDefinition: string,
  client: string,
}


class DDL extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            dataDefinition: {} as IDataDefinition,

            selectedField: {} as IField,

            showDialog: false,
            doDelete: false,
            doImport: false,
            doCopy: false,
            doExtractObject: false,
            extractObjectID: "",
            extractObjectName: "",

            showCalled: false,

            actionID: "",

            copyName: "",


        };

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onDeleteField = this.onDeleteField.bind(this);
        this.onAddChild = this.onAddChild.bind(this);
        this.onCopy = this.onCopy.bind(this);
        this.onSave = this.onSave.bind(this);
        this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
        this.onAddField = this.onAddField.bind(this);
        this.onExtractObject = this.onExtractObject.bind(this);
        this.setSelectedField = this.setSelectedField.bind(this);
        this.findSelectedField = this.findSelectedField.bind(this);
    }

    componentDidUpdate(prevProps: IProps) {
      if (prevProps.dataDefinition !== this.props.dataDefinition) {
          this.authenticatedrefresh();
      }
    }

// picker    
  handleFieldChange(event: any){
    const target = event.target;
    const value : string = target.type === 'checkbox' ? target.checked : target.value;
    const name : string = target.name;


    switch(name)
    {
        case "copyName":
            this.setState({copyName: value});
            break;
        case "extractname":
          this.setState({extractObjectName: value});
          break;
      }
  }

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var r = JSON.parse(JSON.stringify(this.state.dataDefinition));
        switch(name)
        {
            case "name":
                r.name = value;
                break;
            case "description":
                r.description = value;
                break;
            case "opParameter":
                r.opParameter = value;
                break;
        }
        this.setState({dataDefinition: r});
    }

    onAddField()
    {
      var r: IDataDefinition = JSON.parse(JSON.stringify(this.state.dataDefinition));
      var newfield: IField = {
        id: uuid(), 
        name: "New Field", 
        description: "", 
        type: 0, 
        length: 0,
        precision: 0,
        arrayType: 0,
        objectID: "",
        primaryKey: false,
        required: false,
        validate: false,
        minimumValue: "",
        maximumValue: "",
        allowableValues: [],
        caseSensitive: false,
        regExValidation: "",
        builtInValidation: "",
        children: []
      } as IField;

      if(r.fields == null)
      {
        r.fields = [newfield];
      }
      else
      {
        r.fields.push(newfield as IField);
      }
      this.setState({dataDefinition: r, selectedField: newfield});

    }

    setField(src: IField)
    {
      var r: IDataDefinition = JSON.parse(JSON.stringify(this.state.dataDefinition));
      this.setFieldValue(src,r.fields);
      this.setState({dataDefinition: r});
    }

    setFieldValue(src: IField, root: IField[]): boolean
    {
        console.log("setField:",src);
        var i: number;
        for(i = 0; i < root.length;i++)
        {
          if(root[i].id === src.id)
          {
            root[i] = src;
              // root[i].name = src.name;
              // root[i].description = src.description;
              console.log("setField Setting:",root[i]);
              return true;
          }   
          if(this.setFieldValue(src,root[i].children)) return true;
        }

        return false;
    }

    setSelectedField(id: string)
    {
      var i = 0;
      console.log("setSelectedField:",id);
      for(i=0;i<this.state.dataDefinition.fields.length;i++)
      {
          if(this.findSelectedField(this.state.dataDefinition.fields[i],id) )  break;
      }

    }

    findSelectedField(r: IField, id: string)
    {
        if(r.id === id)
        {
            this.setState({selectedField: r});
            return true;
        }
        var i: number;
        for(i = 0; i <r.children.length;i++)
        {
            if(this.findSelectedField(r.children[i],id))      return true;
        }
        return false;
    }

    componentDidMount()
    {
      this.authenticatedrefresh();
    }


    showDialog(context: string, item: any)
    {
        this.setState({showDialog: true, })
    }




    private authenticatedrefresh()
    {
      if((this.props.dataDefinition ?? "") === "") return;

      Get("datadefinition/" + this.props.dataDefinition)
        .then(response => {
              this.setState({ 
                dataDefinition: response.data, 
              });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    onSave()
    {
        if(this.state.dataDefinition.id === "")
        {
            Post("datadefinition",this.state.dataDefinition)
            .then(response => {
                  var d = response.data;
                  this.setState({ 
                    dataDefinition: d, 
                  });
                  this.authenticatedrefresh();
                })
            .catch(function (error) {
              console.log(error);
            });
        }
        else
        {
            Put("datadefinition/" + this.state.dataDefinition.id,this.state.dataDefinition)
            .then(response => {
              this.authenticatedrefresh();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
    }

    public onDeleteField(id:string)
    {
      var r: IDataDefinition = JSON.parse(JSON.stringify(this.state.dataDefinition));
      
      var i: number;
      for(i = 0; i < r.fields.length;i++)
      {
        if(r.fields[i].id === id)
        {
          r.fields.splice(i,1);
          this.setState({dataDefinition: r});
          return;
        }
        if(this.onDeleteChild(id,r.fields[i])) 
        {
          this.setState({dataDefinition: r});
          return;
        }
      }
    }

    public onDeleteChild(id:string,field: IField): boolean
    {
      var i: number;
      for(i = 0; i < field.children.length;i++)
      {
        if(field.children[i].id === id)
        {
          field.children.splice(i,1);
          return true;
        }
        if(this.onDeleteChild(id,field.children[i])) return true;
      }
      return false;
    }

    public onAddChild(id:string)
    {
      var r: IDataDefinition = JSON.parse(JSON.stringify(this.state.dataDefinition));

      console.log("onAddChild:",id);

      var i: number;
      for(i = 0; i < r.fields.length;i++)
      {
        if(r.fields[i].id === id)
        {
          r.fields[i].children.push({ 
            name: "New Field",
            description: "",
            type: 0,
            length: 0,
            precision: 0,
            arrayType: 0,
            objectID: "",
            primaryKey: false,
            required: false,
            validate: false,
            minimumValue: "",
            maximumValue: "",
            allowableValues: [],
            caseSensitive: false,
            regExValidation: "",
            builtInValidation: "",
            children: [],
            id: uuid()
          });
          this.setState({dataDefinition: r});
          return;
        }
        if(this.onAddChildField(id,r.fields[i]))
        {
          this.setState({dataDefinition: r});
          return;
        }
      }
    }

    public onAddChildField(id:string,field: IField): boolean
    {
      console.log("onAddChildField:",id);


      var i: number;
      for(i = 0; i < field.children.length;i++)
      {
        if(field.children[i].id === id)
        {
          field.children[i].children.push({ 
            name: "New Field",
            description: "",
            type: 0,
            length: 0,
            precision: 0,
            arrayType: 0,
            objectID: "",
            primaryKey: false,
            required: false,
            validate: false,
            minimumValue: "",
            maximumValue: "",
            allowableValues: [],
            caseSensitive: false,
            regExValidation: "",
            builtInValidation: "",
            children: [],
            id: uuid()
          });
          return true;
        }
        if(this.onAddChildField(id,field.children[i])) return true;
      }
      return false;
    }

    
    findField(id: string, root: IField): IField | null
    {
        if(root.id === id)
        {
            return root;
        }   

        var i: number;
        var f: IField | null;
        for(i = 0; i < root.children.length;i++)
        {
            f = this.findField(id,root.children[i]);
            if( f!== null)  return f;
        }
        return null;
    }

    findParentField(id: string, root: IField): IField | null
    {
        if(root.id === id)
        {
            return null;
        }   

        var i: number;
        var f: IField | null;
        for(i = 0; i < root.children.length;i++)
        {
            if( root.children[i].id === id)  return root;
        }
        // Ok - Not this rule, so try children
        for(i = 0; i < root.children.length;i++)
        {
            f = this.findParentField(id,root.children[i]);
            if( f!== null)  return f;
        }

        return null;
    }



    public onDelete()
      {
        Delete("datadefinition/" + this.state.actionID)
        .then(response => {
          this.setState({doDelete: false});
          this.authenticatedrefresh();
        })
        .catch(function (error) {
          console.log(error);
        });
    }


    public onCopy()
    {
      const post = {
        id: this.state.actionID,
        newname: this.state.copyName,
      }

      Post("datadefinition/Copy",post)
        .then(response => {
          this.setState({doCopy: false});
          this.authenticatedrefresh();
          })
        .catch(function (error) {
          console.log(error);
        });
    }

    
    onExtractObject()
    {
      const post = {
        name: this.state.extractObjectName,
        objectid: this.state.extractObjectID,
        ddlid: this.state.dataDefinition.id,
      }

      Post("datadefinition/extractobject",post)
        .then(response => {
          this.setState({doExtractObject: false});
          this.authenticatedrefresh();
          })
        .catch(function (error) {
          console.log(error);
        });
    }

    render() 
    {
      if((this.props.dataDefinition ?? "") === "") return <></>;

        var props = <></>;
        if(this.state.selectedField !== null)
        {
            props = <DDLFieldProperties client={this.props.client} field={this.state.selectedField} onChange={(f: IField)=>this.setField(f)}   />;
        }

        const onRenderCell =(item: any, index: number | undefined) => {
          return (
            <div>
              <DDLField 
                        onSelected={(id:string)=>this.setSelectedField(id)} 
                        field={item} 
                        key={uuid()} 
                        onChange={this.handleFieldChange} 
                        onDelete={this.onDeleteField} 
                        onAddChild={this.onAddChild}
                        onExtractObject={(id: string) => {
                          this.setState({extractObjectID: id, doExtractObject: true});
                        }}
                        selected={this.state.selectedField?.id === item.id}
                        />

            </div>
          );
        };

        // {(this.state.dataDefinition.fields == null) ? "No Fields" : this.state.dataDefinition.fields.map((field: IField) => {
        //   return <DDLField 
        //     onSelected={(id:string)=>this.setSelectedField(id)} 
        //     field={field} 
        //     key={uuid()} 
        //     onChange={this.handleFieldChange} 
        //     onDelete={this.onDeleteField} 
        //     onAddChild={this.onAddChild}
        //     />
        // })}


        return (
          <>
            <Stack horizontal grow verticalFill>
                <Stack grow={3} style={{paddingTop: 5}} >
                  <Stack horizontal tokens={{childrenGap: 5}} style={{paddingBottom: 5}}>
                    <PrimaryButton  text="Save" onClick={()=>this.onSave()} />
                    <DefaultButton  text="New Field" onClick={this.onAddField} />
                    <h3  style={{paddingLeft: 20}}>{this.state.dataDefinition.name}</h3>
                  </Stack>
                  <Stack verticalFill >
                  <List
                    style={{overflowY: "auto", height: "100%"}}
                    items={this.state.dataDefinition.fields}
                    onRenderCell={onRenderCell}
                  />
                  </Stack>
                </Stack>
                <Separator vertical />
                <Stack grow={2} style={{width: 400, minWidth: 400}} verticalFill>
                    {props}
                </Stack>
            </Stack>

            <Dialog
                 hidden={!this.state.doExtractObject}
                 onDismiss={()=>{this.setState({doExtractObject: false})}}
                 dialogContentProps={{
                    type: DialogType.normal,
                    title: 'Extract Object',
                    closeButtonAriaLabel: 'Close',
                    subText: 'What name would you like to give to the extracted object?',
                  }}
            >
                <TextField label={this.props.intl.formatMessage({ id: 'schedule.name' })} value={this.state.extractObjectName}  name="extractname" onChange={this.handleFieldChange}/>

                 <DialogFooter>
                   <PrimaryButton onClick={()=>{this.onExtractObject()}} text="Extract" />
                   <DefaultButton onClick={()=>{this.setState({doExtractObject: false})}} text="Cancel" />
                 </DialogFooter>
            </Dialog>    

        </>        
        );
    }

   
}

export default injectIntl(DDL);