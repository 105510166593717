import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import { IFolderSet, IFolder, uuid, } from './IDeus';
import { Get, Put,  } from './authorisation/AMAPI';

import {  
    Stack,  CommandBar, ICommandBarItemProps, Dialog, DialogType, DialogFooter, DefaultButton, PrimaryButton, TextField,
} from '@fluentui/react';

import Tree, { TreeNode } from 'rc-tree';

type IState = {
    folders: IFolderSet,
    selectedFolder: string,
    selectedPath: string,
    doAdd: boolean,
    newFolder: string,
    createInRoot: boolean,
}
  
  
interface IProps extends WrappedComponentProps {
    clientid: string,
    onFolderSelected: (folder: string, path: string) => void,
    manage?: boolean,
    style?: any,
}


class Folders extends Component<IProps,IState> {

    constructor(props: any) {
        super(props);

        this.state = {
            folders: {} as IFolderSet,
            selectedFolder: "Uncategorized",
            selectedPath: "/Uncategorized",
            doAdd: false,
            newFolder: "",
            createInRoot: false,
        }; 

        this.refresh = this.refresh.bind(this);
        this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
        this.updateFolders = this.updateFolders.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }


    componentDidUpdate(prevProps: IProps) {
        if (prevProps.clientid !== this.props.clientid) {
          this.authenticatedrefresh();
            }
    }

   
    componentDidMount()
    {
      this.refresh();
    }


    handleChange(event: any){
      const target = event.target;
      const value : string = target.type === 'checkbox' ? target.checked : target.value;
      const name : string = target.name;

      switch(name)
      {
          case "newfolder":
              this.setState({newFolder: value});
              break;
      }
  }


  
    refresh()
    {
        this.authenticatedrefresh();
    }

    private updateFolders()
    {
      if(this.props.clientid == null) return;
      if(this.props.clientid === "") return;
      Put("folders",this.state.folders)
        .then(response => {
        })
        .catch(error => {
          console.log(error);
        });
    }

    private authenticatedrefresh()
    {
      if(this.props.clientid == null) return;
      if(this.props.clientid === "") return;
      Get("folders/" + this.props.clientid )
        .then(response => {
              this.setState({ 
                folders: response.data
              });
        })
        .catch(error => {
          this.setState({
            folders: {} as IFolderSet,
          });
          console.log(error);
        });
    }

    genNodes(folders : IFolder[],currpath: string) : any[]
    {
        if(folders == null) return [];
        return folders.map(folder=>
            {
            if(folder.children == null || folder.children.length  === 0)
            {
                return <TreeNode title={folder.name} key={folder.id} />;
            }
            else 
            {
                  return <TreeNode title={folder.name} key={folder.id}>
                    {this.genNodes(folder.children,currpath + "/" + folder.name)}
                    </TreeNode>;
            }
        })
    }

  
    findpath(folders : IFolder[],id: string,currpath: string): string
    {
      if(folders == null) return "";
      for(var i = 0; i<folders.length; i++)
      {
        if(folders[i].id === id) return currpath + "/" + folders[i].name;
        if(folders[i].children != null && folders[i].children.length > 0)
        {
          var path = this.findpath(folders[i].children,id,currpath + "/" + folders[i].name);
          if(path !== "") return path;
        }
      }
      return "";
    }

    addFolder(folders : IFolder[],id: string,newname: string): boolean
    {
      if(folders == null) return false;
      for(var i = 0; i<folders.length; i++)
      {
        if(folders[i].id === id) 
        {
          folders[i].children.push({
            id: uuid(), 
            name: newname, 
            children: [], 
            apiSpecification: {
              version: "1.0.0",
              title: "",
              description: "",
            }
          });
          return true;
        }
        if(folders[i].children != null && folders[i].children.length > 0)
        {
          var ret = this.addFolder(folders[i].children,id,newname);
          if(ret) return true;
        }
      }
      return false;
    }

    render() 
    {
        if(this.state.folders == null)  
        {
            return (         
            <Stack grow verticalFill style={{width: 300}}>
                Loading...
            </Stack>
            )
        }

        const _items: ICommandBarItemProps[] = [
            {
              key: 'newItem',
              text: 'New',
              iconProps: { iconName: 'Add' },
              ariaLabel: 'New',
              disabled: (this.state.selectedFolder.length === 0),
              onClick: () => this.setState({doAdd: true, newFolder: "", createInRoot: false}),
            },
            {
              key: 'delete',
              text: 'Delete',
              iconProps: { iconName: 'Delete' },
              ariaLabel: 'Delete',
              disabled: false,
            },
          ];
          

        var nodes = this.genNodes(this.state.folders.folders,"/");
       
          
        var manage = <></>;
        var dialog = <></>;
        if(this.props.manage)
        {
          manage = <CommandBar
                      items={_items}
                      ariaLabel="Use left and right arrow keys to navigate between commands"
                      />        
          dialog = <Dialog
                      hidden={!this.state.doAdd}
                      onDismiss={()=>{this.setState({doAdd: false})}}
                      minWidth={300}
                      maxWidth={300}
                      dialogContentProps={{
                          type: DialogType.normal,
                          title: 'New Folder',
                          closeButtonAriaLabel: 'Close',
                          subText: 'Enter the name for your new folder.',
                        }}
                    >
                      <Stack tokens={{childrenGap:10}}>
                      <TextField prefix={(this.state.createInRoot ? "" : this.state.selectedPath) + "/"} label={this.props.intl.formatMessage({ id: 'rule.description' })} value={this.state.newFolder}  name="newfolder" onChange={this.handleChange}/>
                      <DefaultButton onClick={()=>{this.setState({createInRoot: !this.state.createInRoot})}} text="Create in root" />
                      </Stack>
                      <DialogFooter>
                        <PrimaryButton 
                          disabled={this.state.newFolder === ""} 
                          onClick={()=>{
                            var folders = { ...this.state.folders };
                            if(this.state.createInRoot)
                            {
                              folders.folders.push({
                                id: uuid(), 
                                name: this.state.newFolder, 
                                children: [], 
                                apiSpecification: {
                                  version: "1.0.0",
                                  title: "",
                                  description: "",
                                }
                              });
                              this.setState({folders: folders, doAdd: false},()=>{
                                this.updateFolders();
                              });
                            }
                            else
                            {
                              if(this.addFolder(folders.folders,this.state.selectedFolder,this.state.newFolder))
                              {
                                console.log("NEW FOLDERS:",folders)
                                this.setState({folders: folders, doAdd: false},()=>{
                                  this.updateFolders();
                                });
                              }  
                            }
                            this.setState({doAdd: false})}} 
                          text="Create" />
                        <DefaultButton onClick={()=>{this.setState({doAdd: false})}} text="Cancel" />
                      </DialogFooter>
                    </Dialog>

        }

        return (
            <Stack grow verticalFill style={{width: 250}}>
              {manage}

              <Tree
              style={this.props.style}
              showLine
              onSelect={(keys, event) => {
                var id = keys[0].toString();
                
                var path = this.findpath(this.state.folders.folders,id,"");
                this.props.onFolderSelected(id,path);
                this.setState({selectedFolder: id,selectedPath: path});
                }}
              >
                {nodes}
              </Tree>
              {dialog}
            </Stack>
        );
    }
}

export default injectIntl(Folders);