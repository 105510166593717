import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  ISchedule, IPagination, uuid, eScheduleType, eOpCode, libraryClient, rationalizeNumber, eNoExecutionAction } from './IDeus';

import {  Get, Post, Put, Delete } from './authorisation/AMAPI';

import {  
    Stack, Text,
    IconButton, PrimaryButton, DefaultButton, Icon,
    DetailsList, IColumn, SelectionMode, 
    TextField, 
    Dialog, DialogFooter, 
    DialogType, IDetailsListProps, IDetailsRowStyles, DetailsRow, Modal, Pivot, PivotItem, Panel,  PanelType, IContextualMenuProps,   
} from '@fluentui/react';
import { Separator } from '@fluentui/react';

import RulesetProperties from './RulesetProperties' 
import RulesetFinder from './RulesetFinder' 
import OpenAPIProperties from './OpenAPIProperties';
import Editor from './Editor';
import { Container } from 'reactstrap';
import Debugger from './Debugger';
//import Editor from './Editor';


import { Link } from 'react-router-dom';
import RESTSQLGenerate from './RESTSQLGenerate';


type IState = {
    schedules: ISchedule[],
    selectedSchedule: ISchedule,
    propsPanelTitle: string,
    propsPanelOpen: boolean,
    editorPanelOpen: boolean,
    debuggerPanelOpen: boolean,

    pagination: IPagination,
    showDialog: boolean,
    doDelete: boolean,
    doImport: boolean,
    doCopy: boolean,
    showCalled: boolean,

    actionID: string,

    copyName: string,
    importSchedule?: ISchedule,

    doRESTSQLGenerate: boolean,
}
  
  
interface IProps extends WrappedComponentProps {
  client: string,
  history?: any,
  folder: string,  
}


class Schedules extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            schedules: [],
            selectedSchedule: {} as ISchedule,
            propsPanelTitle: "",
            propsPanelOpen: false,
            editorPanelOpen: false,
            debuggerPanelOpen: false,

            pagination: {
                totalCount: 0,
                current: 0,
                pageSize: 20,
                sortField: "",
                sortOrder: "asc"
            },


            showDialog: false,
            doDelete: false,
            doImport: false,
            doCopy: false,

            showCalled: false,

            actionID: "",

            copyName: "",

            doRESTSQLGenerate: false,
        };

        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.createSchedule = this.createSchedule.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onCopy = this.onCopy.bind(this);
        this.onImport = this.onImport.bind(this);
        this.onSave = this.onSave.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
    }

    componentDidUpdate(prevProps: IProps) {
      if (prevProps.client !== this.props.client || prevProps.folder !== this.props.folder) {
          this.authenticatedrefresh();
      }
    }

// picker    
  handleFieldChange(event: any){
    const target = event.target;
    const value : string = target.type === 'checkbox' ? target.checked : target.value;
    const name : string = target.name;


    switch(name)
    {
        case "copyName":
            this.setState({copyName: value});
            break;
    }
  }

    handleChange(event: any){
        const target = event.target;
        const value : string = target.type === 'checkbox' ? target.checked : target.value;
        const name : string = target.name;

        var r = JSON.parse(JSON.stringify(this.state.selectedSchedule));
        switch(name)
        {
            case "name":
                r.name = value;
                break;
            case "description":
                r.description = value;
                break;
            case "opParameter":
                r.opParameter = value;
                break;
        }
        this.setState({selectedSchedule: r});
    }

    componentDidMount()
    {
      this.refresh();
    }

    createSchedule()
    {
        this.setState({
            selectedSchedule: {
                id: "",
                clientID: this.props.client,
                name: "New Schedule",
                description: "",
                externalID: "",
                folderID: this.props.folder,
                logExceptions: false,
                active: false,

                type: eScheduleType.TimedSchedule,
                parameterDDLName: "",

                executeAt: new Date().toISOString(),
                repeatType: 0,
                repeatValueUnits: 0,
                repeatValue: 1,

                logExecution: false,

                currentCallCount: 0,
                currentExecRuleCount: 0,

                externalBytesReceived: 0,
                externalBytesSent: 0,

                rule: {
                    id: uuid(),
                    name: "New Rule",
                    description: "",
                    x: 100,
                    y: 100,

                    validateInputDDLID: "",
                    validateInputDDLName: "",

                    executionType: 1,
                    secondsDelay: 0,

                    checkForExecution: false,
                    executionCheck: "",
                    noExecutionAction: eNoExecutionAction.GoLeft,
    
                    opCode: 2,
                    opParameters: {},

                    onTrue: [],
                    onFalse: [],
                    onFail: []
                }
                

            },
            propsPanelTitle: "Create New Ruleset",
            propsPanelOpen: true,

        });
    }
  
    refresh()
    {
        this.authenticatedrefresh();
    }


    showDialog(context: string, item: any)
    {
      this.setState({showDialog: true, })
    }


    onPageChange(page: number)
    {
      var p: IPagination = Object.assign(this.state.pagination);
      p.current = page;
      this.setState({pagination: p});
      this.authenticatedrefresh();
    }


    private _onColumnClick = (ev: any, column: IColumn): void => {
    };      
   
    private authenticatedrefresh()
    {
      const params = this.state.pagination;

      const paging = {
        current: params.current + 1,
        pagesize: params.pageSize,
        sortfield: params.sortField,
        sortorder: params.sortOrder,
      }

      const post = {
        paging: paging,
        currentFolder: this.props.folder,
        currentClient: this.props.client
      }

      Post("ruleset/Search",post)
        .then(response => {
              var p = this.state.pagination;
              p.totalCount = response.data.totalCount;   
              this.setState({ 
                schedules: response.data.results, 
                pagination: p, 
              });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    onSave(sched: ISchedule)
    {
        if(sched.id === "")
        {
            Post("ruleset",sched)
            .then(response => {
                sched.id = response.data;
                  this.setState({ 
                    selectedSchedule: sched, 
                  });
                  this.authenticatedrefresh();
                })
            .catch(function (error) {
              console.log(error);
            });
        }
        else
        {
            Put("ruleset/" + sched.id,sched)
            .then(response => {
              this.authenticatedrefresh();
            })
            .catch(function (error) {
              console.log(error);
            });
        }
        this.setState({propsPanelOpen: false});
    }


    private _onItemInvoked = (item: ISchedule): void => {
        this.setState({selectedSchedule: item, propsPanelOpen: true, propsPanelTitle: "Ruleset Properties"});
    };

    public onDelete()
      {
        Delete("ruleset/" + this.state.actionID)
        .then(response => {
          this.setState({doDelete: false});
          this.authenticatedrefresh();
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    onStartSched(id: string)
  {
    Get("ruleset/start/" + id)
    .then(response => {
      this.setState({showCalled: true});
      })
    .catch(function (error) {
      console.log(error);
    });
    }

      public onCopy()
      {
        const post = {
          id: this.state.actionID,
          newname: this.state.copyName,
        }
  
        Post("ruleset/Copy",post)
          .then(response => {
            this.setState({doCopy: false});
            this.authenticatedrefresh();
            })
          .catch(function (error) {
            console.log(error);
          });
      }

    public onImport(sched?: ISchedule)
    {
      if(sched==null)
      {
        return;
      }
      var folder = this.props.folder;
      if(folder==="Uncategorized")  folder="";
      const post = {
        id: sched.id,
        newname: sched.name,
        clientid: this.props.client,
        folderid: folder,
      }

      Post("ruleset/import",post)
        .then(response => {
          this.setState({doImport: false, importSchedule: undefined});
          this.authenticatedrefresh();
          })
        .catch(function (error) {
          console.log(error);
        });
    }


    render() 
    {

        var props = <></>;

        if(this.state.selectedSchedule)
        {
            props = <RulesetProperties client={this.props.client} schedule={this.state.selectedSchedule} onChange={this.onSave} onMoveFolder={this.onSave}  />
        }


        const columns: IColumn[] = [
          {
            key: 'ruletype',
            name: 'Type',
            ariaLabel: '',
            isRowHeader: true,
            isResizable: true,
            minWidth: 50,
            maxWidth: 50,
            data: 'string',
            onRender: (item: ISchedule) => {
              var i;
              if(item.type === eScheduleType.TimedSchedule)
              {
                if(item.active)
                {
                  i = <Icon iconName="AlarmClock" style={{color: "#d6a100", fontSize: 20, height: 20, width: 20}}  />
                }
                else {
                  i = <Icon iconName="AlarmClock" style={{color: "#888", fontSize: 20, height: 40, width: 40}}  />
                }
              }
              else {
                if(item.active)
                {
                  i = <Icon iconName="AzureAPIManagement" style={{color: "#d6a100", fontSize: 20, height: 20, width: 20}} />
                }
                else {
                  i = <Icon iconName="AzureAPIManagement" style={{color: "#888", fontSize: 20, height: 40, width: 40}}  />
                }
              }
             return <>{i}</>
            },
          },
          {
              key: 'name',
              name: this.props.intl.formatMessage({ id: 'schedule.name' }),
              fieldName: 'name',
              minWidth: 200,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              onColumnClick: this._onColumnClick,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'externalid',
              name: this.props.intl.formatMessage({ id: 'schedule.externalid' }),
              fieldName: 'externalID',
              minWidth: 0,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              onColumnClick: this._onColumnClick,
              data: 'string',
              isPadded: true,
            },
            {
              key: 'rulecount',
              name: "Rule Execs",
              fieldName: 'currentExecRuleCount',
              minWidth: 0,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              data: 'number',
              isPadded: true,
             },
             {
              key: 'datasize',
              name: "Data Usage",
              minWidth: 0,
              maxWidth: 0,
              currentWidth: 0,
              isRowHeader: true,
              isResizable: true,
              data: 'number',
              isPadded: true,
              onRender: (item: ISchedule) => {
                var val = rationalizeNumber(item.externalBytesReceived + item.externalBytesSent);
                // return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.deleteClient(item.id)} title="Delete" ariaLabel="Delete" disabled={!item.canDelete} />
                return <Text >{val.value} {val.scale}</Text>
               },
             },
             { 
              key: 'rulesetplay',
              name: 'RuleSet',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
                if(!item.active && item.type === eScheduleType.TimedSchedule)
                {
                  return <IconButton iconProps={{ iconName: 'Play' }} onClick={()=>this.onStartSched(item.id)} title="Rules" ariaLabel="Rules"  />
                }
                else
                {
                  return <></>
                }
              },
            },
            {
              key: 'rulesetdebug',
              name: 'RuleSet',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
                if(item?.rule?.opCode !== eOpCode.ExecFileReceive)
                {
                  return <IconButton iconProps={{ iconName: 'Bug' }}  title="Debugger" ariaLabel="Debugger" onClick={()=>{
                    this.setState({selectedSchedule: item, debuggerPanelOpen: true})
                  }} />
                }
                else
                {
                  //return <Link to={'/debugger/'+item.id}><IconButton iconProps={{ iconName: 'Bug' }}  title="Debugger" ariaLabel="Debugger"  /></Link>
                  return <></>
                }
              },
            },
            {
              key: 'rulesetedit',
              name: 'RuleSet',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               return <IconButton iconProps={{ iconName: 'Edit' }} onClick={()=>this.setState({selectedSchedule: item, propsPanelOpen: true, propsPanelTitle: "Ruleset Properties"})} title="Rules" ariaLabel="Rules"  />
              },
            },
            {
              key: 'ruleedit',
              name: 'Rule',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 32,
              maxWidth: 32,
              onRender: (item: any) => {
                return <Link to={'/editor/'+item.id}><IconButton iconProps={{ iconName: 'BranchMerge' }} 
                title="Edit Data Definition" ariaLabel="Edit Data Definition"  /></Link>
          },
            },
            {
              key: 'rulecopy',
              name: 'Duplicate',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               return <IconButton iconProps={{ iconName: 'copy' }} onClick={()=>this.setState({doCopy: true, copyName: "", actionID: item.id})} title="Copy" ariaLabel="Rules"  />
              },
            },
            {
              key: 'delete',
              name: 'Delete',
              ariaLabel: '',
              isIconOnly: true,
              fieldName: 'id',
              minWidth: 16,
              maxWidth: 16,
              onRender: (item: any) => {
               return <IconButton iconProps={{ iconName: 'Delete' }} onClick={()=>this.setState({doDelete: true, actionID: item.id})} title="Delete" ariaLabel="Delete"  />
              },
            },
  
        ];


        const menuProps: IContextualMenuProps = {
          items: [
            {
              key: 'sql',
              text: 'REST From SQL Table',
              iconProps: { iconName: 'Database' },
              onClick: () => {
                this.setState({doRESTSQLGenerate: true})
              }
            },
          ],
        };

       

        return (
          <Stack grow>
            <Stack horizontal grow verticalFill>
              <Pivot style={{width: "100%"}}>
                <PivotItem headerText="Rule sets">
                  <Stack style={{paddingTop: 5}} grow >
                    <Stack horizontal tokens={{childrenGap: 5}}>
                      <PrimaryButton text="Create new rule set" iconProps={{ iconName: 'PageAdd' }} onClick={()=>this.createSchedule()} width={300} disabled={!this.props.client}/>
                      <DefaultButton text="Import" iconProps={{ iconName: 'Import' }} onClick={()=>this.setState({doImport: true})} width={300} disabled={!this.props.client}/>
                      <DefaultButton 
                      text="Generate" 
                      iconProps={{ iconName: 'Settings' }}  
                      menuProps={menuProps}
                      disabled={!this.props.client}
                      />

                    </Stack>
                    <Stack horizontal tokens={{childrenGap: 5}} grow>
                      <DetailsList  items={this.state.schedules} 
                                  columns={columns} 
                                  onItemInvoked={this._onItemInvoked}
                                  onRenderRow={this._onRenderRow}
                                  selectionMode={SelectionMode.none}
                      />
                    </Stack>
                  </Stack>
                </PivotItem>
                <PivotItem headerText="OpenAPI">
                  <OpenAPIProperties client={this.props.client} folder={this.props.folder} />
                </PivotItem>
              </Pivot>
              
              <Panel
                headerText={this.state.propsPanelTitle}
                type={PanelType.medium}
                isOpen={this.state.propsPanelOpen}
                onDismiss={()=>{ this.setState({propsPanelOpen: false})}}
              >
                {props}
              </Panel>
              <Panel
                headerText="Ruleset Editor"
                type={PanelType.smallFluid}
                isOpen={this.state.editorPanelOpen}
                onDismiss={()=>{ this.setState({editorPanelOpen: false})}}
              >
                <Container style={{ paddingLeft: "5px", paddingTop: "5px", paddingRight: "20px", paddingBottom: "40px", maxWidth: "none" }}
                  className="vh-100 d-flex flex-column ">
                  <Editor client={this.props.client} scheduleID={this.state.selectedSchedule.id} />
                </Container>
              </Panel>
              <Panel
                headerText="Ruleset Debugger"
                type={PanelType.smallFluid}
                isOpen={this.state.debuggerPanelOpen}
                onDismiss={()=>{ this.setState({debuggerPanelOpen: false})}}
              >
                <Container style={{ paddingLeft: "5px", paddingTop: "5px", paddingRight: "20px", paddingBottom: "40px", maxWidth: "none" }}
                  className="vh-100 d-flex flex-column ">
                  <Debugger client={this.props.client} scheduleID={this.state.selectedSchedule.id} />
                </Container>
              </Panel>
              {/* <Panel
                headerText="Ruleset Editor"
                type={PanelType.smallFluid}
                isOpen={this.state.editorPanelOpen}
                onDismiss={()=>{ this.setState({editorPanelOpen: false})}}
              >
                <Stack verticalFill>
                <Editor client={this.props.client} scheduleID={this.state.selectedSchedule.id} />
                </Stack>
              </Panel> */}

            </Stack>
            <Dialog
              hidden={!this.state.doDelete}
              onDismiss={()=>{this.setState({doDelete: false})}}
              dialogContentProps={{
                  type: DialogType.normal,
                  title: 'Delete Rule Set',
                  closeButtonAriaLabel: 'Close',
                  subText: 'Are you sure you want to delete this rule set. This will delete all the rules and is non reversible.',
                }}
            >
            <DialogFooter>
              <PrimaryButton onClick={()=>{this.onDelete()}} text="Delete" />
              <DefaultButton onClick={()=>{this.setState({doDelete: false})}} text="Cancel" />
            </DialogFooter>
            </Dialog>
            <Dialog
              hidden={!this.state.doCopy}
              onDismiss={()=>{this.setState({doCopy: false})}}
              dialogContentProps={{
                  type: DialogType.normal,
                  title: 'Copy Rule Set',
                  closeButtonAriaLabel: 'Close',
                  subText: 'Please provide a name for the new rule set.',
                }}
            >
                <TextField label={this.props.intl.formatMessage({ id: 'schedule.name' })} value={this.state.copyName}  name="copyName" onChange={this.handleFieldChange}/>
              
            <DialogFooter>
              <PrimaryButton onClick={()=>{this.onCopy()}} text="Copy" />
              <DefaultButton onClick={()=>{this.setState({doCopy: false})}} text="Cancel" />
            </DialogFooter>
            </Dialog>
            <Dialog
              hidden={!this.state.showCalled}
              onDismiss={()=>{this.setState({showCalled: false})}}
              dialogContentProps={{
                  type: DialogType.normal,
                  title: 'Started',
                  closeButtonAriaLabel: 'Close',
                  subText: 'Rule Set execution started.',
                }}
            >
            <DialogFooter>
              <DefaultButton onClick={()=>{this.setState({showCalled: false})}} text="Close" />
            </DialogFooter>
            </Dialog>
            <Modal
        isOpen={this.state.doImport}
        onDismiss={()=>{this.setState({doImport: false})}}
        isBlocking={false}
      >
        <Stack style={{margin: 10}}>
          <Text variant="xLarge">Import Rule Set</Text>
        <RulesetFinder client={libraryClient} scheduleSelected={(sched: ISchedule) => {this.setState({importSchedule: sched})}}/>
        <Separator/>
            <Stack  tokens={{childrenGap: 10}} >
              <Stack.Item  align="end">
                <Stack horizontal tokens={{childrenGap: 10}}>
              <PrimaryButton text="Import" disabled={this.state.importSchedule == null} onClick={()=>{
                this.onImport(this.state.importSchedule)
              }} />
              <DefaultButton text="Cancel" onClick={()=>{this.setState({doImport: false, importSchedule: undefined})}} />
              </Stack>
              </Stack.Item>
            </Stack>
        </Stack>
        </Modal>

        <RESTSQLGenerate
              isOpen={this.state.doRESTSQLGenerate}
              client={this.props.client}
              folder={this.props.folder}
              onDismiss={()=>{
                this.setState({doRESTSQLGenerate: false});
                this.authenticatedrefresh();
              }}
            />          

        </Stack>        
      );
    }

    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
      const customStyles: Partial<IDetailsRowStyles> = {};
      if (props) {
        if(this.state.selectedSchedule){
          if (props.item.id  === this.state.selectedSchedule.id) {
            // Every other row renders with a different background color
            customStyles.root = { backgroundColor: "#785a00" };
          }
    
          }
          return <DetailsRow {...props} styles={customStyles} />;
      }
      return null;
    };
}

export default injectIntl(Schedules);