import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  ISchedule, IPagination } from './IDeus';

import {  Post, } from './authorisation/AMAPI';

import {  
    Stack, 
    IColumn,
    IDetailsListProps, IDetailsRowStyles, DetailsRow, Label, List, 
} from '@fluentui/react';
import { Separator } from '@fluentui/react';

import Folders from './Folders' 




type IState = {
    schedules: ISchedule[],
    selectedSchedule: ISchedule,

    currentFolder: string

    pagination: IPagination,
    showDialog: boolean,
    doDelete: boolean,
    doCopy: boolean,
    showCalled: boolean,

    actionID: string,

    copyName: string,
}
  
  
interface IProps extends WrappedComponentProps {
  client: string,
  scheduleSelected?: any
}


class ScheduleFinder extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            schedules: [],
            selectedSchedule: {} as ISchedule,

            pagination: {
                totalCount: 0,
                current: 0,
                pageSize: 20,
                sortField: "",
                sortOrder: "asc"
            },

            currentFolder: "Uncategorized",

            showDialog: false,
            doDelete: false,
            doCopy: false,

            showCalled: false,

            actionID: "",

            copyName: "",

        };

        this.onPageChange = this.onPageChange.bind(this);
        this.refresh = this.refresh.bind(this);
        this.onFolderSelected = this.onFolderSelected.bind(this);
        this._onItemInvoked = this._onItemInvoked.bind(this);
        this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
    }

    componentDidUpdate(prevProps: IProps) {
      if (prevProps.client !== this.props.client) {
        this.authenticatedrefresh();
          }
    }

 
    
    componentDidMount()
    {
      this.refresh();
    }

    
  
    refresh()
    {
        this.authenticatedrefresh();
    }


    

    onPageChange(page: number)
    {
      var p: IPagination = Object.assign(this.state.pagination);
      p.current = page;
      this.setState({pagination: p});
      this.authenticatedrefresh();
    }


    private _onColumnClick = (ev: any, column: IColumn): void => {
    };      

    private showMessage(error:boolean, message: string )
    {
    //   if(error)   this.setState({showError: true, message: message})
    //   else        this.setState({showSuccess: true, message: message})
    }


    private authenticatedrefresh()
    {
      const params = this.state.pagination;

      const paging = {
        current: params.current + 1,
        pagesize: params.pageSize,
        sortfield: params.sortField,
        sortorder: params.sortOrder,
      }

      const post = {
        paging: paging,
        currentFolder: this.state.currentFolder,
        currentClient: this.props.client
      }

      Post("ruleset/Search",post)
        .then(response => {
              var p = this.state.pagination;
              p.totalCount = response.data.totalCount;   
              this.setState({ 
                schedules: response.data.results, 
                pagination: p, 
              });
        })
        .catch(function (error) {
          console.log(error);
        });
    }

    


    private _onItemInvoked = (item: ISchedule): void => {
      console.log("ITEM INVOKED")
        this.setState({selectedSchedule: item});
        if(this.props.scheduleSelected != null)
        {
          this.props.scheduleSelected(item);
        }
    };

    
   
      public onFolderSelected(folder: string)
      {
        this.setState({currentFolder: folder},()=>{
          this.authenticatedrefresh();
        });
      }

      render() 
    {

      return (
          <>
          <Stack>
            <Stack horizontal grow verticalFill>
              <Stack grow={2}> 
                <Label>Folders</Label>
                <Folders clientid={this.props.client} onFolderSelected={this.onFolderSelected}  />
              </Stack>
                <Separator vertical />
                <Stack grow={3} style={{paddingTop: 5}} >
                    <List
                            items={this.state.schedules}
                            onRenderCell={(item?: ISchedule, index?: number, isScrolling?: boolean): JSX.Element => {
                                return (
                                  <div data-is-focusable onClick={()=>{
                                    if(item !== undefined)
                                    {
                                      this.setState({selectedSchedule: item});
                                      if(this.props.scheduleSelected != null)
                                      {
                                        this.props.scheduleSelected(item);
                                      }
                                    }
                                    }}>
                                    <div >
                                      {item?.name ?? ""}
                                    </div>
                                  </div>
                                );
                              }
                            }
                            style={{ width: '100%', border: '1px solid #eaeaea', minHeight:200, maxHeight: 200, minWidth:300 }}
                        />

                </Stack>
            </Stack>
          </Stack>
          </>        );
    }

    private _onRenderRow: IDetailsListProps['onRenderRow'] = props => {
      const customStyles: Partial<IDetailsRowStyles> = {};
      if (props) {
        if(this.state.selectedSchedule){
          if (props.item.id  === this.state.selectedSchedule.id) {
            // Every other row renders with a different background color
            customStyles.root = { backgroundColor: "lightskyblue" };
          }
    
          }
          return <DetailsRow {...props} styles={customStyles} />;
      }
      return null;
    };
}

export default injectIntl(ScheduleFinder);