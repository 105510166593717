import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Switch, } from 'react-router-dom';
import { Container } from 'reactstrap';
import 'bootstrap/dist/css/bootstrap.css';
import "rc-tree/assets/index.css";
import './App.css';

import NavBar from './NavBar';
import Rulesets from './Rulesets';
import Connections from './Connections';
import Clients from './Clients';
import Authorizers from './Authorizers';
import DataDefinitions from './DataDefinitions';
import Templates from './Templates';
import Keys from './Keys';
import Packages from './Packages';
import PackageImport from './PackageImport';
import DDL from './DDL';
import Home from './Home';

import { withAuthenticator } from '@aws-amplify/ui-react'
import { IAuthUser } from './IDeus'
import { getUser } from './authorisation/Auth';
import { Separator, Stack, Text, ThemeProvider, PartialTheme } from '@fluentui/react';
import Folders from './Folders';
import Editor from './Editor';

const appTheme: PartialTheme = {
  palette: {
    themePrimary: '#d6a100',
    themeLighterAlt: '#fdfbf3',
    themeLighter: '#f8efd1',
    themeLight: '#f3e1aa',
    themeTertiary: '#e7c45c',
    themeSecondary: '#dbab1a',
    themeDarkAlt: '#c19100',
    themeDark: '#a37a00',
    themeDarker: '#785a00',
    neutralLighterAlt: '#3e3e3e',
    neutralLighter: '#464646',
    neutralLight: '#535353',
    neutralQuaternaryAlt: '#5b5b5b',
    neutralQuaternary: '#616161',
    neutralTertiaryAlt: '#7c7c7c',
    neutralTertiary: '#e0e0e0',
    neutralSecondary: '#e5e5e5',
    neutralPrimaryAlt: '#eaeaea',
    neutralPrimary: '#d1d1d1',
    neutralDark: '#f4f4f4',
    black: '#f9f9f9',
    white: '#363636',
  }};

function App() {

  const [user, updateUser] = useState<IAuthUser | null>(null);
  const [client, updateClient] = useState<string | null>(null);
  const [folder, updateFolder] = useState<string | null>(null);
  const [path, updatePath] = useState<string | null>(null);

  async function checkUser() {
    var user: IAuthUser | null

    try {
      user = await getUser();
      updateUser(user);

      console.log('id Token: ' + JSON.stringify(user?.idToken));
      console.log('access token: ' + JSON.stringify(user?.accessToken));
    }
    catch (ex) {
      user = { username: 'guest', email: '', idToken: '', accessToken: '', tag: '' };
      updateUser(user);
    }

  }

  useEffect(() => { if (user === null) checkUser(); });

  return (
    <ThemeProvider theme={appTheme}>

    <Router>
      <div>
        <NavBar 
          client={client || ""}
          onSetClient={(client: string) => updateClient(client)}
        />
        <Container style={{ paddingLeft: "5px", paddingTop: "60px", paddingRight: "20px", maxWidth: "none" }} className="vh-100 d-flex flex-column ">

          <Switch>
            <Route exact path="/"
                    render={(props) =>
                      <Home />
                    } />
                                      <Route path="/editor/:id"
                      render={(props) => {
                        return <Editor client={client ?? ""} scheduleID={props.match.params.id} />
                      }} />


            <Route>
              <Stack horizontal grow verticalFill>
                <Stack style={{width: 250}}>
                  <Folders manage clientid={client ?? ""} onFolderSelected={(id,path)=>{ updateFolder(id); updatePath(path);}}  />
                </Stack>
                <Separator vertical />
                <Stack grow style={{width: "100%"}}>
                  <Text variant="mediumPlus">{path}</Text>

                  <Route exact path="/Rulesets"
                    render={(props) =>
                      <Rulesets client={client ?? ""} folder={folder ?? ""} />
                    } />

                  <Route exact path="/Connections"
                    render={(props) =>
                      <Connections client={client ?? ""} folder={folder ?? ""}/>
                    } />

                  <Route exact path="/Clients"
                    render={(props) =>
                      <Clients />
                    } />

                  <Route exact path="/Authorizers"
                    render={(props) =>
                      <Authorizers client={client ?? ""} folder={folder ?? ""} />
                    } />

                  <Route exact path="/DDL"
                    render={(props) =>
                      <DataDefinitions client={client ?? ""} folder={folder ?? ""} />
                    } />

                  <Route exact path="/Templates"
                    render={(props) =>
                      <Templates client={client ?? ""} folder={folder ?? ""} />
                    } />

                  <Route exact path="/Keys"
                    render={(props) =>
                      <Keys client={client ?? ""} folder={folder ?? ""} />
                    } />

                  <Route exact path="/Packages"
                    render={(props) =>
                      <Packages client={client ?? ""} folder={folder ?? ""} />
                    } />

                  <Route exact path="/PackageImport"
                    render={(props) =>
                      <PackageImport client={client ?? ""}  />
                    } />

                  <Route path="/ddleditor/:id"
                      render={(props) => {
                        return <DDL client={client ?? ""} dataDefinition={props.match.params.id} />
                      }} />
                </Stack>
              </Stack>
            </Route>
            
          </Switch>
        </Container>
      </div>
    </Router>
    </ThemeProvider>
  );
}

export default withAuthenticator(App);
