import { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';



import {  
    Stack, Text,  Separator,
} from '@fluentui/react';


type IState = {
}
  
  
interface IProps extends WrappedComponentProps {
}

class AddToAgregate extends Component<IProps,IState> {
    render() 
    {
        return (
            <Stack grow verticalFill >
                <Text variant='xLarge'>Add To Aggregate</Text>
                Add data to the output.<br/>
                Format of the command:<br/>
                Start your path with $ which represents the root and pt each expression n its own line.<br/>
                <Text variant='large'>Examples:</Text>
                <ul>
                    <li>$=$<br/><i>Set the entire output to the input</i></li>
                    <li>$.message="Error in webcall"<br/><i>Set the output node message to string</i></li>
                </ul>
                <Separator />
                <Text variant='large'>Output  Node</Text>
                All these rules will be executed with the same output data object as was recieved by the rule with no changes.
            </Stack>
        )
    }
}
export default injectIntl(AddToAgregate);
