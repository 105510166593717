import React, { Component } from 'react';
import { injectIntl,  WrappedComponentProps  } from 'react-intl';

import {  IConnection } from './IDeus';

import {  Get, Post,  } from './authorisation/AMAPI';

import {  
    Stack, 
    PrimaryButton, DefaultButton, 
    TextField, Text,
    Modal,  
    Label, List, Dropdown, IDropdownOption, Toggle, 
} from '@fluentui/react';
import { Separator } from '@fluentui/react';

type IState = {
    showModal: boolean,

    connections: IConnection[],
    connection: string,

    databases: string[],
    database: string,

    tables: string[],
    table: string,

    name: string,

    genGET: boolean,
    genPOST: boolean,
    genPUT: boolean,
    genDELETE: boolean,

}
  
  
interface IProps extends WrappedComponentProps {
  client: string,
  folder: string,
  onDDLSelected?: any
  onDismiss?: any
  isOpen: boolean
}


class RESTSQLGenerate extends Component<IProps,IState> {
    constructor(props: any) {
        super(props);

        this.state = {
            connections: [],
            connection: "",
            databases: [],
            database: "",
            tables: [],
            table: "",
            showModal: false,
            name: "",

            genGET: true,
            genPOST: true,
            genPUT: true,
            genDELETE: true,

        };

        this.refresh = this.refresh.bind(this);
        this.onConnectionChange = this.onConnectionChange.bind(this);
        this.generate = this.generate.bind(this);
        this.getTables = this.getTables.bind(this);
        this.getDatabases = this.getDatabases.bind(this);
        this.authenticatedrefresh = this.authenticatedrefresh.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidUpdate(prevProps: IProps) {
      if (prevProps.client !== this.props.client) {
        this.authenticatedrefresh();
      }
     
    }

    onConnectionChange(ev: any,option?: any)
    {
        this.setState({connection: option.key, databases: ["Loading..."], tables: ["Loading..."]},()=>{
          this.getDatabases();
        }) ;
    }
    
    componentDidMount()
    {
      this.refresh();
    }


    handleChange(event: any)
    {
      const target = event.target;
      const value : string = target.type === 'checkbox' ? target.checked : target.value;
      
      this.setState({name: value});
    }

    refresh()
    {
        this.authenticatedrefresh();
    }

  
    private authenticatedrefresh()
    {
      if(this.props.client == null)  return;
      if(this.props.client === "")  return;

     

      Get("connection/forclient/"+this.props.client)
      .then(response => {
        console.log("loadConnections");
        console.log(response.data);
            var s: IConnection[] = response.data;
            this.setState({ 
              connections: s, 
            });
        })
      .catch(function (error) {
        console.log(error);
      });
    }

    getTables()
    {
        Get("ruleset/GetTables/"+this.state.connection+"/"+this.state.database)
          .then(response => {
            console.log(response.data);
                this.setState({ 
                  tables: response.data
                });
            })
          .catch(function (error) {
            console.log(error);
          });
    }

    generate()
    {
      var opts = {
        client: this.props.client,
        folder: this.props.folder,
        connection: this.state.connection,
        database: this.state.database,
        table: this.state.table,
        name: this.state.name,
        genGET: this.state.genGET,
        genPOST: this.state.genPOST,
        genPUT: this.state.genPUT,
        genDELETE: this.state.genDELETE,
      }

      Post("ruleset/generate",opts)
          .then(response => {
            console.log(response.data);
            this.props.onDismiss();
            })
          .catch(function (error) {
            console.log(error);
          });
    }

    getDatabases()
    {
        Get("ruleset/GetDatabases/"+this.state.connection)
          .then(response => {
            console.log(response.data);
                this.setState({ 
                  databases: response.data,
                  tables: ["Select database"]
                });
            })
          .catch(function (error) {
            console.log(error);
          });
    }
    
   

      render() 
    {

    


        return (
          <Modal
          isOpen={this.props.isOpen}
          onDismiss={()=>{this.props.onDismiss();}}
          isBlocking={false}
        >
          <Stack style={{margin: 10}}>
                <Text variant="xLarge">Generate REST Rulesets</Text>
                <Separator/>
                <Label>Connection</Label>
                    <Dropdown
                            placeholder="Select..."
                            options={this.state.connections.filter( c => c.type==="database").map(c => {   
                                let rObj: IDropdownOption = { key: c.id, text: c.name}
                                return rObj
                             })}
                            selectedKey={this.state.connection}
                            onChange={this.onConnectionChange}
                        />
                <Stack>
                  <Stack horizontal grow verticalFill>
                    <Stack grow={2}>
                      <Label>Select a database</Label>
                      <List
                            items={this.state.databases}
                            onRenderCell={(item?: string, index?: number, isScrolling?: boolean): JSX.Element => {
                                return (
                                  <div data-is-focusable onClick={()=>{
                                    this.setState({ database: item ?? "", tables: ["Loading..."]},()=>{
                                      this.getTables();
                                    });
                                  }}>
                                    <div >
                                      {item}
                                    </div>
                                  </div>
                                );
                              }
                            }
                            style={{ width: '100%', border: '1px solid #eaeaea', minHeight:200, maxHeight: 200, minWidth:300 }}
                      />
                    </Stack>
                    <Separator vertical />
                    <Stack grow={3} style={{paddingTop: 5}} >
                      <Label>Select a table</Label>
                      <List
                            items={this.state.tables}
                            onRenderCell={(item?: string, index?: number, isScrolling?: boolean): JSX.Element => {
                                return (
                                  <div  data-is-focusable onClick={()=>{
                                    this.setState({ table: item ?? "", name: item ?? ""});
                                  }}>
                                    <div >
                                      {item}
                                    </div>
                                  </div>
                                );
                              }
                            }
                            style={{ width: '100%', border: '1px solid #eaeaea', minHeight:200, maxHeight: 200, minWidth:300 }}
                      />
                    </Stack>
                    <Separator vertical />
                    <Stack grow={3} style={{paddingTop: 5}} >
                      <Label>Options</Label>
                      <Toggle label="GET" inlineLabel checked={this.state.genGET} onChange={()=>{this.setState({genGET: !this.state.genGET})}}/>
                      <Toggle label="POST" inlineLabel checked={this.state.genPOST} onChange={()=>{this.setState({genPOST: !this.state.genPOST})}}/>
                      <Toggle label="PUT" inlineLabel checked={this.state.genPUT} onChange={()=>{this.setState({genPUT: !this.state.genPUT})}}/>
                      <Toggle label="DELETE" inlineLabel checked={this.state.genDELETE} onChange={()=>{this.setState({genDELETE: !this.state.genDELETE})}}/>
                    </Stack>
                  </Stack>
                  <TextField label="Object name" value={this.state.name} onChange={this.handleChange} />
                </Stack>
                </Stack>
                <Separator/>
                <Stack horizontal grow style={{padding: 10}} horizontalAlign="end" tokens={{childrenGap: 10}}>
                  <PrimaryButton text="Generate" disabled={this.state.table === ""} onClick={()=>{
                    this.generate();
                    }} />
                  <DefaultButton text="Cancel" onClick={()=>{this.props.onDismiss();}} />
                </Stack>
              </Modal>
          );
    }

   
}

export default injectIntl(RESTSQLGenerate);